<template>
  <div
    class="card__form-item"
    :class="{
      'card__form-item--animate':
        (typeof dataState === 'string' &&
          dataState &&
          dataState.trim() !== '') ||
        selectSearching ||
        type === 'date' || (type === 'day' && inputValue),
      'card__form-item--error': error || manualError,
      'mb-4': type === 'countries' || type === 'multiselect',
      'mb-0': noMargin,
      'mb-4': !noMargin,
      'overflow-hidden cursor-pointer': type === 'checkbox' && noBoxes,
      'z-60': forceTop
    }"
    :noFocus="noFocus"
    @click="
      () => (type === 'checkbox' && noBoxes ? (dataState = !dataState) : '')
    "
  >
    <!-- COUNTRIES -->
    <multiselect
      v-model="dataState"
      v-if="type === 'countries'"
      class="card__card-input card__card-input--multiselect"
      :options="getCountries()"
      :groups="false"
      :searchable="true"
      :multiple="false"
      :canClear="false"
      :disabled="locked"
      :autocomplete="'off'"
      @open="changeSelect(true)"
      @close="changeSelect(false)"
      label="country"
      trackBy="country"
      :caret="caret"
      valueProp="key"
      :rules="rules"
      :style="
        dataState
          ? 'justify-content: space-between;'
          : 'padding-bottom: 1.9rem !important;'
      "
      :classes="{
        singleLabel: locked
          ? 'relative text-grey-2 text-opacity-50 font-semibold	'
          : 'relative text-grey-2 font-semibold',
        dropdown:
          'min-h-72 max-h-80 pointer-events-all pretty-scroll overflow-y-scroll absolute -left-px -right-px -bottom-1 transform translate-y-full bg-white flex flex-col rounded-2xl shadow-dropdown z-70',
        dropdownTop:
          '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
        dropdownHidden: 'hidden',
        groupLabel:
          'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold cursor-default leading-normal ',
        groupOptions: 'p-0 m-0 border-b-2 border-grey-8',
        optionSelected: 'text-grey-2 font-semibold',
        option:
          'flex text-grey-3 pointer-events-all items-center justify-start box-border text-left cursor-pointer text-sm font-normal leading-snug py-3 px-4',
        optionPointed: 'bg-grey-8',
        optionDisabled: 'opacity-50 cursor-not-allowed',
        optionSelectedPointed: 'font-semibold bg-grey-8',
        search:
          'multiselect-search w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5 rtl:pl-0 rtl:pr-3.5'
      }"
      :name="name"
    >
      <template v-slot:option="option">
        <div
          class="w-6 h-6 bg-cover bg-center rounded-lg mr-2"
          :style="
            'background-image: url(\'' +
            `/img/flags/${option.option.key.toLowerCase()}.png` +
            '\')'
          "
        ></div>
        {{ option.option.country }}</template
      >
    </multiselect>
    <!-- PHONE -->
    <div v-else-if="type === 'phone'" :class="((error || manualError) ? 'mb-8 ' : '') + 'flex'">
      <multiselect
        v-model="phoneCodeState"
        ref="phoneCodeRef"
        class="
          card__card-input card__card-input--phone-code
          pointer-events-none
        "
        :class="locked ? 'card__card-input--phone-code--disabled ' : ''"
        :options="getCountriesCell()"
        :style="'background: transparent'"
        :searchable="true"
        :multiple="false"
        :canClear="false"
        :disabled="locked"
        :autocomplete="'off'"
        label="code"
        inputType="number"
        trackBy="code"
        valueProp="code"
        :closeOnSelect="true"
        :clearOnSelect="false"
        :classes="{
          singleLabel: 'relative text-grey-2 font-semibold	',
          dropdown:
            'min-h-72 max-h-80 pointer-events-all pretty-scroll overflow-y-scroll absolute -left-px -right-px -bottom-1 transform translate-y-full z-50 bg-white flex flex-col rounded-2xl shadow-dropdown',
          dropdownTop:
            '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
          dropdownHidden: 'hidden',
          optionSelected: 'text-grey-2 font-semibold',
          option:
            'flex text-grey-3 pointer-events-all items-center justify-start box-border text-left cursor-pointer text-sm font-normal leading-snug py-3 px-4',
          optionPointed: 'bg-grey-8',
          optionSelectedPointed: 'font-semibold bg-grey-8',
          search:
            'no-error-input w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans h-full rounded pl-3.5 rtl:pl-0 rtl:pr-3.5 ' + bgColor
        }"
        @search-change="maskPhoneCode"
        :name="name + '-phone'"
        ><template v-slot:option="option" class="pointer-events-all">
          <div
            :style="
              'background-image: url(\'' +
              `/img/flags/${option.option.iso.toLowerCase()}.png` +
              '\')'
            "
            class="
              w-6
              h-6
              mr-2
              bg-center bg-cover
              rounded-lg
              pointer-events-all
            "
          ></div>
          <div class="pointer-events-all"></div>
          <span class="text-grey-2 font-semibold pointer-events-all">{{
            '+ ' + option.option.code
          }}</span>
          <span
            class="
              text-grey-2
              font-regular
              text-opacity-80
              ml-1
              pointer-events-all
            "
            >{{ option.option.country }}</span
          >
        </template>
        <template v-slot:singlelabel="value"
          ><div class="multiselect-single-label" style="top: -7px !important">
            {{ '+ ' + value.value.code }}
          </div></template
        >
      </multiselect>
      <Field
        :class="
          (locked
            ? 'card__card-input--disabled card__card-input--phone--disabled z-40 '
            : 'card__card-input card__card-input--phone z-40 ') + bgColor
        "
        ref="field"
        v-model="dataState"
        :type="'number'"
        :name="name"
        :min="min"
        :max="max"
        :disabled="locked"
        @focus="changeSelect(true)"
        @blur="changeSelect(false)"
        :minLength="minLength"
        :maxLength="maxLength"
        :exactLength="exactLength"
        :rules="rules"
        style="
          padding-left: 24px;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          margin-left: 120px;
        "
      />
    </div>
    <!-- MULTISELECT -->
    <multiselect
      v-model="dataState"
      v-else-if="type === 'multiselect'"
      class="card__card-input card__card-input--multiselect"
      :options="options"
      :searchable="searchable"
      :multiple="multiple"
      :autocomplete="autocomplete ? 'on' : 'off'"
      :canClear="false"
      :canDeselect="false"
      :closeOnSelect="true"
      @open="changeSelect(true)"
      @close="changeSelect(false)"
      :label="multiselectLabel"
      :trackBy="multiselectTrackBy"
      :valueProp="multiselectValueProp"
      :name="name"
      :disabled="locked"
      :style="
        dataState
          ? 'background:' +
            styleBgColor +
            '; justify-content: space-between;  min-heigth: 61px'
          : 'background:' + styleBgColor + '; min-heigth: 61px'
      "
      :classes="{
        singleLabel: 'relative text-grey-2 font-semibold',
        dropdown:
          'max-h-80 pointer-events-all pretty-scroll overflow-y-auto absolute -left-px -right-px -bottom-1 transform translate-y-full z-60 bg-white flex flex-col rounded-2xl shadow-dropdown',
        dropdownTop:
          '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
        dropdownHidden: 'hidden',
        options: 'flex flex-col p-0 m-0 list-none z-70',
        optionSelected: 'text-grey-2 font-semibold',
        option:
          'flex text-grey-3 pointer-events-all items-center justify-start box-border text-left cursor-pointer text-sm font-normal leading-snug py-3 px-4',
        optionPointed: 'bg-grey-8',
        optionSelectedPointed: 'font-semibold bg-grey-8',
        search:
          'multiselect-search no-error-input w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5 rtl:pl-0 rtl:pr-3.5'
      }"
    >
    </multiselect>
    <!-- CHECKBOXES -->
    <div v-else-if="type === 'checkbox'" class="flex">
      <!--div
        v-if="!noBoxes && greenBg"
        class="
          h-40
          bg-green-5
          absolute
          left-1
          -top-5
          z-20
          rounded-lg
          transform
          -rotate-12
          transition-all
          origin-left
          duration-500
          rounded-5xl
        "
        :class="dataState ? 'w-full pr-32' : 'w-0'"
      /-->
      <div
        v-if="!noBoxes"
        class="my-auto relative z-50 mr-2"
        :class="{ 'cursor-pointer': !noBoxes }"
        @click="
          () => {
            if (!noBoxes) dataState = !dataState
          }
        "
      >
        <div
          class="w-6 h-6 rounded-lg transition duration-75 relative"
          :class="!dataState ? 'bg-grey-6' : 'bg-green-3'"
        >
          <div
            class="
              absolute
              left-1/2
              top-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
            v-if="dataState"
          >
            <img src="@/assets/svg/check_white.svg" alt="" class="w-3" />
          </div>
        </div>
      </div>
      <div
        class="
          text-grey-3 text-sm text-left
          flex-grow
          col-span-11
          relative
          mr-2
          overflow-hidden
          transition-all
        "
        :class="{
          'selected text-opacity-100 bg-green-5': bold && dataState,
          'bg-grey-ghost hover:bg-green-5 hover:shadow-lg p-2 rounded-lg mb-4': noBoxes
        }"
        style="min-width: 36px"
      >
        <div
          v-if="noBoxes"
          class="
            h-40
            bg-green-5
            absolute
            left-0
            top-0
            z-20
            rounded-lg
            transform
            -rotate-45
            transition-all
            origin-left
            duration-700
            rounded-5xl
          "
          :class="dataState ? 'w-full pr-24' : 'w-0'"
        ></div>
        <div class="relative z-50 no-highlight">
          <slot name="label">
            <div v-html="label" @click="() => {if(!noBoxes) dataState = !dataState}"/>
          </slot>
        </div>
      </div>
    </div>
    <!-- CHECKBOX GROUP -->
    <div v-else-if="type === 'checkbox-group'">
      <template v-if="dataState">
        <div v-for="(option, key, index) in options" v-bind:key="option"
             class="
                flex
                bg-white
                p-4
                cursor-pointer
              "
             :class="{
              'rounded-b-3xl' : index == Object.keys(options).length - 1,
              'rounded-t-3xl' : index == 0,
              'border-b border-grey-7': index !== Object.keys(option).length - 1,
              'opacity-40 cursor-not-allowed': dataState['none'] && key !== 'none'
             }"
             @click="
              () => {
                if (!dataState['none'] || key === 'none') dataState[key] = !dataState[key]
              }
            "
        >
          <div
            v-if="!noBoxes"
            class="my-auto relative z-50 mr-2"
            :class="{ 'cursor-pointer': !noBoxes }"

          >
            <div
              class="w-6 h-6 rounded-lg transition duration-75 relative"
              :class="!dataState[key] ? 'bg-grey-6' : 'bg-green-3'"
            >
              <div
                class="
                  absolute
                  left-1/2
                  top-1/2
                  transform
                  -translate-x-1/2 -translate-y-1/2
                "
                v-if="dataState[key]"
              >
                <img src="@/assets/svg/check_white.svg" alt="" class="w-3" />
              </div>
            </div>
          </div>
          <div
            class="
              text-grey-3 text-sm text-left
              flex-grow
              col-span-11
              relative
              mr-2
              overflow-hidden
              transition-all
            "
            :class="{
              'selected text-opacity-100': dataState[key],
            }"
            style="min-width: 36px"
          >
            <div
              v-if="noBoxes"
              class="
                h-40
                bg-green-5
                absolute
                left-0
                top-0
                z-20
                rounded-lg
                transform
                -rotate-45
                transition-all
                origin-left
                duration-700
                rounded-5xl
              "
              :class="dataState[key] ? 'w-full pr-24' : 'w-0'"
            ></div>
            <div class="relative z-50 no-highlight">
              <slot name="label">
                <div v-html="option" />
              </slot>
            </div>
          </div>
      </div>
      </template>
    </div>
    <!-- TOGGLE SWITCH -->
    <div
      class="
        relative
        inline-block
        w-10
        mr-2
        align-middle
        select-none
        transition
        duration-100
        ease-in
      "
      v-else-if="type === 'toggle'"
    >
      <input
        type="checkbox"
        name="toggle"
        v-model="dataState"
        :id="name"
        class="
          card__card-input__toggle
          absolute
          block
          w-5
          h-5
          top-0.5
          left-0.5
          rounded-full
          bg-white
          appearance-none
          cursor-pointer
          transition
          duration-100
          ease-in
        "
      />
      <label
        :for="name"
        class="
          toggle-label
          block
          overflow-hidden
          h-6
          rounded-full
          bg-gray-300
          cursor-pointer
        "
      ></label>
    </div>
    <!-- RADIO -->
    <div
      v-else-if="type === 'radio'"
      :class="{
        'rounded-2xl bg-white overflow-hidden': !separatedRadio,
        'grid grid-cols-2 lg:grid-cols-3 gap-4': grided
      }"
    >
      <div
        v-for="(label, value, index) of options"
        :key="value"
        class="relative overflow-hidden"
        :class="[
          dataState == value ? 'bg-green-5' : bgColor,
          {
            'green-shadow': dataState == value && separatedRadio,
            'rounded-2xl mb-3': separatedRadio,
            'hover:bg-green-5 hover:shadow-lg': dataState !== value
          }
        ]"
      >
        <button
          :disabled="locked"
          class="flex flex-col w-full"
          :class="{ 'cursor-not-allowed': locked }"
          name="radio-input"
          @click="
            () => {
              if (!locked) dataState = value
            }
          "
          @blur="blured"
        >
          <!-- <div
            class="
              h-96
              bg-green-5
              absolute
              left-6
              -top-5
              z-20
              rounded-lg
              transform
              -rotate-12
              transition-all
              origin-left
              duration-500
              rounded-5xl
            "
            :class="dataState === value ? 'w-full pr-60' : 'w-0'"
            v-if="radioGreenBg"
          ></div> -->

          <div
            class="flex items-start w-full px-4 my-4 z-50"
            :class="{ 'cursor-not-allowed': locked }"
          >
            <div
              v-if="dataState === value && !noBoxes"
              class="
                mr-2
                rounded-full
                bg-green-3
                w-4
                h-4
                flex
                items-center
                justify-center
                mt-px
              "
              style="flex-shrink: 0"
            >
              <div class="rounded-full bg-white w-2 h-2" />
            </div>
            <img
              v-else-if="!separatedRadio && !noBoxes"
              src="@/assets/svg/radio_off.svg"
              alt=""
              class="mr-2 mt-px"
            />
            <div
              v-else-if="!noBoxes"
              class="mr-2 rounded-full bg-grey-6 w-4 h-4"
            />

            <p
              :class="
                dataState === value ? 'text-grey-2 font-bold' : 'text-grey-3'
              "
              class="text-left text-sm transition-all"
            >
              <slot name="label">{{ label }}</slot>
            </p>
          </div>
          <div
            v-if="
              !separatedRadio &&
              index + 1 !== Object.keys(options).length &&
              !noLines
            "
            class="w-full h-px bg-grey-7"
          />
        </button>
      </div>
    </div>
    <!-- DROPDOWN -->
    <div v-else-if="type === 'dropdown'">
      <div
        class="
          w-full
          flex
          justify-between
          items-center
          bg-white
          rounded-2xl
          cursor-pointer
          p-4
          mb-1
        "
        @click="() => (dropdown = !dropdown)"
      >
        <p
          class="text-grey-2"
          :class="
            dropdown || selected
              ? 'font-bold text-opacity-80 text-sm'
              : 'text-13 text-opacity-50'
          "
        >
          {{ label }}
        </p>
        <img
          v-if="dropdown"
          src="@/assets/svg/arrows/down_grey_arrow.svg"
          alt="down"
          class="w-4 h-4 transform rotate-180"
        />
        <img
          v-else
          src="@/assets/svg/arrows/down_grey_arrow.svg"
          alt="down"
          class="w-4 h-4"
        />
      </div>
      <div v-if="dropdown && multiSelect" class="relative">
        <div class="absolute bg-white rounded-2xl py-4 z-40 w-full shadow-xl">
          <div
            class="flex items-center mb-6 px-4"
            v-for="(data, key) of dataState"
            :key="key"
          >
            <img
              v-if="!dataState[key]"
              class="cursor-pointer"
              src="@/assets/svg/checkbox_off.svg"
              alt="checkbox off"
              @click="() => (dataState[key] = true)"
            />
            <img
              v-else
              class="cursor-pointer"
              src="@/assets/svg/checkbox_on.svg"
              alt="checkbox on"
              @click="() => (dataState[key] = false)"
            />
            <p
              class="text-grey-2 text-sm text-opacity-60 text-left ml-2"
              :class="
                bold && dataState[key] ? 'font-bold text-opacity-100' : ''
              "
            >
              {{ optionsLabels[key] }}
            </p>
          </div>
          <div class="w-full bg-grey-2 bg-opacity-10 h-px" />
          <div
            class="mt-4 orange-link text-xs text-right px-4 cursor-pointer"
            @click="clearDropdown"
          >
            {{ $t('wording.clear') }}
          </div>
        </div>
      </div>
      <div v-else-if="dropdown && !multiSelect" class="relative">
        <div class="absolute bg-white rounded-2xl py-4 z-40 w-full shadow-xl">
          <div
            class="flex items-center mb-6 px-4"
            v-for="(data, key) of options"
            :key="key"
          >
            <img
              v-if="dataState === key"
              src="@/assets/svg/radio_on.svg"
              alt=""
              class="mr-2"
            />
            <img
              v-else
              src="@/assets/svg/radio_off.svg"
              alt=""
              class="mr-2"
              @click="() => (dataState = key)"
            />
            <p
              class="text-grey-2 text-sm text-opacity-50"
              :class="
                bold && dataState == key ? 'font-bold text-opacity-100' : ''
              "
            >
              {{ data }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- DAY -->
    <div v-else-if="type === 'day'">
      <div @click="() => (showDayModal = true)">
        <form>
          <input
            autocomplete="off"
            class="card__card-input bg-white"
            :class="{ 'card__card-input--disabled': locked}"
            ref="field"
            :name="name"
            :rules="required ? isRequired : ''"
            :disabled="locked"
            :value="inputValue"
          />
        </form>
      </div>
      <div
        class="
          z-40
          bg-grey-2 bg-opacity-50
          h-screen
          w-full
          fixed
          top-0
          left-0
          lg:relative lg:h-auto lg:bg-opacity-0
        "
        v-if="showDayModal"
      >
        <div
          class="
            flex flex-col
            w-full
            h-screen
            lg:h-auto
            justify-end
            p-2
            lg:px-0
          "
        >
          <div class="bg-white rounded-2xl w-full p-4 pt-6 pb-16 lg:pb-4">
            <div class="flex items-center justify-between">
              <p class="text-left text-grey-2 text-opacity-80 font-semibold">
                {{ modalTitle }}
              </p>
              <img
                src="@/assets/svg/grey_cross.svg"
                alt="cross"
                @click="() => (showDayModal = false)"
              />
            </div>
            <p class="text-left text-grey-2 text-opacity-60 text-xs mt-4 mb-9">
              {{ modalText }}
            </p>
            <div
              class="flex justify-around my-4"
              :key="value"
              v-for="value in [1, 8, 15, 22, 29]"
            >
              <div
                class="
                  flex
                  justify-center
                  items-center
                  w-9
                  h-9
                  p-4
                  text-sm
                  rounded-xl
                "
                :class="selectedDay === value + day - 1 ? 'bg-orange-3' : ''"
                v-for="day in 7"
                :key="day"
              >
                <div
                  class="cursor-pointer text-grey-2 text-sm text-opacity-60"
                  :class="
                    selectedDay === value + day - 1
                      ? 'font-bold text-opacity-100'
                      : ''
                  "
                  v-if="value + day - 1 <= 30"
                  @click="() => (selectedDay = value + day - 1)"
                >
                  {{
                    // eslint-disable-next-line vue/no-parsing-error
                    value + day - 1 < 10
                      ? '0' + (value + day - 1)
                      : value + day - 1
                  }}
                </div>
              </div>
            </div>
            <GpButton
              @click="
                () => {
                  showDayModal = false
                  dataState = '' + selectedDay
                  inputValue = `Day ${selectedDay} of every month`
                }
              "
              :disabled="!selectedDay"
              desktopWidth="w-full"
              class="mt-8"
            >
              {{ $t('wording.confirm') }}
            </GpButton>
          </div>
        </div>
      </div>
    </div>
    <!-- DATE -->
    <div v-else-if="type === 'date'">

      <DatePicker
        v-model="dataState"
        mode="date"
        color="orange"
        ref="calendar"
        timezone="UTC"
        :max-date="to ? new Date(to) : to"
        :min-date="from ? new Date(from) : from"
        :popover="locked ? { visibility: false } : { visibility: 'click' }"
        :locale="locale"
        is-required
        :masks="{
          input: 'DD/MM/YYYY'
        }"
        :disabled="locked"
        @popoverWillShow="datePopup = true"
        @popoverWillHide="datePopup = false"
        :class="{ 'z-0': !datePopup }"
        @update:model-value="handleDate"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="card__card-input"
            :class="(locked ? 'card__card-input--disabled ' : ' ')"
            :value="inputValue"
            v-on="inputEvents"
            v-maska="'##/##/####'"
            @focus="showDatePlaceholder = false"
            @blur="dataState ? '' : (showDatePlaceholder = true)"
            :rules="rules"
            :disabled="locked"
          />
        </template>
      </DatePicker>
    </div>
    <!-- BIRTHDATE -->
    <Field
      v-else-if="type === 'birthdate'"
      class="card__card-input"
      :class="
        (locked ? 'card__card-input--disabled ' : ' ') +
        inputClasses +
        ' ' +
        bgColor
      "
      ref="field"
      v-model="dataState"
      :type="typeDup ?? type"
      :name="name"
      :rules="rules ? rules + '|birthdate' : 'birthdate'"
      :disabled="locked"
      v-maska="'## / ## / ####'"
      @keydown="validateInput"
      :noFocus="noFocus"
      @focus="setBirthdatePlaceholder"
      :autocomplete="'off'"
      novalidate
    />
    <div v-else-if="type === 'textarea'">
      <Field
        as="textarea"
        class="card__card-input card__card-input--textarea"
        :class="
          (locked ? 'card__card-input--disabled ' : ' ') +
          inputClasses +
          ' ' +
          bgColor
        "
        ref="field"
        v-model="dataState"
        :name="name"
        :rules="rules"
        :disabled="locked"
        :autocomplete="'off'"
        :min="min"
        :max="max"
        rows="4"
        :minLength="minLength"
        :maxLength="maxLength"
        novalidate
      />
      <div
        class="
          text-grey-giftcard-title
          ml-auto
          text-xs
          absolute
          right-0
          -bottom-6
        "
      >
        {{ dataState.length }} / {{ maxLength }}
      </div>
    </div>
    <div v-else-if="type === 'click-options'">
      <div class="steps__investor mb-6" @click="next(option.key)" v-for="(option) in options" v-bind:key="option">
        <div class="flex items-center">
          <div class="steps__investor__icon hidden md:block bg-gradient-green">
            <img :src="option.icon" alt="" />
          </div>
          <div>
            <div class="opacity-50 text-11 block md:hidden text-grey-2">
              {{ option.title_mobile }}
            </div>
            <div class="font-bold md:mb-0 mb-4 text-grey-2 opacity-80">
              {{ option.title }}
            </div>
            <div class="text-sm md:text-13 md:opacity-50 hidden md:block">
              {{ option.description }}
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <img src="@/assets/svg/chev-right-2.svg" alt="" />
        </div>
      </div>
    </div>
    <!-- OTHER -->
    <Field
      v-else
      class="card__card-input"
      :class="
        (locked ? (!label ? 'card__card-input--disabled-nolabel ' : 'card__card-input--disabled ') : ' ') +
        inputClasses +
        ' ' +
        bgColor
      "
      ref="field"
      v-model="dataState"
      :type="typeDup ?? type"
      :name="name"
      :min="min"
      :max="max"
      :minLength="minLength"
      :maxLength="maxLength"
      :exactLength="exactLength"
      :pattern="pattern"
      :rules="rules"
      :disabled="locked"
      :inputmode="type === 'dollar' ? 'decimal' : null"
      @keydown="validateInput"
      @keyup="type === 'dollar' ? formatInput(false) : ''"
      @blur="type === 'dollar' ? formatInput(true) : ''"
      :noFocus="noFocus"
      :autocomplete="autocomplete ? 'on' : 'off'"
      @keypress="
        zipCode
          ? maskZip($event)
          : noNumbersOrSymbols
          ? isNumber($event) && isSymbol($event)
          : addressSymbols
          ? isAddress($event)
          : ''
      "
      novalidate
    />

    <div class="absolute right-7 bottom-5">
      <img
        v-if="locked && type !== 'radio'"
        src="@/assets/svg/padlock.svg"
        alt="padlock"
      />
    </div>
    <div
      class="
        absolute
        text-sm
        md:text-base
        top-7
        left-6
        md:top-7 md:left-6
        pointer-events-none
        opacity-50
      "
      v-if="type === 'date' && showDatePlaceholder && !dataState"
    >
      dd/mm/yyyy
    </div>
    <div
      class="
        absolute
        text-xs
        font-bold
        md:text-base
        top-6
        right-4
        md:top-6 md:right-6
        pointer-events-none
        opacity-20
      "
      v-if="optional"
    >
      {{ $t('wording.optional') }}
    </div>
    <div
      class="
        absolute
        text-sm
        md:text-base
        top-8
        left-3
        md:top-5
        pointer-events-none
        opacity-50
      "
      v-if="type === 'dollar' && !locked"
    >
      $
    </div>
    <label
      v-if="type !== 'checkbox' && type !== 'dropdown'"
      :for="name"
      class="card__card-input__label"
      :class="{
        'card__card-input__label--phone z-40': type === 'phone'
      }"
      >{{ label }}</label
    >
    <transition name="fade">
      <div
        class="absolute right-8 top-7 cursor-pointer"
        v-if="type === 'password' && dataState !== ''"
      >
        <div @click="switchVisibility">
          <img
            src="@/assets/ico/eye.svg"
            alt="password eye"
            v-if="typeDup === 'password'"
          />
          <img
            class="relative -top-0.5"
            src="@/assets/ico/eye-slash.svg"
            alt="password eye slash"
            v-else
          />
        </div>
      </div>
    </transition>
    <div
      v-if="error || manualError"
      class="card__card-input__error"
      :style="type === 'phone' ? 'position: absolute; left: 0; bottom: -20px; margin-left: 144px !important' : ''"
    >
      {{ error ?? manualError }}
    </div>
    <div class="text-left text-grey-3 text-xs mt-4" v-if="signupPassword">
      <div class="transition mb-1 flex" :class="{ 'text-grey-2': has8Chars }">
        <span class="font-bold mr-4" v-if="!has8Chars">&#x2022;</span>
        <img src="@/assets/svg/check_circle.svg" alt="" class="mr-2" v-else />
        <span>{{ $t('errors.has_8_chars') }}</span>
      </div>
      <div
        class="transition mb-1 flex"
        :class="{ 'text-grey-2': hasUpperLower }"
      >
        <span class="font-bold mr-4" v-if="!hasUpperLower">&#x2022;</span>
        <img src="@/assets/svg/check_circle.svg" alt="" class="mr-2" v-else />
        <span>{{ $t('errors.has_upper_lower') }}</span>
      </div>
      <div class="transition flex" :class="{ 'text-grey-2': hasNumberSymbol }">
        <span class="font-bold mr-4" v-if="!hasNumberSymbol">&#x2022;</span>
        <img src="@/assets/svg/check_circle.svg" alt="" class="mr-2" v-else />
        <span>{{ $t('errors.has_number_symbol') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, onMounted, watch } from 'vue'
import { Field } from 'vee-validate'
import useRules from '@/assets/js/rules'
import useCountries from '@/assets/js/countries'
import useCountriesCell from '@/assets/js/countries_cell'
import { DatePicker } from 'v-calendar'
import Multiselect from '@vueform/multiselect'
import { pt } from 'date-fns/locale'
import { subYears } from 'date-fns'
import { useVModel } from 'vue-composable'
import { useI18n } from 'vue-i18n'
import GpButton from '@/components/general/GpButton.vue'
import { useStore } from 'vuex'

export default {
  emits: ['update:data', 'update:phoneCode', 'blured', 'next'],
  components: {
    Field,
    Multiselect,
    DatePicker,
    GpButton
  },
  props: {
    triedToSubmit: {
      type: Boolean,
      default: false
    },
    noFocus: {
      type: Boolean,
      default: false
    },
    restrictCountries: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    greenBg: {
      type: Boolean,
      default: false
    },
    radioGreenBg: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    signupPassword: {
      type: Boolean,
      default: false
    },
    zipCode: {
      type: Boolean,
      default: false
    },
    country: String,
    searchable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    noNumbersOrSymbols: {
      type: Boolean,
      default: false
    },
    addressSymbols: {
      type: Boolean,
      default: false
    },
    options: [Array, Object],
    optional: {
      type: Boolean,
      default: false
    },
    optionsLabels: {
      type: Object,
      default: () => {}
    },
    toDate: [Date, Boolean],
    fromDate: [Date, Boolean],
    inputClasses: String,
    bgColor: {
      type: String,
      default: 'bg-white'
    },
    styleBgColor: {
      type: String,
      default: 'white'
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    forceTop: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    caret: {
      type: Boolean,
      default: false
    },
    separatedRadio: {
      type: Boolean,
      default: false
    },
    noLines: {
      type: Boolean,
      default: false
    },
    noBoxes: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    grided: {
      type: Boolean,
      default: false
    },
    rules: String,
    name: String,
    label: String,
    error: String,
    multiselectLabel: String,
    multiselectTrackBy: String,
    multiselectValueProp: String,
    min: String,
    pattern: String,
    max: String,
    minLength: String,
    maxLength: String,
    exactLength: String,
    phoneCode: String,
    periodicity: String,
    modalTitle: String,
    modalText: String,
    data: [String, Date, Boolean, Object, Number],
    suffix: String
  },
  setup(props, { emit }) {
    let field = ref(null)
    const datePopup = ref(false)
    const dataState = useVModel(props, 'data')
    const phoneCodeState = useVModel(props, 'phoneCode')
    let selectSearching = ref(false)
    let dataState_code = ref('+1')
    const store = useStore()
    const calendar = ref(null)
    const i18n = useI18n({ useScope: 'global' })
    const phoneCodeRef = ref(null)
    const { locale } = useI18n({ useScope: 'global' })
    const dropdown = ref(false)
    const manualError = ref(null)
    // const blockedCountries = computed(() => {
    //   return store.state.general.blockedCountries
    // })

    const to = computed(() => {
      return props.toDate !== null ? props.toDate : subYears(new Date(), 18)
    })
    let showDatePlaceholder = ref(true)
    const from = computed(() => {
      return props.fromDate !== null
        ? props.fromDate
        : subYears(new Date(), 120)
    })
    const { isRequired } = useRules()
    const { getCountries } = useCountries()
    const { getCountriesCell } = useCountriesCell()
    const typeDup = ref(null)

    const showDayModal = ref(false)
    const selectedDay = ref(null)
    const inputValue = ref('')

    const validField = computed(() => {
      let valid = false

      if (props.required) {
        valid = isRequired(dataState.value)
      }
      if (props.signupPassword) {
        valid = has8Chars.value && hasUpperLower.value && hasNumberSymbol.value
      }
      if (props.restrictCountries) {
        valid = dataState.value && !countryRestricted(dataState.value)
      }

      return valid
    })

    const next = (value) =>{
      emit('next', value)
    }

    watch(
      () => props.data,
      (first) => {
        if (props.type === 'date') {
          showDatePlaceholder.value = first ? false : true
          if(props.data){
            inputValue.value = props.data.toLocaleDateString('en-GB')
          }
        }
        if(props.type === 'day'){
            dataState.value = props.data
            selectDay()
        }
        if (
          props.type === 'date' ||
          props.type === 'multiselect' ||
          props.type === 'countries'
        ) {
          if (props.required && !props.data) {
            manualError.value = i18n.t('errors.required')
          } else {
            // if (props.type === 'countries' && props.restrictCountries) {
            //   if (countryRestricted(props.data)) {
            //     manualError.value = i18n.t('errors.banned_country')
            //   } else manualError.value = null
            manualError.value = null
          }
        }
      }
    )

    // function countryRestricted(country) {
    //   return blockedCountries.value.includes(country)
    // }

    function blured(evt) {
      emit('blured', evt)
    }

    function isNumber(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        return true
      } else {
        evt.preventDefault()
      }
    }

    function isSymbol(evt) {
      evt = evt ? evt : window.event
      if (
        !/(?=.*[!\\"#$%&/()=?\\_\\-\\@\\^\\+\\'\\»\\«\\,\\.\\>\\<\\£\\§\\$])/.test(
          evt.key
        )
      ) {
        return true
      } else {
        evt.preventDefault()
      }
    }

    function isAddress(evt) {
      evt = evt ? evt : window.event
      if (
        !/(?=.*[!\\"#$%&()=?\\_\\@\\^\\+\\'\\»\\«\\.\\>\\<\\£\\§\\$])/.test(
          evt.key
        )
      ) {
        return true
      } else {
        evt.preventDefault()
      }
    }

    function maskZip(event) {
      if (props.zipCode) {
        if (props.country === 'PT') {
          if (event.key && !event.key.match(/[\d]/)) {
            event.preventDefault()
          } else {
            if (dataState.value.length >= 4 && event.key !== '-') {
              dataState.value =
                dataState.value.substr(0, 4) + '-' + dataState.value.substr(5) // in case people type "-"
            }
          }
        } else {
          if (event.key && !event.key.match(/[\da-zA-Z-]/)) {
            event.preventDefault()
          }
        }
      }
    }

    function isCharNumber(char) {
      if (typeof char !== 'string') {
        return false
      }

      if (char.trim() === '') {
        return false
      }

      return !isNaN(char)
    }
    
    const handleDate = (data) => {
      dataState.value = data;
      emit('update:data', data);
    };

    function setBirthdatePlaceholder(e) {
      let missing = 'DD / MM / YYYY'.substring(dataState.value.length)
      let currentLen = dataState.value.length
      dataState.value = dataState.value + missing
      setTimeout(function () {
        e.target.setSelectionRange(currentLen, currentLen)
      }, 0)
    }

    function formatInput(blur) {
      if (dataState.value) {
        let auxValue = dataState.value.toString()
        let indx = auxValue.indexOf('.')
        auxValue = auxValue.replace(/\,/g, '')
        auxValue = auxValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        if (indx > -1 && blur) {
          let len = auxValue.length - indx - 1
          if (len < 2) {
            for (let i = len; i < 2; i++) {
              auxValue = auxValue + '0'
            }
          }
        }
        if (indx === -1 && blur) auxValue = auxValue + '.00'
        dataState.value = auxValue
      }
    }

    function validateInput(evt) {
      if (props.type === 'dollar') {
        const valid = ['Backspace', 'Enter', 'Tab', '.', ',']
        if (!isCharNumber(evt.key) && !valid.includes(evt.key) && evt) {
          evt.preventDefault()
        }
        if (dataState.value) {
          let auxValue = dataState.value.toString()
          let indx = auxValue.indexOf('.')
          if (evt.key === '.' || evt.key === ',') {
            if (indx === -1) {
              auxValue = auxValue + '.'
            }
            evt.preventDefault()
          } else if (
            indx > -1 &&
            auxValue.length - indx - 1 === 2 &&
            evt.key !== 'Backspace' &&
            evt.key !== 'Enter' &&
            evt.key !== 'Tab'
          ) {
            evt.preventDefault()
          }
          dataState.value = auxValue
        }
      }
    }

    // const validEmail = computed(() => {
    //   return /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/.test(dataState.value)
    // })

    const has8Chars = computed(() => {
      return dataState.value.length > 7
    })

    const hasUpperLower = computed(() => {
      return /(?=.*[a-z])(?=.*[A-Z])/.test(dataState.value)
    })

    const hasNumberSymbol = computed(() => {
      return /(?=.*\d)(?=.*[!\\"#$%&/()=?\\_\\-\\@\\^\\+\\'\\»\\«\\,\\.\\>\\<\\£\\§\\$\\`\\´\\~\\*])/.test(
        dataState.value
      )
    })

    function maskPhoneCode(val) {
      const el = val[val.length - 1]
      if (!parseInt(el) && el !== '+') {
        phoneCodeRef.value.search = phoneCodeRef.value.search.slice(0, -1)
      }
    }

    const today = computed(() => {
      var today = new Date()
      var dd = today.getDate()
      var mm = today.getMonth() + 1 //January is 0!
      var yyyy = today.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      today = yyyy + '-' + mm + '-' + dd
      return today
    })

    onMounted(() => {
      if (props.type === 'password') {
        typeDup.value = props.type
      }
      // if (props.type === 'countries') {
      //   store.dispatch('setBlockedCountries')
      // }
      if (props.type === 'checkbox-group') {
        dataState.value = {}
      }

    })

    function changeSelect(value) {
      selectSearching.value = value
    }

    function switchVisibility() {
      typeDup.value = typeDup.value === 'password' ? 'text' : 'password'
    }

    function selectDay() {
      showDayModal.value = false
      inputValue.value = `Day ${dataState.value} of every month`
    }

    const clearDropdown = () => {
      for (let d in dataState.value) {
        dataState.value[d] = false
      }
    }

    return {
      field,
      changeSelect,
      switchVisibility,
      hasUpperLower,
      hasNumberSymbol,
      selectSearching,
      has8Chars,
      showDatePlaceholder,
      to,
      maskZip,
      pt,
      phoneCodeState,
      today,
      isRequired,
      getCountries,
      dataState,
      getCountriesCell,
      calendar,
      validField,
      dataState_code,
      datePopup,
      typeDup,
      showDayModal,
      selectedDay,
      inputValue,
      selectDay,
      validateInput,
      locale,
      formatInput,
      isNumber,
      phoneCodeRef,
      blured,
      setBirthdatePlaceholder,
      isSymbol,
      maskPhoneCode,
      isAddress,
      from,
      dropdown,
      manualError,
      clearDropdown,
      next,
      handleDate
      // blockedCountries
    }
  }
}
</script>

<style scoped>
.selected {
  text-shadow: 0 0 0.65px #4f5963, 0 0 0.65px #4f5963;
}
.multiselect-search {
  background: red;
}
</style>
