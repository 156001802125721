<template>
  <div
    class="
      flex
      items-center
      justify-between
      text-left
      p-4
      rounded-3xl
      my-8
      lg:my-6
      bg-white
      cursor-pointer
    "
  >
    <div v-if="wallet" class="flex items-center">
      <div class="flex items-center">
        <img
          src="@/assets/svg/settings/wallets.svg"
          :alt="icon"
          class="mr-4 lg:hidden"
        />
        <img
          src="@/assets/svg/settings/wallets_border.svg"
          :alt="icon"
          class="mr-4 hidden lg:block"
        />
      </div>

      <div>
        <div
          class="
            ml-3
            text-sm text-base text-grey-2
            lg:text-grey-3
            font-semibold
          "
        >
          {{ walletName }}
        </div>
      </div>
    </div>
    <img src="@/assets/svg/arrows/right_grey_arrow.svg" alt="" class="mr-4" />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    wallet: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const i18n = useI18n({ useScope: 'global' })

    const walletName = computed(() => {
      return props.wallet.name
        ? props.wallet.name
        : i18n.t('settings.wallets.investment_wallet')
    })

    return { walletName }
  }
}
</script>

<style lang="postcss" scoped></style>
