<template>
  <div v-if="kycConditionsResult && kycConditionsResult.showPopup" class="animate-pulse bg-white rounded-2xl w-full flex flex-col justify-between p-4 mt-4">
    <div class="flex justify-between">
      <div class="flex">
        <div class="flex items-start w-8 mr-1">
          <img class="w-full" :src="'/img/popup/exclamation_grey' + '.svg'" alt="strategy" />
        </div>
        <div>
          <p class="text-left text-grey-2 text-sm font-semibold mb-2">
            {{ $t('home.kyc_popup.'+ kycConditionsResult.localesPath + '.title') }}
          </p>
          <p class="text-left text-grey-2 text-sm text-opacity-50">
            <slot name="content">{{ $t('home.kyc_popup.'+ kycConditionsResult.localesPath + '.content') }}</slot>
          </p>
        </div>
      </div>
    </div>
    <router-link :to="'/dashboard/kyc' " class="orange-link text-right text-13">{{
      $t('home.kyc_popup.'+ kycConditionsResult.localesPath + '.btn')
    }}</router-link>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    const kycsConditions = ref(null)

    onMounted(async () => {
      await store.dispatch('getKycs')
      kycsConditions.value = await store.getters.getKycsConditions()
      await store.dispatch('setKycsConditions', kycsConditions.value)
      await store.dispatch('setUnderlineSettings', {
        description: kycsConditions.value.description,
        color: kycsConditions.value.color
      })
    })

    const kycConditionsResult = computed(() => {
      return store.state.kycs ? store.state.kycs.conditions : null
    })

    return { kycsConditions, kycConditionsResult }
  }
}
</script>
