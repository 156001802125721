<template>
  <div class="page flex">
    <div
      v-if="strategy"
      class="container flex-grow flex flex-col justify-between lg:pt-20"
      :class="changed ? 'hidden lg:flex' : ''"
    >
      <div>
        <Back :to="-1" :title="$t('strategies.edit.menu.plan_owner')" />
        <GeneralInput
          v-model:data="planOwner"
          class="mt-8"
          type="radio"
          name="owner"
          :options="{
            own: $t('strategies.setup.why_saving.beneficiary_me'),
            other: $t('strategies.setup.why_saving.beneficiary_other')
          }"
        />
        <div v-if="planOwner === 'other'">
          <GeneralInput
            class="mt-8"
            v-model:data="beneficiaryName"
            type="name"
            name="name"
            :label="$t('strategies.setup.other_beneficiary.name_placeholder')"
          />
          <GeneralInput
            class="mt-6"
            ref="dateInput"
            :required="true"
            v-model:data="beneficiaryBirthdate"
            type="date"
            name="date"
            :toDate="new Date()"
            :fromDate="false"
            :label="
              $t('strategies.setup.other_beneficiary.birthday_placeholder')
            "
          />
          <div v-if="allFilled" class="pt-2">
            <p
              v-if="isUnderAged"
              class="text-sm text-grey-2 text-opacity-60 text-left"
              v-html="
                $t('strategies.setup.other_beneficiary.under_age_text', {
                  firstName: beneficiaryName.split(' ')[0]
                })
              "
            />
            <p
              v-else
              class="text-sm text-grey-2 text-opacity-60 text-left"
              v-html="
                $t('strategies.setup.other_beneficiary.over_age_text', {
                  firstName: beneficiaryName.split(' ')[0]
                })
              "
            />
          </div>
        </div>
      </div>
      <GpButton :disabled="!anyFilled" @click="confirm" class="ml-auto">
        {{ $t('wording.change') }}
      </GpButton>
    </div>
    <ChangesConfirmation
      v-if="strategy"
      :class="changed ? '' : 'hidden'"
      @discard="discard"
      :displayData="displayData"
      :data="confirmationData"
      :text="$t('strategies.edit.direct_debit.text')"
      vertical
      icon="owner"
      :strategyId="strategy.id"
    />
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import ChangesConfirmation from '@/components/strategies/ChangesConfirmation.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    Back,
    GpButton,
    GeneralInput,
    ChangesConfirmation
  },
  setup() {
    const i18n = useI18n({ useScope: 'global' })
    const beneficiaryName = ref(null)
    const beneficiaryBirthdate = ref(null)
    const confirmationData = ref({})
    const displayData = ref([])
    const changed = ref(false)
    const store = useStore()
    const router = useRouter()
    const { dateFormatting } = useGlobalHelpers()

    const strategy = computed(() => {
      return store.state.strategies.strategy
    })

    if (!strategy.value) {
      router.go(-1)
    }

    const planOwner = ref(null)

    onMounted(() => {
      if(store.state.strategies && store.state.strategies.strategy) {
        planOwner.value = 'own'
        if(store.state.strategies.strategy.beneficiary_name) {
          planOwner.value = 'other'
          beneficiaryName.value = store.state.strategies.strategy.beneficiary_name
          beneficiaryBirthdate.value = store.state.strategies.strategy.beneficiary_birthdate ?? null
        }
      }
    })

    const anyFilled = computed(() => {
      return (
        planOwner.value === 'own' ||
        (beneficiaryName.value && beneficiaryBirthdate.value)
      )
    })

    const isUnderAged = computed(() => {
      const now = new Date()
      let year = beneficiaryBirthdate.value.getFullYear()
      let month = beneficiaryBirthdate.value.getMonth()
      let day = beneficiaryBirthdate.value.getDate()
      let legalAge = new Date(year + 18, month, day)
      return legalAge > now
    })

    const confirm = () => {
      if (planOwner.value === 'own') {
        confirmationData.value = {
          beneficiary_name: null,
          beneficiary_birthdate: null
        }
        displayData.value = [
          {
            label: i18n.t('strategies.edit.menu.plan_owner'),
            value: 'You'
          }
        ]
      } else if (planOwner.value === 'other') {
        confirmationData.value = {
          beneficiary_name: beneficiaryName.value,
          beneficiary_birthdate: dateFormatting(beneficiaryBirthdate.value)
        }
        displayData.value = [
          {
            label: i18n.t('strategies.edit.menu.plan_owner'),
            value: beneficiaryName.value
          },
          {
            label: i18n.t(
              'strategies.setup.other_beneficiary.birthday_placeholder'
            ),
            value: dateFormatting(beneficiaryBirthdate.value)
          }
        ]
      }
      changed.value = true
    }

    const discard = () => {
      changed.value = false
      confirmationData.value = {}
      displayData.value = []
      beneficiaryBirthdate.value =
        beneficiaryName.value =
        planOwner.value =
          null
    }

    return {
      planOwner,
      beneficiaryName,
      beneficiaryBirthdate,
      anyFilled,
      isUnderAged,
      changed,
      discard,
      confirm,
      confirmationData,
      displayData,
      strategy
    }
  }
}
</script>

<style lang="scss" scoped></style>
