<template>
  <div>

    <div class="lg:absolute lg:top-8 lg:right-16 social-wrapper z-20">

      <div class="lg:flex text-center  lg:text-left lg:ml-24 social">
        <div class="mr-28 hidden lg:block">
          {{ $t('404.social') }}
        </div>
        <div class="flex pt-8 lg:pt-0 lg:mx-0 lg:justify-center justify-end mr-6 lg:mr-0">
          <a target="_blank" href="https://www.facebook.com/goparity/" class="mr-6 cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/fb.svg" alt="" class="w-6"></a>
          <a target="_blank"  href="https://t.me/goparity" class="mr-6 cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/tg.svg" alt="" class="w-6"></a>
          <a target="_blank"  href="https://www.instagram.com/goparity/" class="mr-6 cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/ig.svg" alt="" class="w-6"></a>
          <a target="_blank" href="https://www.linkedin.com/company/goparity" class=" cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/lin.svg" alt="" class="w-6"></a>
        </div>
      </div>
    </div>
    <a href="https://goparity.ca" >
      <img
        src="@/assets/img/logo/goparity.svg"
        alt=""
        class="absolute  pt-0 -mt-4 lg:mt-0 w-28  md:pt-8 ml-6 lg:ml-0 sm:w-40 z-40 lg:left-16"
      />
    </a>
    <div class="page  px-0 lg:px-24 container overflow-y-hidden relative ">
      <div class="relative m-24 h-44 mt-24 lg:mt-40">
        <div class="ground-bg absolute w-80 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 transform"></div>
        <Island class="absolute left-1/2 transform -translate-x-1/2 bottom-0"/>
      </div>

      <div class="text-center mx-6 lg:mx-0">
            <h1>
              {{ $t('maintenance.title') }}
            </h1>
            <p class="">
              {{ $t('maintenance.subtitle_1') }}

            </p>
            <p class="pb-20 lg:pb-10" v-html="$t('maintenance.subtitle_2')">
            </p>
            <div>
              <div class="notification-title">{{$t('maintenance.notification.title')}}</div>
              <Form @submit="send" class="relative" v-slot="{ errors }">
                <div class="relative lg:max-w-1/2 mx-auto">
                  <Field class="notification-input" type="text" v-model="email" :placeholder="$t('maintenance.notification.placeholder')" rules="required|email" name="email"/>
                  <button  class="button orange-button mr-6 ml-6 lg:ml-0 mb-4 lg:mb-0 lg:absolute lg:right-0 lg:top-0" :disabled="errors.email || success || loading">
                    {{ $t('maintenance.notification.button') }}
                  </button>
                  <transition name="fade">
                    <div   class="card__card-input__error" v-if="errors.email">{{errors.email}}</div>
                  </transition>
                  <transition name="fade">
                    <div   class="card__card-input__error text-green" style="color: rgba(52, 200, 103,1) !important;" v-if="success">{{$t('maintenance.notification.success')}}</div>
                  </transition>
                  <transition name="fade">
                    <div   class="card__card-input__error text-green" v-if="sendError">{{$t('maintenance.notification.error')}}</div>
                  </transition>
                </div>
              </Form>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import Island from '@/components/maintenance/Island'
import { Form, Field } from 'vee-validate'
import { computed, ref } from "vue";
import axios from 'axios'
import { useStore } from "vuex";
export default {
  components:{
    Island,
    Form,
    Field
  },
 setup(){
    const email = ref(null)
    const store = useStore()
    const success = ref(false)
    const sendError = ref(false)
   const loading = computed(()=>{
     return store.state.general.loading
   })

   async function send(){
      store.dispatch('setLoading', true)
      await axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/subscribe', {email: email.value, maintenance: true}).then(()=>{
        store.dispatch('setLoading', false)
        success.value = true
      }).catch(()=>{
        store.dispatch('setLoading', false)
        sendError.value = true
      })

    }

   return{send, email, success, sendError, loading}
 }
}
</script>
<style scoped>

h1{
  font-family: 'UXUM_GROT';
  color:#154b64 ;
  font-size:32px;
  padding-bottom:24px;
  font-weight: 500;
}
p{
 color: #688695;
  font-size: 24px;
}

.notification-title{
  color: #154b64;
  font-weight: 600;
  padding-bottom:1rem
}

.notification-input::placeholder{
  font-weight: 500;
  line-height: 1.5;
  color: #737d87;
}

.notification-input:focus{
  outline: none;
}

.notification-input{
  padding: 16px 31px 16px 32px;
  border-radius: 30px;
  box-shadow: 0 6px 30px 0 rgba(21, 75, 100, 0.08);
  background-color: #fff;
  width: 100%;
}

.ground-bg {
  border-radius: 50%;
  filter: blur(20px);
  background-image: linear-gradient(202deg, #0b4a89 45%, #3279c0 55%, #f28a10 30%, #f4bc38 10%);
  opacity: 0.15;
  padding-top: 20rem;
}

.button{
  cursor: pointer;
  color: #154b64;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 18px 86px;
  border-radius: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-right: 0px;
}

.button:disabled{
  cursor: not-allowed;
  opacity: 0.5;
}

.orange-button{
  background: #ffc84b;
}

.orange-button:hover:not([disabled]){
  background: #fdd374;
  color: #376478;
}

.blue-button{
  border: solid 3px #154b64;
}

.blue-button:hover{
  border: solid 3px #376478;
  color: #376478;
}

.social{
  color: #154b64;
}

@media (max-width: 1024px) {
  .button{
    width: 100%;
    padding: 18px;
  }

  .orange-button{
    margin-top:24px;
    margin-left:0px;
  }

  h1{
    font-size:32px;
    line-height: normal;
    text-align: center;
    padding-bottom:16px;

  }

  p{
    color:#688695;
    font-size: 20px;
    text-align: center;
  }

  .ground-bg {
    filter: blur(20px);
    padding-top: 20rem;
  }

  .social-wrapper{
    width:100%;
  }

  .notification-input{
    text-align: center;
  }
}
</style>
