import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";

const questions:PageForm =
  {
    title: i18n.global.t('ca_legislation.onboarding.sidebar_title'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.onboarding.questions.title_questions"),
        fields: [
            {
              key: "name",
              title: i18n.global.t("ca_legislation.onboarding.questions.name_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.name_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "surname",
              title: i18n.global.t("ca_legislation.onboarding.questions.surname_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.surname_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "phone",
              title: i18n.global.t("ca_legislation.onboarding.questions.phone_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.phone_text"),
              type: Types.NUMBER,
              rules: 'required|exactLength:10'
            },
            {
              key: "sin",
              title: i18n.global.t("ca_legislation.onboarding.questions.sin_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.sin_text"),
              info: i18n.global.t("home.sin"),
              type: Types.NUMBER,
              rules: 'required|exactLength:9'
            }
        ],
        next: true,
        back: true
      }
    ]
  }

export default questions
