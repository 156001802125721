import axios from 'axios'

export default {
  state: {
    error: null,
    redirect: null
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error
    },
    SET_REDIRECT(state, redirect) {
      state.redirect = redirect
    }
  },
  actions: {
    setError({ commit }, error) {
      commit('SET_ERROR', error)
    },
    // eslint-disable-next-line no-unused-vars
    warnSlack({ commit }, data){

      axios.post(process.env.VUE_APP_SLACK_HOOK, JSON.stringify({ "text": JSON.stringify(data) }), {
        withCredentials: false,
        transformRequest: [(data, headers) => {
          delete headers.common["X-Authorization"]
          return data
        }]
      })

    },
    forceRedirect({ commit }, dest) {
      commit('SET_REDIRECT', dest)
    },
    clearError({ commit }) {
      commit('SET_ERROR', null)
      commit('SET_REDIRECT', null)
    }
  },
  getters: {}
}
