<template>
  <div class="page flex">
    <div
      v-if="project && (offer ? remainingTime : true)"
      class="container flex-grow flex flex-col lg:pt-20"
    >
      <Back :to="-1" :title="$t('simulation.title')" />
      <p class="text-left text-sm text-grey-2 text-opacity-80 mb-8 lg:mb-12">
        {{ $t('simulation.text') }}
      </p>
      <p class="text-left text-sm text-grey-2 font-bold mb-4">
        {{ $t('simulation.input_label') }}
      </p>
      <Form
        class="form mb-12"
        ref="form"
        v-slot="{ errors, meta }"
        @submit="simulate"
      >
        <GeneralInput
          noMargin
          v-model:data="amount"
          type="dollar"
          name="amount"
          :label="$t('simulation.input_placeholder') + (offer ? ' ($)' : '')"
          :locked="offer"
          :error="errors.amount"
          rules="required|minValue:500"
          @keyup.enter="
            () => {
              if (!meta.valid) simulated = null
            }
          "
        />
      </Form>
      <div v-if="simulated">
        <!-- Total assets -->
        <p class="text-grey-2 font-bold text-sm text-left mb-6 lg:mb-4">
          {{ $t('simulation.summary.title') }}
        </p>
        <p class="text-grey-2 text-left text-opacity-80 font-bold text-xl">
          ${{ dollarFormatting(simulated.paymentsSum) }}
        </p>
        <p class="text-grey-2 text-left text-opacity-50 text-sm mb-6">
          {{ $t('simulation.summary.total_assets') }}
          {{ monthFormat(duration) }}
        </p>
        <div class="lg:bg-white rounded-2xl mb-6 lg:p-6 lg:mb-4">
          <!-- Gross interest -->
          <div class="flex items-center justify-between">
            <p class="text-grey-2 text-opacity-80 text-sm">
              {{ $t('simulation.summary.gross_interest') }}
            </p>
            <p class="text-grey-2 text-opacity-80 text-sm font-bold">
              ${{ dollarFormatting(simulated.interestGrossSum) }}
            </p>
          </div>
          <div class="w-full h-px gp-background mb-3 lg:my-4" />
          <!-- Payments during grace -->
          <div v-if="simulated.gracePayments > 0">
            <div class="flex items-center justify-between">
              <p class="text-grey-2 text-opacity-80 text-sm">
                {{ $t('simulation.summary.pay_during_grace') }}
              </p>
              <p
                class="
                  text-grey-2 text-sm text-opacity-80
                  lg:text-opacity-100
                  font-bold
                  lg:font-semibold
                "
              >
                ${{ dollarFormatting(simulated.payDuringGrace) }}
              </p>
            </div>
          </div>
          <div
            v-if="simulated.gracePayments > 0"
            class="w-full h-px gp-background mb-3 lg:my-4"
          />
          <!-- Payments after grace or single payment or periodic payment -->
          <div class="flex items-center justify-between">
            <p
              v-if="simulated.gracePayments > 0"
              class="text-grey-2 text-opacity-80 text-sm"
            >
              {{ $t('simulation.summary.pay_after_grace') }}
            </p>
            <p
              v-else-if="simulated.totalPayments === 1"
              class="text-grey-2 text-opacity-80 text-sm"
            >
              {{ $t('simulation.summary.single_payment') }}
            </p>
            <p v-else class="text-grey-2 text-opacity-80 text-sm">
              {{ $t('simulation.summary.periodic_payment') }}
            </p>
            <p
              class="
                text-grey-2 text-sm text-opacity-80
                lg:text-opacity-100
                font-bold
                lg:font-semibold
              "
            >
              ${{ dollarFormatting(simulated.payAfterGrace) }}
            </p>
          </div>
          <div class="w-full h-px gp-background mb-3 lg:my-4" />
          <!-- First payment -->
          <div class="flex items-center justify-between">
            <p
              v-if="simulated.totalPayments === 1"
              class="text-grey-2 text-opacity-80 text-sm"
            >
              {{ $t('simulation.summary.first_last_payment') }}
            </p>
            <p v-else class="text-grey-2 text-opacity-80 text-sm">
              {{ $t('simulation.summary.first_payment') }}
            </p>
            <p
              class="
                text-grey-2 text-sm text-opacity-80
                lg:text-opacity-100
                font-bold
                lg:font-semibold
              "
            >
              {{ $t('wording.after') }}
              {{ monthFormat(12 / project.funding_rate.periodicity) }}
            </p>
          </div>
          <!-- Last payment -->
          <div
            v-if="simulated.totalPayments !== 1"
            class="w-full h-px gp-background mb-3 lg:my-4"
          />
          <div
            v-if="simulated.totalPayments !== 1"
            class="flex items-center justify-between"
          >
            <p class="text-grey-2 text-opacity-80 text-sm">
              {{ $t('simulation.summary.last_payment') }}
            </p>
            <p
              class="
                text-grey-2 text-sm text-opacity-80
                lg:text-opacity-100
                font-bold
                lg:font-semibold
              "
            >
              {{ $t('wording.after') }}
              {{ monthFormat(duration) }}
            </p>
          </div>
        </div>
        <!-- No fee -->
        <div
          class="
            rounded-2xl
            p-4
            font-semibold
            bg-green-7
            text-sm text-grey-2 text-opacity-80
            mb-8
            lg:mb-12
          "
        >
          {{ $t('simulation.summary.no_fees') }}
        </div>
        <!-- Invest button -->
        <GpButton
          v-if="
            !offer &&
            (project.state === 'campaign' || project.state === 'reserved')
          "
          class="mb-8 lg:flex mt-12 mx-auto"
          @click="invest"
        >
          {{ $t('wording.invest') }}
        </GpButton>
        <router-link v-if="offer" :to="'/mkt-purchase/' + slug">
          <GpButton class="mb-8 mx-auto" desktopWidth="w-1/2">
            {{ $t('wording.purchase') }}
          </GpButton>
        </router-link>
        <!-- Payment plan -->
        <p class="font-bold text-sm text-grey-2 text-left mb-2 lg:mb-4">
          {{ $t('simulation.payment_plan.title') }}
        </p>
        <p class="text-sm text-grey-2 text-opacity-80 text-left mb-6 lg:mb-8">
          {{
            $t(
              'simulation.payment_plan.' +
                (simulated.totalPayments === 1
                  ? 'text_single_pay'
                  : simulated.gracePayments > 0
                  ? 'text_w_grace'
                  : 'text_wo_grace'),
              {
                during_grace: dollarFormatting(simulated.payDuringGrace),
                after_grace: dollarFormatting(simulated.payAfterGrace),
                periodicity: periodicityText,
                duration: monthFormat(duration)
              },
              dollarFormatting(simulated.payDuringGrace),
              dollarFormatting(simulated.payAfterGrace),
              periodicityText,
              monthFormat(duration)
            )
          }}
        </p>
        <!-- Timeline -->
        <PaymentPlanStep
          :description="$t('simulation.payment_plan.investment')"
          :date="'today'"
          :amount="`$${amount}`"
          class="mb-8 lg:mb-6"
        />
        <PaymentPlanStep
          v-if="simulated.gracePayments > 0"
          :description="$t('simulation.payment_plan.first_payment_interest')"
          :date="
            monthFormat(12 / project.funding_rate.periodicity) +
            ' ' +
            $t('simulation.payment_plan.after_funded')
          "
          :amount="`+ $${dollarFormatting(simulated.payDuringGrace)}`"
          isGreen
          class="mb-8 lg:mb-6"
        />
        <PaymentPlanStep
          v-if="simulated.totalPayments !== 1"
          :description="$t('simulation.payment_plan.first_payment_capital')"
          :date="
            monthFormat(project.funding_rate.grace_period_months) +
            ' ' +
            $t('simulation.payment_plan.after_funded')
          "
          :amount="`+ $${dollarFormatting(simulated.payAfterGrace)}`"
          isGreen
          class="mb-8 lg:mb-6"
        />
        <PaymentPlanStep
          :description="
            simulated.totalPayments !== 1
              ? $t('simulation.payment_plan.last_payment_capital')
              : $t('simulation.payment_plan.only_payment')
          "
          :date="
            monthFormat(duration) +
            ' ' +
            $t('simulation.payment_plan.after_funded')
          "
          :amount="`+ $${dollarFormatting(simulated.payAfterGrace)}`"
          isGreen
        />
        <!-- Download -->
        <div class="button-padding-bottom">
          <div
            class="
              bg-white
              rounded-2xl
              flex
              items-center
              justify-between
              p-4
              mt-6
              lg:mt-12
            "
          >
            <p class="font-semibold text-sm text-grey-2 text-opacity-80">
              {{ $t('simulation.payment_plan.title') }}
            </p>
            <a class="flex orange-link" :href="simulated.xlsDownloadLink">
              <img
                src="@/assets/svg/arrows/down_orange_arrow.svg"
                alt="down"
                class="mr-2"
              />
              <p>{{ $t('wording.download') }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref, watch } from 'vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import { Form } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import GeneralInput from '@/components/general/Input.vue'
import PaymentPlanStep from '@/components/simulation/PaymentPlanStep.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
export default {
  components: {
    Back,
    GpButton,
    GeneralInput,
    Form,
    PaymentPlanStep
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const form = ref(null)
    const amount = ref(null)
    const simulated = ref(null)
    const i18n = useI18n({ useScope: 'global' })
    const {
      calculateInstalmentGross,
      calculateInterestGross,
      dollarFormatting,
      monthFormat,
      dollarToNumberFormatting,
      slugIt
    } = useGlobalHelpers()

    onMounted(async () => {
      if (!store.state.projects.project) {
        await store.dispatch('getProject', route.params.id.split('-').pop())
      }

      if (route.query.offer) {
        if (!store.state.offers.offer)
          await store.dispatch('fetchOffer', route.query.offer)
        amount.value = dollarFormatting(parseInt(route.query.amount))
        simulate(true)
      }
    })

    watch(
      () => amount.value,
      () => {
        simulate()
      }
    )

    const project = computed(() => {
      return store.state.projects.project
    })

    const remainingTime = computed(() => {
      if (store.state.offers.offer)
        return (
          store.state.offers.offer.remaining_payments_count *
          (12 / store.state.projects.project.funding_rate.periodicity)
        )
      else return null
    })

    const simulation = (
      investmentValue,
      durationMonths,
      gracePeriodMonths,
      periodicity,
      interestRate
    ) => {
      const investment = dollarToNumberFormatting(investmentValue)
      const monthsPerPayment = 12 / periodicity
      const totalPayments = Math.round(durationMonths / monthsPerPayment)
      const gracePayments = Math.round(gracePeriodMonths / monthsPerPayment)
      const instalmentGross = calculateInstalmentGross(
        investment,
        interestRate,
        totalPayments - gracePayments,
        periodicity
      )
      const paymentPlan = []
      const yearlyPaymentPlan = []

      // Initiate variables for payment iteration
      let capitalOutstanding = investment
      let interestGross,
        interestGrossSum,
        payDuringGrace,
        capitalReimbursement,
        instalmentNet
      interestGrossSum = 0

      for (let i = 1; i <= totalPayments; i++) {
        // Get interest and capital reimbursement
        interestGross = calculateInterestGross(
          capitalOutstanding,
          interestRate,
          periodicity
        )
        interestGrossSum += interestGross

        // Grace period payment
        if (i <= gracePayments) {
          capitalReimbursement = 0
          payDuringGrace = interestGross
          instalmentNet = interestGross 
        } else {
          capitalReimbursement = instalmentGross - interestGross
          instalmentNet = instalmentGross
        }

        // Payment plan
        paymentPlan.push({
          n: i,
          capital_outstanding: Math.round(capitalOutstanding),
          capital_reimbursement: Math.round(capitalReimbursement),
          interest: Math.round(interestGross),
          instalment_net: instalmentNet
        })

        // Capital outstanding after this payment (for next iteration)
        capitalOutstanding -= capitalReimbursement

        // Yearly payment plan
        if (i === 1) {
          // Year 0 ==> First payment - only show investment
          yearlyPaymentPlan.push({
            capital_outstanding: investment
          })
        } else if ((i * monthsPerPayment) % 12 === 0 && i !== totalPayments) {
          // Year N ==> Last payment of the year except the last payment
          yearlyPaymentPlan.push({
            capital_outstanding: capitalOutstanding,
            available_investment: investment - capitalOutstanding,
            interest: interestGrossSum
          })
        } else if (i === totalPayments) {
          // Year LAST - only show capitalOutstanding
          yearlyPaymentPlan.push({
            capital_outstanding: 0,
            available_investment: investment,
            interest: interestGrossSum
          })
        }
      }

      return {
        investmentValue: investment,
        paymentsSum: investment + interestGrossSum,
        interestGrossSum: interestGrossSum,
        payDuringGrace: payDuringGrace,
        payAfterGrace: instalmentGross,
        totalPayments: totalPayments,
        gracePayments: gracePayments,
        monthsToFirstAfterGracePayment: gracePeriodMonths + monthsPerPayment,
        monthsPerPayment: monthsPerPayment,
        paymentPlan: paymentPlan,
        yearlyPaymentPlan: yearlyPaymentPlan,
        xlsDownloadLink:
          process.env.VUE_APP_SERVER_BASE_URL +
          'investment_simulation_xls?amount=' +
          investment +
          '&term=' +
          durationMonths +
          '&interest=' +
          interestRate +
          '&grace_period=' +
          gracePeriodMonths +
          '&periodicity=' +
          periodicity
      }
    }

    const simulate = async (skipValidation) => {
      const validation = await form.value.validate()
      if (validation.valid || skipValidation) {
        simulated.value = simulation(
          amount.value,
          remainingTime.value ?? project.value.funding_rate.duration_months,
          remainingTime.value
            ? remainingTime.value >
              project.value.funding_rate.duration_months -
                project.value.funding_rate.grace_period_months
              ? project.value.funding_rate.grace_period_months -
                (project.value.funding_rate.duration_months -
                  remainingTime.value)
              : 0
            : project.value.funding_rate.grace_period_months,
          project.value.funding_rate.periodicity,
          project.value.funding_rate.interest_investor
        )
      }
    }

    const periodicityText = computed(() => {
      return i18n.t(
        'simulation.payment_plan.periodicity.' +
          project.value.funding_rate.periodicity
      )
    })

    const invest = () => {
      router.push({
        name: 'invest-wallet',
        params: { project: project.value.id}
      })
    }

    const slug = computed(() => {
      return project.value.name
        ? slugIt(project.value.name) + '-' + route.query.offer
        : route.query.offer
    })

    const duration = computed(() => {
      return remainingTime.value ?? project.value.funding_rate.duration_months
    })

    return {
      project,
      form,
      simulate,
      simulation,
      amount,
      simulated,
      dollarFormatting,
      monthFormat,
      periodicityText,
      invest,
      offer: route.query.amount,
      slug,
      remainingTime,
      duration
    }
  }
}
</script>

<style lang="scss" scoped></style>
