export enum Types {
    COUNTRIES = 'countries',
    PHONE = 'phone',
    MULTISELECT = 'multiselect',
    CHECKBOX = 'checkbox',
    CHECKBOX_GROUP = 'checkbox-group',
    TOGGLE = 'toggle',
    RADIO = 'radio',
    DROPDOWN = 'dropdown',
    DAY = 'day',
    DATE = 'date',
    BIRTHDATE = 'birthdate',
    NUMBER = 'number',
    MONEY = 'dollar',
    STRING = 'string',
    CLICK_OPTIONS = 'click-options',
  }