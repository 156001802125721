<template>
  <div class="page flex" id="transactions">
    <div v-if="allLoaded" class="container flex-grow flex flex-col lg:pt-20">
      <div class="w-full flex items-center justify-between mb-6">
        <Back :to="'/dashboard'" title="" />
        <div
          class="lg:hidden bg-white rounded-xl p-3 w-max"
          :class="downloadable ? 'cursor-pointer' : 'cursor-not-allowed'"
          @click="
            () => {
              if (downloadable) exportURL()
            }
          "
        >
          <img
            src="@/assets/svg/transactions/download.svg"
            alt="download"
            class="w-4 h-4"
          />
        </div>
      </div>
      <p
        class="
          hidden
          lg:block
          w-full
          text-left text-grey-2
          font-bold
          text-xl
          mb-4
        "
      >
        {{ $t('transactions.title') }}
      </p>
      <span class="text-5xl text-grey-2 text-left mb-2 lg:mb-1">
        <span>${{ balance.split('.')[0] }}</span>
        <span>.{{ balance.split('.')[1] ?? '00' }}</span>
      </span>
      <p class="text-left text-13 text-grey-2 text-opacity-50 mb-8">
        {{ $t('transactions.wallet_balance') }}
      </p>
      <div class="flex w-full lg:mb-8 justify-between">
        <TransactionFilters
          @setFilters="filter"
          :active="activeFilters"
          @opened="() => (showScroll = false)"
          :downloadable="downloadable"
          @export="exportURL"
        />
      </div>
      <PendingTransactions />
      <infinite-scroll
        v-if="showScroll"
        @infinite-scroll="infiniteHandler"
        :noResult="endScroll"
        :message="''"
      >
        <div v-for="(value, key) of transactions" :key="key">
          <p class="text-left text-13 text-grey-2 mb-4 text-opacity-50">
            {{ $t(`wording.months.${key.split('-')[1]}`) }}
            {{ key.split('-')[0] }}
          </p>
          <div class="bg-white lg:bg-transparent rounded-2xl py-4 lg:p-0 mb-8">
            <TransactionsListElement
              v-for="(transaction, index) of value"
              :key="transaction.id"
              :transaction="transaction"
              :isLast="index + 1 === value.length"
            />
          </div>
        </div>
        <div v-if="transactions.length === 0">
          <p class="text-grey-2 text-opacity-80 font-bold text-left">
            {{ $t('transactions.empty') }}
          </p>
          <p class="text-sm text-grey-2 text-opacity-80 text-left mb-5">
            {{ $t('transactions.empty_helper') }}
          </p>
          <p @click="clearFilters" class="orange-link text-xs text-left">
            {{ $t('transactions.empty_action') }}
          </p>
        </div>
      </infinite-scroll>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import Back from '@/components/general/Back.vue'
import TransactionFilters from '@/components/transactions/TransactionFilters.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import TransactionsListElement from '@/components/transactions/TransactionListElement.vue'
import { useI18n } from 'vue-i18n'
import PendingTransactions from '@/components/transactions/Pending.vue'
import InfiniteScroll from 'infinite-loading-vue3'
import { useRoute, useRouter } from "vue-router";
import axios from 'axios'
import { event } from "vue-gtag";
export default {
  components: {
    TransactionsListElement,
    InfiniteScroll,
    Back,
    TransactionFilters,
    PendingTransactions
  },
  setup() {
    const { dollarFormatting } = useGlobalHelpers()
    const store = useStore()
    const wallet = ref(null)
    const endScroll = ref(false)
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()
    const activeFilters = ref(0)
    const showScroll = ref(true)
    const allLoaded = ref(false)
    const filteredWallet = computed(()=>{
      let wlt = null
      if(store.state.transactions.filters){
        wlt = store.state.wallets.wallet
        if(!wlt && store.state.wallets.promoterWallets)
          wlt = store.state.wallets.promoterWallets.find((el)=>{return el.id == store.state.transactions.filters.wallet})
      }
      return wlt
    })
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const downloadable = ref(true)

    onMounted(async () => {
      if (route.query) {
        switch (route.query.type) {
          case 'topup': {
            if (route.query.transactionId) {
              const transaction = await store.dispatch(
                'fetchVpTransaction',
                route.query.transactionId
              )
              if (transaction.state === 'confirmed') {
                event('topup-confirmed')

                const popup = {
                  title: i18n.t('topup.success.title'),
                  align: 'center',
                  side: false,
                  iconBg: 'bg-green-5',
                  icon: '/img/done_step.svg',
                  content: i18n.t('topup.success.description'),
                  primaryButton: i18n.t('topup.success.button')
                }
                store.dispatch('setPopup', popup)
              } else if (transaction.state === 'failed') {
                const popup = {
                  title: i18n.t('topup.failed.title'),
                  align: 'center',
                  side: false,

                  content: i18n.t('topup.failed.description'),
                  primaryButton: i18n.t('topup.failed.button')
                }
                store.dispatch('setPopup', popup)
              }
            }
          }
        }
      }
      store.dispatch('setLoading', true)
      if (wallet.value) {
        await store.dispatch('fetchWallet', wallet)
      } else {
        await store.dispatch('fetchWallets')
      }
      await store.dispatch('resetNextPage')
      await store.dispatch('fetchTransactions', {
        page: store.state.transactions.nextPage,
        total: 3,
        group: true
      })
      await store.dispatch('fetchPendingVouchers', profile.value.id)
      allLoaded.value = true
      getInitialTransactions()
      store.dispatch('setLoading', false)
    })

    const getInitialTransactions = () => {
      if (
        document.getElementById('transactions').scrollHeight <=
          screen.availHeight &&
        store.state.transactions.nextPage !== -1
      ) {
        store
          .dispatch('fetchTransactions', {
            page: store.state.transactions.nextPage,
            total: 3,
            group: true
          })
          .then(() => {
            getInitialTransactions()
          })
          .catch((e) => {
            store.dispatch('setError', e)
          })
      }
    }

    const transactions = computed(() => {
      return store.state.transactions.transactions
    })

    const balance = computed(() => {
      if(filteredWallet.value){
        return dollarFormatting(filteredWallet.value.balance)
      }
      else if (wallet.value) {
        return dollarFormatting(store.state.wallets.wallet.balance)
      } else {
        let total = 0
        for (let i = 0; i < store.state.wallets.wallets.length; i++) {
          total = total + store.state.wallets.wallets[i].balance
        }
        return dollarFormatting(total)
      }
    })

    const infiniteHandler = () => {
      if (store.state.transactions.nextPage !== -1) {
        store
          .dispatch('fetchTransactions', {
            page: store.state.transactions.nextPage,
            total: 3,
            group: true
          })
          .then(() => {
            transactions.value = store.state.transactions.transactions
          })
          .catch((e) => {
            store.dispatch('setError', e)
          })
      } else {
        endScroll.value = true
      }
    }

    const goBack = () => {
      router.go(-1)
    }

    const filter = (filter = true) => {
      if (filter) {
        activeFilters.value = store.getters.getActiveFilters()
      }
      showScroll.value = true
    }

    const clearFilters = async () => {
      await store.dispatch('clearFilters')
      await store.dispatch('resetNextPage')
      await store.dispatch('fetchTransactions', {
        page: store.state.transactions.nextPage,
        total: 3,
        group: true
      })
      filter()
    }

    const exportURL = () => {
      downloadable.value = false
      store.dispatch('setLoading', true)
      axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/transactions/xls')
        .then(() => {
          const popup = {
            title: i18n.t('transactions.export_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            content: i18n.t('transactions.export_popup.description'),
            primaryButton: i18n.t('transactions.export_popup.btn')
          }
          store.dispatch('setPopup', popup)
          downloadable.value = true
          store.dispatch('setLoading', false)
        })
        .catch((e) => {
          store.dispatch('setError', e)
          downloadable.value = true
          store.dispatch('setLoading', false)
        })
    }

    return {
      transactions,
      infiniteHandler,
      balance,
      endScroll,
      goBack,
      exportURL,
      filter,
      activeFilters,
      clearFilters,
      showScroll,
      allLoaded,
      downloadable,
      filteredWallet
    }
  }
}
</script>
