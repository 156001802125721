<template>
  <div class="page flex">
    <div class="container lg:pt-20">
      <div class="my-4 text-xl text-grey-1 font-bold text-left hidden lg:block">
        {{ $t('account_details.title') }}
      </div>
      <div class="lg:hidden">
        <Back
          :to="'/impact'"
          :title="$t('account_details.title')"
          :hide="true"
        />
      </div>
      <template v-if="profile && wallet">
        <!-- <TabsStatic
          v-if="wallets.length > 1"
          :nTabs="tabsLabels"
          v-model:current="currentTab"
        /> -->
        <Table
          :data="accountData(wallet)"
          :isWallet="true"
          class="my-6 lg:my-8"
        />
      </template>
      <template v-else>
        <div class="project-card project-card--skeleton h-16 mb-4">
          <div
            class="
              project-card__header project-card__header--skeleton
              h-full
              rounded-3xl
            "
          ></div>
        </div>
        <div class="project-card project-card--skeleton h-96">
          <div
            class="
              project-card__header project-card__header--skeleton
              h-full
              rounded-3xl
            "
          ></div>
        </div>
      </template>
      <div v-if="!isBorrower" class="mt-8 border-2 rounded-2xl grey-4 py-4 px-4">
        <div class="flex flex-col space-y-4">
          <div
            class="flex items-start"
            v-for="(element, icon_name) in $tm(
              'account_details.transfers_info'
            )"
            v-bind:key="icon_name"
          >
            <img
              :src="require('@/assets/svg/dashboard/' + icon_name + '.svg')"
              alt="project_categories"
              class="mr-4"
            />
            <p class="text-xs text-grey-3 text-left">{{ element }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--Loading v-else /-->
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Back from '@/components/general/Back.vue'
// import TabsStatic from '@/components/general/TabsStatic.vue'
import Table from '@/components/general/Table.vue'

export default {
  components: {
    Back,
    // TabsStatic,
    Table
  },
  setup() {
    const currentTab = ref(1)
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const isBorrower = computed(() => {
      if (profile.value && profile.value.sub_type === 'borrower')
        return true
      return false
    })

    // const wallets = computed(() => {
    //   return store.state.wallets.wallets
    // })

    const wallet = computed(() => {
      return store.state.wallets.wallet
    })

    const tabsLabels = computed(() => {
      return Object.values(store.getters.getWalletsLabels())
    })

    onMounted(async () => {
      if (!wallet.value) {
        await store.dispatch('fetchWallets')
      }
    })

    const accountData = () => {
      let data = {}
      data.top = [
      ]
      data.bottom = [
        {
          title: i18n.t('settings.wallets.details.address.address_label'),
          value: profile.value.address
        },
        {
          title: i18n.t('settings.wallets.details.address.zip_code_label'),
          value: profile.value.post_code
        },
        {
          title: i18n.t('settings.wallets.details.address.city_label'),
          value: profile.value.city
        },
        {
          title: i18n.t('settings.wallets.details.address.country_label'),
          value: profile.value.country
        }
      ]
      return data
    }

    return {
      profile,
      currentTab,
      isBorrower,
      wallet,
      tabsLabels,
      accountData
    }
  }
}
</script>

<style lang="scss" scoped></style>
