export default () => {
  function getCountries() {
    return [
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Afghanistan',
      //   key: 'AF',
      //   sepa: false,
      //   tax_code: '004'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Åland Islands',
      //   key: 'AX',
      //   sepa: false,
      //   tax_code: '248'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Albania',
      //   key: 'AL',
      //   sepa: false,        
      //   tax_code: '008'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Algeria',
      //   key: 'DZ',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '012'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'American Samoa',
      //   key: 'AS',
      //   sepa: false,
      //   tax_code: '016'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Andorra',
      //   key: 'AD',
      //   sepa: false,
      //   tax_code: '020'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Angola',
      //   key: 'AO',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '024'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Anguilla',
      //   key: 'AI',
      //   sepa: false,
      //   tax_code: '660'
      // },
      // {
      //   continent: 'Antarctica',
      //   country: 'Antarctica',
      //   key: 'AQ',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '010'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Antigua and Barbuda',
      //   key: 'AG',
      //   sepa: false,
      //   tax_code: '028'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Argentina',
      //   key: 'AR',
      //   sepa: false,
      //   tax_code: '032'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Armenia',
      //   key: 'AM',
      //   sepa: false,
      //   tax_code: '051'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Aruba',
      //   key: 'AW',
      //   sepa: false,
      //   tax_code: '533'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Australia',
      //   key: 'AU',
      //   sepa: false,
      //   tax_code: '036'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Austria',
      //   key: 'AT',
      //   sepa: true,
      //   tax_code: '040'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Azerbaijan',
      //   key: 'AZ',
      //   sepa: false,
      //   tax_code: '031'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Bahamas',
      //   key: 'BS',
      //   sepa: false,
      //   tax_code: '044'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Bahrain',
      //   key: 'BH',
      //   sepa: false,
      //   tax_code: '048'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Bangladesh',
      //   key: 'BD',
      //   sepa: false,
      //   tax_code: '050'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Barbados',
      //   key: 'BB',
      //   sepa: false,
      //   tax_code: '052'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Belarus',
      //   key: 'BY',
      //   sepa: false,
      //   tax_code: '112'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Belgium',
      //   key: 'BE',
      //   sepa: true,
      //   tax_code: '056'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Belize',
      //   key: 'BZ',
      //   sepa: false,
      //   tax_code: '084'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Benin',
      //   key: 'BJ',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '204'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Bermuda',
      //   key: 'BM',
      //   sepa: false,
      //   tax_code: '060'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Bhutan',
      //   key: 'BT',
      //   sepa: false,
      //   tax_code: '064'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Bolivia',
      //   key: 'BO',
      //   sepa: false,
      //   tax_code: '068'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Bosnia and Herzegovina',
      //   key: 'BA',
      //   sepa: false,
      //   tax_code: '070'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Botswana',
      //   key: 'BW',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '072'
      // },
      // {
      //   continent: 'Antarctica',
      //   country: 'Bouvet Island',
      //   key: 'BV',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '074'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Brazil',
      //   key: 'BR',
      //   sepa: false,
      //   tax_code: '076'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'British Indian Ocean Territory',
      //   key: 'IO',
      //   sepa: false,
      //   tax_code: '086'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Brunei Darussalam',
      //   key: 'BN',
      //   sepa: false,
      //   tax_code: '096'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Bulgaria',
      //   key: 'BG',
      //   sepa: true,
      //   tax_code: '100'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Burkina Faso',
      //   key: 'BF',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '854'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Burundi',
      //   key: 'BI',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '108'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Cambodia',
      //   key: 'KH',
      //   sepa: false,
      //   tax_code: '116'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Cameroon',
      //   disabled:true,
      //   key: 'CM',
      //   sepa: false,
      //   tax_code: '120'
      // },
      {
        continent: 'North America',
        country: 'Canada',
        key: 'CA',
        sepa: false,
        tax_code: '124',
        // disabled:true
      },
      // {
      //   continent: 'Africa',
      //   country: 'Cape Verde',
      //   key: 'CV',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '132'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Cayman Islands',
      //   key: 'KY',
      //   sepa: false,
      //   tax_code: '136'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Central African Republic',
      //   key: 'CF',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '140'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Chad',
      //   key: 'TD',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '148'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Chile',
      //   key: 'CL',
      //   sepa: false,
      //   tax_code: '152'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'China',
      //   key: 'CN',
      //   sepa: false,
      //   tax_code: '156'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Christmas Island',
      //   key: 'CX',
      //   sepa: false,
      //   tax_code: '162'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Cocos (Keeling) Islands',
      //   key: 'CC',
      //   sepa: false,
      //   tax_code: '166'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Colombia',
      //   key: 'CO',
      //   sepa: false,
      //   tax_code: '170'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Comoros',
      //   key: 'KM',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '174'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Congo',
      //   key: 'CG',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '178'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'The Democratic Republic of The Congo',
      //   key: 'CD',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '180'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Cook Islands',
      //   key: 'CK',
      //   sepa: false,
      //   tax_code: '184'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Costa Rica',
      //   key: 'CR',
      //   sepa: false,
      //   tax_code: '188'
      // },
      // {
      //   continent: 'Africa',
      //   country: "Cote D'ivoire",
      //   key: 'CI',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '384'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Croatia',
      //   key: 'HR',
      //   sepa: true,
      //   tax_code: '191'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Cuba',
      //   key: 'CU',
      //   sepa: false,
      //   tax_code: '192'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Cyprus',
      //   key: 'CY',
      //   sepa: true,
      //   tax_code: '196'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Czech Republic',
      //   key: 'CZ',
      //   sepa: true,
      //   tax_code: '203'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Denmark',
      //   key: 'DK',
      //   sepa: true,
      //   tax_code: '208'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Djibouti',
      //   key: 'DJ',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '262'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Dominica',
      //   key: 'DM',
      //   sepa: false,
      //   tax_code: '212'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Dominican Republic',
      //   key: 'DO',
      //   sepa: false,
      //   tax_code: '214'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Ecuador',
      //   key: 'EC',
      //   sepa: false,
      //   tax_code: '218'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Egypt',
      //   key: 'EG',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '818'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'El Salvador',
      //   key: 'SV',
      //   sepa: false,
      //   tax_code: '222'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Equatorial Guinea',
      //   key: 'GQ',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '226'
      // },
      // {
      //   continent: 'Africa',
      //   country: 'Eritrea',
      //   key: 'ER',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '232'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Estonia',
      //   key: 'EE',
      //   sepa: true,
      //   tax_code: '233'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Ethiopia',
      //   key: 'ET',
      //   sepa: false,
      //   tax_code: '231'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Falkland Islands (Malvinas)',
      //   key: 'FK',
      //   sepa: false,
      //   tax_code: '238'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Faroe Islands',
      //   key: 'FO',
      //   sepa: false,
      //   tax_code: '234'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Fiji',
      //   key: 'FJ',
      //   sepa: false,
      //   tax_code: '242'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Finland',
      //   key: 'FI',
      //   sepa: true,
      //   tax_code: '246'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'France',
      //   key: 'FR',
      //   sepa: true,
      //   tax_code: '250'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'French Guiana',
      //   key: 'GF',
      //   sepa: false,
      //   tax_code: '254'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'French Polynesia',
      //   key: 'PF',
      //   sepa: false,
      //   tax_code: '258'
      // },
      // {
      //   continent: 'Antarctica',
      //   country: 'French Southern Territories',
      //   key: 'TF',
      //   sepa: false,
      //   disabled:true,
      //   tax_code: '260'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Gabon',
      //   key: 'GA',
      //   sepa: false,
      //   tax_code: '266'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Gambia',
      //   key: 'GM',
      //   sepa: false,
      //   tax_code: '270'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Georgia',
      //   key: 'GE',
      //   sepa: false,
      //   tax_code: '268'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Germany',
      //   key: 'DE',
      //   sepa: true,
      //   tax_code: '276'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Ghana',
      //   key: 'GH',
      //   sepa: false,
      //   tax_code: '288'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Gibraltar',
      //   key: 'GI',
      //   sepa: false,
      //   tax_code: '292'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Greece',
      //   key: 'GR',
      //   sepa: true,
      //   tax_code: '300'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Greenland',
      //   key: 'GL',
      //   sepa: false,
      //   tax_code: '304'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Grenada',
      //   key: 'GD',
      //   sepa: false,
      //   tax_code: '308'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Guadeloupe',
      //   key: 'GP',
      //   sepa: false,
      //   tax_code: '312'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Guam',
      //   key: 'GU',
      //   sepa: false,
      //   tax_code: '316'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Guatemala',
      //   key: 'GT',
      //   sepa: false,
      //   tax_code: '320'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Guernsey',
      //   key: 'GG',
      //   sepa: false,
      //   tax_code: '831'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Guinea',
      //   key: 'GN',
      //   sepa: false,
      //   tax_code: '324'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Guinea-bissau',
      //   key: 'GW',
      //   sepa: false,
      //   tax_code: '624'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Guyana',
      //   key: 'GY',
      //   sepa: false,
      //   tax_code: '328'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Haiti',
      //   key: 'HT',
      //   sepa: false,
      //   tax_code: '332'
      // },
      // {
      //   continent: 'Antarctica',
      //   country: 'Heard Island and Mcdonald Islands',
      //   key: 'HM',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '334'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Holy See (Vatican City State)',
      //   key: 'VA',
      //   sepa: false,
      //   tax_code: '336'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Honduras',
      //   key: 'HN',
      //   sepa: false,
      //   tax_code: '340'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Hong Kong',
      //   key: 'HK',
      //   sepa: false,
      //   tax_code: '344'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Hungary',
      //   key: 'HU',
      //   sepa: true,
      //   tax_code: '348'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Iceland',
      //   key: 'IS',
      //   sepa: false,
      //   tax_code: '352'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'India',
      //   key: 'IN',
      //   sepa: false,
      //   tax_code: '356'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Indonesia',
      //   key: 'ID',
      //   sepa: false,
      //   tax_code: '360'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Iran',
      //   key: 'IR',
      //   sepa: false,
      //   tax_code: '364'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Iraq',
      //   key: 'IQ',
      //   sepa: false,
      //   tax_code: '368'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Ireland',
      //   key: 'IE',
      //   sepa: true,
      //   tax_code: '372'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Isle of Man',
      //   key: 'IM',
      //   sepa: false,
      //   tax_code: '833'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Israel',
      //   key: 'IL',
      //   sepa: false,
      //   tax_code: '376'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Italy',
      //   key: 'IT',
      //   sepa: true,
      //   tax_code: '380'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Jamaica',
      //   key: 'JM',
      //   sepa: false,
      //   tax_code: '388'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Japan',
      //   key: 'JP',
      //   sepa: false,
      //   tax_code: '392'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Jersey',
      //   key: 'JE',
      //   sepa: false,
      //   tax_code: '832'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Jordan',
      //   key: 'JO',
      //   sepa: false,
      //   tax_code: '400'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Kazakhstan',
      //   key: 'KZ',
      //   sepa: false,
      //   tax_code: '398'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Kenya',
      //   key: 'KE',
      //   sepa: false,
      //   tax_code: '404'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Kiribati',
      //   key: 'KI',
      //   sepa: false,
      //   tax_code: '296'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: "Democratic People's Republic of Korea",
      //   key: 'KP',
      //   sepa: false,
      //   tax_code: '408'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Republic of Korea',
      //   key: 'KR',
      //   sepa: false,
      //   tax_code: '410'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Kuwait',
      //   key: 'KW',
      //   sepa: false,
      //   tax_code: '414'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Kyrgyzstan',
      //   key: 'KG',
      //   sepa: false,
      //   tax_code: '417'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: "Lao People's Democratic Republic",
      //   key: 'LA',
      //   sepa: false,
      //   tax_code: '418'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Latvia',
      //   key: 'LV',
      //   sepa: true,
      //   tax_code: '428'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Lebanon',
      //   key: 'LB',
      //   sepa: false,
      //   tax_code: '422'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Lesotho',
      //   key: 'LS',
      //   sepa: false,
      //   tax_code: '426'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Liberia',
      //   key: 'LR',
      //   sepa: false,
      //   tax_code: '430'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Libya',
      //   key: 'LY',
      //   sepa: false,
      //   tax_code: '434'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Liechtenstein',
      //   key: 'LI',
      //   sepa: false,
      //   tax_code: '438'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Lithuania',
      //   key: 'LT',
      //   sepa: true,
      //   tax_code: '440'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Luxembourg',
      //   key: 'LU',
      //   sepa: true,
      //   tax_code: '442'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Macao',
      //   key: 'MO',
      //   sepa: false,
      //   tax_code: '446'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Macedonia',
      //   key: 'MK',
      //   sepa: false,
      //   tax_code: '807'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Madagascar',
      //   key: 'MG',
      //   sepa: false,
      //   tax_code: '450'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Malawi',
      //   key: 'MW',
      //   sepa: false,
      //   tax_code: '454'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Malaysia',
      //   key: 'MY',
      //   sepa: false,
      //   tax_code: '458'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Maldives',
      //   key: 'MV',
      //   sepa: false,
      //   tax_code: '462'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Mali',
      //   key: 'ML',
      //   sepa: false,
      //   tax_code: '466'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Malta',
      //   key: 'MT',
      //   sepa: true,
      //   tax_code: '470'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Marshall Islands',
      //   key: 'MH',
      //   sepa: false,
      //   tax_code: '584'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Martinique',
      //   key: 'MQ',
      //   sepa: false,
      //   tax_code: '474'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Mauritania',
      //   key: 'MR',
      //   sepa: false,
      //   tax_code: '478'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Mauritius',
      //   key: 'MU',
      //   sepa: false,
      //   tax_code: '480'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Mayotte',
      //   key: 'YT',
      //   sepa: false,
      //   tax_code: '175'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Mexico',
      //   key: 'MX',
      //   sepa: false,
      //   tax_code: '484'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Micronesia',
      //   key: 'FM',
      //   sepa: false,
      //   tax_code: '583'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Moldova',
      //   key: 'MD',
      //   sepa: false,
      //   tax_code: '498'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Monaco',
      //   key: 'MC',
      //   sepa: false,
      //   tax_code: '492'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Mongolia',
      //   key: 'MN',
      //   sepa: false,
      //   tax_code: '496'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Montenegro',
      //   key: 'ME',
      //   sepa: false,
      //   tax_code: '499'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Montserrat',
      //   key: 'MS',
      //   sepa: false,
      //   tax_code: '500'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Morocco',
      //   key: 'MA',
      //   sepa: false,
      //   tax_code: '504'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Mozambique',
      //   key: 'MZ',
      //   sepa: false,
      //   tax_code: '508'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Myanmar',
      //   key: 'MM',
      //   sepa: false,
      //   tax_code: '104'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Namibia',
      //   key: 'NA',
      //   sepa: false,
      //   tax_code: '516'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Nauru',
      //   key: 'NR',
      //   sepa: false,
      //   tax_code: '520'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Nepal',
      //   key: 'NP',
      //   sepa: false,
      //   tax_code: '524'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Netherlands',
      //   key: 'NL',
      //   sepa: true,
      //   tax_code: '528'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Netherlands Antilles',
      //   key: 'AN',
      //   sepa: false,
      //   tax_code: '530'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'New Caledonia',
      //   key: 'NC',
      //   sepa: false,
      //   tax_code: '540'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'New Zealand',
      //   key: 'NZ',
      //   sepa: false,
      //   tax_code: '554'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Nicaragua',
      //   key: 'NI',
      //   sepa: false,
      //   tax_code: '558'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Niger',
      //   key: 'NE',
      //   sepa: false,
      //   tax_code: '562'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Nigeria',
      //   key: 'NG',
      //   sepa: false,
      //   tax_code: '566'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Niue',
      //   key: 'NU',
      //   sepa: false,
      //   tax_code: '570'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Norfolk Island',
      //   key: 'NF',
      //   sepa: false,
      //   tax_code: '574'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Northern Mariana Islands',
      //   key: 'MP',
      //   sepa: false,
      //   tax_code: '580'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Norway',
      //   key: 'NO',
      //   sepa: true,
      //   tax_code: '578'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Oman',
      //   key: 'OM',
      //   sepa: false,
      //   tax_code: '512'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Pakistan',
      //   key: 'PK',
      //   sepa: false,
      //   tax_code: '586'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Palau',
      //   key: 'PW',
      //   sepa: false,
      //   tax_code: '585'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Palestinia',
      //   key: 'PS',
      //   sepa: false,
      //   tax_code: '275'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Panama',
      //   key: 'PA',
      //   sepa: false,
      //   tax_code: '591'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Papua New Guinea',
      //   key: 'PG',
      //   sepa: false,
      //   tax_code: '598'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Paraguay',
      //   key: 'PY',
      //   sepa: false,
      //   tax_code: '600'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Peru',
      //   key: 'PE',
      //   sepa: false,
      //   tax_code: '604'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Philippines',
      //   key: 'PH',
      //   sepa: false,
      //   tax_code: '608'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Pitcairn',
      //   key: 'PN',
      //   sepa: false,
      //   tax_code: '612'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Poland',
      //   key: 'PL',
      //   sepa: true,
      //   tax_code: '616'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Portugal',
      //   key: 'PT',
      //   sepa: true,
      //   tax_code: '620'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Puerto Rico',
      //   key: 'PR',
      //   sepa: false,
      //   tax_code: '630'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Qatar',
      //   key: 'QA',
      //   sepa: false,
      //   tax_code: '634'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Reunion',
      //   key: 'RE',
      //   sepa: false,
      //   tax_code: '638'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Romania',
      //   key: 'RO',
      //   sepa: true,
      //   tax_code: '642'
      // },
      // {
      //   continent: 'Europe',
      //   country: 'Russian Federation',
      //   key: 'RU',
      //   sepa: false,
      //   tax_code: '643',
      //   disabled: true
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Rwanda',
      //   key: 'RW',
      //   sepa: false,
      //   tax_code: '646'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Saint Helena',
      //   key: 'SH',
      //   sepa: false,
      //   tax_code: '654'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Saint Kitts and Nevis',
      //   key: 'KN',
      //   sepa: false,
      //   tax_code: '659'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Saint Lucia',
      //   key: 'LC',
      //   sepa: false,
      //   tax_code: '662'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Saint Pierre and Miquelon',
      //   key: 'PM',
      //   sepa: false,
      //   tax_code: '666'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Saint Vincent and The Grenadines',
      //   key: 'VC',
      //   sepa: false,
      //   tax_code: '670'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Samoa',
      //   key: 'WS',
      //   sepa: false,
      //   tax_code: '882'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'San Marino',
      //   key: 'SM',
      //   sepa: false,
      //   tax_code: '674'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Sao Tome and Principe',
      //   key: 'ST',
      //   sepa: false,
      //   tax_code: '678'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Saudi Arabia',
      //   key: 'SA',
      //   sepa: false,
      //   tax_code: '682'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Senegal',
      //   key: 'SN',
      //   sepa: false,
      //   tax_code: '686'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Serbia',
      //   key: 'RS',
      //   sepa: false,
      //   tax_code: '688'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Seychelles',
      //   key: 'SC',
      //   sepa: false,
      //   tax_code: '690'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Sierra Leone',
      //   key: 'SL',
      //   sepa: false,
      //   tax_code: '694'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Singapore',
      //   key: 'SG',
      //   sepa: false,
      //   tax_code: '702'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Slovakia',
      //   key: 'SK',
      //   sepa: true,
      //   tax_code: '703'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Slovenia',
      //   key: 'SI',
      //   sepa: true,
      //   tax_code: '705'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Solomon Islands',
      //   key: 'SB',
      //   sepa: false,
      //   tax_code: '090'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Somalia',
      //   key: 'SO',
      //   sepa: false,
      //   tax_code: '706'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'South Africa',
      //   key: 'ZA',
      //   sepa: false,
      //   tax_code: '710'
      // },
      // {
      //   continent: 'Antarctica',
      //   country: 'South Georgia and The South Sandwich Islands',
      //   key: 'GS',
      //   disabled:true,
      //   sepa: false,
      //   tax_code: '239'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Spain',
      //   key: 'ES',
      //   sepa: true,
      //   tax_code: '724'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Sri Lanka',
      //   key: 'LK',
      //   sepa: false,
      //   tax_code: '144'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Sudan',
      //   key: 'SD',
      //   sepa: false,
      //   tax_code: '736'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Suriname',
      //   key: 'SR',
      //   sepa: false,
      //   tax_code: '740'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Svalbard and Jan Mayen',
      //   key: 'SJ',
      //   sepa: false,
      //   tax_code: '744'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Eswatini',
      //   key: 'SZ',
      //   sepa: false,
      //   tax_code: '748'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Sweden',
      //   key: 'SE',
      //   sepa: true,
      //   tax_code: '752'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Switzerland',
      //   key: 'CH',
      //   sepa: true,
      //   tax_code: '756'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Syrian Arab Republic',
      //   key: 'SY',
      //   sepa: false,
      //   tax_code: '760'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Taiwan, Province of China',
      //   key: 'TW',
      //   sepa: false,
      //   tax_code: '158'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Tajikistan',
      //   key: 'TJ',
      //   sepa: false,
      //   tax_code: '762'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Tanzania, United Republic of',
      //   key: 'TZ',
      //   sepa: false,
      //   tax_code: '834'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Thailand',
      //   key: 'TH',
      //   sepa: false,
      //   tax_code: '764'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Timor-leste',
      //   key: 'TL',
      //   sepa: false,
      //   tax_code: '626'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Togo',
      //   key: 'TG',
      //   sepa: false,
      //   tax_code: '768'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Tokelau',
      //   key: 'TK',
      //   sepa: false,
      //   tax_code: '772'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Tonga',
      //   key: 'TO',
      //   sepa: false,
      //   tax_code: '776'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Trinidad and Tobago',
      //   key: 'TT',
      //   sepa: false,
      //   tax_code: '780'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Tunisia',
      //   key: 'TN',
      //   sepa: false,
      //   tax_code: '788'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Turkey',
      //   key: 'TR',
      //   sepa: false,
      //   tax_code: '792'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Turkmenistan',
      //   key: 'TM',
      //   sepa: false,
      //   tax_code: '795'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Turks and Caicos Islands',
      //   key: 'TC',
      //   sepa: false,
      //   tax_code: '796'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Tuvalu',
      //   key: 'TV',
      //   sepa: false,
      //   tax_code: '798'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Uganda',
      //   key: 'UG',
      //   sepa: false,
      //   tax_code: '800'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'Ukraine',
      //   key: 'UA',
      //   sepa: false,
      //   tax_code: '804'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'United Arab Emirates',
      //   key: 'AE',
      //   sepa: false,
      //   tax_code: '784'
      // },
      // {
      //   continent: 'Europe',
      //   disabled:true,
      //   country: 'United Kingdom',
      //   key: 'GB',
      //   sepa: true,
      //   tax_code: '826'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'United States',
      //   key: 'US',
      //   sepa: false,
      //   tax_code: '840',
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'United States Minor Outlying Islands',
      //   key: 'UM',
      //   sepa: false,
      //   tax_code: '581'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Uruguay',
      //   key: 'UY',
      //   sepa: false,
      //   tax_code: '858'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Uzbekistan',
      //   key: 'UZ',
      //   sepa: false,
      //   tax_code: '860'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Vanuatu',
      //   key: 'VU',
      //   sepa: false,
      //   tax_code: '548'
      // },
      // {
      //   continent: 'South America',
      //   disabled:true,
      //   country: 'Venezuela',
      //   key: 'VE',
      //   sepa: false,
      //   tax_code: '862'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Viet Nam',
      //   key: 'VN',
      //   sepa: false,
      //   tax_code: '704'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Virgin Islands, British',
      //   key: 'VG',
      //   sepa: false,
      //   tax_code: '092'
      // },
      // {
      //   continent: 'North America',
      //   disabled:true,
      //   country: 'Virgin Islands, U.S.',
      //   key: 'VI',
      //   sepa: false,
      //   tax_code: '850'
      // },
      // {
      //   continent: 'Oceania',
      //   disabled:true,
      //   country: 'Wallis and Futuna',
      //   key: 'WF',
      //   sepa: false,
      //   tax_code: '876'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Western Sahara',
      //   key: 'EH',
      //   sepa: false,
      //   tax_code: '732'
      // },
      // {
      //   continent: 'Asia',
      //   disabled:true,
      //   country: 'Yemen',
      //   key: 'YE',
      //   sepa: false,
      //   tax_code: '887'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Zambia',
      //   key: 'ZM',
      //   sepa: false,
      //   tax_code: '894'
      // },
      // {
      //   continent: 'Africa',
      //   disabled:true,
      //   country: 'Zimbabwe',
      //   key: 'ZW',
      //   sepa: false,
      //   tax_code: '716'
      // }
    ]
  }
  return { getCountries }
}
