<template>
  <div>
    <div
      @click="clicked"
      class="
        w-full
        flex
        items-center
        justify-between
        cursor-pointer
        py-3.5
        lg:p-4 lg:bg-white lg:mb-6 lg:rounded-3xl
      "
    >
      <div class="flex">
        <img
          :src="require('@/assets/svg/strategies/' + icon + '.svg')"
          alt="icon"
          class="mr-4 lg:hidden"
        />
        <img
          :src="require('@/assets/svg/strategies/' + icon + '_green.svg')"
          alt="icon"
          class="hidden lg:block mr-4"
        />
        <div class="text-left">
          <p class="text-grey-2 text-sm text-opacity-90">{{ title }}</p>
          <p class="text-grey-2 text-xs text-opacity-60">{{ description }}</p>
        </div>
      </div>
      <img src="@/assets/svg/arrows/right_grey_arrow.svg" alt="right arrow" />
    </div>
    <div class="h-0.5 w-full bg-grey-2 bg-opacity-10 lg:hidden" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const router = useRouter()

    const clicked = () => {
      router.push({
        path: '/strategies/' + props.link,
        query: { edit: 1 }
      })
    }

    return {
      clicked
    }
  }
}
</script>

<style lang="scss" scoped></style>
