<template>
  <div v-if="account ? !account.token : true || $route.path === '/settings/bank-accounts'" class="animate-pulse bg-white rounded-2xl w-full flex flex-col justify-between p-4 mt-4">
    <div class="flex justify-between">
      <div class="flex">
        <div class="flex items-start w-8 mr-1">
          <img class="w-full" :src="'/img/popup/exclamation_grey.svg'" alt="!" />
        </div>
        <div>
          <p  v-if="$route.path === '/settings/bank-accounts'" class="text-left text-grey-2 text-sm font-semibold mb-2">
            {{ $t('settings.bank_accounts.kyc_popup.title_2') }}
          </p>
          <p  v-else class="text-left text-grey-2 text-sm font-semibold mb-2">
            {{ $t('settings.bank_accounts.kyc_popup.title') }}
          </p>
          <p class="text-left text-grey-2 text-sm text-opacity-50">
            <slot v-if="$route.path === '/settings/bank-accounts'" name="content">{{ $t('settings.bank_accounts.kyc_popup.name') }}</slot>
            <slot v-else name="content">{{ $t('settings.bank_accounts.kyc_popup.text') }}</slot>
          </p>
        </div>
      </div>
    </div>
    <router-link v-if="$route.path !== '/settings/bank-accounts'" :to="'/settings/bank-accounts' " class="orange-link text-right text-13 uppercase">{{
      $t('settings.bank_accounts.kyc_popup.link')
    }}</router-link>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    
    onMounted(async () => {
      await store.dispatch('getBankAccount')
    })

    const account = computed (() => {
      return store.state.bankAccounts.account
    })

    return { 
      account
    }
  }
}
</script>
