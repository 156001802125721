<template>
  <button
    class="
      h-14
      flex
      items-center
      justify-center
      rounded-2xl
      text-13
      tracking-wider
      font-bold
      uppercase
      cursor-pointer
      transition
      duration-300
      ease-out
    "
    :class="width + (disabled ? disabledColors + 'cursor-not-allowed' : colors)"
    :type="submit ? 'submit' : 'button'"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
import { computed } from 'vue'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'orange'
    },
    desktopWidth: {
      type: String,
      default: 'w-64'
    },
    mobileWidth: {
      type: String,
      default: 'w-full'
    }
  },
  setup(props) {
    const colors = computed(() => {
      if (props.color === 'grey') {
        return 'bg-grey-1 text-white hover:bg-grey-2 '
      } else if (props.color === 'white') {
        return 'bg-white text-orange-1 hover:bg-grey-ghost '
      } else {
        return 'bg-orange-3 text-grey-1 hover:bg-orange-2 '
      }
    })

    const disabledColors = computed(() => {
      if (props.color === 'grey') {
        return 'bg-grey-6 text-grey-5 '
      } else if (props.color === 'white') {
        return 'bg-opacity-60 text-orange-1 text-opacity-30 '
      } else {
        return 'bg-orange-5 text-grey-6 '
      }
    })

    const width = computed(() => {
      return props.mobileWidth + ' lg:' + props.desktopWidth + ' '
    })

    return {
      colors,
      width,
      disabledColors
    }
  }
}
</script>

<style lang="scss" scoped></style>
