<template>
  <div :class="noValues ? 'h-32' : 'h-56'">
    <swiper
      class="w-full contract-slide"
      :class="noValues ? 'h-32' : 'h-56'"
      :space-between="16"
      slides-per-view="auto"
    >
      <swiper-slide
        class="w-max"
        :class="noValues ? 'h-32' : 'h-56'"
        v-for="card in cards"
        v-bind:key="card.key"
      >
        <ImpactCard :noValue="noValues" small :card="card" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import ImpactCard from '@/components/impact/impact/Card'
export default {
  components: {
    Swiper,
    ImpactCard,
    SwiperSlide
  },
  props: {
    cards: Array,
    noValues: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
}
</script>
<style>
.contract-slide .swiper-slide {
  min-height: 0px;
}

</style>
