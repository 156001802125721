<template>
  <div class="page min-h-screen"></div>
</template>

<script>
import { useStore } from 'vuex'
import { onMounted, onUnmounted } from 'vue'
export default {
  props: {
    page: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    onMounted(() => {
      store.dispatch('setLoading', true)
    })
    onUnmounted(() => {
      store.dispatch('setLoading', false)
    })
  }
}
</script>
