<template>
  <div class="page">
    <div v-if="contract" class="container lg:pt-20">
      <div class="flex items-center lg:items-end">
        <div>
          <Back :to="-1" :title="$t('contracts.full_payment_plan.title')" />
        </div>
        <p class="text-sm font-medium text-grey-3 mb-8 lg:mb-4 ml-2">
          {{
            contract.upcoming_payments.next_payment.payment_n -
            1 +
            '/' +
            contract.all_payments.length
          }}
        </p>
      </div>
      <PaymentPlan
        :payments="contract.all_payments"
        :contract="contract"
        full
      />
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import PaymentPlan from '@/components/contracts/PaymentPlan.vue'
import { onBeforeMount, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  components: {
    Back,
    PaymentPlan
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const contract = computed(() => {
      return store.state.contracts.contract
    })

    onBeforeMount(async () => {
      if (!contract.value)
        await store.dispatch('fetchContract', route.params.id.split('-').pop())
    })

    return {
      contract
    }
  }
}
</script>

<style lang="scss" scoped></style>
