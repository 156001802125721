import i18n from '@/i18n'
import reason from "@/assets/ts/suitability/steps/risks/reason_risk";
import percent from "@/assets/ts/suitability/steps/risks/percent_time";
import PageForm from "@/assets/ts/interfaces/form/pageForm";

const risk:PageForm = {
  name: i18n.global.t('ca_legislation.suitability.side_panel.names.risk'),
  title: i18n.global.t('ca_legislation.suitability.side_panel.titles.risk'),
  sub_steps:{
    reason,
    percent
  }
}

export default risk
