<template>
  <div class="page flex">
    <div class="lg:pt-20 container flex-grow">
      <div class="relative flex flex-col justify-between">
        <Back
          :to="'/settings/beneficiary-information'"
          :title="$t('settings.add_beneficiary.title')"
        />
        <Form class="form" ref="form" v-slot="{ errors, meta }">
          <GeneralInput
            v-model:data="data.name"
            type="string"
            name="names"
            :label="$t('settings.legal_representative.first_name')"
            :noNumbersOrSymbols="true"
            rules="required|alphabet|maxLength:20"
            :error="errors.names"
          />
          <GeneralInput
            v-model:data="data.surname"
            type="string"
            name="surnames"
            :label="$t('settings.legal_representative.last_name')"
            :noNumbersOrSymbols="true"
            rules="required|alphabet|maxLength:30"
            :error="errors.surnames"
          />

          <GeneralInput
            v-model:data="data.date_of_birth"
            type="date"
            name="date_of_birth"
            :label="$t('settings.legal_representative.date_of_birth')"
            :required="true"
          />

          <GeneralInput
            v-model:data="data.legal_country"
            type="countries"
            name="country_of_residence"
            :label="$t('settings.legal_representative.country_of_residence')"
            :required="true"
          />

          <GeneralInput
            v-if="regionCountries.includes(data.legal_country)"
            v-model:data="data.region"
            type="text"
            name="region"
            :label="$t('settings.legal_representative.region')"
            :noNumbersOrSymbols="true"
            rules="required|alphabet|maxLength:15"
            :error="errors.region"
          />

          <GeneralInput
            v-model:data="data.address"
            type="text"
            name="address"
            :label="$t('settings.legal_representative.address')"
            :addressSymbols="true"
            rules="required|alphabet|maxLength:40"
            :error="errors.address"
            :autocomplete="true"
          />

          <GeneralInput
            v-model:data="data.city"
            type="text"
            name="city"
            :label="$t('settings.legal_representative.city')"
            rules="required|alphabet|maxLength:25"
            :error="errors.city"
          />

          <GeneralInput
            v-model:data="data.post_code"
            type="tel"
            name="post_code"
            :label="$t('settings.legal_representative.zipCode')"
            :zipCode="true"
            :country="legal_country"
            :pattern="legal_country === 'PT' ? '[0-9\-]+' : '*'"
            :maxLength="legal_country === 'PT' ? '8' : '32'"
            rules="required|zip|maxLength:20"
            :error="errors.post_code"
          />

          <GeneralInput
            v-model:data="data.country_of_birth"
            type="countries"
            name="nationality"
            :label="$t('settings.legal_representative.nationality')"
            rules="required"
            :error="errors.nationality"
          />

          <GeneralInput
            v-model:data="data.city_of_birth"
            type="text"
            name="city_of_birth"
            :label="$t('settings.legal_representative.city_of_birth')"
            rules="required|alphabet|maxLength:25"
            :error="errors.city_of_birth"
          />

          <GpButton
            @click="submitBeneficiary"
            :disabled="!meta.valid"
            class="ml-auto"
          >
            {{ $t('wording.save') }}
          </GpButton>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { Form } from 'vee-validate'
import Back from '@/components/general/Back.vue'
import GeneralInput from '@/components/general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    Back,
    Form,
    GeneralInput,
    GpButton 
  },
  setup() {
    let data = ref({
      type: 'other',
      name: '',
      surname: '',
      date_of_birth: '',
      legal_country: '',
      region: null,
      address: '',
      city: '',
      post_code: '',
      country_of_birth: '',
      city_of_birth: '',
      is_ubo: 1
    })
    const store = useStore()
    const i18n = useI18n()
    const router = useRouter()
    const regionCountries = ref(['CA', 'MX', 'US'])
    const loading = computed(() => {
      return store.state.general.loading
    })

    const submitBeneficiary = async () => {
      store.dispatch('setLoading', true)
      await store.dispatch('add', { beneficiaries: [data.value] })
      await store.dispatch('submitBeneficiaries')
      await store.dispatch('fetchRepresentatives')
      store.dispatch('setLoading', false)
      const popup = {
        title: i18n.t('settings.legal_representative.success_popup.title'),
        align: 'center',
        side: false,
        iconBg: 'bg-green-5',
        icon: '/img/done_step.svg',
        content: i18n.t(
          'settings.legal_representative.success_popup.description'
        ),
        primaryButton: i18n.t('settings.legal_representative.success_popup.btn')
      }
      store.dispatch('setPopup', popup)
      router.push('/settings/beneficiary-information')
    }

    return {
      data,
      regionCountries,
      submitBeneficiary,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
