<template>
  <div class="sm:flex lg:block">
    <div class="sm:w-6/12 lg:w-8/12 lg:ml-8 mt-20 h-screen-1/2 relative">
      <div class=" absolute top-1/2 -translate-y-1/2 transform w-full z-50">
        <swiper
          class="overflow-y-visible pb-6"
          :loop="data.length > 1 ? true : false"
          :slides-per-view="1"
          :space-between="50"
          :preventInteractionOnTransition="true"
          :pagination="data.length ? { clickable: true } : false"
          :speed="5000"
          :allowTouchMove="data.length ? true : false"
          :autoplay="data.length ? true : false"
          @swiper="setFirstSwiper"
          :controller="{ control: secondSwiper }"
        >
          <swiper-slide
            v-for="el in data"
            v-bind:key="el.title"
            style="min-height: auto; height: 100%"
          >
            <div class="text-4xl mb-4 no-highlight" v-html="el.title"></div>
            <div class="text-sm no-highlight" v-html="el.subtitle"></div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div
      class="
      h-screen-1/2
      -mt-20
      sm:w-6/12
      lg:w-full
      "
    >
      <swiper
        :loop="data.length > 1 ? true : false"
        :slides-per-view="1"
        :space-between="50"
        :effect="'fade'"
        :fadeEffect="{ crossFade: true }"
        :allowTouchMove="false"
        :speed="1000"
        @swiper="setSecondSwiper"
        :controller="{ control: firstSwiper }"
        class="h-full"
      >
        <swiper-slide
          class="pb-0"
          v-for="el in data"
          v-bind:key="el.title + '-img'"
          style="overflow: hidden; min-height: auto; height: auto"
        >

          <img :src="dir + el.img" alt="" class="w-full lg:w-auto absolute bottom-0 right-0 lg:right-auto lg:left-1/2 max-h-full  lg:-translate-x-1/2 transform" />
          <img id="ball" src="@/assets/svg/green-ball.svg" alt="green ball" style="width:50%; max-width: none" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-30 w-1/3 z-negative hidden lg:block" />


          <GreenBall
            class="opacity-30 scale-75 transform block lg:hidden absolute right-0 bottom-0"
            cutout="0"
            offset="0%"
            style="z-index: -1"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
//:autoplay="data.length > 1 ? { delay: 6000 } : false"

// Import Swiper Vue.js components
import SwiperCore, {
  Pagination,
  EffectFade,
  Controller,
  Autoplay
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import GreenBall from '../general/GreenBall.vue'

SwiperCore.use([Pagination, EffectFade, Controller, Autoplay])

export default {
  components: {
    Swiper,
    SwiperSlide,
    GreenBall
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    dir: {
      type: String,
      default: null
    }
  },
  setup() {
    let firstSwiper = ref(null)
    let secondSwiper = ref(null)

    const swiperComponentOption = {
      loop: true,
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 10,
      slideClass: 'custom-slide-class',
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    }

    function setFirstSwiper(swiper) {
      firstSwiper.value = swiper
    }

    function setSecondSwiper(swiper) {
      secondSwiper.value = swiper
    }

    return {
      swiperComponentOption,
      setFirstSwiper,
      firstSwiper,
      setSecondSwiper,
      secondSwiper
    }
  }
}
</script>
<style>
.w-inherit {
  width: inherit;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 12rem);
  height: initial;
}

@media (max-width: 768px) {
  .swiper-slide {
    min-height: calc(100vh);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .swiper-slide {
    min-height: calc(100vh - 5rem);
  }
}

.swiper-slide img {
  max-width: fit-content;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}

.swiper-pagination {
  text-align: left;
}

.swiper-pagination-bullet {
  width: 34px;
  height: 3px;
  transition: all 0.3s ease;
  display: inline-block;
  border-radius: 0;
  background: #000;
  opacity: 0.2;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #273441;
}
</style>
