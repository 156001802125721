import { VueCookieNext } from 'vue-cookie-next'
// import useSecurity from '@/mixins/useSecurity'
import store from '../index'
import axios from "axios";

export default {
  state: {
    popup: { side: false },
    impactPopup: {},
    toasts: [],
    impactShowPopup: false,
    showPopup: false,
    loading: false,
    settings: null,
    requestSettings: null,
    share: null,
    shareLink: null,
    showShareholder: false,
    showShareholderNot: false,
    privacyMode: false,
    trigger: null,
    showShare: false,
    leftNav: 0,
    blockedCountries: null,
    giftcards: null,
    fetchingBlocked: false,
    notifications: null,
    formData: {},
    form: null
  },
  mutations: {
    SET_POPUP(state, popup) {
      state.popup = popup
    },
    SET_NOTIFICATIONS(state, data) {
      state.notifications = data
    },
    SET_SHOW_SHAREHOLDER(state, show) {
      state.showShareholder = show
    },
    SET_SHOW_SHAREHOLDER_NOT(state, show) {
      state.showShareholderNot = show
    },
    SET_SHARE_LINK(state, link) {
      state.shareLink = link
    },
    SET_SETTINGS(state, settings){
      if(state.settings) {
        Object.keys(settings).forEach(key => {
          state.settings[key] = settings[key]
        });
      }
      else
        state.settings = settings
    },
    SET_FORMDATA(state, data) {
      state.formData = {
        ...state.formData,
        ...data
      }
    },
    CLEAR_FORMDATA(state) {
      state.formData = null
    },
    SET_REQUEST_SETTINGS(state, requestSettings){
      state.requestSettings = requestSettings
    },
    SET_LEFT_NAV(state, leftNav){
      state.leftNav = leftNav
    },
    SET_FORM(state, form){
      state.form = form
    },
    SET_GIFTCARDS(state, data){
      const giftcards = state.giftcards
      state.giftcards = {
        ...giftcards,
        ...data
      }
    },
    CLEAR_GIFTCARDS(state){
      state.giftcards = null
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_PRIVACY_MODE(state, privacyMode) {
      state.privacyMode = privacyMode
    },
    SET_TRIGGER(state, trigger) {
      state.trigger = trigger
    },
    SET_TOAST(state, toast) {
      if(!state.toasts.some((el)=>{return el.toast === toast.toast}))
        state.toasts.push(toast)
    },
    REMOVE_TOAST(state, toast_name) {
      state.toasts = state.toasts.filter(function (el) {
        return el.toast !== toast_name
      })
    },
    CLEAR_TOASTS(state) {
      state.toasts = []
    },
    SET_IMPACT_POPUP(state, impactPopup) {
      state.impactPopup = impactPopup
    },
    SET_IMPACT_SHOW_POPUP(state, impactShowPopup) {
      state.impactShowPopup = impactShowPopup
    },
    SET_SHARE(state, share) {
      state.share = share
    },
    SET_FETCHING_BLOCKED(state, fetching) {
      state.fetchingBlocked = fetching
    },
    SET_BLOCKED_COUNTRIES(state, blocked) {
      state.blockedCountries = blocked
    },
    SET_SHOW_SHARE(state, showShare) {
      state.showShare = showShare
    },
    SET_SHOW_POPUP(state, showPopup) {
      state.showPopup = showPopup
    }
  },
  actions: {
    // General Popup
    setPopup({ commit }, popup) {
      commit('SET_POPUP', popup)
      commit('SET_SHOW_POPUP', !popup.isHidden)
    },
    setToast({ commit }, toast) {
      commit('SET_TOAST', toast)
    },
    setShowShareholder({ commit }, show) {
      commit('SET_SHOW_SHAREHOLDER', show)
    },
    setShowShareholderNot({ commit }, show) {
      commit('SET_SHOW_SHAREHOLDER_NOT', show)
    },
    clearFormData({commit}){
      commit('CLEAR_FORMDATA')
    },
    setFormData({ commit }, data) {
      commit('SET_FORMDATA', data)
    },
    setShareLink({ commit }, data) {
      commit('SET_SHARE_LINK', data)
    },
    setLeftNav({ commit }, data) {
      commit('SET_LEFT_NAV', data)
    },
    clearGiftcards({ commit }) {
      commit('CLEAR_GIFTCARDS')
    },
    setGiftcards({ commit }, data) {
      commit('SET_GIFTCARDS', data)
    },
    setTrigger({ commit }, trigger) {
      commit('SET_TRIGGER', trigger)
    },
    setRequestSettings({ commit }, requestSettings) {
      commit('SET_REQUEST_SETTINGS', requestSettings)
    },
    resetToasts({ commit }) {
      commit('CLEAR_TOASTS')
    },

    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },

    setForm({ commit }, form) {
      commit('SET_FORM', form)
    },

    setSettings({ commit }, settings) {
      commit('SET_SETTINGS', settings)
    },

    setPrivacyMode({ commit }, privacyMode) {
      commit('SET_PRIVACY_MODE', privacyMode)
      VueCookieNext.setCookie('privacyMode', privacyMode)
    },

    // eslint-disable-next-line no-unused-vars
    hideToast({ commit }, toast_name) {
      const hidden = VueCookieNext.isCookieAvailable('hidden_toast')
        ? JSON.parse(VueCookieNext.getCookie('hidden_toast'))
        : []
      hidden.push(toast_name)
      VueCookieNext.setCookie('hidden_toast', JSON.stringify(hidden))
      commit('REMOVE_TOAST', toast_name)
    },

    clearToast({ commit }, toast) {
      commit('REMOVE_TOAST', toast)
    },

    hideShareholder({ commit, state }, read) {
      if(state.notifications){
        const not = state.notifications.find(obj => obj.type === 'shareholder');
        if(not){
          axios.put(
              process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/notifications/' + not.id + '/' + (read ? 'read' : 'hidden')
          )
          commit('SET_SHOW_SHAREHOLDER', false)
        }
      }
    },

    setShowPopup({ commit }, showPopup) {
      commit('SET_SHOW_POPUP', showPopup)
    },

    setNotifications({ commit }){
      return new Promise((resolve, reject) =>{
        axios.get(
            process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/notifications'
        ).then((response) => {
          commit('SET_NOTIFICATIONS', response.data.data)
          resolve()
        }).catch(()=>{
          reject()
        })
      })

    },

    setBlockedCountries({ commit, state }){
      if(!state.blockedCountries && !state.fetchingBlocked){
        commit('SET_FETCHING_BLOCKED', true)
        axios.get(
          process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/blocked-countries'
        ).then((data) => {
          commit('SET_FETCHING_BLOCKED', false)
          commit('SET_BLOCKED_COUNTRIES', data.data)
        }).catch(()=>{
          commit('SET_FETCHING_BLOCKED', false)
        })
      }
    },

    // Impact Popup
    setImpactPopup({ commit }, impactPopup) {
      commit('SET_IMPACT_POPUP', impactPopup)
      commit('SET_IMPACT_SHOW_POPUP', true)
    },
    setImpactShowPopup({ commit }, impactShowPopup) {
      commit('SET_IMPACT_SHOW_POPUP', impactShowPopup)
    },

    // Social Share Popup
    setShare({ commit }, share) {
      commit('SET_SHOW_SHARE', true)
      commit('SET_SHARE', share)
    },
    setShowShare({ commit }, share) {
      commit('SET_SHOW_SHARE', share)
    }
  },
  getters: {
    getProfile: ( ) => () => {
      // const { decrypt } = useSecurity()
      let profile = VueCookieNext.getCookie('profile')
      if (profile) {
        // forcing sin number to be a string
        if(profile.sin) profile.sin = profile.sin.toString()
        // profile = decrypt(profile);
        // return JSON.parse(profile)
        return profile

      }
      else{
        if(VueCookieNext.isCookieAvailable('token')){
          store.dispatch('fetchProfile').then(()=>{
            location.reload();
          })
        }
      }

    },
    getToasts: (state) => () => {
      let toasts = state.toasts
      let hidden = VueCookieNext.getCookie('hidden_toast')
      if (hidden) {
        hidden = JSON.parse(hidden)
        toasts = state.toasts.filter((el) => {
          return !hidden.includes(el.toast)
        })
      }
      return toasts
    }
  }
}
