<template>
  <span>
    <div
      data-test="backButton"
      id="back"
      class="
        text-left
        font-bold
        flex
        text-lg
        md:hidden
        items-center
        md:mb-8
      "
      :class="[
        swiper ? 'hidden md:block' : swiperParent ? 'absolute mt-1.5' : '', 
        textGrey ? 'text-grey-2 md:text-orange-1' : 'text-orange-1' 
      ]"
    >
      <div
        class="cursor-pointer"
        @click="goTo"
      >
        <img
          v-if="!white"
          src="@/assets/svg/arrows/left_orange_arrow.svg"
          alt=""
          class="mr-5 hidden md:block"
        >
        <img
          v-else
          src="@/assets/svg/arrows/left_white_arrow.svg"
          alt=""
          class="mr-5"
        >
        <img
          v-if="!white"
          src="@/assets/svg/arrows/left_grey_arrow.svg"
          alt=""
          class="mr-5 block md:hidden"
        >
      </div>
      {{ title }}
    </div>
    <div
      class="hidden md:block cursor-pointer w-fit"
      @click="goTo"
    >
      <div data-test="backButton" class="back text-left text-orange-1 flex items-center">
        <img
          src="@/assets/svg/arrows/left_orange_arrow.svg"
          alt="left arrow"
        >

        <span>{{ $t('wording.back') }}</span>
      </div>
    </div>
    <div
      v-if="title"
      :data-test="''+ title.toLowerCase().replace(/\s+/g, '') +'Copy'"
      class="my-4 text-xl text-grey-1 font-bold text-left hidden md:block"
    >
      {{ title }}
    </div>
    <div
      v-if="subtitle"
      class="text-left text-grey-3 mt-6 lg:mt-2 mb-6 text-13 md:text-sm"
    >
      {{ subtitle }}
    </div>
  </span>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  props: {
    to: {
      type: [String, Number],
      default: '/'
    },
    white: {
      type: Boolean,
      default: false
    },
    textGrey: {
      type: Boolean,
      default: false
    },
    title: String,
    subtitle: {
      type: String,
      default: null
    },
    swiper: {
      type: Boolean,
      default: false
    },
    swiperParent: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const goTo = () => {
      if (props.swiper || props.swiperParent) {
        store.dispatch('previous')
        return
      }
      if (props.to === null) return
      if (typeof props.to === 'number') {
        if (props.to === 0) return
        router.go(props.to)
      } else router.push(props.to)
    }
    return {
      goTo
    }
  }
}
</script>

<style scoped>
.w-fit {
  width: fit-content;
}
</style>
