// import axios from 'axios'
import { VueCookieNext } from 'vue-cookie-next'
import axios from 'axios'

export default {
  state: {
    notifications: {}
  },
  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getNotifications({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/notifications')
        .then((data) => {
          Object.keys(data.data).forEach(function (key) {
            data.data[key] = data.data[key] === 1
          })
          commit('SET_NOTIFICATIONS', data.data)
          return data.data
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    },
    // eslint-disable-next-line no-unused-vars
    updateNotifications({ commit }, notifications) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/notifications/update',
            notifications
          )
          .then((data) => {
            Object.keys(data.data).forEach(function (key) {
              data.data[key] = data.data[key] === 1
            })
            VueCookieNext.setCookie('notifications', notifications)
            resolve(data.data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
            reject(e)
          })
      })
    }
  },
  getter: {}
}
