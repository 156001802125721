import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";

const questions:PageForm =
  {
    title: i18n.global.t('ca_legislation.onboarding.sidebar_title_org'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.onboarding.questions_company.title_questions_company"),
        fields: [
          {
            key: "company_name",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.name_org_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.name_org_text"),
            type: Types.STRING,
            rules: 'required'
          },
          {
            key: "formation",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.formation_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.formation_text"),
            limit: {min: 0, max: 300},
            type: Types.DATE,
            rules: 'required'
          },
          {
            key: "countryLocation",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.country_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.country_text"),
            type: Types.COUNTRIES,
            rules: 'required'
          },
          {
            key: "business_number",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_number_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_number_text"),
            type: Types.STRING,
            rules: 'required|exactLength:9'
          },     
          {
            key: "business_type_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_id_text"),
            options: i18n.global.tm("ca_legislation.onboarding.questions_company.industries"),
            type: Types.RADIO,
            rules: 'required'
          },   
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_retail"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: ''
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_retail"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '1'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_digital"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '2'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_food"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '3'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_professional"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '4'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_membership"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '5'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_personal"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '6'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_transportation"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '7'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_travel"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '8'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_medical"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '9'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_education"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '10'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_entertainment"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '11'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_building"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '12'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_finance"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '13'
            }
          },
          {
            key: "business_type_category_id",
            title: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.business_type_category_id_text"),
            options: i18n.global.tm("onboarding.fields.businesses_regulated"),
            type: Types.RADIO,
            rules: 'required',
            condition: {
              key: 'business_type_id',
              value: '14'
            }
          },
        ],
        next: true,
        back: true
      }
    ]
}

export default questions
