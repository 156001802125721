<template>
  <div v-if="pendingVouchers.length" class="mb-8">
    <div
      class="
        p-4
        bg-white bg-opacity-60
        rounded-2xl
        flex
        items-center
        justify-between
        mb-px
        cursor-pointer
      "
      @click="openPending"
    >
      <p class="text-left">
        <span class="font-semibold text-13 text-grey-2 text-opacity-80 mr-1">
          {{ capitalize($t('wording.pending')) }}
        </span>
        <span class="text-13 text-grey-2 text-opacity-50">
          {{ pendingVouchers.length }}
        </span>
      </p>
      <div>
        <img
          v-if="showAll"
          class="w-4 h-4 mx-4 transform rotate-180"
          src="@/assets/svg/arrows/down_grey_arrow.svg"
          alt="down arrow"
        />
        <img
          v-else
          class="w-4 h-4 mx-4"
          src="@/assets/svg/arrows/down_grey_arrow.svg"
          alt="down arrow"
        />
      </div>
    </div>
    <div
      v-if="showAll"
      class="
        bg-white
        lg:bg-opacity-0
        rounded-b-2xl
        px-4
        lg:px-0
        py-4
        lg:py-0 lg:mt-1
      "
    >
      <div
        v-for="(transaction, index) of pendingVouchers"
        :key="transaction.id"
      >
        <TransactionsListElement
          :transaction="transaction"
          :isLast="index + 1 === pendingVouchers.length"
          isVoucher
        />
        <div
          v-if="index !== pendingVouchers.length"
          class="hidden lg:block bg-grey-ghost bg-opacity-60 w-full h-0.5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import TransactionsListElement from '@/components/transactions/TransactionListElement.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
export default {
  components: {
    TransactionsListElement
  },
  setup() {
    const store = useStore()
    const { capitalize } = useGlobalHelpers()
    const pendingVouchers = computed(() => {
      return store.state.transactions.pendingVouchers
    })
    const showAll = ref(true)

    const openPending = () => {
      if (pendingVouchers.value && pendingVouchers.value.length)
        showAll.value = !showAll.value
    }

    return {
      pendingVouchers,
      showAll,
      capitalize,
      openPending
    }
  }
}
</script>

<style lang="scss" scoped></style>
