<template>
  <div
    class="page-no-nav flex flex-col"
    :class="{
      'items-end': !tryInvest
    }"
  >
    <SideBar v-if="!tryInvest && project">
      <ProjectData :project="project" />
    </SideBar>
    <!-- Topup and kyc light options -->
    <SuccessCard
      v-if="tryInvest && (topupRequired || kycRequired || success)"
      :closeable="false"
    >
      <template #icon>
        <img v-if="success" src="@/assets/svg/success.svg" alt="success" />
        <img
          v-else-if="kycRequired || notDR"
          src="@/assets/svg/id_grey_border.svg"
          alt="topup"
        />
        <img
          v-else-if="topupRequired"
          src="@/assets/svg/topup_grey_border.svg"
          alt="topup"
        />
      </template>
      <template #title>
        <p
          v-if="success && topupRequired"
          class="text-2xl font-medium text-grey-2 my-6 text-left"
        >
          {{
            $t('invest.confirmation.success_card.title_reserved', {
              project: project.name
            })
          }}
        </p>
        <p
          v-else-if="editReservation"
          class="text-2xl font-medium text-grey-2 my-6 text-left"
        >
          {{
            $t('invest.confirmation.success_card.title_edit', {
              project: project.name
            })
          }}
        </p>
        <p
          v-else-if="success"
          class="text-2xl font-medium text-grey-2 my-6 text-left"
        >
          {{
            $t('invest.confirmation.success_card.title', {
              project: project.name
            })
          }}
        </p>
        <p
          v-else-if="kycRequired"
          class="text-2xl font-medium text-grey-2 my-6 text-left"
        >
          {{ $t('invest.confirmation.kyc_card.title') }}
        </p>
        <p
          v-else-if="topupRequired"
          class="text-2xl font-medium text-grey-2 my-6 text-left"
        >
          {{ $t('invest.confirmation.topup_card.title') }}
        </p>
        <p
          v-else-if="notDR"
          class="text-2xl font-medium text-grey-2 my-6 text-left"
          v-html="
            $t('invest.confirmation.notdr_card.title')
          "
        />
      </template>
      <template #text>
        <span v-if="success">
          <p
            class="
              text-sm text-grey-2 text-opacity-80 text-left
              unindented-custom-bullet
            "
            v-html="
              $t(
                'invest.confirmation.success_card.text',
                {
                  first_payment: monthFormat(
                    12 / project.funding_rate.periodicity
                  )
                },
                monthFormat(12 / project.funding_rate.periodicity)
              )
            "
          />
        </span>
        <div v-else-if="kycRequired">
          <p
            class="text-sm text-grey-2 text-opacity-80 text-left"
            v-html="
              $t('invest.confirmation.kyc_card.text', {
                name: profile.name
              })
            "
          />
          <a
            class="orange-link"
            target="_blank"
            :href="`https://goparity.ca/faqs`"
            >{{ $t('wording.learn_more') }}
          </a>
        </div>
        <p
          v-else-if="topupRequired"
          class="text-sm text-grey-2 text-opacity-80 text-left"
          v-html="
            $t('invest.confirmation.topup_card.text', {
              amount: dollarFormatting(promiseValue)
            })
          "
        />
        <p
          v-else-if="notDR"
          class="text-sm text-grey-2 text-opacity-80 text-left"
          v-html="
            $t('invest.confirmation.notdr_card.text', {
                name: profile.name
            })
          "
        />
      </template>
      <template #button>
        <router-link v-if="success" :to="'/projects/' + project.id">
          <GpButton class="mx-auto">
            {{ $t('invest.confirmation.success_card.confirm') }}
          </GpButton>
        </router-link>
        <div v-else-if="kycRequired">
          <GpButton
            :disabled="pressed"
            color="white"
            class="mb-4 mx-auto"
            @click="invest"
          >
            {{ $t('invest.confirmation.kyc_card.kyc_later') }}
          </GpButton>
          <GpButton :disabled="pressed" @click="goToKyc" class="mx-auto">
            {{ $t('invest.confirmation.kyc_card.kyc_now') }}
          </GpButton>
        </div>
        <div v-else-if="topupRequired">
          <GpButton
            :disabled="pressed"
            color="white"
            class="mb-4 mx-auto"
            @click="invest"
          >
            {{ $t('invest.confirmation.topup_card.topup_later') }}
          </GpButton>
          <GpButton :disabled="pressed" @click="goToTopup" class="mx-auto">
            {{ $t('invest.confirmation.topup_card.topup_now') }}
          </GpButton>
        </div>
        <div v-else-if="notDR">
          <GpButton :disabled="pressed" color="white" class="mb-4 mx-auto" @click="invest">
            {{ $t('invest.confirmation.topup_card.notdr_continue') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div
      v-else-if="wallet && project && validated"
      class="strategies__desktop-container justify-between"
    >
      <div class="lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <Back id="gp-link-invest-investment-summary-back-22" :to="-1" :title="$t('invest.confirmation.title')" />
        <div class="mt-6 lg:mt-2 bg-white rounded-t-2xl mb-px w-full py-5 px-4">
          <p
            class="
              text-left
              font-semibold
              text-13 text-grey-2 text-opacity-80
              mb-4
              lg:hidden
            "
          >
            {{ project.name }}
          </p>
          <!-- Total investment -->
          <div class="flex items-center justify-between mb-4 lg:mb-0">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.confirmation.total_investment') }}
            </p>
            <p class="font-semibold text-sm text-grey-2">${{ finalAmount }}</p>
          </div>
          <div v-if="voucherValue" class="mb-4 lg:mb-0 -mt-3 lg:mt-0">
            <div class="flex items-center justify-between mb-1 lg:mb-0">
              <p class="text-13 text-grey-3">
                {{ $t('invest.confirmation.your_investment') }}
              </p>
              <p class="text-13 font-semibold text-grey-2">
                ${{
                  dollarFormatting(
                    dollarToNumberFormatting(finalAmount) - voucherValue
                  )
                }}
              </p>
            </div>
            <div class="flex items-center justify-between">
              <p class="text-13 text-grey-3">
                {{ $t('wording.voucher') }}
              </p>
              <p class="text-13 font-semibold text-grey-2">
                ${{ dollarFormatting(voucherValue) }}
              </p>
            </div>
          </div>
          <!-- Waiting list disclaimer -->
          <p
            v-if="project.state === 'reserved' && waitingListChecked === 'true'"
            class="text-13 text-grey-2 text-opacity-50 text-left"
          >
            {{ $t('invest.confirmation.waiting_list_disclaimer') }}
          </p>
          <!-- Return per year -->
          <div class="flex items-center justify-between mb-4 lg:hidden">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.confirmation.return') }}
            </p>
            <p class="font-semibold text-sm text-grey-2">
              {{ project.funding_rate.interest_investor }}%
            </p>
          </div>
          <!-- Term -->
          <div class="flex items-center justify-between mb-4 lg:hidden">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.confirmation.term') }}
            </p>
            <p class="font-semibold text-sm text-grey-2">
              {{ monthFormat(project.funding_rate.duration_months) }}
            </p>
          </div>
          <!-- Payment periodicity -->
          <div class="flex items-center justify-between lg:hidden">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.confirmation.periodicity') }}
            </p>
            <p class="font-semibold text-sm text-grey-2">
              {{
                $t('wording.periodicity.' + project.funding_rate.periodicity)
              }}
            </p>
          </div>
        </div>
        <div class="bg-white bg-opacity-60 rounded-b-2xl px-4 py-5 mb-8">
          <!-- Wallet name -->
          <div
            v-if="walletsCount != 1"
            class="flex items-center justify-between mb-4"
          >
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.confirmation.wallet') }}
            </p>
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ wallet.name ?? $t('invest.confirmation.main_wallet') }}
            </p>
          </div>
          <!-- Balance after investment -->
          <div v-if="!topupRequired" class="flex items-center justify-between">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.confirmation.balance_after') }}
            </p>
            <p class="text-sm text-grey-2 text-opacity-80">
              ${{
                dollarFormatting(
                  Math.max(0, (wallet.balance ?? 0) - amountCents + voucherValue)
                )
              }}
            </p>
          </div>
          <div v-else class="flex items-center justify-between">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.confirmation.available_balance') }}
            </p>
            <p class="text-sm text-grey-2 text-opacity-80">
              ${{ dollarFormatting(wallet.balance ?? 0) }}
            </p>
          </div>
        </div>
        <!-- KYC light popup -->
        <div
          v-if="profile.kyc_level === 'light'"
          class="p-4 text-left mb-10 bg-orange-4 rounded-2xl"
        >
          <p class="text-grey-2 text-opacity-80 text-13 font-bold">
            {{ $t('invest.confirmation.no_kyc_title') }}
          </p>
          <p class="text-grey-2 text-opacity-80 text-13">
            {{ $t('invest.confirmation.no_kyc_text') }}
          </p>
        </div>
        <!-- Not enough balance popup -->
        <!-- <TemporaryPopup
            v-if="promiseValue"
            name="invest:balance"
            :closeable-times="0"
            copy="invest.confirmation.balance_popup"
            icon="/img/popup/exclamation_orange.svg"
            btn-link="topup"
            class="mb-10 mt-4"
        >
          <template #content>
            {{
              $t('invest.confirmation.balance_popup.content', {
                missing: dollarFormatting(promiseValue)
              })
            }}
          </template>
        </TemporaryPopup> -->
      </div>
      <div
        class="
          lg:flex lg:justify-end lg:w-3/4
          xl:w-2/3
          2xl:w-1/2
          button-padding-bottom
        "
      >
        <GpButton
          id="gp-button-invest-investment-summary-waiting-list-26"
          v-if="project.state === 'reserved' && !editReservation"
          :disabled="!allChecked || pressed"
          @click="invest"
          color="grey"
        >
          {{ $t('projects.details.waiting_list_btn') }}
        </GpButton>
        <GpButton
          id="gp-button-invest-investment-summary-confirm-25"
          v-else-if="!promiseValue && !kycRequired"
          :disabled="!allChecked || pressed"
          @click="invest"
        >
          {{ $t('wording.confirm') }}
        </GpButton>
        <GpButton
          id="gp-button-invest-investment-summary-reserve-27"
          v-else
          @click="() => (tryInvest = true)"
          color="grey"
        >
          {{ $t('wording.reserve') }}
        </GpButton>
      </div>
      <!-- Close project -->
      <Drawer
        v-if="showCloseProjectDrawer"
        minHeight="h-max"
        @close="close"
        grey
        class="lg:hidden"
      >
        <template v-slot:content>
          <p class="text-left text-grey-3 text-sm font-semibold">
            {{
              $t('invest.confirmation.close_project.text', {
                amount: dollarFormatting(closeProjectAmount)
              })
            }}
          </p>
          <div class="grid grid-cols-2 gap-4 mt-6">
            <GpButton @click="close" color="white">
              {{ $t('invest.confirmation.close_project.no_btn') }}
            </GpButton>
            <GpButton
              @click="
                () => {
                  closeProject = true
                  showCloseProjectDrawer = false
                }
              "
              color="grey"
            >
              {{
                $t('invest.confirmation.close_project.yes_btn', {
                  amount: dollarFormatting(closeProjectAmount)
                })
              }}
            </GpButton>
          </div>
        </template>
      </Drawer>
      <Popup
        class="z-50 hidden lg:flex"
        icon="/img/popup/money_bag_grey.svg"
        :align="center"
        :side="false"
        :show="showCloseProjectDrawer"
        @close="close"
      >
        <template v-slot:content>
          <p class="text-left text-grey-3 text-sm font-semibold">
            {{
              $t('invest.confirmation.close_project.text', {
                amount: dollarFormatting(closeProjectAmount)
              })
            }}
          </p>
          <div class="grid grid-cols-2 gap-4 mt-6">
            <GpButton @click="close" color="white" desktopWidth="w-full">
              {{ $t('invest.confirmation.close_project.no_btn') }}
            </GpButton>
            <GpButton
              @click="
                () => {
                  closeProject = true
                  showCloseProjectDrawer = false
                }
              "
              color="grey"
              desktopWidth="w-full"
            >
              {{
                $t('invest.confirmation.close_project.yes_btn', {
                  amount: dollarFormatting(closeProjectAmount)
                })
              }}
            </GpButton>
          </div>
        </template>
      </Popup>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
import useFlows from '@/mixins/useFlows.js'
import Back from '@/components/general/Back.vue'
import SideBar from '@/components/general/SideBar.vue'
// import TemporaryPopup from '@/components/dashboard/TemporaryPopup.vue'
import GpButton from '@/components/general/GpButton.vue'
import ProjectData from '@/components/invest/ProjectData.vue'
import Popup from '@/components/general/Popup.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import Drawer from '@/components/general/Drawer.vue'
import SuccessCard from '@/components/general/SuccessCard'
export default {
  props: {
    amount: {
      type: String,
      default: ''
    },
    editReservation: {
      type: Number,
      default: 0
    },
    waitingListChecked: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Back,
    SideBar,
    GpButton,
    // TemporaryPopup,
    SuccessCard,
    ProjectData,
    Drawer,
    Popup
  },
  setup(props) {
    const { resetInvestFlow } = useFlows()
    const store = useStore()
    const { dollarFormatting, monthFormat, dollarToNumberFormatting } =
      useGlobalHelpers()
    const { locale, t } = useI18n({ useScope: 'global' })
    const loanAgreement = ref(null)
    const kiis = ref(null)
    const investLimit = ref(null)
    const router = useRouter()
    const validated = ref(null)
    const promiseValue = ref(null)
    const tryInvest = ref(false)
    const success = ref(false)
    const closeProjectAmount = ref(null)
    const closeProject = ref(false)
    const voucherValue = ref(null)
    const pressed = ref(false)
    const reservation = computed(()=>{
      return store.state.invest.investment
    })

    const editReservationFlow = computed(() => {
      return store.state.invest.reservation ? true : false
    })

    resetInvestFlow(-3)

    onBeforeMount(() => {
      if (project.value) {
        const params = {
          amount: parseInt(dollarToNumberFormatting(props.amount ?? 0)),
          project_id: project.value.id
        }
        axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/investments/validation',
            { params }
          )
          .then(({ data }) => {
            validated.value = true
            promiseValue.value =
              data.not_enough_wallet_balance_promise_value ?? null
            closeProjectAmount.value = data.close_project ?? 0
            showCloseProjectDrawer.value =
              data.close_project && data.close_project > 0 ? true : false
            voucherValue.value = data.vouchers_value ?? 0
            if (
              data.project_not_available_balance &&
              project.value.state !== 'reserved'
            ) {
              store.dispatch('setError', {
                response: {
                  custom: true,
                  status: 403,
                  data: {
                    code: 'project_not_available_balance'
                  }
                }
              })
              router.go(-1)
            }
            // Blacklisted or not active
            if (data.user_require_activation || data.user_blacklisted) {
              let code = data.user_require_activation
                ? 'user_require_activation'
                : data.user_blacklisted
                ? 'user_blacklisted'
                : ''
              store.dispatch('setError', {
                response: {
                  custom: true,
                  status: 403,
                  data: {
                    code
                  }
                }
              })
              router.push({ name: 'dashboard' })
            }
          })
          .catch((e) => {
            store.dispatch('setError', e)
            if(e?.response?.data?.errors?.code === 'profile_not_authorized') router.push({ name: 'dashboard' })
          })
      }
    })

    const showCloseProjectDrawer = ref(false)

    const finalAmount = computed(() => {
      return dollarFormatting(
        dollarToNumberFormatting(props.amount) +
          (closeProject.value ? closeProjectAmount.value : 0) +
          voucherValue.value
      )
    })

    const reservationsOnly = computed(() => {
      return process.env.VUE_APP_RESERVATIONS_ONLY == 'true'
    })

    const wallet = computed(() => {
      return store.state.wallets.wallet
    })
    const walletsCount = computed(() => {
      return store.getters.getWalletsCount()
    })

    const project = computed(() => {
      return store.state.projects.project
    })
    
    const signatures = computed (() => {
      return store.state.invest.signatures
    })

    const amountCents = computed(() => {
      return dollarToNumberFormatting(finalAmount.value)
    })

    const allChecked = computed(() => {
      return (
            true
      )
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const kycRequired = computed(() => {
      return profile.value.kyc_level !== 'regular'
    })

    const notDR = computed(() => {
      return !profile.value.dealer_approved
    })

    const topupRequired = computed(() => {
      return promiseValue.value
    })

    const invest = () => {
      pressed.value = true
      let params = {
        amount: (dollarToNumberFormatting(finalAmount.value) - voucherValue.value),
        project_id: project.value.id,
        contract_draft_checkbox: loanAgreement.value,      
        ififc_checkbox: kiis.value,
        risk_ack: signatures.value,
        ...(profile.value.sub_type === 'eligible'
          ? { '12months_declaration_checkbox': investLimit.value }
          : (profile.value.sub_type === 'retail'
          ? { '12months_declaration_checkbox': investLimit.value }
          : undefined))
      }
      if (project.value.state === 'reserved' && !props.editReservation) {
        store.dispatch('setLoading', true)
        axios
          .post(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/investments/waiting-list',
            params
          )
          .then(() => {
            store.dispatch('setLoading', false)
            success.value = true
            tryInvest.value = true
          })
          .catch((e) => {
            store.dispatch('setLoading', false)
            pressed.value = false
            store.dispatch('setError', e)
          })
      } else {
        store.dispatch('setLoading', true)
        params.wallet_id = wallet.value.id
        if (!props.editReservation) {
          axios
            .post(
              process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/investments',
              params
            )
            .then(() => {
              store.dispatch('setLoading', false)
              success.value = true
              tryInvest.value = true
            })
            .catch((e) => {
              pressed.value = false
              store.dispatch('setLoading', false)
              store.dispatch('setError', e)
            })
        } else {
          store.dispatch('setLoading', true)
          axios
            .put(
              process.env.VUE_APP_SERVER_BASE_URL +
                'api/v1/investments/' +
                props.editReservation,
              params
            )
            .then(() => {
              store.dispatch('setLoading', false)
              success.value = true
              tryInvest.value = true
            })
            .catch((e) => {
              store.dispatch('setLoading', false)
              pressed.value = false
              store.dispatch('setError', e)
            })
        }
      }
    }

    function goToTopup() {
      invest()
      router.push({
        name: 'topup',
        params: {
          propWallet: JSON.stringify(wallet.value),
          propProject: JSON.stringify(project.value)
        }
      })
    }
    
    // validation
    const goToKyc = () => {
      invest()
      router.push('/dashboard/kyc')
    }

    const close = () => {
      showCloseProjectDrawer.value = false
    }

    return {
      wallet,
      project,
      dollarFormatting,
      monthFormat,
      amountCents,
      loanAgreement,
      kiis,
      investLimit,
      allChecked,
      invest,
      validated,
      promiseValue,
      topupRequired,
      notDR,
      kycRequired,
      profile,
      tryInvest,
      router,
      walletsCount,
      success,
      goToTopup,
      showCloseProjectDrawer,
      closeProjectAmount,
      close,
      closeProject,
      reservation,
      finalAmount,
      voucherValue,
      editReservationFlow,
      dollarToNumberFormatting,
      pressed,
      locale,
      goToKyc,
      reservationsOnly
    }
  }
}
</script>

<style lang="scss" scoped></style>
