<template>
  <div class="h-1">
    <div class="relative">
      <div
        class="
          absolute
          top-0
          left-0
          h-1
          w-full
          bg-grey-2 bg-opacity-10
          rounded-2xl
        "
      />
      <div
        class="
          absolute
          top-0
          left-0
          h-1
          green-underline-bar
          bg-opacity-10
          rounded-2xl
        "
        :class="progress"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const progress = computed(() => {
      if (props.total > 0) {
        const progress = (props.step / props.total) * 100

        if (progress > 92) {
          return 'w-full'
        } else if (progress > 83) {
          return 'w-5/6'
        } else if (progress > 80) {
          return 'w-4/5'
        } else if (progress > 75) {
          return 'w-3/4'
        } else if (progress > 67) {
          return 'w-2/3'
        } else if (progress > 60) {
          return 'w-3/5'
        } else if (progress > 50) {
          return 'w-1/2'
        } else if (progress > 42) {
          return 'w-5/12'
        } else if (progress > 40) {
          return 'w-2/15'
        } else if (progress > 34) {
          return 'w-2/6'
        } else if (progress > 25) {
          return 'w-1/4'
        } else if (progress > 20) {
          return 'w-1/5'
        } else if (progress > 17) {
          return 'w-1/6'
        } else if (progress > 9) {
          return 'w-1/12'
        }

        return 'w-0'
      }
      return 'w-0'
    })

    return {
      progress
    }
  }
}
</script>

<style scoped>
.green-underline-bar {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.384) 2.53%,
      rgba(255, 255, 255, 0.8) 100%
    ),
    #b3dabb;
}
</style>
