<template>
  <div
    @click="selected"
    class="
      flex
      items-center
      justify-between
      text-left
      p-4
      rounded-3xl
      my-8
      lg:my-6
      bg-white
      green-shadow
      cursor-pointer
    "
    :class="isStrategies || isWithdraw || isTopup ? 'cursor-pointer' : ''"
  >
    <div class="flex items-center">
      <div>
        <div
          class="
            ml-3
            text-base text-grey-2
            lg:text-grey-3
            font-bold
            lg:font-semibold
          "
        >
          {{ account.name }}
        </div>
      </div>
    </div>
    <img
      v-if="!isStrategies"
      src="@/assets/svg/arrows/right_grey_arrow.svg"
      alt=""
      class="mr-4 lg:mr-2"
    />
    <img
      v-else
      src="@/assets/svg/arrows/right_grey_arrow.svg"
      alt=""
      class="mr-4"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  props: {
    account: {
      type: Object,
      default: () => {}
    },
    isStrategies: {
      type: Boolean,
      default: false
    },
    isWithdraw: {
      type: Boolean,
      default: false
    },
    isTopup: {
      type: Boolean,
      default: false
    }
  },
  emits: ['next'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const selected = () => {
      if(props.isWithdraw || props.isTopup){
        emit('next', 'bankAccountChoosen')
      }
      else if (props.isStrategies) {
        store
          .dispatch('updateNewStrategy', {
            bank_account: props.account
          })
      } else {
        router.push({
          name: 'bank-account-details',
          params: { account: JSON.stringify(props.account) }
        })
      }
    }

    return {
      selected
    }
  }
}
</script>

<style lang="postcss" scoped></style>
