<template>
  <div class="z-60">
    <div
      class="h-screen w-screen fixed top-0 popup-bg left-0 z-60"
      :class="{ 'popup-bg--show': show }"
      @click="close"
      :v-if="!disableClose"
    ></div>
    <div
      class="
        z-60
        fixed
        left-1/2
        top-1/2
        min-h-screen
        max-h-screen
        md:min-h-auto 
        overflow-y-hidden
        -translate-y-1/2 -translate-x-1/2
        transform
        scale-0
      "
      :class="[
        bgColor,
        {
          'overflow-y-auto scale-100 popup--show': show && !side,
          'overflow-y-auto scale-100 popup--show-side': show && side,
          'popup--side md:right-0 md:left-auto md:top-0 md:h-screen': side
        },
        docFormat ? 'px-8 w-full lg:w-3/4 lg:max-w-5xl lg:rounded-lg' : 'popup md:p-16 w-screen md:w-auto'
      ]"
    >
      <div v-if="!disableClose" class="fixed top-8 right-8 cursor-pointer">
        <img src="@/assets/svg/cross.svg" alt="" @click="close" />
      </div>
      <div
        class="w-16 h-16 mb-6 rounded-3xl relative mt-6"
        :class="iconBg + (align === 'center' ? ' mx-auto' : '')"
        v-if="icon"
      >
        <div
          class="
            absolute
            left-1/2
            top-1/2
            transform
            -translate-x-1/2 -translate-y-1/2
          "
        >
          <img :src="icon" alt="" class="min-h-5" />
        </div>
      </div>
      <div
        class="text-grey-2 mb-10 mt-6 md:mt-0 text-xl font-medium"
        v-html="title"
        :class="'text-' + align"
      ></div>
      <div :class="side? 'popup-content-wrapper' : ''">
        <div class="overflow-y-auto popup-content" 
            :class="[
              'text-' + align,
              docFormat ? 'doc-max-h-content' : 'max-h-content'
            ]"
        >
          <slot name="content">{{ content }}</slot>
          <slot name="component"></slot>
        </div>
      </div>
      <div class="flex mt-10 gap-4">
        <gp-button
          color="white"
          v-if="secondaryButton"
          @click="close('secondary')"
        >
          {{ secondaryButton }}
        </gp-button>
        <gp-button
          v-if="primaryButton"
          @click="close('primary')"
          class="mx-auto"
        >
          {{ primaryButton }}
        </gp-button>
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
import { useRouter } from 'vue-router'

export default {
  components: {
    GpButton
  },
  props: {
    title: String,
    content: String,
    primaryButton: String,
    secondaryButton: String,
    show: Boolean,
    align: {
      type: String,
      default: 'left'
    },
    side: {
      type: Boolean,
      default: true
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    docFormat: {
      type: Boolean,
      default: false
    },
    icon: String,
    iconBg: {
      type: String,
      default: 'bg-grey-7'
    },
    primaryLink: String,
    secondaryLink: String,
    bgColor: {
      type: String,
      default: 'bg-white'
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const router = useRouter()

    function close(type) {
      if(!props.disableClose)
        if (
          ((props.primaryLink && type === 'primary') ||
          (props.secondaryLink && type === 'secondary'))
        ) {
          router.push(
            type === 'primary' ? props.primaryLink : props.secondaryLink
          )
          emit('close')
        } else {
          emit('close')
        }
    }
    return {
      close
    }
  }
}
</script>
<style scoped>
.popup {
  border-radius: 3rem;
  max-width: 30rem;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.popup--side {
  transform: translate(-50%, 0%) scale(1);
}

@media (min-width: 768px) {
  .popup--side {
    transform: translate(200%, 0%);
    border-radius: 0;
  }

  .popup--show {
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  .popup--show-side {
    transform: translate(0%, 0%);
    pointer-events: all;
    opacity: 1;
  }
}


.popup-bg {
  background: #273441;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  opacity: 0;
}
.popup-bg--show {
  pointer-events: all;
  display: block;
  opacity: 0.4;
}

.max-h-content {
  max-height: calc(100vh - 14rem);
}
.doc-max-h-content {
    max-height: calc(100vh - 10rem);
  }

@media (max-width: 768px) {
  .popup--show-side {
    transform: translate(-50%, -30%);
    pointer-events: all;
    opacity: 1;
  }
  .max-h-content {
    max-height: calc(100vh - 20rem);
  }
  .doc-max-h-content {
    max-height: calc(100vh - 9rem);
  }
  .popup--show {
    pointer-events: all;
    opacity: 1;
    /* transform: translate(-50%, -40%); */
  }
}
</style>
