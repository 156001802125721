import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default function () {
  const flowSteps = (flow) => {
    switch (flow) {
      case 'strategies':
        return {
          steps: 5,
          subSteps: {
            3: 3
          }
        }
    }
  }

  const noNewStrategy = () => {
    const store = useStore()
    const router = useRouter()
    const newStrategy = store.state.strategies.newStrategy

    if (!newStrategy || Object.keys(newStrategy).length === 0) {
      router.push('/strategies/topup')
    }
  }

  const resetInvestFlow = (backValue) => {
    const store = useStore()
    const router = useRouter()
    const wallet = store.state.wallets.wallet
    const project = store.state.projects.project

    if (
      !wallet ||
      Object.keys(wallet).length === 0 ||
      !project ||
      Object.keys(project).length === 0
    ) {
      router.go(backValue)
    }
  }

  return {
    flowSteps,
    noNewStrategy,
    resetInvestFlow
  }
}
