<template>
  <div class="flex flex-col justify-center items-center">
    <!-- title -->
    <div class="text-center">
      <p class="font-bold text-lg text-grey-2 mb-9 mt-6">
        {{ $t('errors.' + caption + '.title') }}
      </p>
    </div>
    <!-- description -->
    <p class="text-sm text-grey-2 text-opacity-60 mb-6">
      {{ $t('errors.' + caption + '.description') }}
    </p>
    <!-- link -->
    <GpButton @click="next()" class="mb-32" v-if="$t('signup.popup.link')">
      {{ $t('signup.popup.continue') }}
    </GpButton>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import GpButton from '@/components/general/GpButton.vue'
export default {
  components: {
    GpButton
  },
  setup() {
    const store = useStore()
    const error = ref(null)
    const close = ref(false)
    const agree = ref(false)
    const i18n = useI18n({ useScope: 'global' })
    const router = useRouter()

    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const caption = computed(() => {
      if (!profile.value.can_check) return 'fail_can_check'
      else if (profile.value.expired_suitability) return 'expired_suitability'
      else return profile.value.sub_type
    })

    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    function currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`
      return date
    }

    function next() {
      if (isBorrower.value) router.push('/settings/bank-accounts')
      else if (profile.value.expired_suitability) router.push('/settings/suitability')
      else if (profile.value.sub_type.includes('pending') || !profile.value.can_check) window.open(i18n.t('settings.suitability.meetDR'))
      else close.value = true
      store.dispatch('setShowPopup', false)
    }

    return {
      next,
      agree,
      close,
      caption,
      currentDate,
      error
    }
  }
}
</script>
