<template>
	<div class="page flex flex-col items-end pb-32 lg:pb-0">
		<SideBar>
			<div class="h-screen relative">
				<GreenBall
					class="steps__ball w-full"
					start="bottom"
					offset="-2rem"
					cutout="0"
				/>
				<img
					src="@/assets/img/windmill-sidebar.png"
					alt=""
					class="absolute bottom-28 h-96 2xl:h-2/3"
				/>
				<div class="text-left w-3/4 mx-auto absolute z-10 left-24">
					<div class="text-3xl text-grey-3 font-medium">
						{{ capitalize($t('auto_investment.self')) }}
					</div>
					<div class="text-grey-3 font-normal text-3xl">
						{{$t('wording.details') }}
					</div>
				</div>
			</div>
		</SideBar>
		<div
			class="strategies__desktop-container"
		>	
			<div
        :class="oldAutoInvestments && oldAutoInvestments.length > 0 ? 'flex-grow' : ''"
				class="
					flex flex-col
          lg:w-3/4
          xl:w-2/3
          2xl:w-1/2
				"
			>
        <Back :to="'/dashboard'" :title="$t('auto_investment.home.title')" />
        <div class="mb-8 lg:mb-12">
          <div v-if="activeAutoInvestment">
            <p class="text-grey-3 text-base mb-8 text-left">{{$t('auto_investment.home.subtitle')}}</p>
            <div class="p-4 bg-white rounded-t-2xl">
              <div class="flex items-center justify-between text-sm mb-4">
              <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.interest_rate')}}</p>
              <p class="text-grey-2 font-semibold">
                {{ activeAutoInvestment.min_interest }}% - {{ activeAutoInvestment.max_interest }}%
              </p>
              </div>
              <div class="flex items-center justify-between text-sm mb-4">
                <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.max_term')}}</p>
                <p class="text-grey-2 font-semibold">
                  {{ $t('wording.year', activeAutoInvestment.max_term) }}
                </p>
              </div>
              <div class="flex items-center justify-between text-sm mb-4">
                <p class="text-grey-3">
                  <span class="lg:hidden">{{$t('auto_investment.setup.confirmation.wallet_balance_mobile')}}%</span> 
                  <span class="hidden lg:block">{{$t('auto_investment.setup.confirmation.wallet_balance')}}</span>
                </p>
                <p class="text-grey-2 font-semibold">
                  &lt;{{ activeAutoInvestment.balance_percentage }}% {{$t('auto_investment.setup.confirmation.available_funds')}}
                </p>
              </div>
              <div class="flex items-center justify-between text-sm mb-4">
                <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.risk')}}</p>
                <p class="text-grey-2 font-semibold">
                  {{ riskConversion(activeAutoInvestment.max_risk) }}
                </p>
              </div>
              <div class="flex items-center justify-between text-sm mb-4" v-if="activeAutoInvestment.project_max">
                <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.project_max')}}</p>
                <p class="text-grey-2 font-semibold">
                ${{ dollarFormatting(activeAutoInvestment.project_max) }}
                </p>
              </div>
              <div class="flex items-center justify-between text-sm" v-if="activeAutoInvestment.project_min">
                <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.project_min')}}</p>
                <p class="text-grey-2 font-semibold">
                  ${{ dollarFormatting(activeAutoInvestment.project_min) }}
                </p>
              </div>
            </div>
            <div class="p-4 bg-white bg-opacity-60 rounded-b-2xl relative">
              <div class="flex items-center justify-between text-sm mb-4">
                <p class="text-grey-3">{{$t('auto_investment.home.created_on')}}</p>
                <p class="text-grey-2 font-semibold">
                  <span>{{ activeAutoInvestment.created_at }}</span>
                </p>
              </div>
              <div class="flex items-center justify-between text-sm mb-4" v-if="activeAutoInvestment.total_invested_amount > 0">
                <p class="text-grey-3">{{$t('auto_investment.home.total_invested')}}</p>
                <p class="text-grey-2 font-semibold">
                  <span>${{ dollarFormatting(activeAutoInvestment.total_invested_amount) }}</span>
                </p>
              </div>
              <div class="flex items-center justify-between text-sm mb-4" v-if="activeAutoInvestment.invested_projects > 0">
                <p class="text-grey-3">{{$t('auto_investment.home.projects_invested')}}</p>
                <p class="text-grey-2 font-semibold">
                  <span>{{ activeAutoInvestment.invested_projects }}</span>
                </p>
              </div>
              <a @click="editAutoInvestment(activeAutoInvestment)" class="absolute right-2 bottom-2 flex">
                <div class="text-sm text-orange-1 font-semibold mr-2.5">{{ capitalize($t('auto_investment.setup.confirmation.edit'))}} {{ $t('auto_investment.self')}}</div>
                <img src="@/assets/svg/strategies/edit_pencil.svg" alt="Edit SVG">
              </a>
            </div>
            <div class="text-center lg:text-right mt-16 mb-6">
              <a @click="renderCancellationPopup()" class="text-orange-1 font-semibold text-base uppercase text-center my-8 mx-auto">{{$t('auto_investment.home.cancel')}}</a>
            </div>
          </div>
          <div v-else>
            <router-link to="/auto-investment/setup" class="cursor-pointer bg-white flex justify-between rounded-3xl transition ease-in-out duration-200 transform hover:shadow-light hover:-translate-y-1 p-6">
              <h2 class="text-sm text-grey-2 font-bold">{{$t('auto_investment.setup.title_sidebar_1')}} {{	$t('auto_investment.self')}}</h2>
              <div class="flex justify-between font-semibold text-orange-1 text-sm">
                <p class="mr-2">{{$t('auto_investment.setup.title_sidebar_1')}}</p>
                <img src="@/assets/svg/chev-right-yellow.svg" alt="">
              </div>
            </router-link>
          </div>
        </div>
        <div v-if="oldAutoInvestments && oldAutoInvestments.length > 0" class="text-left">
          <div class="text-base lg:text-large font-semibold text-grey-2 mb-4">{{$t('auto_investment.home.history')}}</div>
          <a @click="openAccordion(item.id)" v-for="(item, index) in oldAutoInvestments" v-bind:key="item">
            <div class="cursor-pointer bg-white flex justify-between rounded-3xl p-6" :class="openDetails === item.id ? 'mb-1' : 'mb-4'">
              <h2 class="text-sm text-grey-2 font-bold">{{capitalize($t('auto_investment.self'))}} {{index +1 }}</h2>
              <img v-if="openDetails === item.id" src="@/assets/svg/dashboard/arrow-tip-up.svg" alt="Close Accordion SVG">
              <img v-else src="@/assets/svg/dashboard/arrow-tip-down.svg" alt="Open Accordion SVG">
            </div>
            <div>
              <transition>
                <div v-if="openDetails === item.id" class="relative mb-4">
                  <div class="p-4 bg-white rounded-t-2xl">
                    <div class="flex items-center justify-between text-sm mb-4">
                    <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.interest_rate')}}</p>
                    <p class="text-grey-2 font-semibold">
                      {{ item.min_interest }}% - {{ item.max_interest }}%
                    </p>
                    </div>
                    <div class="flex items-center justify-between text-sm mb-4">
                      <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.max_term')}}</p>
                      <p class="text-grey-2 font-semibold">
                        {{ $t('wording.year', item.max_term) }}
                      </p>
                    </div>
                    <div class="flex items-center justify-between text-sm mb-4">
                      <p class="text-grey-3">
                        <span class="lg:hidden">{{$t('auto_investment.setup.confirmation.wallet_balance_mobile')}}%</span> 
                        <span class="hidden lg:block">{{$t('auto_investment.setup.confirmation.wallet_balance')}}</span>
                      </p>
                      <p class="text-grey-2 font-semibold">
                        {{ item.balance_percentage }}% {{$t('auto_investment.setup.confirmation.available_funds')}}
                      </p>
                    </div>
                    <div class="flex items-center justify-between text-sm mb-4">
                      <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.risk')}}</p>
                      <p class="text-grey-2 font-semibold">
                        {{ riskConversion(item.max_risk) }}
                      </p>
                    </div>
                    <div class="flex items-center justify-between text-sm mb-4" v-if="item.project_max">
                      <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.project_max')}}</p>
                      <p class="text-grey-2 font-semibold">
                      {{ dollarFormatting(item.project_max) }}
                      </p>
                    </div>
                    <div class="flex items-center justify-between text-sm" v-if="item.project_min">
                      <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.project_min')}}</p>
                      <p class="text-grey-2 font-semibold">
                        ${{ dollarFormatting(item.project_min) }}
                      </p>
                    </div>
                  </div>
                  <div class="p-4 bg-white bg-opacity-60 rounded-b-2xl">
                    <div class="flex items-center justify-between text-sm mb-4">
                      <p class="text-grey-3">{{$t('auto_investment.home.created_on')}}</p>
                      <p class="text-grey-2 font-semibold">
                        <span>{{ item.created_at }}</span>
                      </p>
                    </div>
                    <div class="flex items-center justify-between text-sm mb-4">
                      <p class="text-grey-3">{{$t('auto_investment.home.cancelled_on')}}</p>
                      <p class="text-grey-2 font-semibold">
                        <span>{{ item.updated_at }}</span>
                      </p>
                    </div>
                    <div class="flex items-center justify-between text-sm mb-4" v-if="item.total_invested_amount > 0">
                      <p class="text-grey-3">{{$t('auto_investment.home.total_invested')}}</p>
                      <p class="text-grey-2 font-semibold">
                        <span>${{ dollarFormatting(item.total_invested_amount) }}</span>
                      </p>
                    </div>
                    <div class="flex items-center justify-between text-sm mb-4" v-if="item.invested_projects > 0">
                      <p class="text-grey-3">{{$t('auto_investment.home.projects_invested')}}</p>
                      <p class="text-grey-2 font-semibold">
                        <span>{{ item.invested_projects }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </a>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import SideBar from '@/components/general/SideBar.vue'
import GreenBall from '@/components/general/GreenBall.vue'
import Back from '@/components/general/Back.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
	components: {
		SideBar,
		GreenBall,
    Back
	},
	name: 'auto-investment',
	setup() {
    const openDetails = ref(false)
		const loading = ref(false)
		const { dollarFormatting, capitalize, riskConversion } = useGlobalHelpers()
		const store = useStore()
    const router = useRouter()

    const oldAutoInvestments = computed(() => {
      if(store.state.autoInvestment.autoInvestmentsCollection) {
        return store.getters.getOldAutoInvestment
      }
			return null
		})

		const activeAutoInvestment = computed(() => {
			return store.getters.getActiveAutoInvestment
		})

    const renderCancellationPopup = () => {
      const popup = {
        align: 'center',
        side: false,
        icon: '/img/popup/exclamation_orange.svg',
        component: 'CancelAutoInvestmentPopup',
        props: { autoInvestmentId: activeAutoInvestment.value.id }
      }
      store.dispatch('setPopup', popup)
    }

    const openAccordion = (value) => {
      if(openDetails.value) openDetails.value = false
      else openDetails.value = value
    }

    const editAutoInvestment = (autoInvestment) => {
      router.push({
        name: 'edit-auto-investment',
        params: { autoInvestment: JSON.stringify(autoInvestment) }
      })
    }

		// Get AutoInvestments
		onMounted(async () => {
      await store.dispatch('fetchAutoInvestments')
		})

		return {
			dollarFormatting,
			loading,
      riskConversion,
			activeAutoInvestment,
      capitalize,
      oldAutoInvestments,
      openAccordion,
      openDetails,
      editAutoInvestment,
      renderCancellationPopup
		}
	}
}
</script>


<style lang="css" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>