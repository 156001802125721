<template>
  <div class="-mt-8 lg:flex items-center" v-if="impact.project_categories">
    <div class="lg:w-1/2">
      <vue3-chart-js
        :id="chartData.id"
        :type="chartData.type"
        :data="chartData.data"
        :options="chartData.options"
      >
      </vue3-chart-js>
    </div>
    <div class="lg:w-1/2 lg:ml-4">
      <div
        v-for="(sector, index) in sectors"
        v-bind:key="sector"
        class="mb-5 flex justify-between"
        :class="{ 'opacity-50': sector.value === 0 }"
      >
        <div
          class="text-grey-3 flex text-sm w-full text-left"
          :class="{
            'text-grey-3': activeIndex !== null && activeIndex !== index
          }"
        >
          <img
            :src="
              require('@/assets/svg/project_categories/' +
                sector.key +
                '_color.svg')
            "
            alt=""
            class="mr-2"
          />
          {{ sector.label }}
        </div>
        <div
          class="text-base font-bold"
          :class="{
            'text-grey-1': activeIndex !== null && activeIndex === index,
            'text-grey-3': activeIndex !== null && activeIndex !== index,
            'text-grey-2': activeIndex === null
          }"
        >
          ${{ dollarFormatting(sector.value) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { ref, computed } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  components: {
    Vue3ChartJs
  },

  setup() {
    const { dollarFormatting } = useGlobalHelpers()
    const i18n = useI18n({ useScope: 'global' })
    const activeIndex = ref(null)
    const store = useStore()
    const impact = computed(() => {
      return store.state.impact.impact
    })

    const sectors = computed(() =>{
      const array = [
        {
          key: 'blue_economy',
          label: i18n.t('impact.labels.blue_economy'),
          value: impact.value.project_categories && impact.value.project_categories.blue_economy
            ? impact.value.project_categories.blue_economy.total
            : 0,
          percentage: impact.value.project_categories && impact.value.project_categories.blue_economy
            ? impact.value.project_categories.blue_economy.percentage
            : 0,
          color: 'rgba(37, 149, 185, 1)',
          thickness: 100
        },
        {
          key: 'business_in_transition',
          label: i18n.t('impact.labels.business_in_transition'),
          value: impact.value.project_categories && impact.value.project_categories.business_in_transition
            ? impact.value.project_categories.business_in_transition.total
            : 0,
          percentage: impact.value.project_categories && impact.value.project_categories.business_in_transition
            ? impact.value.project_categories.business_in_transition.percentage
            : 0,
          color: 'rgba(77, 203, 132, 1)',
          thickness: 110
        },
        {
          key: 'green_use_of_land',
          label: i18n.t('impact.labels.green_use_of_land'),
          value: impact.value.project_categories && impact.value.project_categories.green_use_of_land
            ? impact.value.project_categories.green_use_of_land.total
            : 0,
          percentage: impact.value.project_categories && impact.value.project_categories.green_use_of_land
            ? impact.value.project_categories.green_use_of_land.percentage
            : 0,
          color: 'rgba(161, 139, 105, 1)',
          thickness: 95
        },
        {
          key: 'sustainable_energy',
          label: i18n.t('impact.labels.sustainable_energy'),
          value: impact.value.project_categories && impact.value.project_categories.sustainable_energy
            ? impact.value.project_categories.sustainable_energy.total
            : 0,
          percentage: impact.value.project_categories && impact.value.project_categories.sustainable_energy
            ? impact.value.project_categories.sustainable_energy.percentage
            : 0,
          color: 'rgba(255, 186, 53, 1)',
          thickness: 100
        },
        {
          key: 'social_economy',
          label: i18n.t('impact.labels.social_economy'),
          value: impact.value.project_categories && impact.value.project_categories.social_economy
            ? impact.value.project_categories.social_economy.total
            : 0,
          percentage: impact.value.project_categories && impact.value.project_categories.social_economy
            ? impact.value.project_categories.social_economy.percentage
            : 0,
          color: 'rgba(233, 136, 115, 1)',
          thickness: 115
        }
      ]
      return array
    })

    const chartData = computed(() => {
      const config = {
        type: 'doughnut',
        data: {
          labels: sectors.value.map((a) => a.label),
          datasets: [
            {
              backgroundColor: sectors.value.map((a) => a.color),
              borderWidth: 0,
              data: sectors.value.map((a) => a.value)
            }
          ]
        },
        options: {
          onHover: (evt, el, chart) => {
            if (el[0]) {
              chart.getDatasetMeta(0).data.forEach((el) => {
                if (!el.active)
                  el.options.backgroundColor = el.options.backgroundColor.replace(
                    ', 1)',
                    ', 0.5)'
                  )
              })
              activeIndex.value = el[0].index
            } else {
              chart.getDatasetMeta(0).data.forEach((el) => {
                el.options.backgroundColor = el.options.backgroundColor.replace(
                  ', 0.5)',
                  ', 1)'
                )
              })
              activeIndex.value = null
            }
          },

          plugins: {
            legend: {
              display: false
            },
            thickness: { thickness: sectors.value.map((a) => a.thickness) },
            tooltip: {
              enabled: false,

              external: function (context) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip')

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div')
                  tooltipEl.classList.add(
                    'bg-white',
                    'p-4',
                    'rounded-2xl',
                    'relative'
                  )
                  tooltipEl.id = 'chartjs-tooltip'
                  document.body.appendChild(tooltipEl)
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip

                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0
                  return
                }
                var innerHtml =
                  '<div class="text-grey-3 text-11 ml-6 mb-2">' +
                  tooltipModel.dataPoints[0].label +
                  '</div>'
                innerHtml +=
                  '<div class="text-grey-2 font-semibold text-13">' +
                  Math.round(
                    sectors.value[tooltipModel.dataPoints[0].dataIndex].percentage
                  ) +
                  '' +
                  i18n.t('impact.contribution.chart.tooltip.description') +
                  '</div>'
                tooltipEl.innerHTML = innerHtml
                const img = document.createElement('img')
                img.src = require('@/assets/svg/project_categories/' +
                  sectors.value[tooltipModel.dataPoints[0].dataIndex].key +
                  '_color.svg')
                img.classList.add('absolute', 'top-4', 'left-4')
                tooltipEl.prepend(img)
                var position = context.chart.canvas.getBoundingClientRect()
                var bodyFont = 'Popins'

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1
                tooltipEl.style.position = 'absolute'
                tooltipEl.style.left =
                  position.left + window.pageXOffset + tooltipModel.caretX + 'px'
                tooltipEl.style.top =
                  position.top + window.pageYOffset + tooltipModel.caretY + 'px'
                tooltipEl.style.font = bodyFont.string
                tooltipEl.style.padding = '16px'
                tooltipEl.style.pointerEvents = 'none'
              }
            }
          }
        }
      }
      return config
    })

    Vue3ChartJs.registerGlobalPlugins([
      {
        id: 'thickness',
        beforeDraw: (chart) => {
          let thicknesses = chart.options.plugins.thickness.thickness
          if (chart.getDatasetMeta(0).data) {
            chart.getDatasetMeta(0).data.forEach((el, index) => {
              el.outerRadius = thicknesses[index]
            })
          }
        }
      },
      {
        id: 'text-inside',
        beforeDraw: function (chart) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx

          ctx.restore()
          ctx.font = '500 1.875rem Popins'
          ctx.textBaseline = 'middle'

          var text ='$' + 
              dollarFormatting(impact.value.project_categories.total).slice(
                0,
                -3
              ),
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2
          ctx.fillStyle = '#273441'
          ctx.fillText(text, textX, textY)

          var text2 = i18n.t('impact.contribution.chart.title')
          ctx.font = '0.688rem Popins'
          ctx.textBaseline = 'middle'
          textX = Math.round((width - ctx.measureText(text2).width) / 2)
          textY = height / 2
          ctx.fillStyle = 'rgba(79, 89, 99, 0.5)'

          ctx.fillText(text2, textX, textY + 25)

          ctx.save()
        }
      }
    ])

    return { chartData, sectors, dollarFormatting, activeIndex, impact }
  }
}
</script>
