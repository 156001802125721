import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import {handle_onboarding} from "@/assets/ts/onboarding/callbacks/handle_onboarding";

const questions:PageForm =
  {
    title: i18n.global.t('ca_legislation.onboarding.sidebar_title'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.onboarding.questions_company.title_questions_company3"),
        fields: [
            {
              key: "name",
              title: i18n.global.t("ca_legislation.onboarding.questions.name_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.name_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "surname",
              title: i18n.global.t("ca_legislation.onboarding.questions.surname_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.surname_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "date_of_birth",
              title: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_text"),
              limit: {min: 18, max: 100},
              type: Types.DATE,
              rules: 'required'
            },
            {
              key: "legal_country",
              title: i18n.global.t("ca_legislation.onboarding.questions.country_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.country_text"),
              type: Types.COUNTRIES,
              rules: 'required'
            },
            {
              key: "address_rep",
              title: i18n.global.t("ca_legislation.onboarding.questions.address_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.address_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "post_code_rep",
              title: i18n.global.t("ca_legislation.onboarding.questions.post_code_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.post_code_text"),
              type: Types.STRING,
              rules: 'required|exactLength:6'
            },
            {
              key: "city_rep",
              title: i18n.global.t("ca_legislation.onboarding.questions.city_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.city_text"),
              type: Types.STRING,
              rules: 'required'
            }
        ],
        next: true,
        nextCallback: handle_onboarding,
        back: true
      }
    ]
  }

export default questions
