<template>
  <div class="bg-white status">
    <div class="status__header flex justify-between py-4 px-6">
      <div class="text-left">
        <div class="font-bold text-grey-95 opacity-80">
          ${{ dollarFormatting(vouchers.total) }} {{ $t('wording.earned') }}
        </div>
        <div class="text-xs opacity-50">
          {{ vouchers.number }} {{ $t('wording.people_invited') }}
        </div>
      </div>
      <div class="font-semibold text-grey-2 cursor-pointer see-all">
        <router-link to="invite/all">
          <span> {{ $t('wording.see_all') }} </span>
          <i
            class="arrow arrow--relative right right--small cursor-pointer"
          ></i>
        </router-link>
      </div>
    </div>
    <div class="py-4 px-6 text-grey-95 opacity-80">
      <div
        class="flex justify-between text-sm"
        v-bind:key="voucher"
        v-for="(voucher, index) in vouchers.vouchers"
        :class="{ 'mb-6': index < vouchers.vouchers.length - 1 }"
      >
        <div v-if="voucher.name && voucher.surname">{{ voucher.name + ' ' + voucher.surname }}</div>
        <div v-else>{{$t('wording.no_name')}}</div>
        <div :class="{ 'opacity-20': voucher.state !== 'used' }">
          {{
            voucher.state === 'used'
              ? formatDate(new Date(voucher.updated_at.replace(/-/g, "/")))
              : voucher.state === 'expired' ? $t('wording.expired')  : $t('wording.pending') + '...'
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core'
import { format } from 'date-fns'
import useGlobalHelpers from "@/mixins/useGlobalHelpers";

export default {
  components: {},
  props:{
    vouchers:{
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { dollarFormatting } = useGlobalHelpers()

    const earned = computed(() => {
      if (props.vouchers.value)
        return props.vouchers.value.reduce((a, b) => a + (b['value'] || 0), 0)
    })

    function formatDate(date) {
      return format(date, 'dd/MM/Y')
    }

    return {
      earned,
      dollarFormatting,
      formatDate
    }
  }
}
</script>
<style scoped>
.status {
  border-radius: 2rem;
}

.status__header {
  background-color: rgba(239, 241, 237, 0.4);
}
</style>
