<template>
  <div>
    <!-- successful payout popup -->
    <!-- title -->
    <div class="text-center mb-6">
      <p class="font-bold text-lg text-grey-2 mb-9 mt-6">
        {{ $t('errors.goodpayout.title') }}
      </p>
      <p class="text-sm text-grey-2 text-opacity-60">
        {{ $t('errors.goodpayout.description') }}
      </p>
    </div>
    <GpButton class="mx-auto" @click="next()">
      {{ $t('errors.goodpayin.ok') }}
    </GpButton>
  </div>
</template>
<script>
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    GpButton
  },
  setup() {
    function next() {
      window.location.href = '/dashboard'
    }
    return {
      next
    }
  }
}
</script>