import { createI18n } from 'vue-i18n'
import { VueCookieNext } from 'vue-cookie-next'
// import useSecurity from '@/mixins/useSecurity'

// const { decrypt } = useSecurity()
let profile = VueCookieNext.getCookie('profile')
// if (profile) profile = decrypt(profile)
// profile = JSON.parse(profile)
var locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');

const i18n = createI18n({
  legacy: false,
  fallbackLocale: 'en',
  globalInjection: true,
  locale: (locale.trim().length && locale != "/" && ['en', 'pt-pt','fr'].includes(locale)) ? locale : profile && profile.language ? profile.language : 'en',
  messages: {
    en: require('../locales/en.json'),
    'pt-pt': require('../locales/pt.json'),
    es: require('../locales/fr.json')
  }
})
export default i18n