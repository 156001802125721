<template>
  <div>
    <div class="hidden lg:block fixed top-0 left-0 bg-green-7 w-1/3 h-screen" />
    <div
      class="
        hidden
        lg:block
        fixed
        top-0
        left-0
        flex-grow flex flex-col
        lg:pt-28
        side-bar
        w-1/3
      "
    >
      <slot />
    </div>
  </div>
</template>
