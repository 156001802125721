const settings = [
  'min_investment',
  'min_topup',
  'default_max_12months_investments_accredited',
  'default_max_12months_investments_eligible',
  'default_max_12months_investments_retail',
  'default_legal_age',
  'british_columbia_max_12months_investments_no_limits',
  'british_columbia_legal_age',
  'manitoba_max_12months_investments_only_retail_limit',
  'nova_scotia_legal_age',
  'length_check_low',
  'length_check_med',
  'length_check_high',
  'vp_org_limit',
  'vp_indv_limit',
  'vp_investment_limit',
  'concentration',
  'accredited_net_worth',
  'accredited_assets_value',
  'accredited_personal_income',
  'accredited_spouse_income',
  'eligible_net_worth',
  'eligible_personal_income',
  'eligible_spouse_income'
];

module.exports = settings;