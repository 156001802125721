import axios from 'axios'
export default {
  state: {
    popup: null
  },
  mutations: {
    SET_POPUP(state, data) {
      state.popup = data
    }
  },
  actions: {
    fetchPopup({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/popup')
        .then((data) => {
          commit('SET_POPUP', data.data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    }
  },
  getters: {}
}
