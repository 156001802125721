import axios from 'axios'
export default {
  state: {
    autoInvestment: {},
    autoInvestmentsCollection: [],
    error: null
  },
  mutations: {
    UPDATE_NEW_AUTO_INVESTMENT(state, data) {
      state.autoInvestment = {
        ...state.autoInvestment,
        ...data
      }
    },
    SET_AUTO_INVESTMENTS_COLLECTION(state, data) {
      state.autoInvestmentsCollection = data
    },
  },
  actions: {
    updateNewAutoInvestment({ commit }, value) {
      commit('UPDATE_NEW_AUTO_INVESTMENT', value)
    },
    // eslint-disable-next-line no-empty-pattern
    createAutoInvestment({}, data) {
      return axios.post(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/auto-investments',
        data
      )
    },
    // eslint-disable-next-line no-empty-pattern
    updateAutoInvestment({}, data) {
      return axios.put(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/auto-investments/' + data.id,
        data
      )
    },
    fetchAutoInvestments({ commit }) {
      return axios.get(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/auto-investments'
      ).then(({data}) => {
        commit('SET_AUTO_INVESTMENTS_COLLECTION', data)
      }).catch((e) => {
        console.log(e)
      })
    },
    // eslint-disable-next-line no-empty-pattern
    cancelAutoInvestment({}, data) {
      return axios.delete(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/auto-investments/' + data
      )
    },
  }, 
  getters: {
    getActiveAutoInvestment: (state) => {
      return state.autoInvestmentsCollection.find((autoInvestment) => autoInvestment.status === 1)
    },
    getOldAutoInvestment: (state) => {
      return state.autoInvestmentsCollection.filter((autoInvestment) => autoInvestment.status === 0)
    },
  }
}
