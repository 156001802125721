<template>
  <div >
    <div class="steps text-left pt-4 md:pt-0 h-full relative">
        <Back id="gp-link-app-giftcards-confirm-payment-back-13" swiper/>
      <div >
        <div class="flex md:hidden pb-4 mb-6 border-b border-grey-6">
          <img
            src="@/assets/svg/arrows/left_grey_arrow.svg"
            alt=""
            class="mr-5 block md:hidden mt-1"
            @click="previous"
          />
          <p class="text-xl font-bold text-grey-2 ml-0">{{$tm('giftcards.confirm.title')}}</p>
        </div>
        <div class="steps__title mt-8">{{ $tm('giftcards.confirm.title') }}</div>
        <div class="steps__subtitle mt-5">{{ $tm('giftcards.confirm.subtitle') }}</div>
        <Table :data="tableData" />
      </div>
      <div class="lg:flex mt-12 gap-4 w-full">
        <router-link to="/dashboard">
          <gp-button
            id="gp-button-app-giftcards-confirm-payment-cancel-14"
            color="white"
            class="mb-4 md:mb-0"
            @click="cancel"
          >
            {{ $t('giftcards.confirm.secondary_button') }}
          </gp-button>
        </router-link>
        <gp-button
          id="gp-button-app-giftcards-confirm-payment-confirm-15"
          class="ml-auto"
          @click="confirm"
        >
          {{ $t('giftcards.confirm.primary_button') }}
        </gp-button>
      </div>
    </div>
  </div>
</template>
<script>
import Back from "@/components/general/Back";
import { useStore } from "vuex";
import { ref, watch, computed } from 'vue'
import { useI18n } from "vue-i18n";
import Table from "@/components/general/Table";
import useGlobalHelpers from "@/mixins/useGlobalHelpers";
import GpButton from "@/components/general/GpButton";
import axios from 'axios'
import { useRouter } from "vue-router";

export default {
  components: {
    Back,
    Table,
    GpButton
  },
  props:{
    isActive:{
      type: Boolean,
      default: false
    }
  },
  emits:['next', 'previous'],
  setup(props, { emit }) {
    const store = useStore()
    const tableData = ref({})
    const i18n = useI18n()
    const {dollarFormatting} = useGlobalHelpers()
    const profile = computed(() => {
      return store.getters.getProfile()
    })

    function previous(){
      emit('previous')
    }

    const router = useRouter()

    function setData(){
      const total = store.state.general.giftcards.data.reduce(function(sum, current) {
        return sum + parseFloat(current.amount.replace(/\,/g, ''));
      }, 0)*100

      if(store.state.general.giftcards.wallet && store.state.general.giftcards.method === 'wallet') {
        tableData.value.top = [
          {
            title: i18n.t("giftcards.confirm.table.amount"),
            value:  "$" + dollarFormatting(total)
          },
          {
            title: i18n.t("giftcards.confirm.table.method"),
            value: i18n.t("giftcards.method." + store.state.general.giftcards.method + ".title")
          }
        ];
        tableData.value.bottom = [
          {
            title: i18n.t('giftcards.confirm.table.balance'),
            value: '$' + dollarFormatting(store.state.general.giftcards.wallet.balance - total)
          }
        ]
      }
      else
        tableData.value.top = [
          {
            title: i18n.t('giftcards.confirm.table.amount'),
            value: '$' + dollarFormatting(total)
          },
          {
            title: i18n.t('giftcards.confirm.table.method'),
            value: i18n.t('giftcards.method.' + store.state.general.giftcards.method + '.title'),
          }
        ]
    }

    function cancel(){
      const popup = {
        title: i18n.t('giftcards.popup.cancel-title'),
        align: 'center',
        side: false,
        iconBg: 'bg-grey-6',
        icon: '/img/popup/exclamation.svg',
        primaryButton: i18n.t('giftcards.popup.btn')
      }
      store.dispatch('clearGiftcards')
      store.dispatch('setPopup', popup)
      router.push({name: 'dashboard'})
    }

    function confirm(){
      store.dispatch('setLoading', true)

      let name = ''
      let amount = ''
      let extra_info = ''
      store.state.general.giftcards.data.forEach((giftcard, index) => {
        name +=
          (index !== 0 ? ',' : '') + encodeURIComponent(giftcard.name)
        amount += (index !== 0 ? ',' : '') + parseFloat(giftcard.amount.replace(/\,/g, ''))*100
        extra_info += (index !== 0 ? ';' : '') + encodeURIComponent(JSON.stringify({message: giftcard.message, template: giftcard.template, language: i18n.locale.value }))
      })
      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', profile.value.email)
      formData.append('campaign_id', 2)
      formData.append('personal', true)
      formData.append('extra_info', extra_info)
      formData.append('amount', amount)
      formData.append('language', i18n.locale.value)

      if(store.state.general.giftcards.wallet && store.state.general.giftcards.method === 'wallet')
        formData.append('vp_wallet_id', store.state.general.giftcards.wallet.id)
        formData.append('redirect_url', process.env.VUE_APP_FE_BASE_URL+'vouchers/redeem'
        )

      axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/giftcards', formData).then(() => {
          store.dispatch('setLoading', false)
          const popup = {
            title: i18n.t('giftcards.popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            content: i18n.t('giftcards.popup.desc'),
            primaryButton: i18n.t('giftcards.popup.btn')
          }
          store.dispatch('clearGiftcards')
          store.dispatch('setPopup', popup)
          router.push({name: 'dashboard'})
      }).catch((e)=>{
        store.dispatch('setLoading', false)
        store.dispatch('setError',e)
      })
    }

    watch(
      () => props.isActive,
      async () => {
        if (props.isActive) {
          setData();
        }
      }
    )

    function proceed(type){
      store.dispatch('setGiftcards', {type: type })
      emit('next');
    }

    return {
      proceed,
      previous,
      setData,
      cancel,
      confirm,
      tableData
    }
  }
}

</script>