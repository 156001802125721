<template>
  <div class="w-full">
    <div class="flex justify-between mb-2">
      <div>
        <p class="text-11 text-grey text-opacity-60 text-left">
          {{ $t('wording.current') }}
        </p>
        <div class="font-bold text-green-1 text-sm" v-if="current !== null">
          {{ current }} 
        </div>
      </div>
      <div>
        <p class="text-11 text-grey text-opacity-60 text-left">
          {{ $t('wording.complete') }}
        </p>
        <div
          class="text-grey text-sm"
          :class="{ 'font-bold': current === null }"
        >
          {{ goal }} 
        </div>
      </div>
    </div>
    <div class="progress-bar opacity-30 bg-grey flex justify-between"></div>
    <div
      class="progress-bar bg-green-2"
      :style="'width: ' + (current / goal) * 100 + '%;'"
    ></div>
  </div>
</template>

<script>

export default {
  props: {
    goal: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: null
    }
  },
  setup() { }
}
</script>
<style lang="postcss" scoped>
.progress-bar {
  @apply h-2 top-0;
}
</style>
