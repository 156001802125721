<template>
  <div
    class="
      bg-green-signup
      h-screen
      w-screen
      fixed
      top-0
      left-0
      z-50
      container
      transform
      transition-all
    "
    :class="show ? 'scale-100' : 'scale-0'"
    v-if="voucher"
  >
    <div class="mb-8 flex items-end">
      <img
        src="@/assets/img/logo/horizontal_black.svg"
        alt=""
        class="mb-10 w-32"
      />

      <GreenBall
        class="w-40"
        start="top"
        offset="2rem"
        cutout="-10rem"
        side="right"
      />

      <GreenBall
        class="w-16 opacity-30"
        start="top"
        offset="4rem"
        cutout="-8rem"
        side="left"
      />
      <div class="-mr-16 transform scale-75">
        <img src="@/assets/img/plant-hand.png" alt="" class="" />
      </div>
    </div>
    <div
      class="font-medium text-4xl text-grey-1 text-left mb-4"
      v-html="$t('signup.slider_voucher.0.title')"
    ></div>
    <div
      class="text-sm text-grey-1 text-left"
      v-html="$t('signup.slider_voucher.0.subtitle', { voucher })"
    ></div>
    <div class="bottom-6 absolute w-100 container left-0">
      <GpButton class="mb-4" color="grey" @click="hide">
        Open Account
      </GpButton>
      <router-link :to="{ name: 'login'}">
        <GpButton color="white"> Log in </GpButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton'
import GreenBall from '@/components/general/GreenBall'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
export default {
  props: {
    show: Boolean
  },
  components: { GpButton, GreenBall },
  emits: ['hide'],
  setup(props, { emit }) {
    const route = useRoute()
    const voucher = computed(() => {
      return route.params.voucher
    })
    function hide() {
      emit('hide')
    }
    return { voucher, hide }
  }
}
</script>
