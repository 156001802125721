<template>
  <div>
    <div class="steps text-left pt-4 md:pt-0 h-full md:overflow-hidden">
      <div class="md:flex md:flex-row-reverse">
        <div class="flex md:hidden pb-4 mb-6 border-b border-grey-6">
          <router-link to="dashboard">
            <img
              src="@/assets/svg/arrows/left_grey_arrow.svg"
              alt=""
              class="mr-5 block md:hidden mt-1"
            />
          </router-link>
          <p class="text-xl font-bold text-grey-2 ml-0">
            {{
              edit
                ? $tm('giftcards.form.title_edit')
                : $tm('giftcards.form.title')
            }}
          </p>
        </div>
        <p class="text-base text-grey-3 ml-0 mb-6 block md:hidden">
          {{ $tm('giftcards.form.subtitle') }}
        </p>

        <div class="w-full md:w-1/2 md:mt-20">
          <div
            v-if="edit"
            style="max-width: 300px"
            class="mx-auto hidden md:block"
          >
            <Card
              :amount="giftcards[selected].amount"
              :name="giftcards[selected].name"
              :message="giftcards[selected].message"
              :template="giftcards[selected].template"
              class="w-full"
            />
          </div>
          <div
            v-for="(card, index) in giftcards"
            :key="card.name + '-card'"
            :class="{ 'mt-8': index > 0 }"
            style="max-width: 300px"
            class="mx-auto hidden md:block"
            v-else
          >
            <Card
              :amount="card.amount"
              :name="card.name"
              :message="card.message"
              :template="card.template"
              @click="editGiftcard(index)"
              class="w-full"
            />
            <div
              v-if="giftcards.length > 1 && !edit"
              class="flex justify-between"
            >
              <div
                id="gp-link-app-giftcards-finalize-edit-8"
                class="
                  flex
                  text-orange-1 text-sm
                  font-semibold
                  mt-2
                  cursor-pointer
                "
                @click="editGiftcard(index)"
              >
                <img
                  src="@/assets/svg/strategies/edit_pencil.svg"
                  alt="Edit SVG"
                  class="mr-2"
                />
                {{ $t('giftcards.edit') }}
              </div>
              <div
                id="gp-link-app-giftcards-finalize-delete-9"
                class="
                  flex
                  text-orange-1 text-sm
                  font-semibold
                  mt-2
                  cursor-pointer
                "
                @click="deleteGiftcard(index)"
              >
                <img
                  src="@/assets/svg/garbage-orange.svg"
                  alt="Edit SVG"
                  class="mr-2"
                />
                {{ $t('wording.delete') }}
              </div>
            </div>
          </div>
          <div
            style="max-width: 370px"
            class="block md:hidden mx-auto mt-8 mb-4"
            v-if="giftcards[selected]"
          >
            <Card
              :name="giftcards[selected].name"
              :amount="giftcards[selected].amount"
              :template="giftcards[selected].template"
              :message="giftcards[selected].message"
              no-shadow
            />
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <div class="steps__title mt-8">
            {{
              giftcards.length > 1 && !edit
                ? $tm('giftcards.form.title_finalize')
                : edit ? $tm('giftcards.form.title_edit') : $tm('giftcards.form.title')
            }}
          </div>
          <div class="steps__subtitle hidden md:block mt-5">
            {{
              giftcards.length > 1 && !edit
                ? $tm('giftcards.form.subtitle_finalize')
                : $tm('giftcards.form.subtitle')
            }}
          </div>
          <Form class="form" ref="form" v-slot="{ errors, meta }">
            <div class="text-grey-giftcard-title font-medium mb-2">
              {{ $t('giftcards.form.fields.name.title') }}
            </div>
            <GeneralInput
              v-model:data="giftcards[selected].name"
              type="text"
              name="name"
              :autocomplete="true"
              :label="$t('giftcards.form.fields.name.placeholder')"
              rules="required|maxLength:65|address"
              :error="errors.name"
              :locked="!isMobile && giftcards.length > 1 && !edit"
              class="mb-6"
            />

            <div class="text-grey-giftcard-title font-medium mb-2">
              {{ $t('giftcards.form.fields.amount.title') }}
            </div>
            <GeneralInput
              v-model:data="giftcards[selected].amount"
              type="dollar"
              name="amount"
              :autocomplete="true"
              :label="$t('giftcards.form.fields.amount.placeholder')"
              rules="required|minValue:2000|maxValue:100000"
              :error="errors.amount"
              :locked="!isMobile && giftcards.length > 1 && !edit"
              class="mb-6"
            />

            <!-- <div class="text-grey-giftcard-title font-medium mb-2">
              {{ $t('giftcards.form.fields.illustration.title') }}
              <span style="color: #a3a8ad">({{ $t('wording.optional') }})</span>
            </div>
            <div class="flex mb-6">
              <div class="w-1/2 mr-4">
                <GiftcardCardGreen
                  id="gp-button-app-giftcards-create-green-2"
                  class="cursor-pointer"
                  @click="giftcards[selected].template = 'christmas'"
                  :class="{
                    'border border-orange-giftcard':
                      giftcards[selected].template === 'christmas'
                  }"
                />
              </div>
              <div class="w-1/2">
                <GiftcardCardWhite
                  id="gp-button-app-giftcards-create-white-3"
                  class="cursor-pointer"
                  @click="giftcards[selected].template = 'default'"
                  :class="{
                    'border border-orange-giftcard':
                      giftcards[selected].template === 'default'
                  }"
                />
              </div>
            </div> -->

            <!-- <div class="text-grey-giftcard-title font-medium mb-2">
              {{ $t('giftcards.form.fields.message.title') }}
              <span style="color: #a3a8ad">({{ $t('wording.optional') }})</span>
            </div>
            <GeneralInput
              v-model:data="giftcards[selected].message"
              type="textarea"
              name="message"
              :autocomplete="true"
              :label="$t('giftcards.form.fields.message.placeholder')"
              rules="maxLength:60"
              :max-length="60"
              :error="errors.message"
              :locked="!isMobile && giftcards.length > 1 && !edit"
              class="mb-6"
            /> -->
            <div
              class="lg:flex mt-12 gap-4 hidden md:block absolute"
              style="margin-bottom: calc(var(--sab) + 115px)"
            >
              <!-- <gp-button
                :id=" edit?'gp-button-app-giftcards-edit-delete-6' : 'gp-button-app-giftcards-create-add-new-4'"
                color="white"
                class="mb-4 md:mb-0"
                desktop-width="w-80"
                @click="addGiftcard"
                :disabled="!meta.valid && !edit"
              >
                <img
                  src="@/assets/svg/garbage-orange.svg"
                  class="mr-2"
                  alt=""
                  v-if="edit"
                />
                {{
                  edit ? $t('wording.delete') : $t('giftcards.secondary_button')
                }}
              </gp-button> 
              <gp-button
                :id=" edit?'gp-button-app-giftcards-create-save-7' : 'gp-button-app-giftcards-create-proceed-payment-5'"
                @click="proceed"
                class="mx-auto"
                desktop-width="w-80"
                :disabled="!meta.valid || !giftcards[selected].amount || !giftcards[selected].name"
              >
                {{ edit ? $t('wording.save') : $t('giftcards.primary_button') }}
              </gp-button> -->
            </div>
            <div
              class="lg:flex mt-12 gap-4 block md:hidden "
              style="margin-bottom: calc(var(--sab) + 115px)"
            >
              <!-- <gp-button
                color="white"
                class="mb-4 md:mb-0"
                @click="addGiftcard"
                :disabled="!meta.valid && !edit"
              >
                <div class="flex">
                  <img
                    src="@/assets/svg/garbage-orange.svg"
                    class="mr-2"
                    alt=""
                    v-if="edit"
                  />
                  {{
                    edit
                      ? $t('wording.delete')
                      : giftcards.length > 1
                      ? $t('wording.cancel')
                      : $t('giftcards.secondary_button')
                  }}
                </div>
              </gp-button> -->
              <gp-button
                id="gp-button-app-giftcards-edit-save-7"
                @click="proceed"
                class="mx-auto"
                :disabled="!meta.valid || !giftcards[selected].amount || !giftcards[selected].name || !isKycRegular"
              >
                {{ edit ? $t('wording.save') : $t('wording.continue') }}
              </gp-button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from 'vee-validate'
import { computed, ref, watch } from 'vue'
import GeneralInput from '@/components/general/Input.vue'
// import GiftcardCardGreen from '@/components/giftcards/GiftcardCardGreen'
// import GiftcardCardWhite from '@/components/giftcards/GiftcardCardWhite'
import Card from '@/components/giftcards/Card'
import GpButton from '@/components/general/GpButton'
import { useStore } from 'vuex'

export default {
  components: {
    Card,
    // GiftcardCardWhite,
    // GiftcardCardGreen,
    Form,
    GpButton,
    GeneralInput
  },
  props: {
    isActive: {
      type: Boolean,
      default: true
    }
  },
  emits: ['next'],
  setup(props, { emit }) {
    const selected = ref(0)
    const form = ref(null)
    const edit = ref(false)
    const giftcards = ref([
      { name: '', amount: '', message: '', template: 'default' }
    ])
    const store = useStore()
    const swiper = ref(null)
    const action = computed(() => {
      return store.state.general.giftcards?.action
    })
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const isKycRegular = computed(() => {
      return profile.value.kyc_level === 'regular' ? true : false
    })

    function editGiftcard(index) {
      selected.value = index
      edit.value = true
    }

    function slideChange() {
      selected.value = swiper.value.activeIndex
      edit.value = true
    }

    function deleteGiftcard(index) {
      giftcards.value.splice(index, 1)
      selected.value--
      edit.value = false
    }

    function isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    }

    watch(
      () => props.isActive,
      async () => {
        if (props.isActive && action.value) {
          if (store.state.general.giftcards?.data)
            giftcards.value = store.state.general.giftcards?.data
          if (action.value === 'add') addGiftcard()
          if (action.value.includes('edit'))
            editGiftcard(action.value.split('@')[1])
          store.dispatch('setGiftcards', { action: null })
        }
      }
    )

    function setSwiper(value) {
      swiper.value = value
    }

    async function addGiftcard() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      if (edit.value) {
        giftcards.value.splice(selected.value, 1)
        edit.value = false
        if (isMobile()) {
          store.dispatch('setGiftcards', { data: giftcards.value })
          emit('next')
        }
      } else {
        if (!isMobile()) {
          edit.value = true
        }
        if(giftcards.value.length > 1 && isMobile() && edit.value){
          giftcards.value.splice(selected.value,1)
          store.dispatch('setGiftcards', {data: giftcards.value })
          emit('next');
        }
        else{
          giftcards.value.push({name: '', amount: '', message: '', template: 'christmas'})
          selected.value = giftcards.value.length - 1;
          await new Promise((r) => setTimeout(r, 1))
          form.value.setErrors('')
        }
      }
    }

    function proceed() {
      if (edit.value && !isMobile()) {
        edit.value = false
      } else {
        edit.value = false
        store.dispatch('setGiftcards', { data: giftcards.value })
        emit('next')
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }

    return {
      giftcards,
      editGiftcard,
      proceed,
      slideChange,
      setSwiper,
      deleteGiftcard,
      edit,
      form,
      addGiftcard,
      isMobile,
      isKycRegular,
      selected
    }
  }
}
</script>