<template>
  <div>
    <p class="text-grey-2 text-3xl text-left mx-14 mb-8">
      {{ $t('strategies.progress.title') }}
    </p>
    <div class="flex flex-col pl-20">
      <ProgressSteps
        v-for="n in currentFlow.steps"
        :key="n"
        :current="step"
        :step="n"
        :copy="copy"
        :sub-steps="currentFlow.subSteps"
      />
    </div>
  </div>
</template>

<script>
import ProgressSteps from '@/components/general/ProgressStep.vue'
import useFlows from '@/mixins/useFlows.js'
import { computed } from '@vue/reactivity'
export default {
  components: {
    ProgressSteps
  },
  props: {
    step: {
      type: String,
      default: '1'
    },
    copy: {
      type: String,
      default: ''
    },
    flow: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { flowSteps } = useFlows()
    const currentFlow = computed(() => {
      return flowSteps(props.flow)
    })
    return {
      currentFlow
    }
  }
}
</script>

<style lang="scss" scoped></style>
