<template>
  <div class="page flex">
    <div class="container lg:pt-20">
      <Back to="/" :title="$t('contracts.title')" />
      <ContractFilters />
      <TabsStatic
        labels="contracts.tabs"
        :labelsData="[totalOngoing, totalSettled]"
        :nTabs="2"
        v-model:current="currentTab"
      />
      <SortMenu class="my-6 relative z-40" data="contracts.sort" @sort="sort" />
      <div
        v-if="contracts && contracts.length"
        class="lg:grid grid-cols-2 gap-6"
      >
        <ContractCard
          v-for="contract in contracts"
          :key="contract"
          :contract="contract"
          class="mb-4"
        />
      </div>
      <div v-else-if="loading" class="lg:grid grid-cols-2 gap-6">
        <ContractSkeleton
          v-for="index in 8"
          v-bind:key="'contract-skeleton-' + index"
        />
      </div>
      <div v-else class="text-left">
        <p class="lg:text-lg font-bold text-grey-3 mb-1">
          {{ $t('contracts.no_contracts_title') }}
        </p>
        <p class="text-sm lg:text-base text-grey-3">
          {{ $t('contracts.no_contracts_text') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import TabsStatic from '@/components/general/TabsStatic.vue'
import ContractFilters from '@/components/contracts/ContractFilters.vue'
import ContractCard from '@/components/contracts/ContractCard.vue'
import SortMenu from '@/components/general/SortMenu.vue'
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import ContractSkeleton from '@/components/contracts/ContractSkeleton'
export default {
  components: {
    ContractSkeleton,
    Back,
    TabsStatic,
    ContractCard,
    SortMenu,
    ContractFilters
  },
  setup() {
    const currentTab = ref(1)
    const store = useStore()
    const sortBy = ref(null)
    const loading = ref(true)

    const search = computed(()=>{
      return store.state.contracts.search
    })

    onBeforeMount(async () => {
      await store.dispatch('setSearch', null)
      await store.dispatch('fetchContracts')
      if (!store.state.wallets.wallets) {
        await store.dispatch('fetchWallets')
      }
      loading.value = false
    })

    const contracts = computed(() => {
      if (currentTab.value == 1)
        return store.getters.getContracts(false, sortBy.value).filter((c)=> {
          return c.project_name.toLowerCase().includes(search.value ? search.value.toLowerCase() : '')
        })
      else if (currentTab.value == 2)
        return store.getters.getContracts(true, sortBy.value).filter((c)=> {
          return c.project_name.toLowerCase().includes(search.value ? search.value.toLowerCase() : '')
        })
      else return null
    })

    const totalOngoing = computed(() => {
      let cont = store.getters.getContracts(false)
      return cont
        ? cont.filter((c)=> {
          return c.project_name.toLowerCase().includes(search.value ? search.value.toLowerCase() : '')
        }).length
        : 0
    })

    const totalSettled = computed(() => {
      let cont = store.getters.getContracts(true)
      return cont
        ? cont.filter((c)=> {
          return c.project_name.toLowerCase().includes(search.value ? search.value.toLowerCase() : '')
        }).length
        : 0
    })

    const sort = (sortCriteria) => {
      sortBy.value = sortCriteria
    }

    return {
      currentTab,
      contracts,
      totalOngoing,
      totalSettled,
      sort,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
