<template>
  <div class="page  px-0 lg:px-24 container overflow-y-hidden relative">
    <a href="https://goparity.ca" >
      <img
        src="@/assets/img/logo/goparity.svg"
        alt=""
        class="absolute ml-4 sm:ml-0 pt-4 w-28 lg:ml-16 sm:pt-14 sm:w-40 z-50"
      />
    </a>
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform text-5xl text-blue-dark font-bold">
      {{ $t('unauthorized.title') }}</div>
    <div class="absolute mt-14 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xl text-blue-dark font-bold">{{$t('unauthorized.subtitle')}}</div>

  </div>
</template>

<script>
import {computed} from 'vue'

export default {
 setup(){
    const url = computed(()=>{
      return process.env.VUE_APP_FE_BASE_URL
    })

   return{url}
 }
}
</script>
<style scoped>


h1{
  font-family: 'UXUM_GROT';
  color:#154b64 ;
  font-size:80px;
  line-height: 80px;
  padding-bottom:49px;
  font-weight: 500;
}
p{
 color: #688695;
  font-size: 24px;
}

.ground-bg {
  border-radius: 50%;
  filter: blur(40px);
  background-image: linear-gradient(202deg, #0b4a89 45%, #3279c0 55%, #f28a10 30%, #f4bc38 10%);
  opacity: 0.15;
  padding-top:100%;
}

.button{
  color: #154b64;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 16px 32px;
  border-radius: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orange-button{
  background: #ffc84b;
}

.orange-button:hover{
  background: #fdd374;
  color: #376478;
}

.blue-button{
  border: solid 3px #154b64;
}

.blue-button:hover{
  border: solid 3px #688695;
  color: #688695;
}

.social{
  color: #154b64;
}

.social-wrapper{
  width:90%;
}

.hoover-animation {
  animation: hoover 6s ease-in-out infinite;
}

.appear {
  animation: sun 0.4s ease-out forwards;
}

@keyframes sun {
  0% {
    transform: translateY(350%);
  }
  70% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hoover {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}
@media (max-width: 1024px) {
  h1{
    font-size:40px;
    line-height: 48px;
    text-align: center;
    padding-bottom:16px;

  }

  p{
    color:#154b64;
    font-size: 16px;
    text-align: center;
  }

  p:last-of-type{
    font-weight: 600;
  }

  .ground-bg {
    filter: blur(20px);
    padding-top: 16rem;
  }

  .social-wrapper{
    width:100%;
  }

}
</style>
