<template>
  <div
    class="
      md:mt-46
      bg-green-6
      w-full
      min-h-72
      md:rounded-3xl
      p-8
      text-left
      relative
      overflow-hidden
    "
    id="impact-card"
  >
    <div class="text-grey-2 pb-6">
      {{
        $t(
          'impact.card.title',
          { name: profile ? profile.name : 'John' },
          level
        )
      }}
    </div>
    <div class="text-grey-1 text-2xl w-3/5 mb-14">
      <div class="font-bold" v-if="tons > 0">
        {{ tons }}
        {{ $t('wording.tons', tons == 1 ? 1 : 0) }}
      </div>
      <div class="font-medium">
        {{ $t('impact.card.subtitle', tons > 0 ? 0 : 1) }}
      </div>
    </div>
    <div class="md:absolute bottom-8 w-3/5">
      <ImpactScale :level="currentInitialLvl" :noAnimation="noAnimation" />
    </div>
    <div
      class="
        w-64
        h-64
        md:w-72 md:h-72
        absolute
        rounded-full
        bottom-8
        md:-bottom-12
        -right-8
        transition-all
        overflow-hidden
      "
      :class="{
        'ball-animation': !noAnimation,
        'ball-morning': !night && computedTime >= 6 && computedTime <= 12,
        'ball-afternoon': !night && computedTime > 12 && computedTime <= 18,
        'ball-night':
          (dynamicDayNight && night) || (!dynamicDayNight && staticNight)
      }"
    >
      <!-- SHOOTING STARS -->
      <div
        class="absolute top-0 right-0 transform rotate-45"
        v-if="
          ((dynamicDayNight && night) || (!dynamicDayNight && staticNight)) &&
          stars
        "
      >
        <div class="shooting-star"></div>
        <div class="shooting-star"></div>
        <div class="shooting-star"></div>
      </div>
    </div>
    <div>
      <!-- AMBIENT -->
      <div>
        <!-- SUN/MOON -->
        <div
          class="
            w-12
            h-12
            absolute
            rounded-full
            sun-animation
            right-24
            transition-all
            ease-out
          "
          :class="{
            'bg-white':
              (dynamicDayNight && night) || (!dynamicDayNight && staticNight),
            'bg-orange-sun sun':
              (dynamicDayNight && !night) || (!dynamicDayNight && !staticNight)
          }"
          :style="
            'bottom: calc(' + (dynamicHeight ? sunHeight : 80) + '% - 24px)'
          "
        ></div>

        <!-- STARS -->
        <transition name="fade">
          <div
            class="stars"
            v-if="
              ((dynamicDayNight && night) ||
                (!dynamicDayNight && staticNight)) &&
              stars
            "
          >
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
          </div>
        </transition>

        <!-- CLOUDS -->
        <transition name="bounce">
          <div
            v-if="(computedTime > 3 && computedTime < 13) || clouds"
            class="absolute right-0 top-0 w-32 origin-bottom"
          >
            <img
              src="@/assets/svg/impact/gamification/cloud.svg"
              alt=""
              class="absolute right-0 z-50 top-18 cloud-animation w-32"
            />
          </div>
        </transition>
        <transition name="bounce">
          <div
            v-if="(computedTime > 3 && computedTime < 13) || clouds"
            class="absolute right-0 top-0 w-32 origin-bottom"
            style="animation-delay: 0.2s"
          >
            <img
              src="@/assets/svg/impact/gamification/cloud.svg"
              alt=""
              class="absolute right-0 z-0 top-12 cloud-animation w-20"
              style="animation-duration: 40s"
            />
          </div>
        </transition>
        <transition name="bounce">
          <div
            v-if="(computedTime > 3 && computedTime < 13) || clouds"
            class="absolute right-0 top-0 w-32 origin-bottom"
            style="animation-delay: 0.4s"
          >
            <img
              src="@/assets/svg/impact/gamification/cloud.svg"
              alt=""
              class="absolute right-0 z-0 top-20 cloud-animation w-14"
              style="animation-duration: 50s"
            />
          </div>
        </transition>

        <!-- BIRDS -->
        <!--img
          src="@/assets/svg/impact/gamification/bird.svg"
          alt=""
          class="absolute right-20 top-24 w-12 bird"
        /-->
      </div>

      <!-- GROUND -->
      <div
        class="absolute bottom-20 md:bottom-8 right-8"
        :class="{ 'hoover-animation': floating && !noAnimation }"
        id="soil"
      >
        <!-- RABBIT -->
        <div
          class="transform scale-40"
          style="position: absolute; bottom: 32px; z-index: 34; left: 20px"
        >
          <div
            class="bottom-5 absolute z-50"
            :class="noAnimation ? '' : 'bunny-run'"
            v-if="currentTons >= 60"
          >
            <div class="rabbit" :class="noAnimation ? '' : 'rabbit-jump'"></div>
          </div>
        </div>

        <!-- BUTTERFLY -->
        <transition name="bounce">
          <div
            class="z-50 absolute right-0 bottom-0 origin-center"
            v-if="currentTons >= 35"
          >
            <div
              class="z-50 absolute right-0 transform scale-15 h-2"
              :class="{
                'butterfly-animation':
                  !noAnimation &&
                  ((!butterflyStop && dynamicDayNight && !night) ||
                    (!dynamicDayNight && !staticNight))
              }"
              style="bottom: 124px"
            >
              <div
                class="
                  transform
                  h-8
                  transition-all
                  ease-out
                  duration-2000
                  absolute
                "
                :class="{
                  '-translate-x-96 translate-y-96':
                    butterflyStop ||
                    (dynamicDayNight && night) ||
                    (!dynamicDayNight && staticNight)
                }"
              >
                <div
                  class="butterfly"
                  :class="noAnimation ? '' : 'butterfly-hover'"
                  :style="
                    '--flap-speed:' +
                    (butterflyStopFlap || night ? 700 : 250) +
                    'ms'
                  "
                >
                  <div
                    class="wing"
                    :class="noAnimation ? '' : 'wing-animation'"
                  >
                    <div class="bit"></div>
                    <div class="bit"></div>
                  </div>
                  <div
                    class="wing"
                    :class="noAnimation ? '' : 'wing-animation'"
                  >
                    <div class="bit"></div>
                    <div class="bit"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!-- SOIL -->
        <img
          src="@/assets/svg/impact/gamification/soil.svg"
          alt=""
          :class="noAnimation ? '' : 'appear'"
        />

        <!-- BUSHES -->
        <div
          class="absolute w-12 z-40"
          :class="noAnimation ? '' : 'ground-appear'"
          style="
            --height-final: 2.1rem;
            --height-top: 2.2rem;
            left: 99px;
            bottom: 34px;
          "
          v-if="currentTons >= 20"
        >
          <div
            style="animation-delay: 0.8s"
            :class="noAnimation ? '' : 'jiggle-animation'"
          >
            <img src="@/assets/svg/impact/gamification/bush.svg" alt="" />
          </div>
          <div v-if="currentTons >= 22">
            <div
              style="
                background: #f75e5e;
                left: 10px;
                bottom: 19px;
                width: 4px;
                height: 4px;
                animation-delay: 1.2s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 16px;
                bottom: 9px;
                width: 4px;
                height: 4px;
                animation-delay: 1.4s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 5px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 1.8s;
              "
              class="absolute rounded-full berry"
            ></div>

            <div
              style="
                background: #f75e5e;
                right: 14px;
                bottom: 20px;
                width: 4px;
                height: 4px;
                animation-delay: 1.6s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 8px;
                bottom: 10px;
                width: 4px;
                height: 4px;
                animation-delay: 2s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 15px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 2.2s;
              "
              class="absolute rounded-full berry"
            ></div>
          </div>
        </div>
        <div
          class="absolute w-10 z-40 ground-appear"
          style="
            --height-final: 1.6rem;
            --height-top: 1.7rem;
            left: 72px;
            bottom: 35px;
            z-index: 35;
          "
          v-if="currentTons >= 20"
        >
          <div class="jiggle-animation">
            <img src="@/assets/svg/impact/gamification/bush-light.svg" alt="" />
          </div>
          <div v-if="currentTons >= 22">
            <div
              style="
                background: #f75e5e;
                left: 10px;
                bottom: 17px;
                width: 4px;
                height: 4px;
                animation-delay: 1.3s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 14px;
                bottom: 9px;
                width: 4px;
                height: 4px;
                animation-delay: 1.5s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 5px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 1.9s;
              "
              class="absolute rounded-full berry"
            ></div>

            <div
              style="
                background: #f75e5e;
                right: 14px;
                bottom: 19px;
                width: 4px;
                height: 4px;
                animation-delay: 1.7s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 8px;
                bottom: 10px;
                width: 4px;
                height: 4px;
                animation-delay: 2.1s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 15px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 2.3s;
              "
              class="absolute rounded-full berry"
            ></div>
          </div>
        </div>
        <div
          class="absolute w-8 z-40 ground-appear"
          style="
            --height-final: 1.6rem;
            --height-top: 1.7rem;
            left: 52px;
            bottom: 45px;
            z-index: 5;
          "
          v-if="currentTons >= 70"
        >
          <div class="jiggle-animation">
            <img src="@/assets/svg/impact/gamification/bush.svg" alt="" />
          </div>
          <div v-if="false">
            <div
              style="
                background: #f75e5e;
                left: 10px;
                bottom: 17px;
                width: 4px;
                height: 4px;
                animation-delay: 1.3s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 14px;
                bottom: 9px;
                width: 4px;
                height: 4px;
                animation-delay: 1.5s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 5px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 1.9s;
              "
              class="absolute rounded-full berry"
            ></div>

            <div
              style="
                background: #f75e5e;
                right: 14px;
                bottom: 19px;
                width: 4px;
                height: 4px;
                animation-delay: 1.7s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 8px;
                bottom: 10px;
                width: 4px;
                height: 4px;
                animation-delay: 2.1s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 15px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 2.3s;
              "
              class="absolute rounded-full berry"
            ></div>
          </div>
        </div>
        <div
          class="absolute w-6 z-40 ground-appear"
          style="
            --height-final: 1.6rem;
            --height-top: 1.7rem;
            left: 32px;
            bottom: 40px;
            z-index: 0;
          "
          v-if="currentTons >= 70"
        >
          <div class="jiggle-animation">
            <img src="@/assets/svg/impact/gamification/bush-light.svg" alt="" />
          </div>
          <div v-if="false">
            <div
              style="
                background: #f75e5e;
                left: 10px;
                bottom: 17px;
                width: 4px;
                height: 4px;
                animation-delay: 1.3s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 14px;
                bottom: 9px;
                width: 4px;
                height: 4px;
                animation-delay: 1.5s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                left: 5px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 1.9s;
              "
              class="absolute rounded-full berry"
            ></div>

            <div
              style="
                background: #f75e5e;
                right: 14px;
                bottom: 19px;
                width: 4px;
                height: 4px;
                animation-delay: 1.7s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 8px;
                bottom: 10px;
                width: 4px;
                height: 4px;
                animation-delay: 2.1s;
              "
              class="absolute rounded-full berry"
            ></div>
            <div
              style="
                background: #f75e5e;
                right: 15px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 2.3s;
              "
              class="absolute rounded-full berry"
            ></div>
          </div>
        </div>

        <!-- INITIAL TREE -->
        <div class="absolute right-5 w-20 bottom-2 z-30">
          <!-- SEED -->
          <div
            class="
              rounded-full
              transition-all
              ease-out
              overflow-hidden
              absolute
              h-6
              duration-500
              delay-500
              w-0
            "
            :class="{
              'w-7 ': currentTons > 0 && currentTons <= 0.5,
              'w-0': currentTons > 0.5
            }"
            style="bottom: -2px; transition-delay: 500ms"
          >
            <div
              class="absolute w-7 h-6 z-10 rounded-full transition-all ease-out"
              style="left: -3px; bottom: -3px; background: #835f38"
            ></div>
            <div
              class="absolute right-5 w-6 z-20 transition-all ease-out"
              style="left: 2px; bottom: 2px"
            >
              <img
                src="@/assets/svg/impact/gamification/components/seed.svg"
                alt=""
                class="seed-animation"
                style="width: 13px"
              />
            </div>
            <!-- STALK -->
            <div
              :style="'--var-height: ' + 18 + 'px; left:4px; bottom:1px'"
              class="stalk-animation w-10 overflow-hidden absolute z-30"
            >
              <img
                src="@/assets/svg/impact/gamification/components/stalk.svg"
                alt=""
                class="absolute w-7 bottom-1 left-1"
                style="height: 50px; width: 20px"
              />
            </div>
          </div>

          <!-- MAIN LOG -->
          <img
            src="@/assets/svg/impact/gamification/components/main-log.svg"
            alt=""
            class="absolute bottom-7 left-5 transition-all ease-out z-negative"
            :style="`height: ${stalkData.height}px; width: ${stalkData.width}px; transition-delay: ${stalkData.delay}s`"
          />

          <!-- BRANCHES -->
          <div
            v-for="(branch, index) in stalkData.branches"
            v-bind:key="'branch-' + index"
            :style="`--tw-rotate: ${branch.deg};`"
            class="transform"
          >
            <div
              :style="`animation-delay: ${branch.delay}s ; --tw-scale-x: ${
                branch.mirror ? -1 : 1
              }; --var-height:${stalkData.height}px;  right:${
                branch.right
              }px; left:${branch.left}px; bottom: ${branch.bottom}px`"
              class="
                branch-animation
                w-24
                overflow-hidden
                absolute
                z-30
                transform
                transition-all
                ease-in-out
              "
              v-if="currentTons >= stalkData.min"
            >
              <img
                :src="
                  require(`@/assets/svg/impact/gamification/components/${
                    branch.sub ? 'sub-branch' : 'branch'
                  }.svg`)
                "
                alt=""
                class="absolute w-4"
                :style="`bottom: 12px; width: 40px; right: 0px; ${
                  branch.reverse ? 'transform: scaleX(-1)' : ''
                }; ${branch.scale ? `transform: scale(${branch.scale});` : ''}`"
              />
            </div>
          </div>

          <!-- LEAVES -->
          <span
            v-for="(leaf, index) in stalkData.leaves"
            v-bind:key="'leaf-' + index"
          >
            <div
              class="transform w-8 absolute transition-all ease-out z-50"
              :class="{ 'leaf-fall': leavesFall }"
              :style="`right: ${leaf.right}px; left: ${leaf.left}px; bottom: ${
                leaf.bottom
              }px; --tw-rotate: ${leaf.deg};--tw-scale-x: ${
                leaf.scale ? leaf.scale : 1
              }; --tw-scale-y: ${leaf.scale ? leaf.scale : 1};`"
              v-if="currentTons >= stalkData.min"
            >
              <img
                :src="
                  require(`@/assets/svg/impact/gamification/components/${
                    leaf.light ? 'leaf-light' : 'leaf'
                  }.svg`)
                "
                alt=""
                class="
                  absolute
                  w-6
                  bottom-18
                  leaf-animation
                  transition-all
                  ease-in-out
                  duration-100
                  z-30
                "
                :style="`left: 17px; animation-delay: ${
                  leaf.delay ? leaf.delay : 0.4
                }s`"
              />
            </div>
          </span>

          <!-- BALLS -->
          <span
            v-for="(ball, index) in stalkData.balls"
            v-bind:key="'ball-' + index"
          >
            <div
              class="transform w-8 absolute transition-all ease-out"
              :style="`left: ${ball.left}px; bottom: ${
                ball.bottom
              }px;--tw-scale-x: ${ball.scale ? ball.scale : 1}; --tw-scale-y: ${
                ball.scale ? ball.scale : 1
              };z-index: ${ball.z};`"
              v-if="currentTons >= stalkData.min"
            >
              <img
                :src="
                  require(`@/assets/svg/impact/gamification/components/${
                    ball.light
                      ? 'ball-light'
                      : ball.dark
                      ? 'ball-dark'
                      : 'ball-regular'
                  }.svg`)
                "
                alt=""
                class="
                  absolute
                  w-6
                  bottom-18
                  leaf-animation
                  transition-all
                  ease-in-out
                  duration-100
                  z-30
                "
                :style="`left: 17px; animation-delay: ${
                  ball.delay ? ball.delay : 0
                }ms`"
              />
            </div>
          </span>

          <div
            :class="{ 'transform scale-0': stalkData.hideTop }"
            style="transition-delay: 1.6s"
          >
            <img
              src="@/assets/svg/impact/gamification/components/stalk-top.svg"
              alt=""
              class="absolute w-6 bottom-7 left-3 transition-all ease-in-out"
              :style="'bottom:' + stalkData.bottom + 'px;'"
            />
          </div>
        </div>

        <!-- BERRY TREE -->
        <div
          class="
            absolute
            left-12
            bottom-10
            z-20
            transform
            transition-all
            ease-out
          "
          :class="currentTons >= 27 ? 'scale-100' : 'scale-75'"
          style="transition-delay: 1400ms"
        >
          <BerryTree v-if="currentTons >= 25" :berries="currentTons >= 80" />
        </div>

        <!-- BROWN TREE -->
        <div
          class="
            absolute
            left-18
            bottom-10
            z-10
            transform
            transition-all
            ease-out
          "
          :class="
            currentTons >= 70
              ? currentTons >= 80
                ? 'scale-100'
                : 'scale-75'
              : 'scale-50'
          "
          style="transition-delay: 1800ms"
        >
          <BrownTree v-if="currentTons >= 60" />
        </div>

        <!-- BLUE TREE -->
        <div class="absolute right-14 bottom-10 z-0">
          <BlueTree v-if="false" />
        </div>

        <!-- BRANCHED TREE -->
        <div class="absolute right-24 bottom-10 z-0">
          <BranchedTree v-if="false" />
        </div>

        <!-- FOUR LEAVES -->
        <img
          src="@/assets/svg/impact/gamification/components/four-leaves.svg"
          alt=""
          class="absolute bottom-12 z-0 w-6 transform transition-all ease-out"
          style="left: 52px; transition-delay: 1.5s"
          :class="currentTons >= 70 ? 'scale-100' : 'scale-0'"
        />

        <!-- TWO BROWN LEAVES -->
        <div class="absolute left-6 bottom-2 z-negative">
          <TwoLeaves v-if="currentTons >= 80" />
        </div>

        <!-- TWO BROWN LEAVES BLUE -->
        <div
          class="absolute z-60 transform"
          style="--tw-scale-x: -1; left: 98px; bottom: -9px"
        >
          <TwoLeaves v-if="currentTons >= 80" blue />
        </div>
      </div>
    </div>

    <!-- DASHBOARD -->
    <!--div
      class="
        fixed
        bottom-4
        rounded-xl
        right-4
        bg-grey-5
        px-6
        py-4
        w-72
        hidden
        lg:block
      "
    >
      <div class="flex justify-between items-start">
        <div
          class="text-grey-2 text-sm cursor-pointer"
          @click="hidden = !hidden"
        >
          Experimental Dashboard
        </div>
        <img
          class="cursor-pointer w-4"
          src="@/assets/svg/cross.svg"
          alt=""
          @click="hidden = true"
        />
      </div>
      <div v-if="!hidden" class="mt-4">
        <div class="text-grey-2 mb-1 text-xs">Values</div>
        <div class="flex items-center">
          <div class="text-grey-2 font-semibold text-sm mr-1">CO2 Tons</div>
        </div>
        <div class="flex items-center mb-2">
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            v-model="tons"
            class="mr-2"
          />
          <div>
            {{ tons }}
            <span class="text-xs">(Level {{ currentInitialLvl }})</span>
          </div>
        </div>

        <div class="flex items-center">
          <div class="text-grey-2 font-semibold text-sm mr-1">
            CO2 Current Tons
          </div>
        </div>
        <div class="flex items-center mb-2">
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model="currentTons"
            class="mr-2"
          />
          <div>
            {{ currentTons }}
          </div>
        </div>
        <div class="flex justify-between items-center mt-2">
          <div class="text-grey-2 font-semibold text-sm mr-1">Real time?</div>
          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            v-model="realTime"
          />
        </div>
        <div
          class="flex justify-between items-center mt-2 mb-2 ml-6"
          :class="{ 'opacity-40': realTime }"
        >
          <div class="text-grey-2 font-semibold text-sm mr-1">Hours</div>
          <input
            type="range"
            min="0"
            max="23"
            step="1"
            v-model="staticTime"
            :disabled="realTime"
          />
          <span>{{ staticTime }}</span>
        </div>
        <hr />
        <div class="text-grey-2 mt-4 mb-1 text-xs">Ambient</div>
        <div class="flex justify-between items-center">
          <div class="text-grey-2 font-semibold text-sm mr-1">
            Dynamic sun height?
          </div>

          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            v-model="dynamicHeight"
          />
        </div>
        <div class="flex justify-between items-center mt-2">
          <div class="text-grey-2 font-semibold text-sm mr-1">
            Dynamic Day Night?
          </div>
          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            v-model="dynamicDayNight"
          />
        </div>
        <div
          class="flex justify-between items-center mt-2 mb-2 ml-6"
          :class="{ 'opacity-40': dynamicDayNight }"
        >
          <div class="text-grey-2 font-semibold text-sm mr-1">Night</div>
          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            v-model="staticNight"
            :disabled="dynamicDayNight"
          />
        </div>
        <div class="flex justify-between items-start mb-2">
          <div class="text-grey-2 font-semibold text-sm mr-1">
            Permanent Clouds?
            <div class="text-grey-3 text-xs font-normal">
              Always show clouds, no matter the user level
            </div>
          </div>

          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            class="mt-1"
            v-model="clouds"
          />
        </div>
        <div class="flex justify-between items-start mb-2">
          <div class="text-grey-2 font-semibold text-sm mr-1">Stars?</div>

          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            class="mt-1"
            v-model="stars"
          />
        </div>
        <hr />
        <div class="text-grey-2 mt-4 mb-1 text-xs">Animations</div>

        <div class="flex justify-between items-center">
          <div class="text-grey-2 font-semibold text-sm mr-1">Floating</div>
          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            v-model="floating"
          />
        </div>
        <div class="flex justify-between items-center">
          <div class="text-grey-2 font-semibold text-sm mr-1">
            Butterfly stop
          </div>
          <input
            type="checkbox"
            min="0"
            max="100"
            step="1"
            v-model="butterflyStop"
          />
        </div>
      </div>
    </div-->
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue'
import ImpactScale from '@/components/impact/Scale'
import BerryTree from '@/components/impact/gamification/BerryTree'
import BlueTree from '@/components/impact/gamification/BlueTree'
import BrownTree from '@/components/impact/gamification/BrownTree'
import TwoLeaves from '@/components/impact/gamification/TwoLeaves'
import { useStore } from 'vuex'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import BranchedTree from '@/components/impact/gamification/BranchedTree'

export default {
  components: {
    BranchedTree,
    ImpactScale,
    TwoLeaves,
    BrownTree,
    BerryTree,
    BlueTree
  },
  props: {
    noAnimation: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { kg2Ton } = useGlobalHelpers()
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const level = ref(0)
    const tons = ref(0)
    const currentTons = ref(0)
    const store = useStore()
    const time = ref(new Date().getHours())
    const staticTime = ref(time.value)
    const dynamicHeight = ref(true)
    const realTime = ref(true)
    const dynamicDayNight = ref(true)
    const floating = ref(true)
    const stars = ref(true)
    const clouds = ref(false)
    const leavesFall = ref(false)
    const wait = ref(false)
    const hidden = ref(true)
    const staticNight = ref(false)
    const butterflyStop = ref(false)
    const butterflyStopFlap = ref(false)
    const butterflySemaphore = ref(false)
    const interval = ref(null)
    const currentLvl = ref(0)

    const levels = ref([
      {
        lvl: 0,
        max: 0.1
      },
      {
        lvl: 1,
        max: 5
      },
      {
        lvl: 2,
        max: 24
      },
      {
        lvl: 3,
        max: 60
      },
      {
        lvl: 4,
        max: Infinity
      }
    ])

    const stalk = ref([
      // Nothing
      {
        height: 0,
        width: 20,
        min: 0,
        hideTop: true,
        wait: 300,
        bottom: 34
      },
      // Sprout
      {
        height: 22,
        delay: 1.5,
        width: 20,
        min: 0.05,
        bottom: 29,
        wait: 1850
      },
      // Sprout w/ Leaves
      {
        height: 50,
        width: 24,
        min: 0.1,
        bottom: 58,
        wait: 1000,
        leaves: [
          {
            left: 44,
            bottom: -21,
            deg: '-25deg',
            scale: 1.4
          },
          {
            right: 120,
            bottom: 75,
            deg: '90deg',
            scale: 0.75,
            delay: 0.6
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      },
      // Sprout w/ Leaves (First branch)
      {
        height: 50,
        width: 32,
        min: 0.5,
        bottom: 58,
        wait: 900,
        branches: [
          {
            right: 55,
            bottom: 27
          }
        ],
        leaves: [
          {
            left: 44,
            bottom: -21,
            deg: '-25deg',
            scale: 1.4
          },
          {
            right: 137,
            bottom: 90,
            deg: '90deg',
            scale: 0.75
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      },
      // Sprout w/ Leaves (Second branch)
      {
        height: 50,
        width: 32,
        min: 1,
        bottom: 58,
        wait: 2000,
        leaves: [
          {
            left: 44,
            bottom: -21,
            deg: '-25deg',
            scale: 1.4
          },
          {
            right: 137,
            bottom: 90,
            scale: 0.75,
            deg: '90deg'
          },
          {
            left: 26,
            bottom: -5
          }
        ],
        branches: [
          {
            right: 55,
            bottom: 27
          },
          {
            left: 22,
            bottom: 20,
            reverse: true
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      },
      // Sprout w/ Leaves (Third branch) - Grows
      {
        height: 70,
        width: 32,
        min: 2,
        bottom: 58,
        wait: 1100,
        leaves: [
          {
            left: 45,
            bottom: 0,
            deg: '-25deg',
            scale: 1.4
          },
          {
            right: 137,
            bottom: 113,
            scale: 0.75,
            deg: '90deg'
          },
          {
            left: 26,
            bottom: 18
          },
          {
            right: 118,
            bottom: 79,
            scale: 0.55,
            deg: '90deg'
          }
        ],
        branches: [
          {
            right: 56,
            bottom: 50
          },
          {
            left: 22,
            bottom: 43,
            reverse: true
          },
          {
            right: 56,
            bottom: 20
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      },
      // Sprout w/ Leaves (Sub Branch)
      {
        height: 70,
        width: 32,
        min: 4,
        bottom: 58,
        wait: 800,
        leaves: [
          {
            left: 45,
            bottom: 0,
            deg: '-25deg',
            scale: 1.4
          },
          {
            right: 137,
            bottom: 113,
            scale: 0.75,
            deg: '90deg'
          },
          {
            left: 26,
            bottom: 18
          },
          {
            right: 118,
            bottom: 79,
            scale: 0.55,
            deg: '90deg'
          },
          {
            right: 33,
            bottom: 45,
            scale: 0.35,
            deg: '-45deg'
          }
        ],
        branches: [
          {
            right: 56,
            bottom: 50
          },
          {
            left: 22,
            bottom: 43,
            reverse: true
          },
          {
            right: 56,
            bottom: 20
          },
          {
            right: 44,
            bottom: -2,
            deg: '-10deg',
            sub: true,
            scale: 0.2
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      },
      // Sprout w/ Leaves (Lots of leaves)
      {
        height: 70,
        width: 32,
        min: 5,
        bottom: 58,
        wait: 1100,
        leaves: [
          {
            left: 73,
            bottom: 24,
            deg: '-45deg',
            scale: 1.4
          },
          {
            right: 137,
            bottom: 113,
            scale: 0.75,
            deg: '90deg'
          },
          {
            left: 26,
            bottom: 18
          },
          {
            right: 118,
            bottom: 79,
            scale: 0.55,
            deg: '90deg'
          },
          {
            right: 33,
            bottom: 45,
            scale: 0.35,
            deg: '-45deg'
          },
          {
            left: 3,
            bottom: 27,
            light: true,
            delay: 0.5
          },
          {
            right: 35,
            bottom: 18,
            scale: 0.75,
            deg: '15deg',
            delay: 0.7
          },
          {
            right: 24,
            bottom: 61,
            scale: 0.35,
            light: true,
            deg: '-25deg',
            delay: 0.8
          },
          {
            right: 13,
            bottom: 37,
            scale: 0.35,
            light: true,
            deg: '-47deg',
            delay: 0.8
          },
          {
            right: 58,
            bottom: 39,
            scale: 0.35,
            deg: '69deg',
            delay: 1
          },
          {
            right: 92,
            bottom: 116,
            scale: 0.55,
            deg: '109deg',
            delay: 1,
            light: true
          },
          {
            right: 29,
            bottom: 80,
            scale: 0.55,
            deg: '205deg',
            delay: 1,
            light: true
          }
        ],
        branches: [
          {
            right: 56,
            bottom: 50
          },
          {
            left: 22,
            bottom: 43,
            reverse: true
          },
          {
            right: 56,
            bottom: 20
          },
          {
            right: 44,
            bottom: -2,
            deg: '-10deg',
            sub: true,
            scale: 0.2
          },
          {
            left: 22,
            bottom: 23,
            reverse: true,
            delay: 0.4
          },
          {
            left: 5,
            bottom: 13,
            deg: '10deg',
            sub: true,
            delay: 0.2,
            mirror: true,
            scale: 0.2
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      },
      // Sprout w/ Leaves (No more leaves)
      {
        height: 100,
        width: 90,
        min: 10,
        bottom: 58,
        wait: 1200,
        leaves: [
          {
            left: 73,
            bottom: 24,
            deg: '-45deg',
            scale: 1.4
          },
          {
            right: 137,
            bottom: 113,
            scale: 0.75,
            deg: '90deg'
          },
          {
            left: 26,
            bottom: 18
          },
          {
            right: 118,
            bottom: 79,
            scale: 0.55,
            deg: '90deg'
          },
          {
            right: 33,
            bottom: 45,
            scale: 0.35,
            deg: '-45deg'
          },
          {
            left: 3,
            bottom: 27,
            light: true,
            delay: 0.5
          },
          {
            right: 35,
            bottom: 18,
            scale: 0.75,
            deg: '15deg',
            delay: 0.7
          },
          {
            right: 24,
            bottom: 61,
            scale: 0.35,
            light: true,
            deg: '-25deg',
            delay: 0.8
          },
          {
            right: 13,
            bottom: 37,
            scale: 0.35,
            light: true,
            deg: '-47deg',
            delay: 0.8
          },
          {
            right: 58,
            bottom: 39,
            scale: 0.35,
            deg: '69deg',
            delay: 1
          },
          {
            right: 92,
            bottom: 116,
            scale: 0.55,
            deg: '109deg',
            delay: 1,
            light: true
          },
          {
            right: 29,
            bottom: 80,
            scale: 0.55,
            deg: '205deg',
            delay: 1,
            light: true
          }
        ],
        branches: [
          {
            right: 50,
            bottom: 73
          },
          {
            deg: '-5deg',

            left: 30,
            bottom: 68,
            reverse: true
          },
          {
            right: 56,
            bottom: 54
          },
          {
            right: 41,
            bottom: 38,
            deg: '-10deg',
            sub: true,
            scale: 0.2
          },
          {
            left: 27,
            bottom: 53,
            reverse: true,
            delay: 0.4
          },
          {
            left: 23,
            bottom: 51,
            sub: true,
            delay: 0.2,
            mirror: true,
            scale: 0.2
          }
        ],
        balls: [
          {
            left: -31,
            bottom: -3,
            dark: true,
            scale: 1.6,
            z: 0,
            delay: 50
          },
          {
            left: -6,
            bottom: 28,
            scale: 1.2,
            z: 1,
            delay: 100
          },
          {
            left: -38,
            bottom: -32,
            light: true,
            scale: 1.7,
            z: -1,
            delay: 150
          },
          {
            left: -8,
            bottom: 3,
            light: true,
            scale: 1.7,
            z: 0,
            delay: 200
          },
          {
            left: -22,
            bottom: -61,
            scale: 2.5,
            z: -4,
            delay: 250
          },
          {
            left: 14,
            bottom: 20,
            scale: 1.2,
            light: true,
            z: -1,
            delay: 300
          },
          {
            left: 24,
            bottom: 4,
            scale: 1.2,
            z: -2,
            delay: 350
          },
          {
            left: 24,
            bottom: -16,
            scale: 1.7,
            dark: true,
            z: -2,
            delay: 400
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      },
      // Sprout w/ Leaves (No more leaves)
      {
        height: 100,
        width: 90,
        min: 20,
        bottom: 58,
        wait: 1200,
        branches: [
          {
            right: 50,
            bottom: 73
          },
          {
            deg: '-5deg',

            left: 30,
            bottom: 68,
            reverse: true
          },
          {
            right: 56,
            bottom: 54
          },
          {
            right: 41,
            bottom: 38,
            deg: '-10deg',
            sub: true,
            scale: 0.2
          },
          {
            left: 27,
            bottom: 53,
            reverse: true,
            delay: 0.4
          },
          {
            left: 23,
            bottom: 51,
            sub: true,
            delay: 0.2,
            mirror: true,
            scale: 0.2
          }
        ],
        balls: [
          {
            left: -31,
            bottom: -3,
            dark: true,
            scale: 1.6,
            z: 0,
            delay: 50
          },
          {
            left: -6,
            bottom: 28,
            scale: 1.2,
            z: 1,
            delay: 100
          },
          {
            left: -38,
            bottom: -32,
            light: true,
            scale: 1.7,
            z: -1,
            delay: 150
          },
          {
            left: -8,
            bottom: 3,
            light: true,
            scale: 1.7,
            z: 0,
            delay: 200
          },
          {
            left: -22,
            bottom: -61,
            scale: 2.5,
            z: -4,
            delay: 250
          },
          {
            left: 14,
            bottom: 20,
            scale: 1.2,
            light: true,
            z: -1,
            delay: 300
          },
          {
            left: 24,
            bottom: 4,
            scale: 1.2,
            z: -2,
            delay: 350
          },
          {
            left: 24,
            bottom: -16,
            scale: 1.7,
            dark: true,
            z: -2,
            delay: 400
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      }
    ])

    const currentInitialLvl = computed(() => {
      return levels.value.filter((el) => {
        return currentTons.value <= el.max
      })[0].lvl
    })

    const stalkData = computed(() => {
      const filtered = stalk.value.filter((el) => {
        return currentTons.value >= el.min
      })
      return filtered[filtered.length - 1]
    })

    const computedTime = computed(() => {
      return realTime.value ? time.value : staticTime.value
    })

    const impact = computed(() => {
      return store.state.impact.impact
    })

    const night = computed(() => {
      return computedTime.value > 18 || computedTime.value < 6
    })

    const getHours = computed(() => {
      return computedTime.value % 12 || 12
    })

    const sunHeight = computed(() => {
      if (night.value) return 70
      return 60 - Math.abs(computedTime.value - 12) * 10 + 20
    })

    function stopButterfly() {
      butterflySemaphore.value = true
      butterflyStop.value = true
      setTimeout(function () {
        butterflyStop.value = false
        setTimeout(function () {
          butterflySemaphore.value = false
        }, 2000)
      }, 15000)
    }

    function shouldStop() {
      return Math.random() < 0.2
    }

    onMounted(async () => {
      tons.value = impact.value.co2_kg_equivalent
        ? impact.value.co2_kg_equivalent / 1000
        : 0
      window.setInterval(async () => {
        if (shouldStop() && !butterflySemaphore.value) {
          stopButterfly()
        }
      }, 3500)

      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      async function load() {
        // We need to wrap the loop into an async function for this to work
        for (var i = 1; i < currentInitialLvl.value + 1; i++) {
          currentLvl.value = i
          await timer(1000) // then the created Promise can be awaited
        }
      }
      load()
      async function loadTons() {
        let z = 0.01
        if (!props.noAnimation) {
          while (z <= Math.min(tons.value, 1)) {
            var beforeMin = stalkData.value.min
            z = z + 0.01
            z = Math.round(z * 100) / 100

            let filteredZ = stalk.value.filter((el) => {
              return z >= el.min
            })
            filteredZ = filteredZ[filteredZ.length - 1]

            // CHANGED!
            if (beforeMin != filteredZ.min) {
              await timer(stalkData.value.wait)
            }

            currentTons.value = Math.round(z * 100) / 100
          }

          if (tons.value > 1) {
            z = 1
            while (z + 1 <= tons.value) {
              beforeMin = stalkData.value.min
              z = z + 1

              let filteredZ = stalk.value.filter((el) => {
                return z >= el.min
              })

              filteredZ = filteredZ[filteredZ.length - 1]

              // CHANGED!
              if (beforeMin != filteredZ.min) {
                await timer(filteredZ.wait)
              }
              if (z === 10) {
                await timer(400)
                leavesFall.value = true
                await timer(300)
              }
              if (z === 24) {
                await timer(800)
              }
              if (z === 30) {
                await timer(1000)
              }
              if (z === 60) {
                await timer(1000)
              }
              if (z === 70) {
                await timer(1000)
              }
              if (z === 80) {
                await timer(1000)
              }
              currentTons.value = z
            }
          }
        } else {
          currentTons.value = tons.value
        }
      }
      loadTons()
      interval.value = setInterval(() => {
        time.value = Intl.DateTimeFormat(navigator.language, {
          hour: 'numeric',
          hour12: false
        }).format()
      }, 500)
    })

    watch(
      () => butterflyStop.value,
      () => {
        if (butterflyStop.value) {
          setTimeout(function () {
            butterflyStopFlap.value = true
          }, 2000)
        }
        butterflyStopFlap.value = false
      }
    )

    watch(
      () => currentInitialLvl.value,
      () => {
        currentLvl.value = currentInitialLvl.value
      }
    )

    return {
      profile,
      level,
      impact,
      currentInitialLvl,
      dynamicHeight,
      kg2Ton,
      leavesFall,
      wait,
      getHours,
      staticTime,
      butterflyStopFlap,
      floating,
      time,
      interval,
      currentLvl,
      hidden,
      tons,
      night,
      staticNight,
      stars,
      clouds,
      realTime,
      currentTons,
      computedTime,
      sunHeight,
      stalkData,
      butterflyStop,
      dynamicDayNight
    }
  }
}
</script>

<style lang="css" scoped>
.ball {
  background: rgb(79, 89, 99);
  background: linear-gradient(
    180deg,
    rgba(79, 89, 99, 0.1) 0%,
    rgba(0, 212, 255, 0) 100%
  );
}

.stalk {
  width: var(--var-width);
  height: 0px;
  border-bottom: 85px solid #00475f;
  border-left: var(--bl) solid transparent;
  border-right: var(--br) transparent;
  transform-origin: bottom;
}

.rabbit {
  width: 5em;
  height: 3em;
  background: #ffffff;
  border-radius: 70% 90% 60% 50%;
  position: relative;
  transform: rotate(0deg) translate(-2em, 0);
  z-index: 1;
}

.rabbit-jump {
  animation: hop 1s infinite linear;
}

.no-flexbox .rabbit {
  margin: 10em auto 0;
}

.rabbit:before {
  content: '';
  position: absolute;
  width: 1em;
  height: 1em;
  background: white;
  border-radius: 100%;
  top: 0.5em;
  left: -0.3em;
  box-shadow: 4em 0.4em 0 -0.35em #3f3334, 0.5em 1em 0 white,
    4em 1em 0 -0.3em white, 4em 1em 0 -0.3em white, 4em 1em 0 -0.4em white;
}

.rabbit-jump:before {
  animation: kick 1s infinite linear;
}

.rabbit:after {
  content: '';
  position: absolute;
  width: 0.75em;
  height: 2em;
  background: white;
  border-radius: 50% 100% 0 0;
  transform: rotate(-30deg);
  right: 1em;
  top: -1em;
  border-top: 1px solid #f7f5f4;
  border-left: 1px solid #f7f5f4;
  box-shadow: -0.5em 0em 0 -0.1em white;
}

.bunny-run {
  animation: run 6s infinite linear;
}

@keyframes run {
  0% {
    transform: translateX(0) scaleX(1);
  }
  55% {
    transform: translateX(300%);
  }
  60% {
    transform: translateX(300%) scaleX(-1);
  }
  95% {
    transform: translateX(0) scaleX(-1);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}

@keyframes hop {
  20% {
    transform: rotate(-10deg) translate(0em, -2em);
  }
  40% {
    transform: rotate(10deg) translate(0em, -4em);
  }
  60%,
  75% {
    transform: rotate(0) translate(0em, 0);
  }
}
@keyframes kick {
  20%,
  50% {
    box-shadow: 4em 0.4em 0 -0.35em #3f3334, 0.5em 1.5em 0 white,
      4em 1.75em 0 -0.3em white, 4em 1.75em 0 -0.3em white,
      4em 1.9em 0 -0.4em white;
  }
  40% {
    box-shadow: 4em 0.4em 0 -0.35em #3f3334, 0.5em 2em 0 white,
      4em 1.75em 0 -0.3em white, 4.2em 1.75em 0 -0.2em white,
      4.4em 1.9em 0 -0.2em white;
  }
}

.ball-night {
  background: rgb(79, 89, 99);
  background: linear-gradient(
    180.14deg,
    #083b6f 19.46%,
    rgba(23, 83, 143, 0.2) 86.98%
  );
  opacity: 0.6;
}

.ball-morning {
  background: linear-gradient(
    200.97deg,
    #f4bc38 15.81%,
    #3279c0 52.59%,
    #194c7e 90.55%
  );
  opacity: 0.15;
}

.ball-afternoon {
  background: linear-gradient(
    200.38deg,
    #0b4a89 21.24%,
    #3279c0 44.81%,
    #f28a10 70.09%,
    #f4bc38 90.38%
  );
  opacity: 0.15;
}

.ball-animation {
  transform: scale(0);
  animation: scale 1s;
  animation-fill-mode: forwards;
}

.hoover-animation {
  animation: hoover 6s ease-in-out infinite;
}

.cloud-animation {
  animation: cloud 20s linear;
  animation-iteration-count: infinite;
}

.sun-animation {
  transform: translateY(350%);
  animation: sun 1.3s ease-in-out forwards;
}

.sun {
  filter: blur(4.5px);
}

.ground-appear {
  overflow: hidden;
  height: 0px;
  animation: ground 0.4s ease-out forwards;
}

.appear {
  animation: sun 0.4s ease-out forwards;
}

.jiggle-animation {
  transform-origin: bottom center;
  animation: jiggle 0.6s ease-in-out forwards;
  animation-delay: 0.4s;
}

.seed-animation {
  transform: scale(0);
  transform-origin: bottom;
  animation: bounce-in 0.6s ease-in-out forwards;
  animation-delay: 0.6s;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

.top-animation {
  animation: top 0.5s both;
}

.leaf-animation {
  transform: scale(0);
  animation: leaf 0.5s forwards;
}

.leaf-fall {
  animation: fall 1s forwards;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fall {
  100% {
    bottom: -50px;
    opacity: 0;
  }
}

@keyframes top {
  0% {
    left: 8px;
    transform: scale(0);
  }
  50% {
    left: 12px;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes leaf {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.stalk-w-animation {
  width: 0;
  height: var(--var-height);
  transform-origin: bottom;
  animation: stalk-w 0.3s ease-out forwards;
  animation-delay: 0.2s;
}

.branch-animation {
  width: 0;
  height: var(--var-height);
  transform-origin: bottom;
  animation: stalk-w 0.3s ease-out forwards;
}

.stalk-animation {
  height: 0;
  transform-origin: bottom;
  animation: stalk 0.6s ease-out forwards;
  animation-delay: 1s;
}

.hide-stalk-animation {
  height: var(--var-height);
  animation: hide-stalk 0.3s linear forwards;
}

@keyframes stalk-w {
  0% {
    width: 0;
  }
  100% {
    width: 24px;
  }
}

@keyframes hide-stalk {
  0% {
    transform: skew(0);
  }
  99% {
    transform: skew(-14deg, 32deg) translateY(16px) translateX(-4px);
    opacity: 1;
  }
  100% {
    transform: skew(-14deg, 32deg) translateY(16px) translateX(-4px);
    opacity: 0;
  }
}

@keyframes stalk {
  0% {
    height: 0;
  }
  100% {
    height: var(--var-height);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg) translateY(-22px) scale(1);
  }
  33% {
    transform: rotate(-10deg) translateY(0px) scale(1.05) translateX(-15px);
  }
  50% {
    transform: rotate(0deg) translateY(7px) scale(1) translateX(5px);
  }
  66% {
    transform: rotate(5deg) translateY(15px);
  }
  75% {
    transform: rotate(0deg) translateY(8px);
  }
  100% {
    transform: rotate(10deg) translateY(0px) translateX(-15px);
  }
}

@keyframes jiggle {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.15, 0.85, 1);
  }
  40% {
    transform: scale3d(0.85, 1.15, 1);
  }
  50% {
    transform: scale3d(1.05, 0.95, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes sun {
  0% {
    transform: translateY(350%);
  }
  70% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes ground {
  0% {
    height: 0px;
  }
  70% {
    height: var(--height-top);
  }
  99% {
    height: var(--height-final);
    overflow: hidden;
  }
  100% {
    height: var(--height-final);
    overflow: visible;
  }
}

@keyframes hoover {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes cloud {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  30% {
    transform: translateX(-100%);
    opacity: 1;
  }
  60% {
    transform: translateX(-200%);
    opacity: 0;
  }
  61% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.stars {
  position: absolute;
  width: 16rem;
  height: 100%;
  top: 0;
  right: 0;
}
.stars .star {
  position: relative;
  background: #f1f1f1;
  border-radius: 50%;
  animation: twinkle 2s ease-in-out infinite;
}
@keyframes twinkle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.stars .star:nth-of-type(1) {
  top: 45%;
  left: 80%;
  width: 6px;
  height: 6px;
  animation-delay: 2s;
}
.stars .star:nth-of-type(2) {
  top: 70%;
  left: 48%;
  width: 5px;
  height: 5px;
  animation-delay: 4s;
}
.stars .star:nth-of-type(3) {
  top: 4%;
  left: 44%;
  width: 3px;
  height: 3px;
  animation-delay: 1s;
}
.stars .star:nth-of-type(4) {
  top: 23%;
  left: 53%;
  width: 1px;
  height: 1px;
  animation-delay: 2s;
}
.stars .star:nth-of-type(5) {
  top: 26%;
  left: 93%;
  width: 3px;
  height: 3px;
  animation-delay: 1.3s;
}
.stars .star:nth-of-type(6) {
  top: 46%;
  left: 13%;
  width: 4px;
  height: 4px;
  animation-delay: 2.3s;
}
.stars .star:nth-of-type(7) {
  top: 66%;
  left: 34%;
  width: 2px;
  height: 2px;
  animation-delay: 1.3s;
}
.stars .star:nth-of-type(8) {
  top: 36%;
  left: 3%;
  width: 3px;
  height: 3px;
  animation-delay: 3.3s;
}
.stars .star:nth-of-type(9) {
  top: 76%;
  left: 54%;
  width: 1px;
  height: 1px;
  animation-delay: 2.3s;
}
.stars .star:nth-of-type(10) {
  top: 56%;
  left: 24%;
  width: 3px;
  height: 3px;
  animation-delay: 1.3s;
}
.stars .star:nth-of-type(11) {
  top: 76%;
  left: 25%;
  width: 3px;
  height: 3px;
  animation-delay: 0.3s;
}
.stars .star:nth-of-type(12) {
  top: 56%;
  left: 55%;
  width: 1px;
  height: 1px;
  animation-delay: 2.31s;
}
.stars .star:nth-of-type(13) {
  top: 36%;
  left: 35%;
  width: 3px;
  height: 3px;
  animation-delay: 1.31s;
}
.stars .star:nth-of-type(14) {
  top: 86%;
  left: 45%;
  width: 2px;
  height: 2px;
  animation-delay: 2.31s;
}
.stars .star:nth-of-type(15) {
  top: 82%;
  left: 85%;
  width: 3px;
  height: 3px;
  animation-delay: 0.31s;
}
.stars .star:nth-of-type(16) {
  top: 81%;
  left: 15%;
  width: 1px;
  height: 1px;
  animation-delay: 1.31s;
}
.stars .star:nth-of-type(17) {
  top: 83%;
  left: 35%;
  width: 3px;
  height: 3px;
  animation-delay: 2.31s;
}
.stars .star:nth-of-type(18) {
  top: 23%;
  left: 55%;
  width: 2px;
  height: 2px;
  animation-delay: 1.31s;
}
.stars .star:nth-of-type(19) {
  top: 53%;
  left: 50%;
  width: 1px;
  height: 1px;
  animation-delay: 1.31s;
}
.stars .star:nth-of-type(20) {
  top: 83%;
  left: 20%;
  width: 1px;
  height: 1px;
  animation-delay: 1.51s;
}

.shooting-star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, rgb(255, 255, 255), rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px rgb(255, 255, 255));
  animation: tail 3000ms ease-in-out infinite,
    shooting 3000ms ease-in-out infinite;
}

.shooting-star:nth-child(1) {
  top: calc(50% - -173px);
  left: calc(50% - 119px);
  animation-delay: 3358ms;
}

.shooting-star:nth-child(2) {
  top: calc(50% - -118px);
  left: calc(50% - 254px);
  animation-delay: 9421ms;
}

.shooting-star:nth-child(3) {
  top: calc(50% - -248px);
  left: calc(50% - 254px);
  animation-delay: 16421ms;
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
@keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(350px);
  }
}

.butterfly-animation {
  animation: butterfly 5s ease-in-out infinite;
  animation-delay: 1s;
}

.butterfly {
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateY(0px);
  width: 30px;
}

.butterfly-hover {
  animation: hover 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
  animation-direction: alternate;
  animation-fill-mode: reverse;
}

.butterfly::before {
  background: #23455b;
  border-radius: 50%;
  content: '';
  display: block;
  height: 110px;
  left: 50%;
  margin-left: -10px;
  outline: 1px solid transparent;
  position: absolute;
  top: -15px;
  transform: rotatey(100deg);
  width: 20px;
  z-index: 2;
}

.wing {
  background: #888;
  display: block;
  opacity: 0.7;
  outline: 1px solid transparent;
  position: absolute;
  top: 0;
}

.wing:first-child {
  height: 1px;
  left: 0;
  transform: rotateY(-20deg);
  transform-origin: 700% 50%;
  width: 1px;
  z-index: 3;
}
.wing-animation:first-child {
  animation: leftflap var(--flap-speed) cubic-bezier(0.48, 0.01, 0.54, 1)
    infinite;
  animation-direction: alternate;
  animation-fill-mode: reverse;
}

.wing-animation:last-child {
  animation: rightflap var(--flap-speed) cubic-bezier(0.48, 0.01, 0.54, 1)
    infinite;
  animation-direction: alternate;
  animation-fill-mode: reverse;
}
.wing:last-child {
  right: 0;
  transform: rotateY(200deg);
  z-index: 1;
}
.wing .bit {
  background: #ffb600;
}
.wing .bit::after {
  background: #f4b20e;
}
.wing .bit,
.wing .bit::after {
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: 100% 50%;
}
.wing .bit:first-child {
  height: 70px;
  text-align: center;
  top: 15px;
  transform: rotateZ(40deg);
  width: 130px;
}
.wing .bit:first-child::after {
  content: '';
  display: inline-block;
  height: 60px;
  left: -30px;
  top: 5px;
  width: 100px;
}
.wing .bit:last-child {
  height: 55px;
  transform: rotateZ(-40deg);
  width: 100px;
}
.wing .bit:last-child::after {
  content: '';
  display: inline-block;
  height: 45px;
  left: -24px;
  top: 5px;
  width: 60px;
  z-index: 1;
}

@keyframes butterfly {
  0% {
    transform: translateY(0%) scale(0.15);
  }
  50% {
    transform: translateY(250%) scale(0.15);
  }
  100% {
    transform: translateY(0%) scale(0.15);
  }
}

@keyframes hover {
  0% {
    transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(0px);
  }
  100% {
    transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(-3px);
  }
}

@keyframes leftflap {
  0% {
    transform: rotateY(-20deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rightflap {
  0% {
    transform: rotateY(200deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}
</style>
