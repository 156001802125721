<template>
  <div class="px-14">
    <div class="text-left text-3xl font-medium text-grey-2 mb-6">
      <span class="text-opacity-10">
        {{ $t('invest.project_sidebar.invest_in') }}
      </span>
      <span>
        {{ ' ' + project.name }}
      </span>
    </div>
    <!-- Picture -->
    <div
      :style="
        project.images.card
          ? 'background-image: url(\'' + project.images.card.path + '\')'
          : ''
      "
      class="
        w-full
        relative
        p-6
        bg-cover bg-center
        z-40
        lg:rounded-2xl lg:mt-6
        h-52
        mb-10
      "
      :class="{ 'bg-grey-600': !project.images.card }"
    >
      <div class="absolute top-4 right-4">
        <RiskAndCategory
          class="hidden lg:flex"
          :risk="project.risk"
          :category="mkt ? project.project_category : project.category"
        />
      </div>
      <div v-if="!mkt" class="absolute bottom-4 left-4 text-sm text-white">
        <span class="font-semibold">
          {{
            '$ ' + 
            dollarFormatting(
              project.invested.confirmed + project.invested.reserved
            ) + ' ' +
            $t('wording.raised')
          }}
        </span>
        <span class="white-text-80">
          {{
            ' ' +
            $t('wording.of') +
            ' $ ' +
            dollarFormatting(project.crowdlending_value)
          }}
        </span>
      </div>
    </div>
    <!-- Project info -->
    <!-- Interest -->
    <div class="w-full flex justify-between items-center mb-3">
      <p class="text-sm text-grey-2 text-opacity-80">
        {{ $t('invest.project_sidebar.interest') }}
      </p>
      <p class="text-sm font-semibold text-grey-2 text-opacity-80">
        {{ conditions.interest_investor }}%
      </p>
    </div>
    <!-- Periodicity -->
    <div class="w-full flex justify-between items-center mb-3">
      <p class="text-sm text-grey-2 text-opacity-80">
        {{ $t('invest.project_sidebar.periodicity') }}
      </p>
      <p class="text-sm font-semibold text-grey-2 text-opacity-80">
        {{ $t('wording.periodicity.' + conditions.periodicity) }}
      </p>
    </div>
    <!-- Term -->
    <div class="w-full flex justify-between items-center mb-3">
      <p class="text-sm text-grey-2 text-opacity-80">
        {{ $t('invest.project_sidebar.term') }}
      </p>
      <p class="text-sm font-semibold text-grey-2 text-opacity-80">
        {{
          $t(
            'wording.month',
            { n: conditions.duration_months },
            conditions.duration_months
          )
        }}
      </p>
    </div>
    <!-- Grace period -->
    <div
      class="w-full flex justify-between items-center mb-3"
      v-if="conditions.grace_period_months !== 0"
    >
      <p class="text-sm text-grey-2 text-opacity-80">
        {{ $t('invest.project_sidebar.grace_period') }}
      </p>
      <p class="text-sm font-semibold text-grey-2 text-opacity-80">
        {{
          $t(
            'wording.month',
            { n: conditions.grace_period_months },
            conditions.grace_period_months
          )
        }}
      </p>
    </div>
    <!-- Risk rating -->
    <div class="w-full flex justify-between items-center">
      <p class="text-sm text-grey-2 text-opacity-80">
        {{ $t('invest.project_sidebar.risk') }}
      </p>
      <p class="text-sm font-semibold text-grey-2 text-opacity-80">
        {{ riskConversion(project.risk) }}
      </p>
    </div>
  </div>
</template>

<script>
import RiskAndCategory from '@/components/projects/RiskAndCategory.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { computed } from '@vue/reactivity'
export default {
  components: {
    RiskAndCategory
  },
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    mkt: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { dollarFormatting, riskConversion } = useGlobalHelpers()

    const conditions = computed(() => {
      return props.mkt
        ? props.project.offer_conditions
        : props.project.funding_rate
    })

    return {
      dollarFormatting,
      riskConversion,
      conditions
    }
  }
}
</script>

<style scoped>
.white-text-80 {
  color: rgba(255, 255, 255, 0.8);
}
</style>
