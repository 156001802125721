<template>
  <div
    class="flex cursor-pointer"
    @mouseover="() => (hover = true)"
    @mouseleave="() => (hover = false)"
  >
    <router-link
      v-if="router"
      :to="link"
      :class="[{ 'order-last': imageFirst }, textStyle]"
      class="hover:underline"
    >
      {{ text }}
    </router-link>
    <a
      v-else-if="link"
      :href="link"
      :class="[{ 'order-last': imageFirst }, textStyle]"
      class="hover:underline"
    >
      {{ text }}
    </a>
    <div
      v-else
      :class="[{ 'order-last': imageFirst }, textStyle]"
      class="hover:underline"
    >
      {{ text }}
    </div>
    <slot v-if="hover" name="hoverImage"></slot>
    <slot v-else name="image"></slot>
    <slot name="singleImage"></slot>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    link: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    textStyle: {
      type: String,
      default: ''
    },
    imageFirst: {
      type: Boolean,
      default: false
    },
    router: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const hover = ref(false)

    return {
      hover
    }
  }
}
</script>

<style lang="scss" scoped></style>
