import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import Info from "@/assets/ts/interfaces/form/info";

const subtitle:Info = {
  img: 'light_bulb_white_border',
  title: i18n.global.t('ca_legislation.onboarding.info_title'),
  subtitle: i18n.global.t('ca_legislation.onboarding.info_text'),
}

const info:PageForm =
  {
    title: i18n.global.t('ca_legislation.onboarding.sidebar_title'),
    image: require('@/assets/img/lamp-sidebar.png'),
    form: [
      {
        title: i18n.global.t('ca_legislation.onboarding.info_title'),
        subtitle: subtitle,
        fields: [],
        next: true,
        back: false,
        hideHeader: true,
      }
    ]
  }

export default info
