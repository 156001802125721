import { useI18n } from 'vue-i18n'

export default function () {
  const seller = (sellerName) => {
    const i18n = useI18n({ useScope: 'global' })

    if (sellerName && sellerName.substr(sellerName.length - 1) === 's')
      return i18n.t('mkt.offer_owner_s', { name: sellerName }, sellerName)
    return i18n.t('mkt.offer_owner', { name: sellerName }, sellerName)
  }

  return {
    seller
  }
}
