import axios from 'axios'

export default {
  state: {
    loan: null,
    payments: null,
    next_page: 1,
    total_payments: null,
    fetched: [],
    fetchedLoans: false,
    loans: [],
    order: null,
    has_next_page: true
  },
  mutations: {
    SET_LOANS(state, loans) {
      state.loans = loans
    },
    SET_ORDER(state, order) {
      state.order = order
    },
    SET_FETCHED_LOANS(state, fetched) {
      state.fetchedLoans = fetched
    },
    SET_LOAN(state, loan) {
      state.loan = loan
    },
    SET_PAYMENTS(state, payments) {
      if (state.payments) state.payments = state.payments.concat(payments)
      else state.payments = [].concat(payments)
    },
    CLEAR_PAYMENTS(state) {
      state.payments = null
    },
    CLEAR_FETCHED(state) {
      state.fetched = []
    },
    SET_TOTAL_PAYMENTS(state, total_payments) {
      state.total_payments = total_payments
    },
    SET_FETCHED(state, page) {
      state.fetched.push(page)
    },
    SET_NEXT_PAGE(state, next_page) {
      state.next_page = next_page
    },
    SET_HAS_NEXT_PAGE(state, has_next_page) {
      state.has_next_page = has_next_page
    }
  },
  actions: {
    fetchLoans({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/promoters')
        .then((loans) => {
          commit('SET_FETCHED_LOANS', true)
          commit('SET_LOANS', loans.data)
        })
    },
    setLoanOrder({ commit }, order) {
      commit('SET_ORDER', order)
    },
    fetchLoan({ commit }, id) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/promoters/' + id)
        .then(({ data }) => {
          commit('SET_LOAN', data)
          return data
        })
    },
    // eslint-disable-next-line no-unused-vars
    sendPaymentsExcel({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/promoters/' +
              id +
              '/payments/excel'
          )
          .then(({ data }) => {
            resolve(data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
            reject(e)
          })
      })
    },
    clearPayments({ commit }) {
      commit('CLEAR_PAYMENTS')
      commit('CLEAR_FETCHED')
      commit('SET_HAS_NEXT_PAGE', true)
      commit('SET_NEXT_PAGE', 1)
      commit('SET_TOTAL_PAYMENTS', null)
    },
    fetchPayments({ commit, state }, id) {
      if (!state.fetched.includes(state.next_page)) {
        commit('SET_FETCHED', state.next_page)
        return axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/promoters/' +
              id +
              '/payments',
            {
              params: {
                page: state.next_page
              }
            }
          )
          .then(({ data }) => {
            commit('SET_PAYMENTS', data.data)
            commit('SET_HAS_NEXT_PAGE', data.links.next)
            commit('SET_NEXT_PAGE', state.next_page + 1)
            commit('SET_TOTAL_PAYMENTS', {
              total: data.meta.total,
              total_paid: data.total_paid
            })

            return data
          })
      }
    }
  },
  getters: {
    orderedDashboardLoans: (state) => () => {
      if (state.loans) {
        if (state.loans.length > 1) {
          let loansCopy = JSON.parse(JSON.stringify(state.loans))
          let ongoingLoans = loansCopy.filter((el) => {
            return el.state === 'funded'
          })
          let settledLoans = loansCopy.filter((el) => {
            return el.state === 'settled'
          })
          ongoingLoans.sort(function (a, b) {
            return (
              (a.next_payment ? a.next_payment.days_to_next_payment : 0) -
              (b.next_payment ? b.next_payment.days_to_next_payment : 0)
            )
          })
          settledLoans.sort(function (a, b) {
            return (b.settled_at ?? '01/01/97') - (a.settled_at ?? '01/01/97')
          })

          return ongoingLoans.concat(settledLoans)
        }
      }
    },
    getNextDD: (state) => () => {
      let dd = null
      if (state.loans) {
        if (state.loans.length === 1) {
          dd = state.loans[0]
        } else {
          const loansWithDD = state.loans.filter((el) => {
            return el.next_direct_debit
          })
          if (loansWithDD.length > 0) {
            loansWithDD.sort(function (a, b) {
              return (
                new Date(
                  a.next_direct_debit
                    ? a.next_direct_debit.execution_date
                    : '01/01/97'
                ) -
                new Date(
                  b.next_direct_debit
                    ? b.next_direct_debit.execution_date
                    : '01/01/97'
                )
              )
            })
            const failedDDs = loansWithDD.filter((el) => {
              return el.next_direct_debit.status === 'FAILED'
            })
            if (failedDDs.length > 0) dd = failedDDs[0]
            else dd = loansWithDD[0]
          }
        }
      }
      return dd
    },
    getLoansInArrears: (state) => () => {
      if (state.loans)
        return state.loans.filter((l) => {
          return l.loan_status.in_arrears > 0
        })
      else return null
    }
  }
}
