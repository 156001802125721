<template>
  <div class="page container">
    <span class="md:block md:pt-28">
      <Back />
    </span>
    <div
      class="
        text-lg text-grey-2
        md:text-xl md:text-grey-1
        font-bold
        text-left
        absolute
        md:relative md:top-auto md:left-auto
        top-5
        left-12
      "
    >
      {{ $t('promoter.loans.title') }}
    </div>
    <div class="mb-2 justify-between items-end">
      <div class="mt-6">
        <div class="flex">
          <div
            class="mr-6 md:mr-12 cursor-pointer text-sm md:text-base"
            :class="{
              'font-bold text-grey-2': index === state,
              'text-grey-4': index !== state
            }"
            @click="changeState(index)"
            :id="index"
            v-for="(el, index) in $tm('promoter.loans.tabs')"
            v-bind:key="index"
          >
            {{ el.title }} ({{ amount(index) }})
          </div>
        </div>
        <div class="relative" ref="barParent">
          <div
            class="w-6 h-1 bg-orange-3 absolute bottom-0 transition-all"
            :style="'left:' + left + 'px'"
          ></div>
          <div class="h-0.5 w-full bg-grey-6 mt-4"></div>
        </div>
      </div>
    </div>
    <div class="text-left">
      <LoansOrder class="mb-4" v-if="order" :state="state" />
    </div>
    <Suspense>
      <Loans :state="state" @empty="setOrder" />
      <template #fallback>
        <div class="grid grid-cols-2 gap-x-6 gap-y-8">
          <ProjectSkeletons />
        </div>
      </template>
    </Suspense>
  </div>
</template>

<script>
import { defineAsyncComponent, ref, computed } from '@vue/runtime-core'
import Loans from '@/components/promoter/Loans'
import { useStore } from 'vuex'
import LoansOrder from '@/components/promoter/LoansOrder'

export default {
  components: {
    LoansOrder,
    Loans,
    ProjectSkeletons: defineAsyncComponent(() =>
      import('@/components/projects/ProjectSkeletons.vue')
    ),
    Back: defineAsyncComponent(() => import('@/components/general/Back.vue'))
  },

  setup() {
    const showPopup = ref(true)
    const store = useStore()
    const left = ref(0)
    const order = ref(true)
    const barParent = ref(null)
    const state = ref('funded')
    const loans = computed(() => {
      return store.state.promoters.loans
    })

    const settledLoans = computed(() => {
      return loans.value.filter((el) => {
        return el.state === 'settled'
      })
    })

    const setOrder = (new_order) => {
      order.value = new_order
    }

    const ongoingLoans = computed(() => {
      return loans.value.filter((el) => {
        return el.state === 'funded'
      })
    })

    const amount = (state) => {
      return state === 'funded'
        ? ongoingLoans.value.length
        : settledLoans.value.length
    }

    async function changeState(new_state) {
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      await timer(1)
      state.value = new_state
      left.value =
        document.getElementById(new_state).offsetLeft -
        barParent.value.offsetLeft
    }
    return {
      state,
      changeState,
      showPopup,
      left,
      order,
      amount,
      barParent,
      setOrder,
      settledLoans,
      ongoingLoans
    }
  }
}
</script>
