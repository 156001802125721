<template>
  <div class="steps">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.investment_amount.title')}}</p>
    <p class="text-sm text-grey-2 text-opacity-80 text-left mb-8">{{$t('auto_investment.setup.investment_amount.subtitle')}}</p>
    <Form @submit="next" class="form flex flex-col" ref="form" v-slot="{ errors, meta }">
      <div>
        <p class="text-sm text-grey-2 text-opacity-80 text-left mb-4 font-bold">{{$t('auto_investment.setup.project_min.title')}}</p>
        <GeneralInput
          v-model:data="projectMin"
          type="dollar"
          name="projectMin"
          :error="errors.projectMin"
          :locked="checkAmountCheck('projectMin')"
          :label="checkAmountCheck('projectMin') ? '' : $t('invest.amount.title')"
          :class="{'opacity-50': checkAmountCheck('projectMin')}"
          :rules="projectMinCheck ? '' : projectMax ? 'minValue:500|maxValue:' + dollarToNumberFormatting(projectMax) + ',1' : 'minValue:500|maxValue:2499900,1'"
          prefix="$"
        />
        <div class="flex mb-4">
          <div
            v-for="el in projectMinAmounts"
            v-bind:key="el"
            :class="{'cursor-not-allowed': checkAmountCheck('projectMin')}"
            class="
              text-13 text-orange-1
              flex-initial
              font-bold
              px-4
              py-2
              bg-white
              rounded-xl
              mr-3
              cursor-pointer
              mt-3
              lg:mt-0
            "
            @click="checkAmountCheck('projectMin') ? null : projectMin = dollarFormatting(el)"
          >
            $ {{ numberFormatting(dollarFormatting(el)) }} 
          </div>
        </div>
        <GeneralInput
          v-model:data="projectMinCheck"
          class="custom-checkbox ml-2"
          type="checkbox"
          name="noProjectMin"
        >
          <template #label>
            <span class="ml-2">
              {{ $t('auto_investment.setup.project_min.info_box') }}
            </span>
          </template>
        </GeneralInput>
        <p class="text-sm text-grey-2 text-opacity-80 text-left mb-4 mt-10 font-bold">{{$t('auto_investment.setup.project_max.title')}}</p>
        <GeneralInput
          v-model:data="projectMax"
          type="dollar"
          name="projectMax"
          :error="errors.projectMax"
          :locked="checkAmountCheck('projectMax') ? '' : checkAmountCheck('projectMax')"
          :label="checkAmountCheck('projectMax') ? '' : $t('invest.amount.title')"
          :data-vv-as="$t('auto_investment.setup.project_max.title')"

          :class="{'opacity-50': checkAmountCheck('projectMax')}"
          :rules="projectMaxCheck ? '' : projectMin ? 'minValue:'+dollarToNumberFormatting(projectMin)+'|maxValue:2499900,1' + ',1' : 'minValue:500|maxValue:2499900,1'"
          prefix="$"
        />
        <div class="flex mb-4">
          <div
            v-for="el in projectMaxAmounts"
            v-bind:key="el"
            :class="{'cursor-not-allowed': checkAmountCheck('projectMax')}"
            class="
              text-13 text-orange-1
              font-bold
              flex-initial
              px-4
              py-2
              bg-white
              rounded-xl
              mr-3
              cursor-pointer
              mt-3
              lg:mt-0
            "
            @click="checkAmountCheck('projectMax') ? null : projectMax = dollarFormatting(el)"
          >
            $ {{ dollarFormatting(el).split(".")[0] }} 
          </div>
        </div>
        <GeneralInput
          v-model:data="projectMaxCheck"
          class="custom-checkbox ml-2"
          type="checkbox"
          name="noProjectMax"
        >
          <template #label>
            <span class="ml-2">
              {{ $t('auto_investment.setup.project_max.info_box') }}
            </span>
          </template>
        </GeneralInput>
      </div>
      <div class="flex justify-between mt-20">
        <div></div>
        <GpButton
          mobileWidth="w-full"
          @click="next"
          :disabled="!formFilled || !meta.valid"
          class="text-right"
        >
          {{ $t('wording.continue') }}
        </GpButton>
      </div>
    </Form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import { Form } from 'vee-validate'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    GpButton,
    GeneralInput,
    Form
  },
  setup(props, { emit }) {
    const store = useStore()
    const form = ref(null)
    const projectMin = ref(null)
    const projectMinCheck = ref(false)
    const projectMinAmounts = ref([5000, 7500, 15000, 25000, 50000])
    const { dollarFormatting, numberFormatting, dollarToNumberFormatting } = useGlobalHelpers()
    const projectMax = ref(null)
    const projectMaxCheck = ref(false)
    const projectMaxAmounts = ref([10000, 50000, 100000, 200000])
    const projectMaxToStore = ref(null)
    const projectMinToStore = ref(null)

    async function next() {
      if((projectMin.value || projectMinCheck.value) && (projectMax.value || projectMaxCheck.value)) {
        if(projectMinCheck.value) projectMinToStore.value = false
        else projectMinToStore.value = parseInt(dollarToNumberFormatting(projectMin.value)) 
        if(projectMaxCheck.value) projectMaxToStore.value = false 
        else projectMaxToStore.value = parseInt(dollarToNumberFormatting(projectMax.value))
        store.dispatch('updateNewAutoInvestment', {
          project_min: projectMinToStore.value,
          project_max: projectMaxToStore.value
        })
        emit('next')
      }
    }

    const checkAmountCheck = (value) => {
      if((value === 'projectMin' && projectMinCheck.value === true) || (value === 'projectMax' && projectMaxCheck.value === true)) {
        if(value === 'projectMin')  projectMin.value = null
        if(value === 'projectMax')  projectMax.value = null
        return true
      }
      return false
    }

    const formFilled = computed(() => {
      return  ( projectMin.value || projectMinCheck.value ) && ( projectMax.value || projectMaxCheck.value )
    })

    return {
      next,
      projectMin,
      projectMax, 
      projectMaxCheck,
      projectMaxAmounts,
      dollarFormatting,
      projectMinCheck,
      numberFormatting,
      projectMinAmounts,
      form,
      formFilled,
      checkAmountCheck,
      dollarToNumberFormatting,
      projectMinToStore,
      projectMaxToStore
    }
  }
}
</script>

<style lang="css" scoped>
.custom-checkbox > :first-child {
  display: flex;
}

</style>
