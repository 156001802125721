<template>
  <div>
    <div class="text-left flex text-grey-2">
      <div
        class="cursor-pointer mr-12"
        @click="changeTab($event, true)"
        :class="{ 'text-grey-4': !isPending }"
      >
        <span :class="{ 'font-bold': isPending }" class="no-highlight">{{
          $t('invite.invitations.pending.tab_name')
        }}</span>
        <span class="text-xs"> ({{ pendingVouchers.length }}) </span>
      </div>
      <div
        class="cursor-pointer no-highlight"
        @click="changeTab($event, false)"
        :class="{ 'text-grey-4': isPending }"
      >
        <span :class="{ 'font-bold': !isPending }">{{
          $t('invite.invitations.redeemed.tab_name')
        }}</span>
        <span class="text-xs"> ({{ vouchers.length }})</span>
      </div>
    </div>

    <div class="w-8 h-1 bg-orange-3 absolute nav-bottom" id="nav-bottom"></div>

    <hr class="mt-3" />

    <div>
      <transition name="fade" mode="out-in">
        <div class="" v-if="isPending">
          <div class="opacity-40 text-left mt-4 mb-7">
            {{ $t('invite.invitations.pending.description', {value: mgmValue}) }}
          </div>
          <div
            class="lg:bg-white w-full rounded-3xl lg:p-6 text-left text-grey-2"
          >
            <div v-if="pendingVouchers.length">
              <div
                v-bind:key="voucher"
                class="relative"
                v-for="(voucher, index) in pendingVouchers"
              >
                <transition name="toast-fade">
                  <div
                    class="absolute w-42 bottom-1 right-32"
                    v-if="show === voucher.id"
                  >
                    <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
                      <img src="@/assets/svg/copy_inverted.svg" alt="" />
                      <div class="ml-3 opacity-80 font-semibold text-13">
                        {{ $t('invite.invitations.pending.reminder') }}
                      </div>
                    </div>
                  </div>
                </transition>
                <div class="flex justify-between mb-6 lg:mb-0">
                  <div class="opacity-80">
                    {{ voucher.name + ' ' + voucher.surname }}
                    <div>
                      <div class="text-grey-4 text-xs">
                        {{
                          $t('invite.invitations.pending.expires', {
                            days: daysLeft(voucher.end_date)
                          })
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="orange-link"
                    :class="{
                      'opacity-30 cursor-not-allowed':
                        voucher.last_reminded &&
                        !canRemind(voucher.last_reminded)
                    }"
                    @click="
                      (voucher.last_reminded &&
                        canRemind(voucher.last_reminded)) ||
                      !voucher.last_reminded
                        ? remindUser(voucher.id, index)
                        : ''
                    "
                  >
                    {{ $t('invite.invitations.pending.remind') }}
                  </div>
                </div>
                <hr
                  class="my-4 hidden lg:block"
                  v-if="index < pendingVouchers.length - 1"
                />
              </div>
            </div>
            <div v-else>
              {{ $t('invite.invitations.pending.no_invitations') }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="opacity-40 text-left mt-4 mb-7">
            {{ $t('invite.invitations.redeemed.description') }}
          </div>
          <div
            class="lg:bg-white w-full rounded-3xl lg:p-6 text-left text-grey-2"
          >
            <div v-if="vouchers.length">
              <div v-bind:key="voucher" v-for="(voucher, index) in vouchers">
                <div class="flex justify-between mb-6 lg:mb-0">
                  <div class="opacity-80">
                    {{ voucher.name + ' ' + voucher.surname }}
                  </div>
                  <div class="opacity-60">
                    {{ formatDate(new Date(voucher.updated_at.replace(/-/g, "/"))) }}
                  </div>
                </div>
                <div class="text-grey-4 text-xs">
                   ${{ dollarFormatting(voucher.value) }}
                  <span class="lowercase">{{ $t('wording.invested') }}</span>
                </div>
                <hr
                  class="my-4 hidden lg:block"
                  v-if="index < vouchers.length - 1"
                />
              </div>
            </div>
            <div v-else>
              {{ $t('invite.invitations.redeemed.no_invitations') }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from 'vuex'
import { format } from 'date-fns'
import useGlobalHelpers from '@/mixins/useGlobalHelpers'

export default {
  components: {},
  setup() {
    const store = useStore()
    const show = ref(null)
    const { dollarFormatting } = useGlobalHelpers()
    const vouchers = ref([])
    const pendingVouchers = ref([])
    const mgmValue = computed(()=>{
      return store.getters.getMgmValue()
    })
    let isPending = ref(true)
    const right = ref([
      '4',
      '8',
      '12',
      '20',
      '16',
      '14',
      '24',
      '32',
      '56',
      '64',
      '72',
      '80',
      '96'
    ])

    function canRemind(date) {
      const hours = 1000 * 60 * 60 * 25
      const hourago = Date.now() - hours
      return new Date(date.replace(/-/g, "/")) <= new Date(hourago)
    }

    function daysLeft(date) {
      const diffTime = Math.abs(new Date() - new Date(date.replace(/-/g, "/")))
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    }

    function remindUser(id, index) {
      store
        .dispatch('remindUser', id)
        .then((data) => {
          pendingVouchers.value[index].last_reminded = data.last_reminded
          show.value = id
          setTimeout(function () {
            show.value = null
          }, 800)
        })
        .catch((e) => {
          store.dispatch('setError', e)
        })
    }

    onMounted(async () => {
      if (!store.state.vouchers.usedVouchers)
        await store.dispatch('getUsedVouchers')

      if (!store.state.vouchers.pendingVouchers)
        await store.dispatch('getPendingVouchers')

      vouchers.value = store.state.vouchers.usedVouchers
      pendingVouchers.value = store.state.vouchers.pendingVouchers
    })

    function changeTab(e, value) {
      isPending.value = value
      const navBottom = document.getElementById('nav-bottom')
      navBottom.style.left = e.target.getBoundingClientRect().left + 'px'
    }

    function formatDate(date) {
      return format(date, 'dd/MM/Y')
    }

    return {
      vouchers,
      pendingVouchers,
      right,
      isPending,
      canRemind,
      changeTab,
      formatDate,
      remindUser,
      show,
      daysLeft,
      mgmValue,
      dollarFormatting
    }
  }
}
</script>
<style scoped>
.nav-bottom {
  transition: left 0.2s ease-in-out;
  margin-top: 0.65rem;
}

.balloon {
  animation: animate-balloon ease-in both infinite;
  animation-delay: var(--delay);
  animation-duration: var(--duration);
}

@keyframes animate-balloon {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateY(-200px);
  }
}
</style>
