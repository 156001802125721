import info from "@/assets/ts/onboarding/steps/info";
import types from "@/assets/ts/onboarding/steps/types";
import questions from "@/assets/ts/onboarding/steps/questions";
import questions2 from "@/assets/ts/onboarding/steps/questions2";

const form =
  {
      info,
      types,
      questions,
      questions2
  }

export default form
