<template>
  <div>
    <div class="flex items-center mb-2 lg:mb-6">
      <p class="text-grey-2 font-bold text-sm text-left mr-2">
        {{
          offer
            ? $t('projects.details.conditions.title_offer')
            : $t('projects.details.conditions.title')
        }}
      </p>
      <img
        src="@/assets/svg/info.svg"
        alt="info"
        class="cursor-pointer"
        @click="openPopup"
      />
    </div>
    <!-- Popup -->
    <div class="hidden">
      <div class="text-left" id="popup-content-project-details">
        <p class="font-bold text-lg text-grey-2 mb-9">
          {{
            offer
              ? $t('projects.details.popup.title_offer')
              : $t('projects.details.popup.title')
          }}
        </p>
        <p class="font-bold text-sm text-grey-2 mb-4">
          {{ $t('projects.details.popup.interest_rate_title') }}
        </p>
        <p class="text-sm text-grey-2 text-opacity-60 mb-12">
          {{ $t('projects.details.popup.interest_rate_text') }}
        </p>
        <div v-if="!offer">
          <p class="font-bold text-sm text-grey-2 mb-4">
            {{ $t('projects.details.popup.term_title') }}
          </p>
          <p class="text-sm text-grey-2 text-opacity-60 mb-12">
            {{ $t('projects.details.popup.term_text') }}
          </p>
        </div>
        <div v-else>
          <p class="font-bold text-sm text-grey-2 mb-4">
            {{ $t('projects.details.popup.remaining_term_title') }}
          </p>
          <p class="text-sm text-grey-2 text-opacity-60 mb-12">
            {{ $t('projects.details.popup.remaining_term_text') }}
          </p>
        </div>
        <div v-if="conditions.grace_period_months">
          <p class="font-bold text-sm text-grey-2 mb-4">
            {{ $t('projects.details.popup.grace_period_title') }}
          </p>
          <p class="text-sm text-grey-2 text-opacity-60 mb-12">
            {{ $t('projects.details.popup.grace_period_text') }}
          </p>
        </div>
        <p class="font-bold text-sm text-grey-2 mb-4">
          {{ $t('projects.details.popup.periodicity_title') }}
        </p>
        <p class="text-sm text-grey-2 text-opacity-60 mb-12">
          {{ $t('projects.details.popup.periodicity_text') }}
        </p>
        <p class="font-bold text-sm text-grey-2 mb-4">
          {{ $t('projects.details.popup.risk_title') }}
        </p>
        <p class="text-sm text-grey-2 text-opacity-60 mb-12">
          {{ $t('projects.details.popup.risk_text') }}
        </p>
      </div>
    </div>
    <template v-if="campaign">
      <div class="flex w-full justify-between items-center mb-2 lg:mb-4">
        <p class="text-sm text-grey-2 text-opacity-80 text-left">
          {{
            campaign
              ? $t('projects.details.conditions.nominal')
              : $t('projects.details.conditions.interest')
          }}
        </p>
        <p class="font-bold text-sm text-grey-2 text-opacity-80">
          {{ project.funding_rate.effective }}%
        </p>
      </div>
    </template>
    <div class="flex w-full justify-between items-center mb-2 lg:mb-4">
      <p class="text-sm text-grey-2 text-opacity-80 text-left">
        {{ $t('projects.details.conditions.interest') }}
      </p>
      <p class="font-bold text-sm text-grey-2 text-opacity-80">
        {{ conditions.interest_investor }}%
      </p>
    </div>
    <div class="flex w-full justify-between items-center mb-2 lg:mb-4">
      <p class="text-sm text-grey-2 text-opacity-80 text-left">
        {{ $t('projects.details.conditions.periodicity') }}
      </p>
      <p class="text-sm text-grey-2 text-opacity-80 font-bold">
        {{ $t('wording.periodicity.' + conditions.periodicity) }}
      </p>
    </div>
    <div class="flex w-full justify-between items-center mb-2 lg:mb-4">
      <p class="text-sm text-grey-2 text-opacity-80">
        {{
          $t(
            'projects.details.conditions.' +
              (offer ? 'remaining_' : '') +
              'term'
          )
        }}
      </p>
      <p class="text-sm text-grey-2 text-opacity-80 font-bold text-left">
        {{
          $t(
            'wording.month',
            { n: conditions.duration_months },
            conditions.duration_months
          )
        }}
      </p>
    </div>
    <div
      v-if="conditions.grace_period_months"
      class="flex w-full justify-between items-center mb-2 lg:mb-4"
    >
      <p class="text-sm text-grey-2 text-opacity-80 text-left">
        {{ $t('projects.details.conditions.grace_period') }}
      </p>
      <p class="text-sm text-grey-2 text-opacity-80 font-bold">
        {{
          $t(
            'wording.month',
            { n: conditions.grace_period_months },
            conditions.grace_period_months
          )
        }}
      </p>
    </div>
    <template v-if="campaign">
      <div class="flex w-full justify-between items-center mb-2 lg:mb-4">
        <p class="text-sm text-grey-2 text-opacity-80 text-left">
          {{ $t('projects.details.conditions.upfront') }}
        </p>
        <p class="text-sm text-grey-2 text-opacity-80 font-bold">
          {{ project.funding_rate.fee_setup }}%
        </p>
      </div>
      <div class="flex w-full justify-between items-center mb-2 lg:mb-4">
        <p class="text-sm text-grey-2 text-opacity-80 text-left">
          {{ $t('projects.details.conditions.ongoing') }}
        </p>
        <p class="text-sm text-grey-2 text-opacity-80 font-bold">
          {{ project.funding_rate.fee_ongoing }}%
        </p>
      </div>
    </template>
    <div class="flex w-full justify-between items-center mb-2 lg:mb-4">
      <p class="text-sm text-grey-2 text-opacity-80 text-left">
        {{ $t('projects.details.conditions.risk') }}
      </p>
      <p class="text-sm text-grey-2 text-opacity-80 font-bold">
        {{ riskConversion(project.risk) }}
      </p>
    </div>
    <div
      @click="simulate"
      class="
        hidden
        lg:block
        orange-link
        text-xs
        cursor-pointer
        mt-12
        mb-8
        mx-auto
      "
    >
      {{ $t('projects.details.simulate_link') }}
    </div>
  </div>
</template>

<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
export default {
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    offer: {
      type: Object,
      default: () => {}
    },
    campaign: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { riskConversion } = useGlobalHelpers()
    const store = useStore()
    const router = useRouter()
    const { dollarFormatting, slugIt } = useGlobalHelpers()

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: true,
        content: document.getElementById('popup-content-project-details')
          .outerHTML
      }

      store.dispatch('setPopup', popup)
    }

    const conditions = computed(() => {
      return props.offer
        ? {
            ...props.offer.offer_conditions,
            duration_months: props.offer.remaining_payments_count,
            grace_period_months:
              props.offer.remaining_payments_count >
              props.project.funding_rate.duration_months -
                props.project.funding_rate.grace_period_months
                ? props.project.funding_rate.grace_period_months -
                  (props.project.funding_rate.duration_months -
                    props.offer.remaining_payments_count)
                : 0
          }
        : props.project.funding_rate
    })

    const simulate = () => {
      let simulationSlug = props.project.name
        ? slugIt(props.project.name) + '-' + props.project.id
        : props.project.id
      if (props.offer)
        router.push({
          path: '/simulation/' + simulationSlug,
          query: { offer: props.offer.id, amount: props.offer.offer_amount }
        })
      else router.push('/simulation/' + simulationSlug)
    }

    return {
      openPopup,
      riskConversion,
      dollarFormatting,
      conditions,
      simulate
    }
  }
}
</script>

<style lang="scss" scoped></style>
