<template>
  <div class="flex flex-col">
    <div class="flex items-center w-full justify-between py-4">
      <div class="flex items-center">
        <img
          :src="
            require('@/assets/svg/vouchers/' +
              type +
              '_' +
              voucher.state +
              '.svg')
          "
          alt="icon"
          class="mr-4"
        />
        <div class="text-left">
          <p
            class="text-grey-2 text-sm lg:text-base mb-1"
            :class="{
              'text-opacity-100': voucher.state === 'active',
              'text-opacity-80': voucher.state === 'used',
              'text-opacity-50': voucher.state === 'expired'
            }"
          >
            {{ voucher.code }}
          </p>
          <p
            class="text-grey-2 text-13"
            :class="{
              'text-opacity-80': voucher.state === 'active',
              'text-opacity-50':
                voucher.state === 'expired' || voucher.state === 'used'
            }"
          >
            {{ $t('vouchers.redeem.state.' + voucher.state) }}
          </p>
        </div>
      </div>
      <p
        class="text-grey-2 text-sm lg:text-base font-semibold"
        :class="{
          'text-opacity-100': voucher.state === 'active',
          'text-opacity-50':
            voucher.state === 'expired' || voucher.state === 'used',
          'line-through': voucher.state === 'expired'
        }"
      >
        ${{ dollarFormatting(voucher.value) }}
      </p>
    </div>
    <div v-if="!isLast" class="bg-grey-2 bg-opacity-10 w-full h-px" />
  </div>
</template>

<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { ref } from 'vue'
export default {
  props: {
    voucher: {
      type: Object,
      default: () => {}
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { dollarFormatting } = useGlobalHelpers()
    const type = ref(
      props.voucher.type === 'giftcard'
        ? 'giftcard'
        : props.voucher.type === 'referral'
        ? 'referral'
        : 'voucher'
    )

    return {
      type,
      dollarFormatting
    }
  }
}
</script>

<style lang="scss" scoped></style>
