<template>
  <div style="display: none">
    <slot></slot>
  </div>
</template>

<script>
import HeatmapOverlay from 'leaflet-heatmap'
import { getCurrentInstance } from 'vue'
import { onMounted } from 'vue'
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
      custom: false
    },
    maxOpacity: {
      type: Number,
      custom: true,
      default: 0.8
    },
    minOpacity: {
      type: Number,
      custom: true,
      default: 0.0
    },
    maxZoom: {
      type: Number,
      custom: true,
      default: 30
    },
    minZoom: {
      type: Number,
      custom: true,
      default: 0
    },
    radius: {
      type: Number,
      custom: true,
      default: 2
    },
    max: {
      type: Number,
      custom: true,
      default: 10
    },
    visible: {
      type: Boolean,
      custom: true,
      default: true
    },
    scaleRadius: {
      type: Boolean,
      custom: true,
      default: true
    },
    useLocalExtrema: {
      type: Boolean,
      custom: true,
      default: false
    },
    gradient: {
      type: Object,
      custom: true,
      default() {
        return {
          0.0: 'green',
          0.5: 'yellow',
          1.0: 'red'
        }
      }
    }
  },
  setup(props) {
    onMounted(() => {
      const instance = getCurrentInstance()
      var data = {
        max: props.max,
        data: props.data
      }
      var cfg = {
        ...props,

        // which field name in your data represents the latitude - default "lat"
        latField: 'lat',
        // which field name in your data represents the longitude - default "lng"
        lngField: 'lng',
        // which field name in your data represents the data value - default "value"
        valueField: 'count'
      }
      var heatmapLayer = new HeatmapOverlay(cfg)

      heatmapLayer.setData(data)

      heatmapLayer.addTo(instance.parent.proxy.leafletObject)
    })
  }
}
</script>
