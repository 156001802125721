<template>
  <div class="steps flex-grow flex flex-col justify-between">
    <div>
      <div class="steps__title--mobile ml-5 md:ml-0">
        {{ $tm('promoter.topup.amount_to_pay.title') }}
      </div>
      <div>
        <Table :data="tableData" />
        <div class="py-8" v-if="defaultInterest">
          <div class="flex border border-orange-3 rounded-2xl">
            <div class="pr-2 pl-4">
              <img
                :src="require('@/assets/svg/toast/warning.svg')"
                alt=""
                class="pt-6 w-8 lg:w-4"
              />
            </div>
            <div>
              <p
                class="text-grey-3 text-sm pt-6 mr-4 text-left font-bold"
                v-html="$t('promoter.topup.amount_to_pay.info.title')"
              ></p>
              <p
                class="
                  text-grey-4
                  lg:text-grey-3
                  text-sm
                  pt-2
                  pb-6
                  mr-4
                  text-left
                "
                v-html="$t('promoter.topup.amount_to_pay.info.content')"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <GpButton mobileWidth="w-full" @click="next" class="ml-auto">
        {{ $t('promoter.topup.amount_to_pay.button') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed, ref, inject, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GpButton from '@/components/general/GpButton.vue'
import Table from '@/components/general/Table.vue'

export default {
  components: {
    Table,
    GpButton
  },
  emits: ['next'],
  data() {
    return {
      openPopup: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const cookies = inject('vue-cookie')
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting } = useGlobalHelpers()
    const profile = ref(cookies.getCookie('profile'))
    const loading = ref(false)
    const tableData = ref({
      top: [],
      bottom: []
    })

    /** COMPUTED **/
    const loan = computed(() => {
      return store.state.promoters.loan
    })

    const loansInArrears = computed(() => {
      return store.getters.getLoansInArrears()
    })

    const latePayments = computed(() => {
      let sumInArrears = 0
      if (loansInArrears.value.length > 1) {
        loansInArrears.value.forEach((el) => {
          sumInArrears += el.loan_status.total_due_in_arrears
        })
        return sumInArrears
      } else {
        return loan.value.loan_status.total_due_in_arrears
      }
    })

    const defaultInterest = computed(() => {
      let sumDefaultInterest = 0
      if (loansInArrears.value.length > 1) {
        loansInArrears.value.forEach((el) => {
          sumDefaultInterest += el.loan_status.extra_payments_di
        })
        return sumDefaultInterest
      } else {
        return loan.value.loan_status.extra_payments_di
      }
    })

    const ddFailedFee = computed(() => {
      let sumDdFailedFee = 0
      if (loansInArrears.value.length > 1) {
        loansInArrears.value.forEach((el) => {
          sumDdFailedFee += el.loan_status.extra_payments_dd
        })
        return sumDdFailedFee
      } else {
        return loan.value.loan_status.extra_payments_dd
      }
    })

    const amortizationFailedFee = computed(() => {
      return store.state.promoters.loan.loan_status.extra_payments_amort
    })

    const currentWalletBalance = computed(() => {
      return loan.value.project_wallet.balance
    })

    const total = computed(() => {
      if (currentWalletBalance.value > latePayments.value) {
        return 0
      } else {
        return (
          latePayments.value +
          defaultInterest.value +
          ddFailedFee.value +
          amortizationFailedFee.value -
          currentWalletBalance.value
        )
      }
    })

    onBeforeMount(async () => {
      if (store.state.promoters.loans.length <= 0)
        await store.dispatch('fetchLoans')
      if (!loan.value && route.params.id) {
        const id = route.params.id.split('-').pop()
        if (id && !isNaN(id))
          if (route.params.id === loan.value.id)
            await store.dispatch('fetchLoan', route.params.id.split('-').pop())
      } else {
        tableData.value.top = [
          {
            title: i18n.t('promoter.topup.amount_to_pay.total'),
            value: '$' + dollarFormatting(total.value) 
          }
        ]
        tableData.value.bottom = [
          {
            title: i18n.t('promoter.topup.amount_to_pay.late_payments'),
            value: '$' + dollarFormatting(latePayments.value) 
          }
          // {
          //   title: i18n.t('settings.wallets.details.address.deposit'),
          //   value: ('to be developed')
          // },
        ]
        if (defaultInterest.value) {
          tableData.value.bottom.push({
            title: i18n.t('promoter.topup.amount_to_pay.default_interest'),
            value: '$' + dollarFormatting(defaultInterest.value) 
          })
        }
        if (ddFailedFee.value) {
          tableData.value.bottom.push({
            title: i18n.t('promoter.topup.amount_to_pay.dd_failed_fee'),
            value: '$' + dollarFormatting(ddFailedFee.value) 
          })
        }
        if (amortizationFailedFee.value) {
          tableData.value.bottom.push({
            title: i18n.t(
              'promoter.topup.amount_to_pay.amortization_failed_fee'
            ),
            value: '$' + dollarFormatting(amortizationFailedFee.value)
          })
        }
        if (currentWalletBalance.value) {
          tableData.value.bottom.push({
            title: i18n.t('promoter.topup.amount_to_pay.available_balance'),
            value: '-' + '$' +  dollarFormatting(currentWalletBalance.value) 
          })
        }
      }
    })

    async function next() {
      await store.dispatch('setAmount', Math.abs(total.value))
      emit('next')
    }

    return {
      next,
      dollarFormatting,
      profile,
      tableData,
      loading,
      defaultInterest,
      loansInArrears
    }
  }
}
</script>