import store from '@/store/index'

const handle_suitability = () =>
{
  return new Promise((resolve, reject) =>{


    const obj = {
      ...store.state.general.formData,
    }
    if(!obj.personal_income){
      const date = (new Date()).getFullYear()
      obj.three_year_income = {
        [date+1] : obj.income_1_years,
        [date+2] : obj.income_2_years,
        [date+3] : obj.income_3_years
      }
      obj.three_year_income_rp = {
        [date+1] : obj.rp_income_1_years,
        [date+2] : obj.rp_income_2_years,
        [date+3] : obj.rp_income_3_years
       }
      delete obj.income_1_years
      delete obj.income_2_years
      delete obj.income_3_years
      delete obj.rp_income_1_years
      delete obj.rp_income_2_years
      delete obj.rp_income_3_years
    }

    store.dispatch('setLoading', true)
    store.dispatch('updateSuitability', obj).then(async () => {
      store.dispatch('setForm', null)
      store.dispatch('clearFormData')
      store.dispatch('setLoading', false)
      store.dispatch('fetchProfile').then(() => {
        store.dispatch('setLoading', false)
        window.location.href = '/dashboard'
      })
      resolve('done')
      
    }).catch((e:any)=>{
      store.dispatch('setLoading', false)
      reject(e)
    })

  })
}

export default handle_suitability
