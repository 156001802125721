<template>
  <div>
    <!-- title -->
    <div class="text-center">
      <p class="font-bold text-lg text-grey-2 mb-9 mt-6">
        {{ title }} 
      </p>
    </div>
    <!-- content -->
    <div class="text-left" id="popup-auto-logout">
      <div class="text-sm text-grey-2 text-opacity-60 space-y-1" v-html="content"
        ></div>
    </div><br/><br/><br/>.
  </div>
</template>
<script>

export default {
  props: {
    title: String,
    content: String
  },
  setup(){  }
}
</script>
