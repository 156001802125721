<template>
  <div v-if="transaction" class="flex flex-grow flex-col justify-between">
    <div>
      <!-- Header (amount + icon) -->
      <div class="lg:hidden w-full flex justify-between mb-3">
        <p
          class="font-bold text-4xl text-left"
          :class="{
            'text-green-1': isGreen,
            'text-grey-2': !isGreen,
            'text-grey-2 text-opacity-50':
              transaction.state === 'failed' || transaction.state === 'pending',
            'line-through': transaction.state === 'failed'
          }"
        >
          <span v-if="isGreen">+</span>
          <span v-else>-</span>
          ${{ dollarFormatting(transaction.amount) }}
        </p>
        <img
          v-if="
            transaction.state === 'failed' || transaction.state === 'pending'
          "
          :src="
            require('@/assets/svg/transactions/' +
              failed_icon +
              '_details_failed.svg')
          "
          alt="transaction icon"
          class=""
        />
        <img
          v-else
          :src="require('@/assets/svg/transactions/' + icon + '_details.svg')"
          alt="transaction icon"
          class=""
        />
      </div>
      <!-- Header (description) -->
      <p class="lg:hidden text-left text-grey-2 font-bold text-lg mb-1">
        <!-- eslint-disable-next-line vue/no-parsing-error -->
        <span>{{ title }}</span
        ><span v-if="transaction.type === 'topup'" class="mx-1">{{ '•' }}</span
        ><span v-if="transaction.type === 'topup'">{{ description }}</span>
      </p>
      <!-- Header (date) -->
      <p
        class="lg:hidden text-left text-grey-2 font-medium text-opacity-80 mb-8"
      >
        {{ date }}
      </p>
      <!-- Voucher sender -->
      <div
        v-if="
          isVoucher &&
          transaction.state === 'pending' &&
          transaction.transfer_voucher
        "
        class="lg:hidden w-full bg-white rounded-2xl p-4 mb-4 text-left"
      >
        <p class="text-grey-2 text-13 text-opacity-60">
          {{ $t('transactions.gift_from') }}
        </p>
        <p class="text-grey-2 text-sm text-opacity-80 font-semibold">
          {{ transaction.transfer_voucher.profile.user.name }}
        </p>
      </div>
      <!-- Table -->
      <div
        class="
          rounded-2xl
          overflow-hidden
          mb-4
          lg:-mt-1 lg:rounded-t-none lg:mb-1 lg:px-20 lg:bg-white lg:py-5
        "
      >
        <div class="bg-white p-4 pb-5">
          <div class="flex items-center justify-between">
            <p
              class="text-sm text-grey-2 text-opacity-80 text-left"
              :class="transaction.state === 'pending' ? 'mb-0' : 'mb-4'"
            >
              {{ $t('transactions.details.status') }}
            </p>
            <p
              class="font-semibold text-sm text-right rounded"
              :class="{
                'px-2':
                  transaction.state === 'failed' ||
                  transaction.state === 'pending',
                'text-grey-2': transaction.state !== 'failed',
                'bg-red-primary text-white': transaction.state === 'failed',
                'bg-grey-2 bg-opacity-20 mb-0 lg:mb-4':
                  transaction.state === 'pending',
                'mb-4': transaction.state !== 'pending'
              }"
            >
              {{ $t('transactions.states.' + transaction.state) }}
            </p>
          </div>
          <div class="hidden lg:flex items-center justify-between">
            <p class="text-sm text-grey-2 text-opacity-80 text-left">
              {{ $t('transactions.details.time') }}
            </p>
            <p class="text-grey-2 font-semibold text-sm text-right">
              {{ time }}
            </p>
          </div>
        </div>
        <div class="bg-grey-ghost h-px lg:h-0.5 w-full" />
        <div class="bg-white bg-opacity-60 lg:bg-opacity-100 p-4 pb-5">
          <div class="flex items-center justify-between mb-4">
            <p
              class="text-sm text-grey-2 text-opacity-80 text-left"
              
            >
              {{ $t('transactions.details.amount') }}
            </p>
            <p
              class="text-grey-2 font-semibold text-sm text-right"
              
            >
              ${{ dollarFormatting(transaction.amount) }}
            </p>
          </div>
          <div
            v-if="transaction.fee_amount"
            class="flex items-center justify-between"
            :class="transaction.state === 'pending' ? 'mb-0' : 'mb-4'"
          >
            <p class="text-sm text-grey-2 text-opacity-80 text-left">
              {{ $t('withdraw.details.payout_after_payin') }}
            </p>
            <p  
              class="text-grey-2 font-semibold text-sm text-right"
            >
              ${{ dollarFormatting(transaction.fee_amount) }}
            </p>
          </div>
          <div
            v-if="transaction.state !== 'pending'"
            class="flex items-center justify-between"
          >
            <p class="text-sm text-grey-2 text-opacity-80 text-left">
              {{ $t('transactions.details.after_balance') }}
            </p>
            <p
              v-if="transaction.wallet_balance || transaction.wallet_balance === 0"
              class="text-grey-2 font-semibold text-sm text-right"
            >
              ${{ dollarFormatting(transaction.wallet_balance) }}
            </p>
            <p v-else class="text-grey-2 font-semibold text-sm text-right">-</p>
          </div>
        </div>
        <div class="hidden lg:block bg-grey-ghost h-0.5 w-full" />
        <!-- <div class="w-full hidden lg:flex items-center justify-between p-4">
          <p class="text-grey-2 text-opacity-80 text-sm font-semibold">
            {{ $t('transactions.details.statement') }}
          </p>
          <a @click="downloadTransactionPdf" class="flex items-center">
            <img
              src="@/assets/svg/arrows/down_orange_arrow.svg"
              alt="down"
              class="mr-2"
            />
            <p class="orange-link text-xs">
              {{ $t('transactions.details.download') }}
            </p>
          </a>
        </div> -->
        <div
          v-if="
            (transaction.type === 'topup' ||
              transaction.type === 'withdrawal') &&
            transaction.state === 'failed' &&
            wallet
          "
        >
          <div class="hidden lg:block bg-grey-ghost h-0.5 w-full mb-4" />
          <div class="hidden lg:flex w-full items-center justify-center">
            <p class="text-sm text-grey-3 font-semibold">
              {{
                $t('transactions.details.failed_text', { transaction: title })
              }}
              <a @click="retry" class="orange-link">
                {{ $t('transactions.details.failed_link') }}
              </a>
            </p>
          </div>
        </div>
      </div>
      <!-- Download mobile -->
      <!-- <div
        class="
          bg-white
          rounded-2xl
          w-full
          flex
          items-center
          justify-between
          p-4
          lg:hidden
        "
      >
        <p class="text-grey-2 text-opacity-80 text-sm font-semibold">
          {{ $t('transactions.details.statement') }}
        </p>
        <a @click="downloadTransactionPdf" class="flex items-center">
          <img
            src="@/assets/svg/arrows/down_orange_arrow.svg"
            alt="down"
            class="mr-2"
          />
          <p class="orange-link text-xs">
            {{ $t('transactions.details.download') }}
          </p>
        </a>
      </div> -->
    </div>
    <!-- Mobile retry payin and payout -->
    <div
      v-if="
        (transaction.type === 'topup' || transaction.type === 'withdrawal') &&
        transaction.state === 'failed' &&
        wallet
      "
      class="lg:hidden p-4 bg-green-7 rounded-2xl text-left"
    >
      <p class="text-13 text-grey-3">
        {{ $t('transactions.details.failed_question') }}
      </p>
      <p class="text-sm text-grey-3 font-semibold">
        {{ $t('transactions.details.failed_text', { transaction: title }) }}
        <a @click="retry" class="orange-link">
          {{ $t('transactions.details.failed_link') }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useTransactions from '@/mixins/useTransactions.js'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default {
  props: {
    transaction: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    // const transaction = computed(() => {
    //   return store.state.transactions.transaction
    // })
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting } = useGlobalHelpers()
    const { types, isGreen, title, icon, failed_icon, isVoucher, description } =
      useTransactions()

    onBeforeMount(() => {
      if (!props.transaction) router.push('/transactions')
    })

    const transactionTypes = ref(types())

    const date = computed(() => {
      let onlyDate = props.transaction.date.split(' ')[0].split('-')
      let weekday = i18n.t(
        `wording.weekdays.${new Date(props.transaction.date).getDay()}`
      )
      let day = onlyDate[2]
      let month = i18n.t(`wording.months.${onlyDate[1]}`).slice(0, 3)
      let year = onlyDate[0]
      let onlyTime = props.transaction.date.split(' ')[1].split(':')
      let hours = onlyTime[0]
      let minutes = onlyTime[1]
      return (
        weekday +
        ', ' +
        day +
        ' ' +
        month +
        ' ' +
        year +
        ', ' +
        hours +
        'h' +
        minutes
      )
    })

    const downloadTransactionPdf = () => {
      axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/transactions/' +
            parseInt(props.transaction.id) +
            '/pdf',
          {
            responseType: 'arraybuffer'
          }
        )
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = props.transaction.description + '.pdf'
          link.click()
        }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
        })
    }

    const time = computed(() => {
      let onlyTime = props.transaction.date.split(' ')[1].split(':')
      let hours = onlyTime[0]
      let minutes = onlyTime[1]
      let meridiem = i18n.t('wording.am')
      if (hours > 12) {
        hours = hours - 12
        meridiem = i18n.t('wording.pm')
      }
      return hours + ':' + minutes + ' ' + meridiem + ' ' + i18n.t('wording.gmt')
    })

    const wallet = computed(() => {
      let wallet = store.getters.getWalletById(props.transaction.vp_wallet_id)
      if (wallet) {
        return wallet.name ?? i18n.t('transactions.filters.main_wallet')
      } else return i18n.t('transactions.filters.no_wallet')
    })

    const transactionsDescription = computed(() => {
      return description(props.transaction)
    })

    const walletObject = computed(() => {
      return store.getters.getWalletById(props.transaction.vp_wallet_id)
    })

    const retry = () => {
      if (props.transaction.type === 'topup')
        router.push({
          name: 'topup',
          params: {
            propWallet: JSON.stringify(walletObject.value),
            propsAmount: props.transaction.amount
          }
        })
      else if (props.transaction.type === 'withdrawal')
        router.push({
          name: 'withdraw',
          params: {
            wallet: JSON.stringify(walletObject.value),
            propsAmount: props.transaction.amount
          }
        })
    }

    return {
      // transaction,
      dollarFormatting,
      transactionTypes,
      isGreen: isGreen(props.transaction),
      date,
      title: title(props.transaction),
      icon: icon(props.transaction),
      failed_icon: failed_icon(props.transaction),
      wallet,
      downloadTransactionPdf,
      time,
      isVoucher: isVoucher(props.transaction),
      description: transactionsDescription,
      retry
    }
  }
}
</script>
