<template>
  <!-- Profile -->

  <router-view v-if="route && route.name !== 'settings'" />

  <div class="page flex" v-else>
    <div v-if="profile" class="lg:pt-20 container flex-grow">
      <Back :to="'/dashboard'" :title="$t('settings.title')" />

      <!-- KYC popup -->
      <KycConditions />
      <!-- <BankAccountKyc /> -->

      <div
        class="
          w-full
          text-sm
          lg:text-base
          text-left
          font-bold
          mt-11
          mb-6
          text-grey-3
        "
      >
        {{ $t('settings.categories.profile') }}
      </div>

      <!-- Particular Profile -->
      <!-- Personal Details -->
      <div v-if="underlineProfileSettings && profile.type === 'particular' && !isBorrower">
        <router-link to="/settings/personal-details">
          <SettingsMenuElement
            icon="personal_details"
            :text="$t('settings.personal_details.title')"
            :subtitle="
              underlineProfileSettings.description
                ? $t(underlineProfileSettings.description)
                : ''
            "
            :subtitle-color="underlineProfileSettings.color"
          />
        </router-link>
        <!-- Fiscal Info -->
        <router-link to="/settings/fiscal-information">
          <SettingsMenuElement
            icon="fiscal_information"
            :text="$t('settings.fiscal_information.title')"
          />
        </router-link>
        <!-- Bank Accounts -->
        <router-link to="/settings/bank-accounts">
          <SettingsMenuElement
            icon="bank_accounts"
            :text="$t('settings.bank_accounts.title')"
          />
        </router-link>
        <!-- Suitability -->
        <router-link to="/settings/suitability">
          <SettingsMenuElement
            icon="suitability"
            :text="$t('settings.suitability.title')"
          />
        </router-link>
        <!-- Documents -->
        <router-link to="/settings/documents"
        >
          <SettingsMenuElement
            icon="documents"
            :text="$t('settings.documents.title')"
          />
        </router-link>
      </div>

      <!-- Organization Profile -->
      <div v-if="underlineProfileSettings && profile.type !== 'particular'">
        <!-- Organization Details -->
        <router-link to="/settings/organization-details">
          <SettingsMenuElement
            icon="organizations_details"
            :text="$t('settings.organization_details.title')"
            :subtitle="
              underlineProfileSettings.description
                ? $t(underlineProfileSettings.description)
                : ''
            "
            :subtitle-color="underlineProfileSettings.color"
          />
        </router-link>
        <!-- Legal representative details -->
        <router-link to="/settings/legal-representative-details">
          <SettingsMenuElement
            icon="legal_representative"
            :text="$t('settings.legal_representative.title')"
            :subtitle="
              underlineProfileSettings.description
                ? $t(underlineProfileSettings.description)
                : ''
            "
            :subtitle-color="underlineProfileSettings.color"
          />
        </router-link>
        <!-- Beneficiary information -->
        <!-- <router-link to="/settings/beneficiary-information">
          <SettingsMenuElement
            icon="personal_details"
            :text="$t('settings.beneficiary_information.title')"
            :subtitle="
              underlineProfileSettings.description
                ? $t(underlineProfileSettings.description)
                : ''
            "
            :subtitle-color="underlineProfileSettings.color"
          />
        </router-link> -->
        <!-- Bank Accounts -->
        <router-link to="/settings/bank-accounts">
          <SettingsMenuElement
            icon="bank_accounts"
            :text="$t('settings.bank_accounts.title_organizations')"
          />
        </router-link>
        <!-- Documents -->
        <router-link to="/settings/documents">
          <SettingsMenuElement
            icon="documents"
            :text="$t('settings.documents.title')"
          />
        </router-link>
        <!-- Suitability (hide 4 borrowers) -->
        <router-link to="/settings/suitability"
         v-if="!isBorrower" >
          <SettingsMenuElement
            icon="suitability"
            :text="$t('settings.suitability.title')"
          /> 
        </router-link>
      </div>

      <!-- Security -->
      <div
        class="
          w-full
          text-sm
          lg:text-base
          text-left
          font-bold
          mt-11
          mb-6
          text-grey-3
        "
      >
        {{ $t('settings.categories.security') }}
      </div>
      <!-- Change Pin -->
      <router-link to="/settings/change-pin" 
        class="hidden">
        <SettingsMenuElement
          icon="change_pin"
          :text="$t('settings.change_pin.title')"
        />
      </router-link>
      <!-- Change Password -->
      <router-link to="/settings/change-password">
        <SettingsMenuElement
          icon="change_password"
          :text="$t('settings.change_password.title')"
        />
      </router-link>
      <!-- 2FA -->

      <!-- Face id -->
      <SettingsMenuElement
        icon="face_id"
        :text="$t('settings.face_id_title')"
        class="hidden"
      />
      <!-- General -->
      <div
        class="
          w-full
          text-sm
          lg:text-base
          text-left
          font-bold
          mt-11
          mb-6
          text-grey-3
        "
      >
        {{ $t('settings.categories.general') }}
      </div>
      <!-- Email Notifications -->
      <router-link to="/settings/email-notifications">
        <SettingsMenuElement
          icon="email_notifications"
          :text="$t('settings.email_notifications.title')"
        />
      </router-link>
      <!-- Language -->
      <!-- <router-link to="/settings/language">
        <SettingsMenuElement
          icon="language"
          :text="$t('settings.language.title')"
        />
      </router-link> -->
      <!-- Support -->
      <div
        class="
          w-full
          text-sm
          lg:text-base
          text-left
          font-bold
          mt-11
          mb-6
          text-grey-3
        "
      >
        {{ $t('settings.categories.support') }}
      </div>
      
      <!-- loop that gets static docs -->
      <div>
        <a v-for="(value, key) in statics" v-bind:key="key"
          :href="value"
          target="_blank"
        >
          <SettingsMenuElement
            icon="documents"
            :text="$t('settings.documents.' + key)"
          />
        </a>
      </div>

      <a :href="`https://goparity.ca/${locale}/faqs`" target="_blank">
        <SettingsMenuElement icon="faqs" :text="$t('settings.faqs_title')" />
      </a>
      <!-- Logout -->
      <SettingsMenuElement
        class="lg:hidden"
        @click="logout"
        icon="logout"
        :text="$t('settings.logout_title')"
      />
    </div>
  </div>
</template>

<script>
import { inject, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import Back from '@/components/general/Back.vue'
import SettingsMenuElement from '@/components/settings/MenuElement.vue'
import KycConditions from '@/components/general/KycConditions.vue'
// import BankAccountKyc from '@/components/general/BankAccountKyc.vue'
import { event } from "vue-gtag";

export default {
  components: {
    KycConditions,
    SettingsMenuElement,
    Back,
    // BankAccountKyc
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const cookies = inject('vue-cookie')
    const { locale } = useI18n({ useScope: 'global' })
    const statics = ref({})
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower' ? true : false
    })

    const kycsConditions = computed(() => {
      return store.state.kycs.conditions
    })

    const underlineProfileSettings = computed(() => {
      return store.state.kycs ? store.state.kycs.settingsSubtitles : null
    })

    onMounted(async () => {
      getDocuments()
      if (
        !kycsConditions.value ||
        (kycsConditions.value && !kycsConditions.value.length)
      ) {
        await store.getters.getKycsConditions()
      }
    })

    // get public docs
    const getDocuments = () => {
      store.dispatch('setLoading', true)
      axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/documents/public' )
        .then((response) => {
          store.dispatch('setLoading', false)
          Object.keys(response.data).forEach(function(key){
            statics.value[key] = response.data[key]
          });    
        }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
      })
    }
    const logout = () => {
      store.dispatch('logout', cookies.getCookie('token')).then(() => {
        router.push({name: 'login'})
        store.dispatch('resetToasts')
        event('logout', { method: 'settings' })

      })
    }

    return {
      logout,
      underlineProfileSettings,
      profile,
      isBorrower,
      getDocuments,
      statics,
      route,
      locale,
      kycsConditions
    }
  }
}
</script>

<style lang="postcss" scoped></style>
