<template>
  <div
    :class="{
      'w-full h-screen flex items-center justify-center': page
    }"
  >
    <div class="mx-auto">
      <img
        class="animate-spin"
        :class="{ 'w-32': page, 'w-12': !page, 'mx-auto': center }"
        src="@/assets/svg/loading.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  }
}
</script>
