<template>
  <div class="flex-grow flex flex-col justify-between steps">
    <div v-if="!loading">
      <div class="steps__title--mobile ml-5 md:ml-0">
        {{ $tm('topup.method.title') }}
      </div>
      <div class="steps__subtitle">
        {{ $tm('topup.method.subtitle') }}
      </div>

      <div class="gap-x-2 grid-cols-2 mt-8">
        <div
          class="steps__investor mb-6"
          @click="next(method.value)"
          v-for="method in $tm('topup.method.methods')"
          v-bind:key="method.value"
        >
          <div 
            class="flex items-start md:items-center"
          >
            <div
              class="steps__investor__icon steps__investor__icon--0 bg-gradient-green hidden md:block"
            >
              <img :src="'/img/topup/' + method.icon + '.svg'" alt="" />
            </div>
            <div class="block md:hidden mr-2 mt-1">
              <img :src="'/img/topup/' + method.icon + '_mobile.svg'" alt="" />
            </div>
            <div>
              <div class="flex">
                <div
                  class="font-bold md:mb-0 mb-1 text-grey-2 md:opacity-80 md:text-base text-sm"
                  :id="'gp-button-top-up-method-' + method.icon + '-2'"
                >
                  {{ method.name }}
                </div>
              </div>
              <div class="text-13 md:text-sm opacity-50">
                {{ method.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
        <GpButton
        mobileWidth="w-full"
        type="submit"
        @click="addAccount"
        class="ml-auto"
        color="white"
      >
      {{ $t(account ? 'topup.confirmation.bankwire.connect_other_bank' : 'topup.confirmation.bankwire.connect_bank') }}
    </GpButton>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    GpButton
  },
  props: {
    project: String
  },
  emits: ['next'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })

    const loading = computed(() => {
      return store.state.general.loading
    })
    const profile = computed(() => {
      return store.getters.getProfile()
    })

    // Get bankaccount
    const account = computed(() => {
      return store.state.bankAccounts?.account?.is_active ? store.state.bankAccounts.account : null
    })

    onMounted(async () => {
      await store.dispatch('setLoading', true)
      await store.dispatch('getBankAccount')
      await store.dispatch('setLoading', false)
    })

    async function next(value) {
      if(account.value){
        await store.dispatch('setBankAccount', account.value)
      }
      else if(value == 'bankwire'){
        const popup = {
            title: i18n.t('topup.method.bank_popup.title'),
            content: i18n.t('topup.method.bank_popup.content'),
            align: 'center',
            side: false,
            iconBg: 'bg-grey-8',
            icon: '/img/popup/exclamation_orange.svg',
            primaryButton: i18n.t('topup.method.bank_popup.btn'),
            primaryLink: '/settings/bank-accounts'
          }
          await store.dispatch('setPopup', popup)
          return
      }
      else{
          const popup = {
            title: i18n.t('topup.method.permissions_popup.title'),
            content: i18n.t('topup.method.permissions_popup.content'),
            align: 'center',
            side: false,
            iconBg: 'bg-grey-8',
            icon: '/img/popup/exclamation_orange.svg',
            primaryButton: i18n.t('topup.method.permissions_popup.btn'),
          }
          await store.dispatch('setPopup', popup)
      }
      await store.dispatch('setMethod', value)
      emit('next', value)
    }

    const addAccount = () => {
      router.push('/settings/bank-accounts')
    }

    return {
      next,
      addAccount,
      profile,
      account,
      env: process.env.VUE_APP_ENV,
      loading
    }
  }
}
</script>