<template>
  <div
    class="absolute z-0"
    :class="!mobile ? 'hidden lg:flex' : ''"
    :style="customBallStyle"
  >
    <img id="ball" src="@/assets/svg/green-ball.svg" alt="green ball" />
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  props: {
    start: {
      type: String,
      default: 'top'
    },
    offset: {
      type: String,
      default: '100px'
    },
    side: {
      type: String,
      default: 'left'
    },
    mobile: {
      type: Boolean,
      default: true
    },
    cutout: {
      type: String,
      default: '25'
    }
  },
  setup(props) {
    const customBallStyle = computed(() => {
      const style = {}
      style[props.start] = props.offset
      style[props.side] = -355 * (parseInt(props.cutout) / 100) + 'px'
      return style
    })
    return { customBallStyle }
  }
}
</script>
