<template>
  <div >
    <div class="steps text-left pt-4 md:pt-0 h-full">
      <div class="md:flex md:flex-row-reverse">
        <div class="flex md:hidden pb-4 mb-6 border-b border-grey-6">
          <img
            src="@/assets/svg/arrows/left_grey_arrow.svg"
            alt=""
            class="mr-5 block md:hidden mt-1"
            @click="editGiftcard(0)"
          />
          <p class="text-xl font-bold text-grey-2 ml-0">{{$t('giftcards.form.title_finalize') }}</p>
        </div>
        <p class="text-base text-grey-3 ml-0 mb-6 block md:hidden">{{$t('giftcards.form.subtitle_finalize')}}</p>

        <div class="w-full md:w-1/2 md:mt-20 ">
          <div  v-for="(card, index) in giftcards" :key="card.name + '-card'" :class="{'mt-12': index > 0}" style="max-width: 300px" class="mx-auto" >
            <Card :amount="card.amount" :name
              ="card.name" :message="card.message" :template="card.template" class="w-full"/>
            <div v-if="giftcards.length > 1" class="flex justify-between">
              <div class="flex text-orange-1 text-sm font-semibold mt-2 cursor-pointer" @click="editGiftcard(index)">
                <img src="@/assets/svg/strategies/edit_pencil.svg" alt="Edit SVG" class="mr-2">
                {{$t('giftcards.edit')}}
              </div>
              <div class="flex text-orange-1 text-sm font-semibold mt-2 cursor-pointer" @click="deleteGiftcard(index)">
                <img src="@/assets/svg/garbage-orange.svg" alt="Edit SVG" class="mr-2">
                {{$t('wording.delete')}}
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex mt-16 gap-4 " style="margin-bottom: calc(var(--sab) + 115px)">
          <gp-button
            color="white"
            class="mb-4 md:mb-0"
            @click="addGiftcard"
          >
            {{ $t('giftcards.secondary_button') }}
          </gp-button>
          <gp-button
            @click="proceed"
            class="mx-auto"
          >
            {{ $t('giftcards.primary_button') }}
          </gp-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import Card from "@/components/giftcards/Card";
import GpButton from "@/components/general/GpButton";
import { useStore } from "vuex";

export default {
  components: {
    Card,
    GpButton,
  },
  emits:['next' ,'add'],
  setup(props, { emit }) {
    const selected = ref(0)
    const edit = ref(false)
    const giftcards = computed(()=>{
      return store.state.general.giftcards?.data
    })
    const store = useStore()

    function editGiftcard(index){
      store.dispatch('setGiftcards', {action: 'edit@'+index })
      emit('previous')
    }

    function deleteGiftcard(index){
      let dup = JSON.parse(JSON.stringify(store.state.general.giftcards?.data))
      dup.splice(index, 1)
      store.dispatch('setGiftcards', {data: dup })
    }

    function addGiftcard(){
      store.dispatch('setGiftcards', {action: 'add' })
      emit('previous')
    }

    function proceed(){
      emit('next');
    }

    return {
      giftcards,
      editGiftcard,
      proceed,
      deleteGiftcard,
      edit,
      addGiftcard,
      selected
    }
  }
}

</script>