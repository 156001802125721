<template>
  <div class="text-left">
    <infinite-scroll
      :no-result="has_next_page === null"
      @infinite-scroll="infiniteHandler"
    >
      <table class="w-full">
        <thead>
          <tr class="text-xs text-grey-4">
            <th class="font-normal">
              {{ $t('promoter.loan.list.tabs.payment_n') }}
            </th>
            <th
              v-if="!isMobile"
              class="font-normal"
            >
              {{ $t('promoter.loan.list.tabs.date') }}
            </th>
            <th class="font-normal text-right">
              {{ $t('promoter.loan.list.tabs.amount') }}
            </th>
            <th class="font-normal text-right">
              {{ $t('promoter.loan.list.tabs.outstanding') }}
            </th>
          </tr>
        </thead>
        <!-- eslint-disable vue/no-v-for-template-key -->
        <tbody>
          <template v-if="!payments">
            <tr
              v-for="i in 5"
              :key="i"
              class="border-b"
            >
              <td colspan="4">
                <div
                  class="
                    project-card__header--skeleton
                    w-full
                    h-12
                    my-5
                    rounded-2xl
                  "
                />
              </td>
            </tr>
          </template>
          <template
            v-for="(payment, index) in payments"
            :key="'payment-' + payment.id"
          >
            <tr
              class="padding-row text-sm text-grey-3 relative no-highlight"
              :class="{
                'font-bold': nextPayment === payment.id,
                ' cursor-pointer':
                  expanded !== index && payment.id < nextPayment,
                'space-bottom':
                  isArrears(payment) &&
                  payments.length > index &&
                  payments[index + 1] &&
                  isArrears(payments[index + 1]) &&
                  page,
                'row-white':
                  (nextPayment === payment.id || isArrears(payment)) &&
                  page &&
                  !isMobile
              }"
              @click="expanded = index"
            >
              <td
                :class="
                  nextPayment === payment.id
                    ? 'text-grey-2'
                    : payment.id < nextPayment
                      ? 'text-grey-4'
                      : isMobile
                        ? 'text-grey-3 ml-5'
                        : 'text-grey-3'
                "
              >
                <div class="flex items-start">
                  <div
                    v-if="nextPayment === payment.id && !isArrears(payment)"
                    class="
                      w-2
                      h-2
                      bg-grey-2
                      pulse-black
                      rounded-full
                      mr-3
                      z-40
                      mt-1
                    "
                  />
                  <img
                    v-if="isArrears(payment)"
                    src="@/assets/svg/warning.svg"
                    alt=""
                    class="mt-0 mr-3"
                  >
                  <img
                    v-if="payment.id < nextPayment"
                    src="@/assets/svg/success_arrow.svg"
                    alt=""
                    class="mx-3"
                  >
                  <div>
                    <div>Payment #{{ payment.payment_n }}</div>
                    <div
                      v-if="isMobile"
                      class="text-xs text-grey-4 font-normal"
                    >
                      {{
                        dateFormatting(
                          new Date(payment.schedule_date),
                          '/',
                          true
                        )
                      }}
                    </div>
                  </div>
                </div>
              </td>
              <td
                v-if="!isMobile"
                :class="{ 'text-grey-2': nextPayment === payment.id }"
              >
                {{ dateFormatting(new Date(payment.schedule_date), '/', true) }}
              </td>
              <td
                :class="
                  nextPayment === payment.id
                    ? 'text-grey-2'
                    : payment.id < nextPayment
                      ? 'text-green-1'
                      : 'text-grey-1'
                "
                class="text-right"
              >
                ${{
                  dollarFormatting (
                    payment.payment_investor + payment.fee_ongoing
                  )
                }}
              </td>
              <td
                :class="{
                  'text-grey-2': nextPayment === payment.id,
                  'font-semibold': payment.id < nextPayment
                }"
                class="text-right"
              >
                ${{ dollarFormatting (payment.capital_outstanding) }}
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="accordion md:flex flex-wrap"
                  :class="{
                    'accordion--expanded pb-4 mt-2':
                      expanded === index && payment.id < nextPayment
                  }"
                >
                  <div
                    v-for="download in downloads"
                    :key="download"
                    class="
                      flex
                      bg-white
                      p-4
                      text-sm
                      font-bold
                      rounded-2xl
                      md:w-64
                      w-full
                      items-center
                      mr-8
                      text-grey-2
                      mb-4
                      justify-between
                    "
                    :class="
                      download === 'fee' && !payment.fee_invoice_link
                        ? 'opacity-50'
                        : ''
                    "
                  >
                    <div>
                      {{ $t('promoter.loan.payment.downloads.' + download) }}
                    </div>
                    <a
                      v-if="download === 'fee'"
                      :href="payment.fee_invoice_link"
                      class="flex orange-link"
                      download
                    >
                      <p>{{ $t('wording.download') }}</p>
                      <img
                        src="@/assets/svg/arrows/down_orange_arrow.svg"
                        alt="down"
                        class="mr-2 w-4"
                      >
                    </a>
                    <div
                      v-else
                      class="flex orange-link"
                      @click="get(download)"
                    >
                      <p>{{ $t('wording.download') }}</p>
                      <img
                        src="@/assets/svg/arrows/down_orange_arrow.svg"
                        alt="down"
                        class="mr-2 w-4"
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              :class="{
                'border-b':
                  !isMobile &&
                  (!isArrears(payment) || !page) &&
                  index < payments.length - 1 &&
                  (!page ||
                    (page &&
                      nextPayment - payment.id !== 1 &&
                      nextPayment - payment.id !== 0))
              }"
            />
          </template>
        </tbody>
        <!-- eslint-enable -->
      </table>
    </infinite-scroll>
  </div>
</template>

<script>
import InfiniteScroll from 'infinite-loading-vue3'
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
import { computed, ref, onMounted } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'

export default {
  components: { InfiniteScroll },
  props: {
    payments: {
      type: Array,
      default: null
    },
    page: {
      type: Boolean,
      default: false
    },
    has_next_page: String
  },
  emits: ['next'],
  setup(props, { emit }) {
    const { dollarFormatting , dateFormatting } = useGlobalHelpers()
    const innerWidth = ref(window.innerWidth)
    const expanded = ref(-1)
    const route = useRoute()
    const downloads = ref(['fee', 'payment_plan', 'amortization_proof_pdf'])

    const nextPayment = computed(() => {
      if (props.payments) {
        const el = props.payments.find((element) => {
          return !element.state
        })
        return el ? el.id : Infinity
      }
      return null
    })

    const isMobile = computed(() => {
      return innerWidth.value < 768
    })

    function get(download) {
      axios({
        url:
          process.env.VUE_APP_SERVER_BASE_URL +
          'api/v1/promoters/' +
          props.payments[expanded.value].id +
          '/files',
        method: 'GET',
        params: { file_type: download, project_id: route.params.id },
        responseType: 'arraybuffer' // important
      }).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          response.headers['content-disposition'].split('filename=')[1]
        )
        document.body.appendChild(link)
        link.click()
      })
    }

    const isArrears = (payment) => {
      return new Date(payment.schedule_date) < new Date() && !payment.state
    }

    onMounted(() => {
      window.addEventListener('resize', () => {
        innerWidth.value = window.innerWidth
      })
    })

    async function infiniteHandler() {
      emit('next')
    }

    return {
      dollarFormatting ,
      dateFormatting,
      expanded,
      get,
      nextPayment,
      infiniteHandler,
      isArrears,
      isMobile,
      downloads
    }
  }
}
</script>
<style>
.padding-row > td {
  padding: 1.25rem 0rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.space-bottom.row-white > td:first-of-type::before {
  height: calc(100% + 8px);
}

.space-bottom.row-white > td:last-of-type::after {
  height: calc(100% + 8px);
}

.row-white > td {
  background: white;
}

.row-white > td:first-of-type::before {
  content: '';
  width: 1.5rem;
  position: absolute;
  background: white;
  height: 100%;
  left: -1.5rem;
  z-index: 0;
  top: 0;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.row-white > td:last-of-type::after {
  content: '';
  width: 1.5rem;
  position: absolute;
  background: white;
  height: 100%;
  right: -1.5rem;
  top: 0;
  z-index: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

@media (min-width: 768px) {
  .space-bottom {
    border-bottom: solid 17px transparent;
  }
}

@media (max-width: 1023px) {
  .padding-row > td {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.75rem 0rem;
  }
}
.pulse-black {
  animation: pulse-black-animation 1s infinite;
}

.white-bef-aft::before {
  content: '';
  width: 1.5rem;
  position: absolute;
  background: white;
  height: 100%;
  left: -1.5rem;
  z-index: 0;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.white-bef-aft::after {
  content: '';
  width: 1.5rem;
  position: absolute;
  background: white;
  height: 100%;
  right: -1.5rem;
  z-index: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.accordion {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
}

.accordion--expanded {
  margin-top: -20px;
  max-height: 200px;
}

@keyframes pulse-black-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(79, 89, 99, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(79, 89, 99, 0);
  }
}
</style>
