<template>
  <div class="page-no-nav flex relative">
    <div class="container flex flex-col flex-grow relative  lg:pt-20">
      <Back :to="'/settings'" :title="$t('vouchers.redeem.title')" />
      <div class="w-full text-left text-grey-2 my-6 text-sm lg:text-base">
        {{ $t('vouchers.redeem.subtitle') }}
        <span class="orange-link" @click="openPopup">
          {{ $t('wording.here') }}.
        </span>
      </div>
      <!-- Popup -->
      <div class="hidden">
        <div class="text-left" id="popup-content-vouchers">
          <p class="font-bold text-grey-2 mb-6">
            {{ $t('vouchers.redeem.popup.title') }}
          </p>
          <div class="flex items-start">
            <img
              src="@/assets/svg/vouchers/giftcard_active.svg"
              alt="giftcard"
              class="mr-4 mt-1"
            />
            <div>
              <p class="font-medium text-sm text-grey-2">
                {{ $t('vouchers.redeem.popup.giftcards_title') }}
              </p>
              <p
                class="text-13 text-grey-2 text-opacity-80 mb-6"
                v-html="$t('vouchers.redeem.popup.giftcards_text')"
              />
            </div>
          </div>
          <div class="flex items-start">
            <img
              src="@/assets/svg/vouchers/referral_active.svg"
              alt="giftcard"
              class="mr-4 mt-1"
            />
            <div>
              <p class="font-medium text-sm text-grey-2">
                {{ $t('vouchers.redeem.popup.referral_title') }}
              </p>
              <p class="text-13 text-grey-2 text-opacity-80 mb-6">
                {{ $t('vouchers.redeem.popup.referral_text') }}
              </p>
            </div>
          </div>
          <div class="flex items-start">
            <img
              src="@/assets/svg/vouchers/voucher_active.svg"
              alt="giftcard"
              class="mr-4 mt-1"
            />
            <div>
              <p class="font-medium text-sm text-grey-2">
                {{ $t('vouchers.redeem.popup.vouchers_title') }}
              </p>
              <p class="text-13 text-grey-2 text-opacity-80 mb-6">
                {{ $t('vouchers.redeem.popup.vouchers_text') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:relative ">
        <GeneralInput
          class="mb-8"
          v-model:data="redeemCode"
          type="text"
          name="redeemCode"
          :label="$t('vouchers.redeem.label')"
          :required="true"
        />
        <GpButton
          @click="redeem"
          :disabled="isFilled"
          desktopWidth="w-40"
          mobileWidth="w-full"
          class="lg:h-16 lg:absolute right-0 top-0"
        >
          {{ $t('vouchers.redeem.add_btn') }}
        </GpButton>
      </div>
      <p
        v-if="vouchers.length"
        class="hidden lg:block text-grey-1 font-semibold mb-5 mt-14 text-left"
      >
        {{ $t('vouchers.redeem.used_title') }}
      </p>
      <div
        v-if="vouchers.length"
        class="
          absolute
          lg:relative
          z-40
          w-full
          bg-white
          rounded-t-2xl
          lg:rounded-2xl
          bottom-5
          left-0
          transition-heigth ease-in-out duration-700
        "
        :class="expanded ? 'h-full' : 'h-1/10 lg:h-auto'"
      >
        <div class="flex flex-col w-full h-full px-6 lg:py-2">
          <div class="w-full py-2 lg:hidden">
            <img
              src="@/assets/svg/arrows/left_grey_arrow.svg"
              alt="left arrow"
              class="transform w-4 h-4 mx-auto"
              :class="expanded ? '-rotate-90' : 'rotate-90'"
              @click="() => (expanded = !expanded)"
            />
          </div>
          <!-- For draggable -->
          <div
            v-if="false"
            class="h-1 w-8 my-4 bg-grey-2 bg-opacity-20 mx-auto lg:hidden"
            @click="() => (expanded = !expanded)"
          />
          <p
            class="
              text-grey-2 text-opacity-80
              w-full
              text-left
              font-semibold
              lg:hidden

            "
          >
            {{ $t('vouchers.redeem.used_title') }}
          </p>
          <VoucherListElement
            v-for="(voucher, index) of vouchers"
            :key="index"
            :class="expanded ? 'block' : 'hidden'"
            :voucher="voucher"
            :isLast="index === vouchers.length - 1"
            class="lg:hidden"
          />
          <VoucherListElement
            v-for="(voucher, index) of vouchersDesktop"
            :key="index"
            :voucher="voucher"
            :isLast="index === vouchersDesktop.length - 1"
            class="hidden lg:flex"
          />
        </div>
      </div>
    </div>
    <CodeRedeemed v-if="redeemed" :giftcard="false" @close="reload" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import VoucherListElement from '@/components/vouchers/VoucherListElement.vue'
import CodeRedeemed from '@/components/vouchers/CodeRedeemed.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
  components: {
    Back,
    GeneralInput,
    VoucherListElement,
    CodeRedeemed,
    GpButton
  },
  async setup() {
    const redeemCode = ref('')
    const { locale } = useI18n({ useScope: 'global' })
    const store = useStore()
    const expanded = ref(false)
    const redeemed = ref(false)
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()

    onMounted(() => {
      if(route.params.code) {
        redeemCode.value = route.params.code
        redeem()
      }
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    await store.dispatch('fetchVouchers')

    const vouchers = computed(() => {
      if (expanded.value) return store.state.vouchers.vouchers
      else return store.state.vouchers.vouchers.slice(0, 3)
    })

    const vouchersDesktop = computed(() => {
      return store.state.vouchers.vouchers
    })

    const isFilled = computed(() => {
      return redeemCode.value === ''
    })

    const redeem = () => {
      store.dispatch('setLoading', true)
      store.dispatch('redeemVoucher', redeemCode.value).then((data) => {
        // if(data.campaign_id === 299 ) {
        //   store.dispatch('warnCustomerSupportSlack', "[Profile ID " + data.profile_id + "] just added WEBSUMMIT [Voucher ID "+ data.id + "]")
        // }

        if(data?.campaign?.type === 'giftcard'){
          let popup = null

          if (profile.value.kyc_level === 'light') {
            popup = {
              title: i18n.t('giftcards.redeem_light.title'),
              align: 'center',
              side: false,
              iconBg: 'bg-green-5',
              icon: '/img/done_step.svg',
              content: i18n.t(
                'giftcards.redeem_light.content'
              ),
              primaryButton: i18n.t(
                'giftcards.redeem_light.btn'
              ),
              secondaryButton: i18n.t(
                'giftcards.redeem_light.btn_sec'
              ),
              primaryLink: '/dashboard/kyc',
            }
          }
          else{
            popup = {
              title: i18n.t('giftcards.redeem.title'),
              align: 'center',
              side: false,
              iconBg: 'bg-green-5',
              icon: '/img/done_step.svg',
              content: i18n.t(
                'giftcards.redeem.content'
              ),
              primaryButton: i18n.t(
                'giftcards.redeem.btn'
              )
            }
          }

          store.dispatch('setPopup', popup)
        }
        else
          redeemed.value = true
        store.dispatch('setLoading', false)
      }).catch((e)=>{
        store.dispatch('setError', e)
        store.dispatch('setLoading', false)
      })

    }

    const reload = () => {
      redeemed.value = false
      router.go()
    }

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: true,
        content: document.getElementById('popup-content-vouchers').outerHTML
      }

      store.dispatch('setPopup', popup)
    }

    return {
      redeemCode,
      locale,
      isFilled,
      vouchers,
      expanded,
      redeem,
      redeemed,
      vouchersDesktop,
      reload,
      openPopup
    }
  }
}
</script>

<style lang="css" scoped>
@media only screen and (max-width: 600px) {
  .page {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }
}
</style>
