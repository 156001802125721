<template>
  <div class="page">
    <div
      class="
        w-2/4
        bg-white
        absolute
        left-1/2
        -translate-x-1/2
        top-1/2
        -translate-y-1/2
        transform
        rounded-3xl
        p-14
      "
    >
      <div class="p-6 bg-green-5 w-max rounded-3xl mx-auto mb-6">
        <img src="@/assets/svg/success_arrow.svg" alt="" />
      </div>
      <h1 class="text-lg font-medium text-grey-2 mb-6">
        {{ $t('topup.success.title') }}
      </h1>
      <p class="text-grey-3 text-sm mb-16">
        {{ $t('topup.success.description') }}
      </p>
      <router-link to="/dashboard">
        <GpButton class="mx-auto" desktopWidth="w-1/2" mobileWidth="w-1/2">
          {{ $t('topup.success.button') }}
        </GpButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: { GpButton },
  name: 'home',
  setup() {}
}
</script>
