import i18n from '../i18n'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Signup from '../views/onboarding/Signup.vue'
import SignupCompany from '../views/onboarding/SignupCompany.vue'
import Login from '../views/onboarding/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Onboarding from '../views/onboarding/Onboarding.vue'
import PasswordForgot from '../views/onboarding/PasswordForgot.vue'
import PasswordReset from '../views/onboarding/PasswordReset.vue'
import KycSubmit from '../views/kyc/_id.vue'
import Settings from '../views/dashboard/Settings.vue'
import AccountDetails from '../views/dashboard/AccountDetails.vue'
import DirectDebitDetails from '../views/dashboard/DirectDebitDetails.vue'
import LoanDetails from '../views/dashboard/loans/LoanDetails.vue'
import Loan from '../views/dashboard/loans/Loan.vue'
import Payments from '../views/dashboard/loans/Payments.vue'
import PersonalDetails from '../views/dashboard/settings/PersonalDetails.vue'
import FiscalInformation from '../views/dashboard/settings/FiscalInformation.vue'
import BankAccounts from '../views/dashboard/settings/BankAccounts.vue'
import BankAccountDetails from '../views/dashboard/settings/BankAccountDetails.vue'
import OrganizationDetails from '../views/dashboard/settings/OrganizationDetails.vue'
import LegalRepresentativeDetails from '../views/dashboard/settings/LegalRepresentativeDetails.vue'
import BeneficiaryInformation from '../views/dashboard/settings/BeneficiaryInformation.vue'
import BeneficiaryDetails from '../views/dashboard/settings/BeneficiaryDetails.vue'
import AddBeneficiary from '../views/dashboard/settings/AddBeneficiary.vue'
import Wallets from '../views/dashboard/settings/Wallets.vue'
import Loans from '../views/dashboard/Loans.vue'
import WalletDetails from '../views/dashboard/settings/WalletDetails.vue'
import Documents from '../views/dashboard/settings/Documents.vue'
import Profile from '../views/Profile.vue'
import AddPhoneNumber from '../views/dashboard/settings/AddPhoneNumber.vue'
import VerificationCodePage from '../views/dashboard/settings/VerificationCodePage.vue'
import Invite from '../views/dashboard/Invite.vue'
import Impact from '../views/Impact.vue'
import PathNotFound from '../views/404.vue'
import Unauthorized from '../views/Unauthorized.vue'
import InviteAll from '../views/dashboard/invite/All.vue'
import ChangePin from '../views/dashboard/settings/ChangePin.vue'
import ChangePassword from '../views/dashboard/settings/ChangePassword.vue'
import EmailNotifications from '../views/dashboard/settings/EmailNotifications.vue'
import Language from '../views/dashboard/settings/Language.vue'
import SettingsSuitability from '../views/dashboard/settings/Suitability.vue'
import Redeem from '../views/dashboard/vouchers/Redeem.vue'
import auth from '@/middleware/auth'
import validLocale from '@/middleware/validLocale'
import activate from '@/middleware/activate'
import Giftcard from '../views/Giftcard.vue'
import isLogged from '@/middleware/isLogged'
//import hasKyc from '@/middleware/hasKyc'
import profileType from '@/middleware/profileType'
import isPromoter from '@/middleware/isPromoter'
import isActivated from '@/middleware/isActivated'
import AddStrategies from '@/views/dashboard/strategies/AddStrategies.vue'
import Topup from '@/views/dashboard/strategies/Topup.vue'
import WhySaving from '@/views/dashboard/strategies/WhySaving.vue'
import OtherBeneficiary from '@/views/dashboard/strategies/OtherBeneficiary.vue'
import DirectDebit from '@/views/dashboard/strategies/DirectDebit.vue'
import Investment from '@/views/dashboard/strategies/Investment.vue'
import MaxAmount from '@/views/dashboard/strategies/MaxAmount.vue'
import Confirmation from '@/views/dashboard/strategies/Confirmation.vue'
import EditStrategy from '@/views/dashboard/strategies/EditStrategy.vue'
import EditName from '@/views/dashboard/strategies/EditName.vue'
import EditOwner from '@/views/dashboard/strategies/EditOwner.vue'
import Transactions from '@/views/dashboard/Transactions.vue'
import Projects from '@/views/Projects.vue'
import TopUp from '@/views/TopUp.vue'
import TopUpSuccess from '@/views/TopUpSuccess.vue'
import Withdraw from '@/views/Withdraw.vue'
import TopUpPromoter from '@/views/promoter/TopUp.vue'
import Campaigns from '@/views/promoter/Campaigns.vue'
import ProjectDetails from '@/views/projects/ProjectDetails.vue'
import ProjectUpdates from '@/views/projects/ProjectUpdates.vue'
import UploadProof from '@/views/invest/UploadProof.vue'
import TransactionDetails from '@/views/dashboard/transactions/TransactionDetails.vue'
import ErrorPage from '@/views/ErrorPage.vue'
import Maintenance from '@/views/Maintenance.vue'
import Simulation from '@/views/Simulation.vue'
import InvestWallet from '@/views/invest/Wallet.vue'
import InvestAmount from '@/views/invest/Amount.vue'
import InvestConfirmation from '@/views/invest/Confirmation.vue'
import ReservationDetails from '@/views/invest/ReservationDetails.vue'
import KycFlow from '@/views/dashboard/KycFlow.vue'
import Contracts from '@/views/contracts/Contracts.vue'
import ContractDetails from '@/views/contracts/ContractDetails.vue'
import FullPaymentPlan from '@/views/contracts/FullPaymentPlan.vue'
import SaleConfirmation from '@/views/marketplace/SaleConfirmation.vue'
import PurchaseConfirmation from '@/views/marketplace/PurchaseConfirmation.vue'
import SetupAutoInvestment from '@/views/dashboard/auto-investment/SetupAutoInvestment.vue'
import EditAutoInvestment from '@/views/dashboard/auto-investment/EditAutoInvestment.vue'
import AutoInvestment from '@/views/dashboard/auto-investment/AutoInvestment.vue'
import InvestorClassification from "@/views/legislation/InvestorClassification";
import Suitability from "@/views/legislation/Suitability";
import isMaintenance from "@/middleware/isMaintenance";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      middleware: auth
    }
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized
  },
  {
    path: '/topup',
    name: 'topup',
    component: TopUp,
    meta: {
      middleware: [auth, isActivated],
      type: 'particular',
      particular: 'true',
      fallback: 'dashboard'
    },
    props: true
  },
  {
    path: '/topup&success=true',
    name: 'topupsuccess',
    component: TopUpSuccess,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: Withdraw,
    meta: {
      middleware: auth
    },
    props: true
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      middleware: auth
    },
  },
  {
    path: '/giftcards',
    name: 'giftcards',
    component: Giftcard,
    meta: {
      middleware: auth
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/projects/:id',
    name: 'project-details',
    component: ProjectDetails,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/projects/:id/updates',
    name: 'project-updates',
    component: ProjectUpdates,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/kyc/:id',
    component: KycSubmit
  },
  {
    path: '/:locale(es|en|pt-pt)?',
    component:Home,
    children: [
      {
        path: 'login',
        name: 'login',
        props: (route) => ({ activated: route.query.activated }),
        component: Login,
        meta: {
          middleware: [isLogged, isMaintenance]
        }
      },
      {
        path: 'password-forgot',
        name: 'password-forgot',
        component: PasswordForgot,
        meta: {
          middleware: [isLogged, isMaintenance]
        }
      },
      {
        path: 'password-reset',
        name: 'password-reset',
        component: PasswordReset,
        meta: {
          middleware: [isLogged, isMaintenance]
        }
      },
      {
        path: 'error',
        name: 'error',
        component: ErrorPage
      },
      {
        path: 'activate/:code',
        name: 'activate',
        component: Login,
        meta: {
          middleware: [activate, isLogged, isMaintenance]
        }
      },
      {
        path: 'signup/company',
        name: 'signup-company',
        component: SignupCompany,
        meta: {
          middleware: [isLogged, isMaintenance]
        }
      },
      {
        path: 'signup',
        name: 'signup',
        component: Signup,
        meta: {
          middleware: [isLogged, isMaintenance]
        },
        children: [
          {
            path: ':type',
            name: 'signuptype',
            component: Signup,
            children: [
              {
                path: ':voucher',
                name: 'signupvoucher',
                component: Signup
              }
            ]
          }
        ]
      },

    ],
    meta: {
      middleware: [validLocale, isMaintenance]
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/dashboard/kyc',
    name: 'dashboard-kyc',
    component: KycFlow,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/loan/:id',
    name: 'loan',
    component: Loan,
    meta: {
      middleware: [auth, isPromoter]
    }
  },
  {
    path: '/loan/:id/details',
    name: 'loan-details',
    component: LoanDetails,
    meta: {
      middleware: [auth, isPromoter]
    }
  },
  {
    path: '/loan/:id/details/payments',
    name: 'payments',
    component: Payments,
    meta: {
      middleware: [auth, isPromoter]
    }
  },
  {
    path: '/topup/:id',
    name: 'topup-promoter',
    component: TopUpPromoter,
    meta: {
      middleware: [auth, isPromoter]
    },
    props: true
  },
  {
    path: '/impact',
    name: 'impact',
    component: Impact,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: Campaigns,
    meta: {
      middleware: [auth, isPromoter],
      fallback: 'projects'
    },
    children: [
      {
        path: ':id',
        name: 'campaign-details',
        component: Campaigns,
        meta: {
          middleware: [auth, isPromoter],
          fallback: 'project-details'
        }
      }
    ]
  },

  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      middleware: auth
    },
    children: [
      // PARTICULAR ROUTES
      {
        path: 'personal-details',
        name: 'settings-personal-details',
        component: PersonalDetails,
        meta:{
          middleware: [auth, profileType],
          type: 'particular',
          fallback: 'settings-organization-details'
        }
      },

      {
        path: 'fiscal-information',
        name: 'settings-fiscal-information',
        component: FiscalInformation,
        meta: {
          middleware: [auth, profileType],
          type: 'particular',
          fallback: 'settings'
        }
      },

      // COMPANY ROUTES
      {
        path: 'organization-details',
        name: 'settings-organization-details',
        component: OrganizationDetails,
        meta: {
          middleware: [auth, profileType],
          type: 'company',
          fallback: 'settings-personal-details'
        }
      },
      {
        path: 'legal-representative-details',
        name: 'settings-legal-representative-details',
        component: LegalRepresentativeDetails,
        meta: {
          middleware: [auth, profileType],
          type: 'company',
          fallback: 'settings'
        }
      },
      {
        path: 'beneficiary-information',
        name: 'settings-beneficiary-information',
        component: BeneficiaryInformation,
        meta: {
          middleware: [auth, profileType],
          type: 'company',
          fallback: 'settings'
        }
      },
      {
        path: 'add-beneficiary',
        name: 'settings-add-beneficiary',
        component: AddBeneficiary,
        meta: {
          middleware: [auth, profileType],
          type: 'company',
          fallback: 'settings'
        }
      },
      {
        path: 'beneficiary-details/',
        name: 'settings-beneficiary-details',
        props: true,
        component: BeneficiaryDetails,
        meta: {
          middleware: [auth, profileType],
          type: 'company',
          fallback: 'settings'
        }
      },
      {
        path: 'wallets',
        name: 'settings-wallets',
        component: Wallets,
        meta: {
          middleware: [auth, profileType, isPromoter],
          type: 'company',
          fallback: 'settings'
        }
      },
      {
        path: 'wallet-details',
        name: 'settings-wallet-details',
        props: true,
        component: WalletDetails,
        meta: {
          middleware: [auth, profileType, isPromoter],
          type: 'company',
          fallback: 'settings'
        }
      },

      // COMMON ROUTES
      {
        path: 'change-password',
        name: 'settings-change-password',
        component: ChangePassword,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'change-pin',
        name: 'settings-change-pin',
        component: ChangePin,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'email-notifications',
        name: 'settings-email-notifications',
        component: EmailNotifications,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'language',
        name: 'settings-language',
        component: Language,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'bank-accounts',
        name: 'settings-bank-accounts',
        component: BankAccounts,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'bank-account-details',
        name: 'bank-account-details',
        props: true,
        component: BankAccountDetails,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'documents',
        name: 'settings-documents',
        component: Documents,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'add-phone-number',
        name: 'settings-add-phone-number',
        component: AddPhoneNumber,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'verification-code',
        name: 'settings-verification-code',
        component: VerificationCodePage,
        meta: {
          middleware: auth
        }
      },
      {
        path: 'suitability',
        name: 'settings-suitability',
        component: SettingsSuitability,
        meta: {
          middleware: auth
        }
      }
    ]
  },
  {
    path: '/account-details',
    name: 'account-details',
    component: AccountDetails,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/direct-debit-details',
    name: 'direct-debit-details',
    component: DirectDebitDetails,
    meta: {
      middleware: auth
    }, 
    children: [
      {
        path: ':id',
        name: 'dd-details',
        component: DirectDebitDetails,
        meta: {
          middleware: [auth, isPromoter],
          fallback: 'dashboard'
        }
      }
    ]
  },
  {
    path: '/vouchers/invite',
    name: 'invite',
    component: Invite,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/loans',
    name: 'loans',
    component: Loans,
    meta: {
      middleware: [auth, profileType],
      type: 'company',
      fallback: 'dashboard'
    }
  },
  {
    path: '/vouchers/invite/all',
    name: 'all',
    component: InviteAll,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/add-strategy',
    name: 'add-strategies',
    component: AddStrategies,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/topup',
    name: 'strategies-topup',
    component: Topup,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/why-saving',
    name: 'strategies-why-saving',
    component: WhySaving,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/other-beneficiary',
    name: 'strategies-other-beneficiary',
    component: OtherBeneficiary,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/direct-debit',
    name: 'strategies-direct-debit',
    component: DirectDebit,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/investment',
    name: 'strategies-investment',
    component: Investment,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/max-amount',
    name: 'strategies-max-amount',
    component: MaxAmount,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/confirmation',
    name: 'strategies-confirmation',
    component: Confirmation,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/edit/:id',
    name: 'strategies-edit',
    component: EditStrategy,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/edit-name',
    name: 'strategies-edit-name',
    component: EditName,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/strategies/edit-owner',
    name: 'edit-owner',
    component: EditOwner,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/vouchers/redeem',
    name: 'redeem-code',
    component: Redeem,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: Transactions,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/transactions/details',
    name: 'transactions-details',
    component: TransactionDetails,
    meta: {
      middleware: auth
    }
  },

  {
    path: '/simulation/:id',
    name: 'simulation',
    component: Simulation,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/invest/wallet',
    name: 'invest-wallet',
    component: InvestWallet,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/invest/amount',
    name: 'invest-amount',
    component: InvestAmount,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/invest/confirmation',
    name: 'invest-confirmation',
    component: InvestConfirmation,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/upload-proof',
    name: 'upload-proof',
    component: UploadProof,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/reservation-details',
    name: 'reservation-details',
    component: ReservationDetails,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: Contracts,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/contract-details/:id',
    name: 'contracts-details',
    component: ContractDetails,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/contract-details/:id/payment-plan',
    name: 'contracts-details-payment-plan',
    component: FullPaymentPlan,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/mkt-sell/:id',
    name: 'mkt-sell',
    component: SaleConfirmation,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/mkt-purchase/:id',
    name: 'mkt-purchase',
    component: PurchaseConfirmation,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/auto-investment/setup',
    name: 'setup-auto-investment',
    component: SetupAutoInvestment,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/auto-investment/:id?/edit',
    name: 'edit-auto-investment',
    component: EditAutoInvestment,
    props: true,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/auto-investment',
    name: 'auto-investment',
    component: AutoInvestment,
    meta: {
      middleware: auth
    }
  },
  { path: '/:pathMatch(.*)*',name:'not-found', component: PathNotFound },
  { path: '/maintenance',name:'maintenance', component: Maintenance },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: Onboarding,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/investor-classification',
    name: 'investor-classification',
    component: InvestorClassification,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/suitability',
    name: 'suitability',
    component: Suitability,
    meta: {
      middleware: auth
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app')?.scrollIntoView({ behavior: 'smooth' });
  }
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  document.title = Object.keys(i18n.global.tm('page_title.' + to.name)).length 
    ? i18n.global.t('page_title.' + to.name) + ' | ' + process.env.VUE_APP_TITLE 
    : process.env.VUE_APP_TITLE
  if (to.meta && to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    middleware[0]({ ...context, next: nextMiddleware })
    next()
    return
  }
  next()
})

export default router
