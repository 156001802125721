import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueCookieNext } from 'vue-cookie-next'
import axios from 'axios'
import '@/main.css'
import Maska from 'maska'
import './assets/styles/global.postcss'
import rules from './plugins/vee-validate'
import '@vueform/multiselect/themes/default.css'
import { configure } from 'vee-validate'
// import useSecurity from '@/mixins/useSecurity'
import i18n from './i18n'
// @ts-ignore
// import VueHtml2Canvas from 'vue-html2canvas';
const GAuth = require('vue3-google-oauth2')
const VueCryptojs = require('vue-cryptojs')
const VueClipboard = require('vue3-clipboard')

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true,
  generateMessage: context => {
    return i18n.global.t('errors.general', {field: i18n.global.te('fields.' + context.field) ?i18n.global.t('fields.' + context.field) :  context.field });
  },
})

const app = createApp(App)

// API KEY
axios.defaults.headers.common['X-Authorization'] =
  process.env.VUE_APP_GP_API_KEY

// @ts-ignore
VueCookieNext.config({ expire: '10min', secure: true, path: '/', domain: '' })

const gAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'email',
  prompt: 'consent',
  fetch_basic_profile: false
}

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

app.use(GAuth, gAuthOptions)

app.use(VueCookieNext)
app.use(i18n)
app.use(VueCryptojs)
app.use(rules)
// app.use(VueHtml2Canvas)
app.use(Maska)
app.use(store).use(router).mount('#app')
app.provide('vue-cookie', VueCookieNext)

//adding token to all axios requests if token exists
axios.interceptors.request.use(
  (config) => {
    const requestSettings = store.state.general.requestSettings
    if(requestSettings) {
      if(config.params)
        config.params.settings = requestSettings;
      else
        config.params = {settings: requestSettings}
      store.dispatch('setRequestSettings', null)
    }

    const token = VueCookieNext.getCookie('token')
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  async (response) => {
    let token = VueCookieNext.getCookie('token')
    if(response.headers && response.headers.settings){
      await store.dispatch('setSettings', JSON.parse(response.headers.settings))
    }
    /*if(response.config.method === 'post' && response.config.url.includes('api/v1/profiles')) {
      VueCookieNext.setCookie('profile', response.data, {
        path: '/',
        domain: '',
        secure: true
      })
      store.dispatch('setForceNext', true)
    }*/

    if (
      response.headers.expires_in &&
      token &&
      response.headers.expires_in <= 120
    ) {
      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/refresh', {
          token: token
        })
        .then(({ data }) => {
          token = data.token
          VueCookieNext.removeCookie('token')
          VueCookieNext.setCookie('token', token, { expire: "20min" })
          const twentyMinutesLater = new Date();
          twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);
          VueCookieNext.setCookie('token_expiry', JSON.stringify(twentyMinutesLater), { expire: "20min" })
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        })
    }
    return response
  },
  (error) => {
    const originalRequest = error.config
    if(error.response && error.response.status === 503 ){
      VueCookieNext.removeCookie('token')
      window.location.href = '/maintenance'
    }

    // If user is unauthorized we will try to refresh the token and retry the original request
    let token = VueCookieNext.getCookie('token')
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      token
    ) {
      originalRequest._retry = true
      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/refresh', {
          token: token
        })
        .then(({ data }) => {
          token = data.token
          VueCookieNext.removeCookie('token')
          VueCookieNext.setCookie('token', token, { expire: "20min" })
          const twentyMinutesLater = new Date();
          twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);
          VueCookieNext.setCookie('token_expiry', JSON.stringify(twentyMinutesLater), { expire: "20min" })
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          // Try the original request again
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          axios(originalRequest)
          /*if(originalRequest.method === 'post' && originalRequest.url.includes('api/v1/profiles')){
            // empty
          }else{
            // Refresh the page so the content gets updated
          }*/
          window.location.reload()
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            // If it can't refresh token let's rgo back to login with no token
            if (error.response.data.error === 'could_not_refresh_token') {
              delete axios.defaults.headers.common['Authorization']
              VueCookieNext.removeCookie('token')
              window.location.reload()
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error', error.message)
          }
        })
    }
    return Promise.reject(error)
  }
)
