<template>
  <div class="relative">
    <div v-if="!fetching || next_page > 1">
      <infinite-scroll
        v-if="projects && projects.length > 0"
        @infinite-scroll="infiniteHandler"
        :noResult="has_next_page === null"
        :message="''"
        class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 pb-14 lg:pb-0"
      >
        <ProjectCard
          :isOffer="type === 'offers'"
          :project="project"
          v-for="(project, index) in projects"
          v-bind:key="project"
          :style="
            'animation-delay: ' +
            100 * (index - 6 * Math.floor(index / 6)) +
            'ms'
          "
          :campaign="campaigns"
        />
        <div v-if="loading" class="mt-4">
          <img
            class="animate-spin w-12 mx-auto"
            src="@/assets/svg/loading.svg"
            alt=""
          />
        </div>
      </infinite-scroll>
      <!-- No offers -->
      <div v-else-if="type === 'offers'" class="relative">
        <div class="hidden">
          <div class="text-left" id="popup-content-mkt-no-offers">
            <div>
              <p
                v-for="(text, index) of $tm('mkt.no_offers.popup')"
                :key="index"
                class="
                  even:text-sm even:text-grey-3 even:mb-4
                  odd:font-bold odd:text-grey-2 odd:mb-1
                "
              >
                {{ text }}
              </p>
            </div>
          </div>
        </div>
        <img
          class="absolute top-0 right-0"
          src="@/assets/svg/mkt/bg.svg"
          alt="ball"
        />
        <div class="relative z-40">
          <p
            class="
              text-lg
              lg:text-4xl
              text-grey-2 text-left
              w-40
              lg:w-96
              mb-4
              lg:mb-12
            "
          >
            {{ $t('mkt.no_offers.title') }}
          </p>
          <p class="text-grey-3 text-left text-sm lg:text-base">
            {{ $t('mkt.no_offers.text') }}
          </p>
          <p
            class="
              text-left text-sm
              lg:text-base
              font-bold
              text-orange-1
              cursor-pointer
              hover:underline
            "
            @click="openPopup"
          >
            {{ $t('mkt.no_offers.link') }}
          </p>
          <!-- Sell investment -->
          <router-link
            to="/contracts"
            class="bg-white rounded-2xl p-4 mt-12 flex justify-between"
          >
            <div class="flex">
              <div>
                <img
                  class="mr-1 mt-0.5"
                  src="@/assets/svg/mkt/sell-orange.svg"
                  alt="bag"
                />
              </div>
              <div class="text-left">
                <p class="text-sm font-semibold text-grey-3">
                  {{ $t('mkt.no_offers.sell_title') }}
                </p>
                <p class="text-13 text-grey-4">
                  {{ $t('mkt.no_offers.sell_text') }}
                </p>
              </div>
            </div>
            <img src="@/assets/svg/arrows/right_grey_arrow.svg" alt="arrow" />
          </router-link>
          <!-- Set alert -->
          <router-link
            to="/settings/email-notifications"
            class="bg-white rounded-2xl p-4 mt-4 flex justify-between"
          >
            <div class="flex">
              <div>
                <img
                  class="mr-1 mt-0.5"
                  src="@/assets/svg/mkt/alert-orange.svg"
                  alt="alert"
                />
              </div>
              <div class="text-left">
                <p class="text-sm font-semibold text-grey-3">
                  {{ $t('mkt.no_offers.alert_title') }}
                </p>
                <p class="text-13 text-grey-4">
                  {{ $t('mkt.no_offers.alert_text') }}
                </p>
              </div>
            </div>
            <img src="@/assets/svg/arrows/right_grey_arrow.svg" alt="arrow" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8" v-else>
      <ProjectSkeletons />
    </div>
  </div>
</template>
<script>
import ProjectCard from '@/components/projects/Card.vue'
import InfiniteScroll from 'infinite-loading-vue3'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { onMounted, computed, ref, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    campaigns: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'projects'
    }
  },
  components: {
    ProjectCard,
    InfiniteScroll,
    ProjectSkeletons: defineAsyncComponent(() =>
      import('@/components/projects/ProjectSkeletons.vue')
    )
  },

  setup(props) {
    const store = useStore()
    const loading = ref(false)
    const route = useRoute()
    const i18n = useI18n({ useScope: 'global' })

    const projects = computed(() => {
      return store.state.projects.projects[store.state.projects.type]?.items
    })

    const fetching = computed(() => {
      return store.state.projects.fetching
    })

    const has_next_page = computed(() => {
      return store.state.projects.projects[store.state.projects.type]
        ?.has_next_page
    })

    const next_page = computed(() => {
      return store.state.projects.projects[store.state.projects.type]?.next_page
    })

    onMounted(async () => {
      if (route.query.order) {
        await store.dispatch('setOrder', route.query.order)
      } else {
        if (props.campaigns) {
          await store.dispatch('setIsCampaign', true)
          await store.dispatch('setType', 'campaigns')
        }
        if (!projects.value.length) {
          await store.dispatch('getProjects')
        }
      }
    })

    async function infiniteHandler() {
      if (has_next_page.value && !loading.value) {
        loading.value = true
        await store.dispatch(
          'getProjects',
          props.campaigns ? '' : 'campaign,comingsoon,reserved'
        )
        loading.value = false
      }
    }

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: true,
        content: document.getElementById('popup-content-mkt-no-offers')
          .outerHTML
      }

      store.dispatch('setPopup', popup)
    }

    return {
      projects,
      infiniteHandler,
      fetching,
      loading,
      has_next_page,
      next_page,
      openPopup
    }
  }
}
</script>
