<template>
  <div>
    <!-- LOG -->
    <div
      class="
        bottom-0
        z-30
        absolute
        w-20
        left-1/2
        transform
        -translate-x-1/2
        log
      "
    >
      <svg
        width="100%"
        height="auto"
        viewBox="0 0 122 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="width: fit-content"
        class="left-1/2 -translate-x-1/2 transform absolute"
      >
        <path
          d="M55.3122 4.16393V251.533C55.3122 252.469 54.8654 253.199 54.3235 253.199H50.6874C50.1455 253.199 49.6987 252.469 49.6987 251.533V4.16027C49.6987 2.26387 50.6178 0.710938 51.742 0.710938H53.2653C54.3894 0.710938 55.3122 2.26387 55.3122 4.16393Z"
          fill="#23455B"
          class="svg-elem-1"
        ></path>
        <path
          d="M95.9042 43.2931L82.575 55.4544L55.1191 80.5117L52.9032 82.5323C52.8828 82.5494 52.8623 82.5663 52.8384 82.5765C52.6748 82.6718 52.4498 82.6208 52.2998 82.4575L51.1612 81.2158C50.9908 81.0287 50.9771 80.76 51.1408 80.6137L52.8384 79.066L55.1191 76.9841L80.683 53.6548L94.1451 41.3711C94.4758 41.0683 95.0315 41.1398 95.386 41.5242L95.8633 42.0446C96.2144 42.429 96.2348 42.9903 95.9042 43.2931V43.2931Z"
          fill="#23455B"
          class="svg-elem-2"
        ></path>
        <path
          d="M108.977 107.824L100.363 115.253L55.1189 154.288L53.37 155.795C53.2098 155.932 53.0019 155.955 52.8382 155.877C52.7837 155.853 52.736 155.819 52.6951 155.772L51.5906 154.496C51.4235 154.305 51.4542 154.006 51.6656 153.826L52.8382 152.816L55.1189 150.846L98.6345 113.304L107.269 105.854C107.696 105.487 108.326 105.507 108.667 105.902L109.131 106.436C109.472 106.831 109.404 107.457 108.977 107.824V107.824Z"
          fill="#23455B"
          class="svg-elem-3"
        ></path>
        <path
          d="M0.435434 73.2786L52.2009 138.024C52.3952 138.269 52.7122 138.33 52.9099 138.174L54.2292 137.123C54.4269 136.966 54.4372 136.646 54.2394 136.402L2.47401 71.656C2.07856 71.1593 1.41722 71.0199 1.00815 71.343L0.455887 71.7819C0.0468092 72.1084 0.0365831 72.7786 0.435434 73.2786V73.2786Z"
          fill="#23455B"
          class="svg-elem-4"
        ></path>
        <path
          d="M23.4531 32.5616L50.5477 66.4498C50.65 66.5791 50.892 66.5485 51.0897 66.392L52.409 65.3408C52.6067 65.1844 52.6885 64.953 52.5863 64.8272L25.4916 30.9389C25.2837 30.6804 24.7792 30.7314 24.3701 31.058L23.8178 31.4968C23.4087 31.8234 23.2451 32.2996 23.4531 32.5616V32.5616Z"
          fill="#23455B"
          class="svg-elem-5"
        ></path>
        <path
          d="M1.0217 154.919L50.285 216.535C50.4725 216.77 50.7793 216.821 50.977 216.664L52.2963 215.613C52.494 215.457 52.511 215.144 52.3235 214.913L3.06028 153.297C2.68188 152.824 2.03758 152.705 1.63191 153.028L1.07966 153.467C0.670577 153.793 0.646713 154.446 1.02511 154.919H1.0217Z"
          fill="#23455B"
          class="svg-elem-6"
        ></path>
        <path
          d="M120.714 158.434L110.419 166.204L55.1184 207.943L52.8378 209.665L51.9822 210.311C51.7231 210.508 51.3958 210.495 51.2424 210.294L50.2231 208.95C50.0731 208.75 50.1515 208.433 50.4106 208.236L52.8378 206.402L55.1184 204.681L108.752 164.197L119.143 156.356C119.671 155.958 120.36 155.971 120.673 156.39L121.103 156.951C121.416 157.37 121.243 158.033 120.714 158.434V158.434Z"
          fill="#23455B"
          class="svg-elem-7"
        ></path>
      </svg>
    </div>

    <!-- TREETOP -->
    <div
      class="
        w-11
        -mb-1
        -ml-1
        bottom-20
        z-20
        absolute
        left-1/2
        transform
        -translate-x-1/2
      "
    >
      <img
        src="@/assets/svg/impact/gamification/components/green-treetop.svg"
        alt=""
        class="w-full blue-treetop"
        style="animation-delay: 1000ms"
      />
    </div>
    <!-- TREETOP - LEFT -->
    <div class="w-4 -mb-1 -ml-1 bottom-7 z-20 absolute left-4 transform">
      <img
        src="@/assets/svg/impact/gamification/components/green-treetop.svg"
        alt=""
        class="w-full blue-treetop"
        style="animation-delay: 700ms"
      />
    </div>
    <div class="w-3 -mb-1 -ml-1 bottom-11 z-20 absolute left-3 transform">
      <img
        src="@/assets/svg/impact/gamification/components/green-treetop.svg"
        alt=""
        class="w-full blue-treetop"
        style="animation-delay: 800ms"
      />
    </div>
    <div class="w-4 -mb-1 -ml-1 bottom-16 z-20 absolute left-3 transform">
      <img
        src="@/assets/svg/impact/gamification/components/green-treetop.svg"
        alt=""
        class="w-full blue-treetop"
        style="animation-delay: 900ms"
      />
    </div>
    <!-- TREETOP - RIGHT -->
    <div class="w-6 -mb-1 -ml-1 bottom-7 z-20 absolute right-3 transform">
      <img
        src="@/assets/svg/impact/gamification/components/green-treetop.svg"
        alt=""
        class="w-full blue-treetop"
        style="animation-delay: 750ms"
      />
    </div>
    <div class="w-4 -mb-1 -ml-1 bottom-14 z-20 absolute right-3 transform">
      <img
        src="@/assets/svg/impact/gamification/components/green-treetop.svg"
        alt=""
        class="w-full blue-treetop"
        style="animation-delay: 850ms"
      />
    </div>
    <div class="w-3 -mb-1 -ml-1 bottom-18 z-20 absolute right-2 transform">
      <img
        src="@/assets/svg/impact/gamification/components/green-treetop.svg"
        alt=""
        class="w-full blue-treetop"
        style="animation-delay: 950ms"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  setup() {}
}
</script>
<style scoped>
.blue-treetop {
  transform: scale(0);
  transform-origin: center;
  animation: scale-top 0.5s ease-out forwards;
}

@keyframes scale-top {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.svg-elem-1 {
  fill: rgb(35, 69, 91);
  transform-origin: bottom;
  animation: animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
}

@keyframes animate-svg-fill-2 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.svg-elem-2 {
  transform-origin: center;
  animation: animate-svg-fill-2 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s
    both;
}

@keyframes animate-svg-fill-3 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.svg-elem-3 {
  transform-origin: center;

  animation: animate-svg-fill-3 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s
    both;
}

@keyframes animate-svg-fill-4 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.svg-elem-4 {
  transform-origin: center;

  animation: animate-svg-fill-4 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s
    both;
}

@keyframes animate-svg-fill-5 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.svg-elem-5 {
  transform-origin: center;
  animation: animate-svg-fill-5 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s
    both;
}

@keyframes animate-svg-fill-6 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.svg-elem-6 {
  fill: rgb(35, 69, 91);
  transform-origin: center;
  animation: animate-svg-fill-6 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s
    both;
}

@keyframes animate-svg-fill-7 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.svg-elem-7 {
  fill: rgb(35, 69, 91);
  transform-origin: center;
  animation: animate-svg-fill-7 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s
    both;
}
</style>
