import axios from 'axios'
export default {
  state: {
    bankAccount: null,
    amount: null,
    error: null,
    details: null
  },
  mutations: {
    SET_AMOUNT(state, data) {
      state.amount = data
    },
    SET_BANK_ACCOUNT(state, data) {
      state.bankAccount = data
    },
    SET_DETAILS(state, data) {
      state.details = data
    }
  },
  actions: {
    setAmount({ commit }, value) {
      commit('SET_AMOUNT', value)
    },
    setBankAccount({ commit }, value) {
      commit('SET_BANK_ACCOUNT', value)
    },
    setInstant({ commit }, value) {
      commit('SET_INSTANT', value)
    },

    withdraw({ commit }, data) {
      return new Promise((resolve, reject) => {
        const walletId = data.wallet_id
        axios
          .post(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/wallets/' +
              parseInt(walletId) +
              '/payout',
            data.params
          )
          .then((response) => {
            resolve(response.data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
            reject(e)
          })
      })
    },
    withdrawDetails({ commit }, data) {
      return new Promise((resolve, reject) => {
        const walletId = data.wallet_id
        axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/wallets/' +
              parseInt(walletId) +
              '/payout',

            { params: data.params }
          )
          .then((response) => {
            commit('SET_DETAILS', response.data)
            resolve(response.data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
            reject(e)
          })
      })
    }
  }
}
