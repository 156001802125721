<template>
  <div class="page  px-0 lg:px-24 container overflow-y-hidden relative">
    <a href="https://goparity.ca" >
      <img
        src="@/assets/img/logo/goparity.svg"
        alt=""
        class="absolute  pt-4 w-28  sm:pt-8 ml-6 lg:ml-0 sm:w-40"
      />
    </a>

    <div class="lg:absolute lg:top-1/2 lg:-translate-y-1/2 transform mt-24 lg:mt-0">
        <div class="grid grid-cols-2 gap-8">
          <div class=" text-left lg:col-span-1 col-span-2 order-1 lg:order-none">
            <div class="mx-4 lg:mx-0">
              <h1>
                {{ $t('404.title') }}
              </h1>
              <p class="pb-6">
                {{ $t('404.subtitle_1') }}

              </p>
              <p class="lg:pb-24 pb-10">
                {{ $t('404.subtitle_2') }}

              </p>
            </div>
            <div class="lg:flex ">
              <a  :href="url" class="button orange-button mr-6 ml-6 lg:ml-0 mb-4 lg:mb-0">
                {{ $t('404.buttons.back') }}
              </a>
              <a href="mailto:hello@goparity.com" class="button blue-button mx-6 mb-12 lg:mb-0">
                {{ $t('404.buttons.contact') }}
              </a>
            </div>
          </div>
          <div class="relative mx-24 lg:col-span-1 col-span-2 mb-32 lg:mb-0 mt-12 lg:mt-0">
            <div class="ground-bg absolute w-64 lg:w-full  top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 transform"></div>
            <!-- GROUND -->
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div
                class="hoover-animation"
              >
                <!-- SOIL -->
                <div class="w-40 lg:w-52">
                  <img
                    src="@/assets/svg/impact/gamification/soil.svg"
                    alt=""
                    :class="'appear'"
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="lg:absolute lg:bottom-12 social-wrapper">
      <div class="lg:grid grid-cols-2 gap-8">
        <div>
        </div>
        <div class="lg:flex text-center justify-between lg:text-left lg:ml-24 social">
          <div>
            {{ $t('404.social') }}
          </div>
          <div class="flex lg:mt-0 mt-4 mx-auto lg:mx-0 justify-center">
            <a target="_blank" href="https://www.facebook.com/goparity/" class="mr-6 cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/fb.svg" alt="" class="w-6"></a>
            <a target="_blank"  href="https://t.me/goparity" class="mr-6 cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/tg.svg" alt="" class="w-6"></a>
            <a target="_blank"  href="https://www.instagram.com/goparity/" class="mr-6 cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/ig.svg" alt="" class="w-6"></a>
            <a target="_blank" href="https://www.linkedin.com/company/goparity" class=" cursor-pointer hover:opacity-80 transition-all"><img src="@/assets/svg/404/lin.svg" alt="" class="w-6"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'

export default {
 setup(){
    const url = computed(()=>{
      return process.env.VUE_APP_FE_BASE_URL
    })

   return{url}
 }
}
</script>
<style scoped>


h1{
  font-family: 'UXUM_GROT';
  color:#154b64 ;
  font-size:80px;
  line-height: 80px;
  padding-bottom:49px;
  font-weight: 500;
}
p{
 color: #688695;
  font-size: 24px;
}

.ground-bg {
  border-radius: 50%;
  filter: blur(40px);
  background-image: linear-gradient(202deg, #0b4a89 45%, #3279c0 55%, #f28a10 30%, #f4bc38 10%);
  opacity: 0.15;
  padding-top:100%;
}

.button{
  color: #154b64;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 16px 32px;
  border-radius: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orange-button{
  background: #ffc84b;
}

.orange-button:hover{
  background: #fdd374;
  color: #376478;
}

.blue-button{
  border: solid 3px #154b64;
}

.blue-button:hover{
  border: solid 3px #688695;
  color: #688695;
}

.social{
  color: #154b64;
}

.social-wrapper{
  width:90%;
}

.hoover-animation {
  animation: hoover 6s ease-in-out infinite;
}

.appear {
  animation: sun 0.4s ease-out forwards;
}

@keyframes sun {
  0% {
    transform: translateY(350%);
  }
  70% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hoover {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}
@media (max-width: 1024px) {
  h1{
    font-size:40px;
    line-height: 48px;
    text-align: center;
    padding-bottom:16px;

  }

  p{
    color:#154b64;
    font-size: 16px;
    text-align: center;
  }

  p:last-of-type{
    font-weight: 600;
  }

  .ground-bg {
    filter: blur(20px);
    padding-top: 16rem;
  }

  .social-wrapper{
    width:100%;
  }

}
</style>
