<template>
  <router-view/>
</template>

<script>
export default {
  name: 'home',
  setup() {
  }
}
</script>
