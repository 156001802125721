<template>
  <div>
    <div>
      <img v-if="img !== ''" :src="require('@/assets/svg/form/' + img + '.svg')" alt="img" class="mb-4" />
      <p
        class="text-left mb-6 text-2xl lg:text-xl font-medium lg:font-bold text-grey-2"
      >
        {{ title }}
      </p>
      <p
        class="text-left text-grey-3 text-sm lg:text-base"
        v-html="subtitle"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: { 
    img: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>