<template>
  <div v-if="walletData" class="steps flex-grow flex flex-col justify-between">
    <div>
      <div class="steps__title--mobile ml-5 md:ml-0">
        {{ $tm('withdraw.details.title') }}
      </div>
      <div
        v-if="payoutAfterPayinFee"
      >
        {{ $tm('withdraw.details.fee_explination') }}
      </div>
      <div class="pt-8">
        <div class="pb-8"><Table v-if="isActive" :data="tableData" /></div>
      </div>
    </div>
    <div class="absolute lg:bottom-0 button-bottom w-4/5 md:w-3/4">
      <GpButton
        mobileWidth="w-full"
        @click="next"
        :disabled="loading"
        class="ml-auto"
      >
        {{ $t('wording.confirm') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed, ref, onMounted, onUnmounted, watch } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
import Table from '@/components/general/Table.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    GpButton,
    Table
  },
  props: {
    wallet: Object,
    isActive: Boolean
  },
  emits: ['next'],

  setup(props, { emit }) {
    const i18n = useI18n({ useScope: 'global' })
    const store = useStore()
    const { dollarFormatting } = useGlobalHelpers()
    const walletData = ref(null)
    const tableData = ref({
      top: [],
      bottom: []
    })
    const innerWidth = ref(window.innerWidth)

    /** COMPUTED **/
    const details = computed(() => {
      return store.state.withdraw.details
    })

    const loading = computed(() => {
      return store.state.general.loading
    })

    const bankAccount = computed(() => {
      return store.state.withdraw.bankAccount
    })

    const amount = computed(() => {
      return store.state.withdraw.amount
    })

    const instantFee = computed(() => {
      return store.state.withdraw.details?.fees.instant_payout_fee
    })

    const payoutAfterPayinFee = computed(() => {
      return store.state.withdraw.details?.fees.payout_after_payin_fee
    })

    const balanceAfterPayout = computed(() => {
      return store.state.withdraw.details?.balance_after_payout
    })

    const recipientGets = computed(() => {
      return store.state.withdraw.details?.fees.total_fees > amount.value ? 0 : (amount.value - store.state.withdraw.details?.fees.total_fees)
    })

    /** FUNCTIONS **/
    onMounted(async () => {
      window.addEventListener('resize', handleResize)
      if (props.wallet) walletData.value = props.wallet
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    function handleResize() {
      innerWidth.value = window.innerWidth
    }

    watch(details, async () => {
      if (details.value) {
        if (instantFee.value || payoutAfterPayinFee.value) {
          tableData.value.top = [
            {
              title: i18n.t('withdraw.details.amount'),
              value: '$' + dollarFormatting(amount.value),
              subtitle: [
                {
                  title: i18n.t('withdraw.details.recipient'),
                  value: '$' + dollarFormatting(recipientGets.value)
                }
              ]
            }
          ]
        } else {
          tableData.value.top = [
            {
              title: i18n.t('withdraw.details.amount'),
              value: '$' + dollarFormatting(amount.value)
            }
          ]
        }
        if (instantFee.value) {
          tableData.value.top[0].subtitle.push({
            title: i18n.t('withdraw.details.instant_fee'),
            value: '$' + dollarFormatting(instantFee.value)
          })
        }
        if (payoutAfterPayinFee.value) {
          tableData.value.top[0].subtitle.push({
            title: i18n.t('withdraw.details.payout_after_payin'),
            value: '$' + dollarFormatting(payoutAfterPayinFee.value) 
          })
        }
        tableData.value.top.push({
          title: i18n.t('withdraw.details.transfered_to'),
          value: bankAccount.value.name
        })

        tableData.value.bottom = [
          {
            title: i18n.t('withdraw.details.transfered_from'),
            value: walletData.value.name
              ? walletData.value.name
              : i18n.t('withdraw.details.main_wallet')
          },
          {
            title: i18n.t('withdraw.details.balance_after'),
            value: '$' + dollarFormatting(balanceAfterPayout.value)
          }
        ]
      }
    })

    async function next() {
      store.dispatch('setLoading', true)
      await store
        .dispatch('sendSms')
        .then(() => {
          store.dispatch('setLoading', false)
        })
        .catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('setLoading', false)
        })
      emit('next')
    }

    return {
      bankAccount,
      walletData,
      details,
      payoutAfterPayinFee,
      instantFee,
      balanceAfterPayout,
      amount,
      next,
      loading,
      dollarFormatting,
      tableData,
      recipientGets
    }
  }
}
</script>