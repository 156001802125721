<template>
  <div>
    <!-- div class="christmas-ball christmas-ball--star right-12 w-28 top-52">
      <img src="~/assets/svg/star.svg" alt="green ball">
    </div-->
    <div class="christmas-ball christmas-ball--yellow right-5 w-4 top-3">
      <img src="@/assets/svg/yellow-ball-2.svg" alt="green ball">
    </div>
    <div class="christmas-ball right-8 w-3 top-10">
      <img src="@/assets/svg/green-ball.svg" alt="green ball">
    </div>
    <div class="christmas-ball  right-2 w-4 top-8">
      <img src="@/assets/svg/green-ball.svg" alt="green ball">
    </div>
  </div>
</template>
<script>
export default {
  setup () {}
}
</script>
<style lang="postcss" scoped>
.christmas-ball {
  @apply absolute;
}

.christmas-ball img {
  @apply w-full;
}

.christmas-ball::before {
  @apply bg-grey-5;
  content: '';
  width: 1px;
  height: 20rem;
  top: -20rem;
  position: absolute;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
}

.christmas-ball--star::before {
  margin-top: 2px;
}

.christmas-ball--yellow::before {
  margin-left: 0px;
  margin-top: 6px;
}
</style>
