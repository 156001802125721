<template>
  <div class="page">
    <div class="md:pt-24">
      <div class="container">
        <Back
          :to="'/vouchers/invite'"
          :title="$t('invite.invitations.title')"
        />
        <StatusBanner class="mb-10" />
        <StatusList />
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import StatusBanner from '@/components/invite/StatusBanner.vue'
import StatusList from '@/components/invite/StatusList.vue'

export default {
  components: { Back, StatusBanner, StatusList },
  setup() {}
}
</script>
