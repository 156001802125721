<template>
  <div>
    <!-- LOG -->
    <div
      class="
        h-20
        bottom-0
        z-30
        absolute
        w-14
        left-1/2
        transform
        -translate-x-1/2
        log
      "
    >
      <img
        src="@/assets/svg/impact/gamification/components/log.svg"
        alt=""
        class="w-full"
        style="height: inherit"
      />
    </div>

    <!-- TREETOP -->
    <div
      class="w-14 bottom-16 z-20 absolute left-1/2 transform -translate-x-1/2"
    >
      <img
        src="@/assets/svg/impact/gamification/components/treetop.svg"
        alt=""
        class="w-full blue-treetop"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  setup() {}
}
</script>
<style scoped>
.log {
  height: 0px;
  animation: log-grow 0.5s ease-out forwards;
  animation-delay: 0.5s;
}

.blue-treetop {
  transform: scale(0);
  transform-origin: center;
  animation: scale-top 0.5s ease-out forwards;
  animation-delay: 0.7s;
}

@keyframes scale-top {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes log-grow {
  0% {
    height: 0px;
  }
  70% {
    height: 5.2rem;
  }
  100% {
    height: 5rem;
    overflow: auto !important;
  }
}
</style>
