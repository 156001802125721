<template>
  <div>
      <img src="@/assets/svg/exclamation_grey_border.svg" alt="" class="min-h-5 mx-auto mb-6" />
    <p v-if="!account" class="mb-10 text-center text-grey-2 text-xl font-medium">
      {{ $t('settings.bank_accounts.add_bank_account.title') }}
    </p>
    <p v-else class="mb-8 text-center text-grey-2 text-xl font-medium">
      {{ $t('settings.bank_accounts.replace_bank_account.title') }}
    </p>
    <p v-if="!account" class="mb-12 text-center">
      {{ $t('settings.bank_accounts.add_bank_account.warning') }}
    </p>
    <p v-else class="mb-10 text-center">
      {{ $t('settings.bank_accounts.replace_bank_account.text') }}
    </p>
    <GpButton class="mx-auto" @click="showiFrame()">
      {{ $t('settings.bank_accounts.add_bank_account.success.btn') }}
    </GpButton>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
import { useStore } from 'vuex'
export default {
  components: {
    GpButton
  },
  props: {
    account: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const store = useStore()

    const showiFrame = () => {
      // get embed url
      let redirectURL =
        process.env.VUE_APP_FE_BASE_URL + 'settings/bank-accounts'

      store
        .dispatch('tokenizeiFrame', redirectURL)
        .then(async (EmbedURL) => {
          window.location.href = EmbedURL
        })
        // errors
        .catch(async (e) => {
          await store.dispatch('setError', e)
        })
    }

    return {
      showiFrame
    }
  }
}
</script>

<style lang="scss" scoped>
</style>