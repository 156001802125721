<template>
  <div class="absolute w-screen h-screen bg-grey-2 bg-opacity-80 z-40">
    <div
      class="
        flex
        w-full
        h-full
        flex-col
        justify-end
        lg:items-center lg:justify-center
      "
    >
      <div
        class="
          bg-white
          rounded-t-2xl
          lg:rounded-3xl
          w-full
          h-2/3
          p-6
          lg:px-8 lg:pb-20 lg:pt-8 lg:h-auto lg:w-auto
          justify-between
          flex flex-col
        "
      >
        <div>
          <div
            class="flex relative items-start justify-between lg:justify-center"
          >
            <img
              src="@/assets/svg/check_grey_border.svg"
              alt="success"
              class="w-24 mt-12"
            />
            <img
              class="lg:absolute lg:top-0 lg:right-0 lg:w-6"
              src="@/assets/svg/cross.svg"
              alt="cross"
              @click="close"
            />
          </div>
          <p
            class="
              my-6
              text-grey-2 text-xl
              lg:text-2xl
              text-left
              lg:text-center lg:w-72 lg:mx-28
            "
          >
            {{ $t('vouchers.redeem.success_title') }}
          </p>
          <p
            class="
              text-grey-2 text-opacity-80 text-sm text-left
              mb-4
              lg:mb-16 lg:text-center
            "
            v-if="!giftcard"
          >
            {{ $t('vouchers.redeem.success_voucher_text') }}
          </p>
          <p
            class="
              text-grey-2 text-opacity-80 text-sm text-left
              mb-8
              lg:mb-16 lg:text-center
            "
            v-else
          >
            {{ $t('vouchers.redeem.success_giftcard_text') }}
          </p>
        </div>
        <div v-if="!giftcard" class="w-full lg:flex lg:justify-around">
          <div>
            <GpButton class="hidden lg:flex" color="white" @click="close">
              {{ $t('vouchers.redeem.success_voucher_sec') }}
            </GpButton>
          </div>
          <div>
            <router-link to="/projects">
              <GpButton>
                {{ $t('vouchers.redeem.success_voucher_btn') }}
              </GpButton>
            </router-link>
          </div>
        </div>
        <div v-else class="w-full flex lg:justify-center">
          <router-link to="/dashboard">
            {{ $t('vouchers.redeem.success_giftcard_btn') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
export default {
  components: {
    GpButton
  },
  props: {
    giftcard: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { emit } = getCurrentInstance()
    const close = () => {
      emit('close')
    }
    return {
      close
    }
  }
}
</script>

<style lang="scss" scoped></style>
