<template>
  <div>
    <div
      class="pt-2 text-base"
      :class="{
        'rounded-t-3xl': data.bottom,
        'rounded-3xl': !data.bottom,
        'lg:bg-white lg:px-6': mobileList,
        'bg-white px-6 ': !mobileList
      }"
      v-if="data.top"
    >
      <div
        class="border-grey-6 relative"
        v-for="(el, index) in data.top"
        v-bind:key="el.title"
        :class="{
          'border-b-2': index < data.top.length - 1 && !mobileList,
          'lg:border-b-2': index < data.top.length - 1 && mobileList,
          'py-4 ': !mobileList,
          'py-2 lg:py-4': mobileList
        }"
      >
        <div class="flex text-left justify-between">
          <div class="text-grey-3">{{ el.title }}</div>
          <div
            class="font-semibold text-right pl-8 md:pl-0"
            :class="el.copyable ? 'flex break-all' : ''"
            @click="el.copyable ? copy(el.value_copy ?? el.value) : ''"
          >
            <div
              :class="
                el.copyable
                  ? 'mr-2'
                  : '' + ' ' + el.value === 'Succeeded' ||
                    el.value === 'Created' ||
                    el.value === 'Failed'
                  ? 'text-white rounded text-sm px-1 ' +
                    stateBg[el.value.toLowerCase()]
                  : ''
              "
            >
              {{ el.value }}
            </div>
            <img
              src="@/assets/svg/copy.svg"
              alt=""
              v-if="el.copyable"
              class="cursor-pointer w-4"
            />
          </div>
        </div>
        <template v-if="el.subtitle">
          <div
            class="w-full flex items-center justify-between"
            v-for="subEl in el.subtitle"
            :key="subEl"
          >
            <p
              class="pt-2 text-sm text-grey-4"
              :class="{ underline: subEl.underline }"
            >
              {{ subEl.title }}
            </p>
            <p class="pt-2 text-sm text-grey-4 font-semibold">
              {{ subEl.value }}
            </p>
          </div>
        </template>
        <transition name="toast-fade">
          <div
            class="absolute -right-24 -mt-2 hidden md:block"
            v-if="show === el.value || show === el.value_copy"
          >
            <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
              <img src="@/assets/svg/copy_inverted.svg" alt="" />
              <div class="ml-3 opacity-80 font-semibold text-13">
                {{ $t('wording.copied') }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div
      class="pb-2 text-base border-grey-6"
      :class="{
        'rounded-b-3xl ': data.top,
        'rounded-3xl pt-2': !data.top,
        'lg:bg-white lg:bg-opacity-60 lg:px-6': mobileList,
        'bg-white bg-opacity-60  px-6 ': !mobileList,
        'border-t-2': data.top && !mobileList,
        'lg:border-t-2': data.top && mobileList
      }"
      v-if="data.bottom"
    >
      <div
        class="border-grey-6"
        v-for="(el, index) in data.bottom"
        v-bind:key="el.title"
        :class="{
          'border-b-2': index < data.bottom.length - 1 && !mobileList,
          'lg:border-b-2': index < data.bottom.length - 1 && mobileList,
          'py-4 ': !mobileList,
          'py-2 lg:py-4': mobileList
        }"
      >
        <div class="flex text-left justify-between">
          <div class="text-grey-3">{{ el.title }}</div>
          <div
            class="font-semibold text-right pl-4 md:pl-0"
            :class="el.copyable ? 'flex break-all' : ''"
            @click="el.copyable ? copy(el.value_copy ?? el.value) : ''"
          >
            <div :class="{ 'mr-2': el.copyable }">{{ el.value }}</div>
            <img src="@/assets/svg/copy.svg" alt="" v-if="el.copyable" />
          </div>
        </div>
        <template v-if="el.subtitle">
          <div v-for="subEl in el.subtitle" :key="subEl">
            {{ subEl.title }}
            {{ subEl.value }}
          </div>
        </template>
        <transition name="toast-fade">
          <div
            class="absolute -right-24 -mt-2 hidden md:block"
            v-if="show === el.value && el.copyable"
          >
            <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
              <img src="@/assets/svg/copy_inverted.svg" alt="" />
              <div class="ml-3 opacity-80 font-semibold text-13">
                {{ $t('wording.copied') }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <transition name="toast-fade">
      <div
        :class="isWallet ? 'bottom-30' : 'bottom-8'"
        class="absolute w-4/5 md:hidden"
        v-if="show"
      >
        <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
          <img src="@/assets/svg/copy_inverted.svg" alt="" />
          <div class="ml-3 opacity-80 font-semibold text-13">
            {{ $t('wording.copied') }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { copyText } from 'vue3-clipboard'
export default {
  components: {},
  props: {
    data: Object,
    isWallet: { type: Boolean, default: false },
    mobileList: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggleButton'],
  data() {
    return {
      openPopup: false
    }
  },
  setup() {
    const show = ref(null)

    async function copy(text) {
      copyText(text, undefined, (error) => {
        if (error) {
          console.log(error)
        } else {
          show.value = text
          setTimeout(function () {
            show.value = null
          }, 500)
        }
      })
    }

    const stateBg = ref({
      succeeded: 'bg-green-1',
      created: 'bg-grey-1',
      failed: 'pulse bg-failed'
    })

    return {
      copy,
      show,
      stateBg
    }
  }
}
</script>
<style>
.bg-failed {
  background: #e98873;
}
.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(233, 136, 115, 0.7);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(233, 136, 115, 0);
  }
}
</style>
