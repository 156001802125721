<template>
  <div class="shadow-md px-2 py-4 rounded-xl overflow-hidden relative bg-white">
    <img
      src="@/assets/img/logo/goparity.svg"
      alt=""
      class="absolute w-12 top-2 left-2"
    />
    <GiftcardChristmas />

    <div class="mb-3 text-bordeaux-light text-5xl font-medium">
      <div
        class="mb-2 w-3/5 border-b-3 h-4 border-bordeaux-light"
      >
      </div>
      <div class="font-bold flex">
        <div  class="w-1/5 border-b-3  border-bordeaux-light" />
      </div>
    </div>

  </div>
</template>
<script>
import GiftcardChristmas from './GiftcardChristmas'

export default {
  components: {
    GiftcardChristmas
  },
  setup() {
  }
}

</script>