<template>
  <div class="mb-8">
    <div class="flex w-full -mb-4">
      <img
        class="lg:hidden mr-3 -mb-2"
        src="@/assets/svg/transactions/filters.svg"
        alt="filters"
        @click="() => (showDrawer = true)"
      />
      <div class="mr-6 flex-grow">
        <GeneralInput
          v-model:data="search"
          type="text"
          name="search"
          :label="$t('contracts.search')"
        >
        </GeneralInput>
      </div>
      <div
        class="
        hidden
        lg:flex
        bg-white
        rounded-2xl
        px-8
        py-0
        cursor-pointer
        items-center
        mb-4
      "
        @click="showFiltersPopup"
      >
        <img
          src="@/assets/svg/transactions/filters_desktop.svg"
          alt="cross"
          class="mr-2 w-4"
        />
        <p class="text-grey-2 text-sm font-semibold text-opacity-80">
          {{ $t('transactions.filters.title') }}
        </p>
      </div>

    </div>
    <Drawer v-if="showDrawer" minHeight="h-5/6" @close="close">
      <template v-slot:content>
        <ContractFiltersDrawer @filter="close" :search="search"/>
      </template>
    </Drawer>
  </div>
</template>

<script>
import Drawer from '@/components/general/Drawer.vue'
import GeneralInput from '@/components/general/Input.vue'
import ContractFiltersDrawer from '@/components/contracts/ContractFiltersDrawer.vue'
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { watch } from "vue";
export default {
  components: {
    Drawer,
    ContractFiltersDrawer,
    GeneralInput
  },
  setup() {
    const showDrawer = ref(false)
    const store = useStore()
    const search = ref(null)

    const close = () => {
      showDrawer.value = false
    }

    const showFiltersPopup = () => {
      const popup = {
        side: true,
        component: 'ContractFiltersDrawer'
      }
      store.dispatch('setPopup', popup)
    }

    watch(
      () => search.value,
      async () => {
        store.dispatch('setSearch', search.value)
      }
    )

    return {
      showDrawer,
      close,
      search,
      showFiltersPopup
    }
  }
}
</script>

<style lang="scss" scoped></style>
