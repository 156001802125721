<template>
  <div class="page flex">
    <div class="lg:pt-20 container flex-grow flex flex-col justify-between">
      <transition name="fade" mode="out-in">
        <div>
          <Back
            :to="'/settings'"
            :title="$t('settings.add_phone_number.verification_code.title')"
          />
          <VerificationCode />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {} from 'vue'
import Back from '@/components/general/Back.vue'
import VerificationCode from '@/components/general/VerificationCode.vue'

export default {
  components: {
    Back,
    VerificationCode
  },
  setup() {
    return {}
  }
}
</script>

<style lang="sass" scoped></style>
