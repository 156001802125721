<template>
  <div class="relative">
    <div
      class="px-2 py-px rounded w-max rounded"
      :class="[
        bgColor,
        { pulse: project.state === 'campaign' && daysToClose <= 1 }
      ]"
    >
      <p class="text-white text-11">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
export default {
  props: {
    project: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const i18n = useI18n({ useScope: 'global' })
    const daysToClose = computed(() => {
      const date1 = new Date(props.project.closing_date.replace(/-/g, "/"))
      const date2 = new Date()
      const diffTime = (date2 - date1) * -1
      return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0)
    })

    const bgColor = computed(() => {
      if (props.project.state === 'campaign') {
        if (daysToClose.value <= 1) return 'bg-orange-2'
        else return 'bg-orange-1'
      } else if (props.project.state === 'reserved') return 'bg-green-1'
      else if (props.project.state === 'comingsoon') return 'bg-grey-1'
      else return 'bg-orange-1'
    })

    const text = computed(() => {
      if (props.project.state === 'campaign') {
        return i18n.t(
          'projects.state_options.campaign',
          { dayCount: daysToClose.value },
          daysToClose.value
        )
      } else if (props.project.state === 'funded') {
        return i18n.t(
          'projects.state_options.funded',
          { investorCount: props.project.invested.investors },
          props.project.invested.investors
        )
      }
      return i18n.t('projects.state_options.' + props.project.state)
    })

    return {
      daysToClose,
      text,
      bgColor
    }
  }
}
</script>

<style scoped>
.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(232, 174, 28, 0.7);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(232, 174, 28, 0);
  }
}
</style>
