<template>
  <router-link :to="'/loan/' + project.id">
    <div
      class="text-white rounded-3xl pt-4 px-4 bg-cover bg-center text-left pb-6"
      :style="
        project.image
          ? isSettled
            ? 'box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.4); background-image:linear-gradient(black, black), url(\'' +
              project.image.path +
              '\');background-blend-mode: saturation;'
            : 'box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 2000px inset; background-image:  url(\'' + project.image.path + '\');'
          : ''
      "
    >

      <LoanCircleSmall
        class="ml-auto"
        :amount="project.loan_status.capital_paid"
        :arrears="project.loan_status.in_arrears"
        :total="project.loan_status.loan_amount"
      />
      <div class="font-bold mb-1">
        {{ project.name }}
      </div>
      <div class="text-13 flex items-center" v-if="isSettled">
        <img src="@/assets/svg/check_white.svg" alt="" class="mt-0 mr-2" />
        <span v-html="$t('home.next_payment.settled')"> </span>
      </div>
      <div
        class="text-13 flex items-start"
        v-if="project.next_payment && !isSettled"
      >
        <img
          src="@/assets/svg/clock_yellow.svg"
          alt=""
          class="mr-2"
          v-if="isArrears"
        />
        <img src="@/assets/svg/clock.svg" alt="" class="mr-2" v-else />

        <span
          v-html="
            $t('home.next_payment.' + (isArrears ? 'arrears' : 'default'), {
              amount: dollarFormatting(project.next_payment.amount),
              daysLeft: project.next_payment.days_to_next_payment,
              arrearsDate: project.next_payment.next_payment_date
            })
          "
        >
        </span>
      </div>
    </div>
  </router-link>
</template>
<script>
import LoanCircleSmall from '@/components/promoter/LoanCircleSmall'
import { computed } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
export default {
  components: { LoanCircleSmall },

  props: {
    project: Object
  },
  setup(props) {
    const { dollarFormatting } = useGlobalHelpers()

    const isArrears = computed(() => {
      return props.project.loan_status.in_arrears > 0
    })

    const isSettled = computed(() => {
      return props.project.state === 'settled'
    })

    return { isArrears, dollarFormatting, isSettled }
  }
}
</script>
<style></style>
