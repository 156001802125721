<template>
  <div class="w-full relative">
    <div
      @click="() => (menuOpen = !menuOpen)"
      class="w-full flex items-center cursor-pointer"
    >
      <p class="text-left text-grey-4 text-sm">
        <span>
          {{ $t(data + '.title') }}
        </span>
        <span v-if="selectedOption" class="ml-1 text-grey-2">{{
          $tm(data + '.options.' + selectedOption)
        }}</span>
      </p>
      <img
        src="@/assets/svg/arrows/right_grey_arrow_dark.svg"
        alt="down"
        class="transform rotate-90 ml-1"
      />
    </div>
    <div
      v-if="menuOpen"
      class="absolute right-0 w-4/5 py-4 bg-white rounded-2xl"
    >
      <GeneralInput
        v-model:data="selectedOption"
        noLines
        type="radio"
        name="sort"
        :options="$tm(data + '.options')"
        @click="sort"
      />
      <div class="w-full h-px bg-grey-7" />
      <p
        @click="clear"
        class="font-semibold text-sm text-orange-1 mt-4 cursor-pointer"
      >
        {{ $t('wording.clear') }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import GeneralInput from '@/components/general/Input.vue'
export default {
  components: {
    GeneralInput
  },
  props: {
    data: {
      type: String,
      default: ''
    }
  },
  emits: ['sort'],
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const menuOpen = ref(false)
    const selectedOption = ref(null)

    const sort = () => {
      menuOpen.value = false
      emit('sort', selectedOption.value)
    }

    const clear = () => {
      selectedOption.value = null
      sort()
    }

    return {
      menuOpen,
      selectedOption,
      sort,
      clear
    }
  }
}
</script>

<style lang="scss" scoped></style>
