import axios from 'axios'

export default {
  state: {
    impact: {}
  },
  mutations: {
    SET_IMPACT(state, impact) {
      state.impact = impact
    }
  },
  actions: {
    fetchImpact({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/impact')
          .then((response) => {
            commit('SET_IMPACT', response.data)
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  },
  getters: {}
}
