<template>
  <div class="page flex flex-col items-end">
    <SideBar>
      <p class="text-left text-grey-2 text-xl lg:text-3xl w-80 mx-auto">
        {{ $t('strategies.setup.title') }}
      </p>
    </SideBar>
    <img
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div class="strategies__desktop-container justify-between">
      <div class="lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <div>
          <Back to="/dashboard" title="" class="hidden lg:flex" />
          <div class="lg:hidden w-full flex justify-between">
            <img
              src="@/assets/svg/strategies/strategies.svg"
              alt="strategies"
            />
          </div>
          <p
            class="
              text-left text-grey-2 text-xl
              lg:text-3xl lg:hidden
              my-4
              lg:my-0
            "
          >
            {{ $t('strategies.setup.title') }}
          </p>
          <p class="hidden lg:block text-left mb-9 text-xl font-semibold">
            {{ $t('strategies.setup.lg_extra_text') }}
          </p>
          <div
            class="
              text-left text-grey-2
              opacity-60
              text-sm
              lg:text-base
              indented-custom-bullet
            "
            v-html="$t('strategies.setup.text')"
          />
        </div>
      </div>
      <div class="lg:flex lg:justify-end lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <GpButton @click="newStrategy" desktopWidth="w-1/2" class="ml-auto">
          {{ $t('strategies.setup.btn_first') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
import Back from '@/components/general/Back.vue'
import SideBar from '@/components/general/SideBar.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {
    GpButton,
    Back,
    SideBar
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const newStrategy = () => {
      store.dispatch('resetNewStrategy')
      router.push('/strategies/topup')
    }

    return {
      newStrategy
    }
  }
}
</script>
