import { VueCookieNext } from 'vue-cookie-next'

export default function auth({ to, next, router }) {
  if (!VueCookieNext.isCookieAvailable('token')) {
    if(!window.localStorage.getItem('clicked_logout'))
      window.localStorage.setItem('path', to.path)
    else
      window.localStorage.removeItem('clicked_logout')
    return router.replace('/login')
  }

  if (to.name === 'home') {
    return router.replace('/dashboard')
  }

  if(VueCookieNext.isCookieAvailable('profile') && (VueCookieNext.getCookie('profile').surname === 'particular' || VueCookieNext.getCookie('profile').surname === 'company') && to.name !== 'onboarding'){
    return router.replace('/onboarding')
  }

  if (VueCookieNext.isCookieAvailable('profile') && VueCookieNext.getCookie('profile').surname !== 'particular' && VueCookieNext.getCookie('profile').surname !== 'company' && to.name === 'onboarding' && (!to.query || (to.query && to.query.step !== 'KYC'))) {
    return router.replace('/dashboard')
  }

  return next()
}
