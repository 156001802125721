import { VueCookieNext } from 'vue-cookie-next'
import axios from 'axios'
import store from '../index'

export default {
  state: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-empty-pattern
    createPhone({}, phone_data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/phones',
            phone_data
          )
          .then(async (data) => {
            await store.dispatch('fetchProfile')
            resolve(data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    checkVerificationCode({ commit }, data) {
      return axios.post(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/phones/verification',
        data
      )
    },
    // eslint-disable-next-line no-unused-vars
    sendSms({ commit }, data) {
      return axios.post(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/phones/sms',
        data
      )
      .then((data) => {
        VueCookieNext.setCookie('profile', data.data, {
          path: '/',
          domain: '',
          secure: true
        })
      }).catch((e)=>{
        store.dispatch('setError', e)
        store.dispatch('setLoading', false)
      })
    }
  }
}
