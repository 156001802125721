export default function () {

  const levelRisk = (risk) => {
    if (risk === 0) return 'A+'
    else if (risk === 11.1) return 'A'
    else if (risk === 22.2) return 'A-'
    else if (risk === 33.3) return 'B+'
    else if (risk === 44.4) return 'B'
    else if (risk === 55.5) return 'B-'
    else if (risk === 66.6) return 'C+'
    else if (risk === 77.7) return 'C'
    else if (risk === 88.8) return 'C-'
    else return 'D'
  }

  const levelRiskFinal = (risk) => {
    if (risk === 10) return 'A+'
    else if (risk === 9) return 'A'
    else if (risk === 8) return 'A-'
    else if (risk === 7) return 'B+'
    else if (risk === 6) return 'B'
    else if (risk === 5) return 'B-'
    else if (risk === 4) return 'C+'
    else if (risk === 3) return 'C'
    else if (risk === 2) return 'C-'
    else return 'D'
  }

  const risktoSliderConversion = (risk) => {
    const conversion= {
      1: 99.9,
      2: 88.8,
      3: 77.7,
      4: 66.6,
      5: 55.5,
      6: 44.4,
      7: 33.3,
      8: 22.2,
      9: 11.1,
      10: 0
    }
    return conversion[risk]
  }

  const valueRisk = (level) => {
    if (level === 'A+') return 10
    else if (level === 'A') return 9
    else if (level === 'A-') return 8
    else if (level === 'B+') return 7
    else if (level === 'B') return 6
    else if (level === 'B-') return 5
    else if (level === 'C+') return 4
    else if (level === 'C') return 3
    else if (level === 'C-') return 2
    else if (level === 'D') return 1
    else return null
  }

  const colorRisk = (risk) => {
    if (risk <= 44.4) return 'text-green-3'
    else if (risk === 55.5) return 'text-green-sidebar-confirmation'
    else if (risk === 66.6) return 'text-yellow-light'
    else if (risk === 77.7) return 'text-yellow-primary'
    else if (risk === 88.8) return 'text-yellow-dark'
    else return 'text-red-primary'
  }

  const interestRisk = (risk) => {
    if (risk <= 44.4) return 4
    else if (risk === 55.5) return 4
    else if (risk === 66.6) return 5
    else if (risk === 77.7) return 6
    else if (risk === 88.8) return 7
    else return 8
  }

  return {
    colorRisk,
    interestRisk,
    levelRisk,
    valueRisk,
    levelRiskFinal,
    risktoSliderConversion
  }
}
