<template>
  <div class="project-card project-card--skeleton h-20">
    <div
      class="
        project-card__header project-card__header--skeleton
        h-full
        rounded-3xl
      "
    ></div>
  </div>
</template>

<script>
export default {
  components: {},
  setup() {}
}
</script>
