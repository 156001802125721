<template>
  <div class="page-no-nav flex">
    <SuccessCard
      v-if="hasChanges"
      :title="$t('settings.discard_changes.title')"
      :text="$t('settings.discard_changes.content')"
      :closeable="false"
    >
      <template #icon>
        <img
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="exclamation icon"
        />
      </template>
      <template #button>
        <div class="lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto"
            desktopWidth="w-full"
            @click="discard"
          >
            {{ $t('wording.discard_changes') }}
          </GpButton>
          <GpButton @click="cancel" class="mx-auto" desktopWidth="w-full">
            {{ $t('wording.no_go_back') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div
      v-else
      class="lg:pt-20 container flex-grow min-p-button relative justify-between"
    >
      <div v-if="legalRepresentative" class="flex flex-col justify-between">
        <div @click="checkChanges">
          <Back :to="null" :title="$t('settings.legal_representative.title')" />
        </div>
        <div v-if="kycsConditions" class="flex flex-col justify-between">
          <div class="flex text-left text-grey-3 mt-6 lg:mt-2 mb-6 text-sm">
            {{
              $t(
                'settings.personal_details.status.' +
                  kycsConditions.localesPath +
                  '.text'
              )
            }}
            <router-link
              v-if="
                kycsConditions.localesPath !== 'validation_asked' &&
                kycsConditions.localesPath !== 'validated'
              "
              to="/dashboard/kyc"
              class="flex orange-link text-xs cursor-pointer ml-1"
            >
              {{
                $t(
                  'settings.personal_details.status.' +
                    kycsConditions.localesPath +
                    '.btn'
                )
              }}
            </router-link>
          </div>
        </div>
        <Form class="form" ref="form" v-slot="{ errors, meta }">
          <GeneralInput
            v-model:data="legalRepresentative.name"
            type="text"
            name="names"
            :label="$t('settings.legal_representative.first_name')"
            :locked="kycSubmitted"
            :noNumbersOrSymbols="true"
            :error="errors.names"
            rules="required|alphabet|maxLength:60"
          />
          <GeneralInput
            v-model:data="legalRepresentative.surname"
            type="text"
            name="surnames"
            :label="$t('settings.legal_representative.last_name')"
            :locked="kycSubmitted"
            :noNumbersOrSymbols="true"
            :error="errors.surnames"
            rules="required|alphabet|maxLength:60"
          />

          <GeneralInput
            v-model:data="legalRepresentative.date_of_birth"
            type="date"
            :fromDate="from_more_1_day"
            :toDate="to"
            name="date_of_birth"
            :label="$t('settings.legal_representative.date_of_birth')"
            :locked="true"
            :required="true"
          />

          <GeneralInput
            v-model:data="legalRepresentative.legal_country"
            type="countries"
            name="country"
            :label="$t('settings.legal_representative.country_of_residence')"
            :locked="true"
            :required="true"
          />

          <GeneralInput
            v-model:data="legalRepresentative.address"
            type="text"
            name="address"
            :label="$t('settings.legal_representative.address')"
            :addressSymbols="true"
            rules="required|address|maxLength:80"
            :error="errors.address"
            :autocomplete="true"
          />

          <GeneralInput
            v-model:data="legalRepresentative.city"
            type="text"
            name="city"
            :label="$t('settings.legal_representative.city')"
            rules="required|alphabet|maxLength:25"
            :error="errors.city"
            :noNumbersOrSymbols="true"
          />

          <GeneralInput
            v-model:data="legalRepresentative.post_code"
            type="tel"
            name="post_code"
            :label="$t('settings.legal_representative.zipCode')"
            :country="legalRepresentative.country"
            rules="required|exactLength:6"
            :locked="true"
            :error="errors.post_code"
          />
          
          <div
            class="
              absolute
              bottom-0
              w-screen
              container
              left-1/2
              -translate-x-1/2
              transform
            "
          >
            <div class="w-full relative">
              <GpButton
                :disabled="!(isDirty || meta.dirty) || !meta.valid"
                @click="updateDetails"
                class="ml-auto"
              >
                {{ $t('wording.save') }}
              </GpButton>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { Form } from 'vee-validate'
import useRules from '@/assets/js/rules'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GeneralInput from '@/components/general/Input.vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'

export default {
  components: {
    GeneralInput,
    Back,
    Form,
    GpButton,
    SuccessCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { isRequired } = useRules()
    const { prettierDate } = useGlobalHelpers()
    const i18n = useI18n()
    const form = ref(null)
    const dateOfBirth = ref(null)
    const now = new Date(Date.now())
    const nowYear = now.getFullYear()
    const from = new Date(now.setFullYear(nowYear - 120))
    const from_more_1_day = new Date(from)
    from_more_1_day.setDate(from_more_1_day.getDate() + 1)
    const to = new Date(now.setFullYear(nowYear - 18))
    const citizenship = ref(null)
    const legalCountry = ref(null)
    const loading = computed(() => {
      return store.state.general.loading
    })
    const initialProfile = ref(null)
    const hasChanges = ref(null)

    let legalRepresentative = computed(() => {
      return store.getters.getLegalRepresentative()
    })

    const representatives = computed(() => {
      return store.state.representatives.representatives
    })

    const kycsConditions = computed(() => {
      return store.state.kycs.conditions
    })

    onMounted(async () => {
      if (!store.state.kycs.kycs) {
        store.dispatch('setLoading', true)

        await store.dispatch('getKycs')
      }
      if (!kycsConditions.value) {
        store.dispatch('setLoading', true)

        const conditions = await store.getters.getKycsConditions()
        await store.dispatch('setKycsConditions', conditions)
      }
      if (representatives.value.length === 0) {
        store.dispatch('setLoading', false)

        await store.dispatch('fetchRepresentatives')
      }
      dateOfBirth.value = legalRepresentative.value.date_of_birth
      citizenship.value = legalRepresentative.value.country_of_birth
      legalCountry.value = legalRepresentative.value.legal_country
      initialProfile.value = JSON.parse(
        JSON.stringify(legalRepresentative.value)
      )
      store.dispatch('setLoading', false)
    })

    const kycSubmitted = computed(() => {
      if (kycsConditions.value) {
        return ['validation_asked', 'validated', 'document_failed'].includes(
          kycsConditions.value.localesPath
        )
      } else {
        return false
      }
    })

    const kycRefused = computed(() => {
      if (kycsConditions.value) {
        return ['document_failed'].includes(kycsConditions.value.localesPath)
      } else {
        return false
      }
    })

    const validationAsked = computed(() => {
      if (kycsConditions.value) {
        return ['validation_asked'].includes(kycsConditions.value.localesPath)
      } else {
        return false
      }
    })


    const isDirty = computed(() => {
      return (
        JSON.stringify(legalRepresentative.value) !==
        JSON.stringify(initialProfile.value)
      )
    })

    const updateDetails = () => {
      store.dispatch('setLoading', true)
      legalRepresentative.value.date_of_birth = prettierDate(
        legalRepresentative.value.date_of_birth
      )
      store
        .dispatch('updateRepresentative', {
          ...legalRepresentative.value,
          type: 'company',
          citizenship: legalRepresentative.value.country_of_birth
        })
        .then(() => {
          store.dispatch('setLoading', false)

          form.value.resetForm()
          const popup = {
            title: i18n.t('settings.legal_representative.success_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            content: i18n.t(
              'settings.legal_representative.success_popup.description'
            ),
            primaryButton: i18n.t(
              'settings.legal_representative.success_popup.btn'
            )
          }
          store.dispatch('setPopup', popup)
          router.push({ name: 'settings' })
        })
    }

    const validate = () => {
      //TODO
    }

    const checkChanges = () => {
      if (isDirty.value === true) {
        hasChanges.value = true
      } else {
        router.go(-1)
      }
    }

    const discard = () => {
      router.go(-1)
    }

    const cancel = () => {
      hasChanges.value = false
    }

    return {
      kycSubmitted,
      validationAsked,
      kycRefused,
      legalRepresentative,
      representatives,
      kycsConditions,
      isRequired,
      updateDetails,
      form,
      isDirty,
      loading,
      hasChanges,
      validate,
      checkChanges,
      discard,
      cancel,
      from,
      from_more_1_day,
      to,
      now,
      nowYear
    }
  }
}
</script>

<style lang="sass" scoped></style>
