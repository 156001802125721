<template>
  <div class="cursor-pointer flex-col">
    <div
      class="
        flex
        justify-between
        items-center
        my-6
        lg:bg-white lg:rounded-3xl lg:p-4
        transition
        ease-in-out
        duration-200
        transform
        hover:shadow-light hover:-translate-y-1
      "
    >
      <div class="flex items-center">
        <img
          :src="require('../../assets/svg/settings/' + icon + '.svg')"
          :alt="icon"
          class="mr-4 lg:hidden"
        />
        <img
          :src="require('../../assets/svg/settings/' + icon + '_border.svg')"
          :alt="icon"
          class="mr-4 hidden lg:block"
        />
        <p class="lg:text-base text-grey-3 font-normal lg:font-bold text-left">
          {{ text }}
          <br />
          <span :class="subtitleColor" class="text-xs font-normal">
            {{ subtitle }}
          </span>
        </p>
      </div>
      <div
        v-if="tagLabel !== ''"
        :class="tagColor"
        class="lg:mr-4 px-4 py-1 rounded w-max"
      >
        <p class="text-white text-xs lg:text-sm">{{ tagLabel }}</p>
      </div>
      <div v-else>
        <img
          src="../../assets/svg/arrows/right_grey_arrow.svg"
          alt="right_arrow"
          class="lg:hidden"
        />
        <img
          src="../../assets/svg/arrows/right-grey-lighter-arrow.svg"
          alt="right_arrow"
          class="hidden lg:block lg:h-5"
        />
      </div>
    </div>
    <div class="w-full h-0.5 bg-grey-2 bg-opacity-10 lg:hidden" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    tagLabel: {
      type: String,
      default: ''
    },
    tagColor: {
      type: String,
      default: 'bg-grey-4'
    },
    subtitleColor: {
      type: String,
      default: 'text-grey-4'
    }
  },
  setup() {
    return {}
  }
}
</script>

<style lang="sass" scoped></style>
