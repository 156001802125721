import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";

const questions:PageForm =
  {
    title: i18n.global.t('ca_legislation.onboarding.sidebar_title'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.onboarding.questions_company.title_questions_company2"),
        fields: [
            {
              key: "region",
              title: i18n.global.t("ca_legislation.onboarding.questions.region_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.region_text"),
              options: i18n.global.tm("onboarding.fields.provsBorrower"),
              type: Types.RADIO,
              rules: 'required'
            },
            {
              key: "address",
              title: i18n.global.t("ca_legislation.onboarding.questions_company.address_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.address_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "post_code",
              title: i18n.global.t("ca_legislation.onboarding.questions_company.post_code_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.post_code_text"),
              type: Types.STRING,
              rules: 'required|exactLength:6'
            },
            {
              key: "city",
              title: i18n.global.t("ca_legislation.onboarding.questions_company.city_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.city_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "business_website",
              title: i18n.global.t("ca_legislation.onboarding.questions_company.website_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.website_text"),
              type: Types.STRING,
              rules: 'required'
            },
            {
              key: "phone",
              title: i18n.global.t("ca_legislation.onboarding.questions_company.phone_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions_company.phone_text"),
              type: Types.NUMBER,
              rules: 'required|exactLength:10'
            }
        ],
        next: true,
        back: true
      }
    ]
  }

export default questions
