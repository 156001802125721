<template>
  <div class="page flex flex-col items-end">
    <SideBar v-if="offer && mainWallet">
      <ProjectData :project="offer" mkt />
    </SideBar>
    <div v-if="offer && mainWallet" class="strategies__desktop-container">
      <!-- Popup -->
      <div class="hidden">
        <div class="text-left" id="popup-content-mkt-purchase">
          <img
            class="mx-auto mb-6"
            src="/img/popup/success_green.svg"
            alt="check"
          />
          <p class="font-medium text-xl text-grey-2 mb-6">
            {{
              $t(
                'mkt.purchase.popup_title',
                {
                  seller: seller(offer.seller_name),
                  project: offer.project_name
                },
                seller(offer.seller_name),
                offer.project_name
              )
            }}
          </p>
          <p
            class="text-sm text-grey-3 unindented-custom-bullet"
            v-html="$t('mkt.purchase.popup_text')"
          />
        </div>
      </div>
      <div class="flex-grow flex flex-col lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <Back :to="-1" :title="$t('mkt.purchase.title')" />
        <!-- Table top -->
        <div class="pt-6 pb-4 px-4 bg-white rounded-t-2xl">
          <p class="text-left text-sm font-semibold text-grey-3 mb-4 lg:hidden">
            {{ offer.project_name }}
          </p>
          <div class="flex items-center justify-between text-sm mb-4 lg:mb-0">
            <p class="text-grey-3">{{ $t('mkt.purchase.amount') }}</p>
            <p class="text-grey-2 font-semibold">
              ${{ dollarFormatting(offer.offer_amount) }}
            </p>
          </div>
          <div class="flex items-center justify-between text-sm mb-4 lg:hidden">
            <p class="text-grey-3">{{ $t('mkt.purchase.interest') }}</p>
            <p class="text-grey-2 font-semibold">
              {{ offer.offer_conditions.interest_investor }}%
            </p>
          </div>
          <div class="flex items-center justify-between text-sm mb-4 lg:hidden">
            <p class="text-grey-3">{{ $t('mkt.purchase.periodicity') }}</p>
            <p class="text-grey-2 font-semibold">
              {{
                $t('wording.periodicity.' + offer.offer_conditions.periodicity)
              }}
            </p>
          </div>
          <div class="flex items-center justify-between text-sm mb-4 lg:hidden">
            <p class="text-grey-3">{{ $t('mkt.purchase.term') }}</p>
            <p class="text-grey-2 font-semibold">
              {{
                $t(
                  'wording.month',
                  { n: offer.offer_conditions.duration_months },
                  offer.offer_conditions.duration_months
                )
              }}
            </p>
          </div>
          <div class="flex items-center justify-between text-sm mb-4 lg:hidden">
            <p class="text-grey-3">{{ $t('mkt.purchase.grace_period') }}</p>
            <p class="text-grey-2 font-semibold">
              {{
                $t(
                  'wording.month',
                  { n: offer.offer_conditions.grace_period_months },
                  offer.offer_conditions.grace_period_months
                )
              }}
            </p>
          </div>
          <div class="flex items-center justify-between text-sm mb-4 lg:hidden">
            <p class="text-grey-3">{{ $t('mkt.purchase.risk') }}</p>
            <p class="text-grey-2 font-semibold">
              {{ riskConversion(offer.risk) }}
            </p>
          </div>
        </div>
        <!-- Table bottom -->
        <div class="pb-6 pt-4 px-4 bg-white rounded-b-2xl bg-opacity-60 mb-4">
          <div class="flex items-center justify-between text-sm">
            <p class="text-grey-3">{{ $t('mkt.purchase.balance_after') }}</p>
            <p class="text-grey-2 font-semibold">
              ${{
                (hasMoney ? '' : '-') +
                dollarFormatting(mainWallet.balance - offer.offer_amount)
              }}
            </p>
          </div>
        </div>
        <GeneralInput
          v-model:data="transferAgreement"
          type="checkbox"
          name="transferAgreement"
        >
          <template #label>
            {{ $t('mkt.purchase.transfer_agreement_label') }}
            <a
              @click="downloadConfirmationPDF"
              class="orange-link"
              target="_blank"
            >
              {{ $t('mkt.purchase.transfer_agreement_link') }}
            </a>
          </template>
        </GeneralInput>
        <GeneralInput v-model:data="kiis" type="checkbox" name="kiis">
          <template #label>
            {{ $t('mkt.purchase.kiis_label') }}
            <a :href="offer.links.ififc" class="orange-link" target="_blank">
              {{ $t('mkt.purchase.kiis_link') }}
            </a>
          </template>
        </GeneralInput>
        <GeneralInput v-model:data="maxInvest" type="checkbox" name="kiis">
          <template #label>
            {{ $t('mkt.purchase.max_invest_label') }}
            <a
              class="orange-link"
              target="_blank"
              :href="`https://goparity.ca/${locale}/faqs/14`"
              >{{ $t('mkt.purchase.max_invest_link') }}</a
            >
          </template>
        </GeneralInput>
      </div>
      <div
        class="
          w-full
          lg:flex lg:items-center lg:justify-between lg:w-3/4
          xl:w-2/3
          2xl:w-1/2
        "
      >
        <GpButton color="white" @click="cancel" class="mb-4 lg:mb-0">
          {{ $t('wording.cancel') }}
        </GpButton>
        <GpButton
          v-if="hasMoney"
          :disabled="!allChecked || pressed"
          @click="purchase"
        >
          {{ $t('wording.purchase') }}
        </GpButton>
        <GpButton v-else @click="() => {}" color="grey">
          {{ $t('mkt.purchase.topup') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import Back from '@/components/general/Back.vue'
import ProjectData from '@/components/invest/ProjectData.vue'
import SideBar from '@/components/general/SideBar.vue'
import GeneralInput from '@/components/general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useMarketplace from '@/mixins/useMarketplace.js'

export default {
  components: {
    Back,
    GeneralInput,
    GpButton,
    ProjectData,
    SideBar
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { dollarFormatting, riskConversion } = useGlobalHelpers()
    const { seller } = useMarketplace()
    const { locale } = useI18n({ useScope: 'global' })
    const transferAgreement = ref(false)
    const kiis = ref(false)
    const maxInvest = ref(false)
    const pressed = ref(false)

    onBeforeMount(async () => {
      if (!offer.value)
        await store.dispatch('fetchOffer', route.params.id.split('-').pop())
      if (!store.state.wallets.wallets) await store.dispatch('fetchWallets')
    })

    const offer = computed(() => {
      return store.state.offers.offer
    })

    const mainWallet = computed(() => {
      return store.getters.getMainWallet()
    })

    const hasMoney = computed(() => {
      return mainWallet.value.balance >= offer.value.offer_amount
    })

    const allChecked = computed(() => {
      return transferAgreement.value && kiis.value && maxInvest.value
    })

    const cancel = () => {
      router.go(-1)
    }

    const downloadConfirmationPDF = () => {
      if(offer.value.id && offer.value.project_name) {
        axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/offers/' +
              parseInt(offer.value.id) +
              '/purchase-draft-offer',
            { responseType: 'arraybuffer' }
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = offer.value.project_name + 'purchase_draft_offer.pdf'
            link.click()
          })
          .catch((e) => {
            store.dispatch('setError', e)
          })
      }
    }

    const purchase = () => {
      pressed.value = true
      const params = {
        term_agreement_checkbox: transferAgreement.value,
        ififc_checkbox: kiis.value,
        '12months_declaration_checkbox': maxInvest.value
      }
      axios
        .put(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/offers/' +
            offer.value.id,
          params
        )
        .then(async () => {
          const popup = {
            align: 'center',
            side: false,
            content: document.getElementById('popup-content-mkt-purchase')
              .outerHTML
          }

          await store.dispatch('setPopup', popup)
          router.push('/contracts')
        })
        .catch((e) => {
          pressed.value = false
          store.dispatch('setError', e)
        })
    }

    return {
      offer,
      dollarFormatting,
      riskConversion,
      mainWallet,
      transferAgreement,
      kiis,
      maxInvest,
      cancel,
      allChecked,
      purchase,
      seller,
      hasMoney,
      pressed,
      locale,
      downloadConfirmationPDF
    }
  }
}
</script>

<style lang="scss" scoped></style>
