<template>
  <div
    class="
      text-left text-sm text-grey-3
      border
      p-4
      rounded-3xl
      flex
      items-start
    "
    v-show="ddWarning !== false"
  >
    <img src="@/assets/svg/waiting-orange.svg" alt="" class="mr-2 w-4" />
    <div>
      <div class="font-bold mb-2">{{ $t('promoter.warning.title') }}</div>
      <div>{{ $t('promoter.warning.content') }}</div>
    </div>
    <img
      src="@/assets/svg/cross.svg"
      alt=""
      class="w-4 cursor-pointer"
      @click="dismiss"
    />
  </div>
</template>
<script>
import { inject, ref } from 'vue'

export default {
  props: {},
  setup() {
    const cookies = inject('vue-cookie')
    const ddWarning = ref(!cookies.isCookieAvailable('dd-warning'))
    const dismiss = () => {
      cookies.setCookie('dd-warning', false)
      ddWarning.value = false
    }
    return { dismiss, ddWarning }
  }
}
</script>
