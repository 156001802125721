import i18n from '@/i18n'
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import {get_type} from "@/assets/ts/suitability/callbacks/update_suitability";
const order_exp = ['little_exp', 'new_investor', 'some_exp', 'well_versed'];
const order_instruments = ['alternative_investments', 'bonds', 'etfs', 'mutual_funds', 'real_estate', 'stocks', 'term_deposits', 'none'];
const goal_org = ['capital_growth', 'passive_income', 'save_money','speculation'];
const goal_indv = ['capital_growth', 'passive_income', 'save_money','speculation'];

const education_market:PageForm = {
  name: i18n.global.t('ca_legislation.suitability.side_panel.names.education_market'),
  title: i18n.global.t('ca_legislation.suitability.side_panel.titles.education_market'),
  form: [
    {
      title: i18n.global.t('ca_legislation.suitability.education_market.title'),
      fields: [
        {
          title: i18n.global.t('ca_legislation.suitability.academic_qualifications.title'),
          subtitle:  i18n.global.t('ca_legislation.suitability.academic_qualifications.subtitle'),
          key: "investing_exp",
          value: "",
          options: order_exp.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.academic_qualifications.options")[key]};
          }, {}),
          rules: "required",
          type: Types.RADIO
        },
        {
          title: i18n.global.t('ca_legislation.suitability.goal.title'),
          subtitle:  i18n.global.t('ca_legislation.suitability.goal.subtitle'),
          key: "goal",
          value: "",
          options: goal_indv.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.goal.options_indv")[key]};
          }, {}),
          type: Types.RADIO,
          condition: {
            key: 'type',
            value: 'particular'
          }
        },
        {
          title: i18n.global.t('ca_legislation.suitability.goal.title'),
          subtitle:  i18n.global.t('ca_legislation.suitability.goal.subtitle'),
          key: "goal",
          value: "",
          options: goal_org.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.goal.options_org")[key]};
          }, {}),
          type: Types.RADIO,
          condition: {
            key: 'type',
            value: 'company'
          }
        },
        {
          title: i18n.global.t('ca_legislation.suitability.instruments.title'),
          subtitle:  i18n.global.t('ca_legislation.suitability.instruments.subtitle'),
          key: "previous_investments",
          value: "",
          options: order_instruments.reduce((obj, key) => {
            // @ts-ignore
            return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.instruments.options")[key]};
          }, {}),
          rules: "required",
          type: Types.CHECKBOX_GROUP
        },
      ],
      next: true,
      mountedCallback: get_type,
      back: true
    }
  ]
}

export default education_market
