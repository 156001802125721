<template>
  <div class="page flex">
    <div
      class="lg:pt-20 container flex-grow min-p-button relative"
      :class="updateSuccess ? 'justify-center' : 'justify-between'"
    >
      <div v-if="beneficiaries">
        <Back
          :to="'/settings'"
          :title="$t('settings.beneficiary_information.title')"
          :subtitle="
            kycStatusCopy
              ? $t(
                  'settings.beneficiary_information.kyc_subtitle.' +
                    kycStatusCopy
                )
              : null
          "
        />
        <SettingsBeneficiaryElement
          v-for="(beneficiary, index) of beneficiaries"
          :key="beneficiary.id"
          :beneficiary="beneficiary"
          :index="index"
          @click="getBeneficiaryDetails(beneficiary)"
        />
        <!-- Buttons available if KYC not submited -->
        <div v-if="kycNotSubmitted && beneficiaries.length < 4" class="mt-10">
          <div
            class="
              absolute
              bottom-0
              w-screen
              container
              left-1/2
              -translate-x-1/2
              transform
            "
          >
            <div class="w-full relative">
              <div class="md:flex absolute bottom-8 right-0 w-full md:w-auto">
                <GpButton
                  @click="addBeneficiary"
                  color="white"
                  class="mr-4 mb-4 md:mb-0"
                  mobile-width="w-full"
                >
                  {{ $t('settings.beneficiary_information.btn_add') }}
                </GpButton>
                <GpButton @click="confirmBeneficiary" mobile-width="w-full">
                  {{ $t('settings.beneficiary_information.btn_confirm') }}
                </GpButton>
              </div>
            </div>
          </div>
        </div>
        <!-- Text on KYC validated  and under validation (to be confirmed)-->
        <div
          v-if="!kycNotSubmitted"
          class="text-left text-grey-3 mt-9 lg:mt-4 mb-6 text-sm"
          v-html="$t('settings.beneficiary_information.update_info')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Back from '@/components/general/Back.vue'
import SettingsBeneficiaryElement from '@/components/settings/BeneficiaryElement.vue'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    Back,
    SettingsBeneficiaryElement,
    GpButton
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const updateSuccess = ref(false)

    const beneficiaries = computed(() => {
      return store.getters.getBeneficiaries()
    })

    const representatives = computed(() => {
      return store.state.representatives.representatives
    })

    const kycsConditions = computed(() => {
      return store.state.kycs.conditions
    })

    onMounted(async () => {
      if (!store.state.kycs.kycs) {
        store.dispatch('setLoading', true)
        await store.dispatch('getKycs')
      }
      if (!kycsConditions.value) {
        store.dispatch('setLoading', true)

        const conditions = await store.getters.getKycsConditions()
        await store.dispatch('setKycsConditions', conditions)
      }
      if (
        !kycsConditions.value ||
        (kycsConditions.value && !kycsConditions.value.length)
      ) {
        await store.getters.getKycsConditions()
      }
      if (representatives.value.length === 0) {
        store.dispatch('setLoading', true)

        await store.dispatch('fetchRepresentatives')
      }
      store.dispatch('setLoading', false)
    })

    const kycNotSubmitted = computed(() => {
      if (kycsConditions.value) {
        return ['kyc_not_submitted'].includes(kycsConditions.value.localesPath)
      } else {
        return false
      }
    })

    let kycStatusCopy = computed(() => {
      if (kycsConditions.value.localesPath === 'validated') {
        return null
      }
      return kycsConditions.value ? kycsConditions.value.localesPath : ''
    })

    const addBeneficiary = () => {
      router.push({
        path: '/settings/add-beneficiary'
      })
    }

    const confirmBeneficiary = () => {
      updateSuccess.value = true
    }

    const getBeneficiaryDetails = (beneficiary) => {
      router.push({
        name: 'settings-beneficiary-details',
        params: { beneficiary: JSON.stringify(beneficiary) }
      })
    }

    return {
      beneficiaries,
      representatives,
      kycsConditions,
      kycNotSubmitted,
      addBeneficiary,
      confirmBeneficiary,
      updateSuccess,
      getBeneficiaryDetails,
      kycStatusCopy
    }
  }
}
</script>

<style lang="sass" scoped></style>
