import axios from 'axios'
import useGlobalHelpers from "@/mixins/useGlobalHelpers";

export default {
  state: {
    rank: [],
    vouchers: [],
    usedVouchers: null,
    pendingVouchers: null,
    vouchersSample: null,
    code: null
  },
  mutations: {
    SET_RANK(state, rank) {
      state.rank = rank
    },
    SET_VOUCHERS(state, vouchers) {
      state.vouchers = vouchers
    },
    SET_VOUCHERS_SAMPLE(state, vouchersSample) {
      state.vouchersSample = vouchersSample
    },
    SET_USED_VOUCHERS(state, usedVouchers) {
      state.usedVouchers = usedVouchers
    },
    SET_PENDING_VOUCHERS(state, pendingVouchers) {
      state.pendingVouchers = pendingVouchers
    },
    SET_CODE(state, code) {
      state.code = code
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchVouchers({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/vouchers', {
          params: {
            state_in: 'active, used, expired',
            profile_used_codes: true,
            _limit: 50,
          }
        })
        .then(({ data }) => {
          commit('SET_VOUCHERS', data)
        })
    },
    getRanks({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/rankings/self')
        .then(({ data }) => {
          commit('SET_RANK', data)
        })
    },
    getVouchers({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/vouchers', {
          params: {
            _limit: 1000,
            _names: true
          }
        })
        .then(({ data }) => {
          commit('SET_VOUCHERS', data)
        })
    },
    getUsedVouchers({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/vouchers', {
          params: {
            state: 'used',
            _limit: 1000,
            _names: true
          }
        })
        .then(({ data }) => {
          commit('SET_USED_VOUCHERS', data)
        })
    },
    getPendingVouchers({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/vouchers', {
          params: {
            state: 'active',
            _limit: 1000,
            _names: true
          }
        })
        .then(({ data }) => {
          commit('SET_PENDING_VOUCHERS', data)
        })
    },
    getVouchersSample({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/vouchers', {
          params: {
            _limit: 2,
            _names: true,
            _total: true,
            _mgm_value: true
          }
        })
        .then(({ data }) => {
          commit('SET_VOUCHERS_SAMPLE', data)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    remindUser({}, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/vouchers/' + id)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    getCode({ commit }, code) {
      // MISSING CAMPAIGNS ENDPOINT AND CONTROLLER TO GET CODE
      commit('SET_CODE', code)
    },

    // eslint-disable-next-line no-empty-pattern
    redeemVoucher({}, code) {
      return axios
        .put(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/vouchers', {
          code
        })
        .then(({ data }) => {
          return data
        })
    }
  },
  getters: {
    getMgmValue: (state) => () => {
        const {dollarFormatting} = useGlobalHelpers()
        return state.vouchersSample ? dollarFormatting(state.vouchersSample.mgm_value) : 0
    }
  }
}
