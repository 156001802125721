import axios from 'axios'
export default {
  state: {
    offer: null
  },
  mutations: {
    SET_OFFER(state, data) {
      state.offer = data
    }
  },
  actions: {
    fetchOffer({ commit }, offerId) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/offers/' + offerId)
        .then((data) => {
          commit('SET_OFFER', data.data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    }
  },
  getters: {}
}
