<template>
  <div class="page container">
    <span class="md:block md:pt-28">
      <Back />
    </span>
    <div
      class="
        text-lg text-grey-2
        md:text-xl md:text-grey-1
        font-bold
        text-left
        absolute
        md:relative md:top-auto md:left-auto
        top-5
        left-12
      "
    >
    <div v-if="isBorrower">{{ $t('invest.list.borrower_title') }}</div>
    <div v-else>{{ $t('invest.list.title') }}</div>
      
    </div>
    <div class="mb-2 justify-between items-end">
      <div class="mt-6">
        <div class="flex">
          <div
            class="mr-6 md:mr-12 cursor-pointer text-sm md:text-base"
            :class="{
              'font-bold text-grey-2': 'projects' === type,
              'text-grey-4': 'projects' !== type
            }"
            @click="changeType('projects')"
            id="projects-nav"
          >
            {{ $t('invest.list.campaigns') }}
          </div>
          <!-- <div
            class="mr-6 md:mr-12 cursor-pointer text-sm md:text-base"
            :class="{
              'font-bold text-grey-2': 'offers' === type,
              'text-grey-4': 'offers' !== type
            }"
            @click="changeType('offers')"
            id="offers-nav"
          >
            {{ $t('invest.list.marketplace') }}
          </div> -->
        </div>
        <div class="relative" ref="barParent">
          <div
            class="w-6 h-1 bg-orange-3 absolute bottom-0 transition-all"
            :style="'left:' + left + 'px'"
          ></div>
          <div class="h-0.5 w-full bg-grey-6 mt-4"></div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <ProjectOrder class="mb-2" :mkt="type === 'offers'" />
    </div>
    <Projects :type="type" v-if="isMounted" />
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Projects from '@/components/projects/Projects'

export default {
  components: {
    Projects,
    ProjectOrder: defineAsyncComponent(() =>
      import('@/components/projects/Order.vue')
    ),
    Back: defineAsyncComponent(() => import('@/components/general/Back.vue'))
  },

  setup() {
    const showPopup = ref(true)
    const store = useStore()
    const isMounted = ref(false)
    const left = ref(0)
    const barParent = ref(null)
    const type = computed(() => {
      return store.state.projects.type
    })
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const isBorrower = computed(() => {
      if (profile.value && profile.value.sub_type === 'borrower')
        return true
      return false
    })

    onMounted(() => {
      isMounted.value = false
      if (location.hash) {
        const val = location.hash.replace('#', '')
        if (val === 'projects' || val === 'offers') changeType(val)
      }
      isMounted.value = true
    })

    async function changeType(type) {
      store.dispatch('setType', type)
      location.hash = type
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      await timer(1)
      left.value =
        document.getElementById(type + '-nav').offsetLeft -
        barParent.value.offsetLeft
    }
    return { 
      type, 
      isBorrower,
      changeType, 
      showPopup, 
      left, 
      barParent, 
      isMounted 
    }
  }
}
</script>
