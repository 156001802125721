<template>
  <div
    v-if="isPending & !isNew"
    class="bg-white rounded-2xl w-full flex flex-col justify-between p-4 mt-4"
  >
    <div class="flex justify-between">
      <div class="flex">
        <div class="flex items-start w-8 mr-1">
          <img class="w-full" src="@/assets/svg/info.svg" alt="info" />
        </div>
        <div>
          <p class="text-left text-grey-2 text-sm font-semibold mb-2">
            {{ $t('settings.suitability.invest_more.title') }}
          </p>
          <p class="text-left text-grey-2 text-sm text-opacity-50">
            <slot name="content">
              {{ $t('settings.suitability.invest_more.description') }}</slot
            >
          </p>
        </div>
      </div>
    </div>
    <router-link
      :to="'/settings/suitability'"
      class="orange-link text-right text-13 text-transform: uppercase"
      >{{ $t('settings.suitability.invest_more.link') }}</router-link
    >
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const isPending = computed(() => {
      return ['eligible_pending', 'accredited_pending'].includes(profile.value.sub_type)
    })

    const isNew = computed(() => {
      return store.state.wallets.wallet?.current_invested>0
    })

    return {
      isPending,
      isNew
    }
  }
}
</script>
