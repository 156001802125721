<template>
  <div class="page flex flex-col">
    <SuccessCard
      v-if="deleting"
      :title="$t('settings.bank_accounts.deleting_bank_account.title')"
      :text="$t('settings.bank_accounts.deleting_bank_account.description')"
      :closeable="false"
    >
      <template #icon>
        <img
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="exclamation icon"
        />
      </template>
      <template #button>
        <div class="lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 lg:mb-0"
            @click="confirm"
            desktopWidth="w-full"
          >
            {{ $t('wording.delete') }}
          </GpButton>
          <GpButton @click="() => (deleting = false)" desktopWidth="w-full">
            {{ $t('wording.cancel') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div
      v-else-if="account"
      class="flex-grow flex flex-col justify-between lg:pt-20 container flex-grow min-p-button relative"
    >
        <Back
          :to="'/settings/bank-accounts'"
          :title="$t('settings.bank_accounts.details_title')"
        />
        <!-- <div v-if="account.is_active">
          <GeneralInput
            v-model:data="holderName"
            name="name"
            :label="
              $t('settings.bank_accounts.add_bank_account.name_placeholder')
            "
            :locked="true"
          />
        </div>
        <div v-else>
          <div class="
            w-full
            text-xl
            lg:text-base
            text-left
            mt-6
            mb-6
            text-grey-3">
            {{ $t('settings.bank_accounts.not_active') }}
          </div>
        </div> -->
        <GeneralInput
          v-model:data="account.name"
          name="account_name"
          :label="
            $t(
              'settings.bank_accounts.add_bank_account.account_name_placeholder'
            )
          "
        />

      <div class="md:flex md:justify-between">
        <GpButton @click="save" color="orange" class="bottom-10 right-0">
          {{ $t('settings.bank_accounts.save') }}
        </GpButton>
        <GpButton
          @click="() => (deleting = true)"
          color="white"
          class="bottom-10 right-0"
        >
          {{ $t('settings.bank_accounts.deleting_bank_account.btn') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import Back from '@/components/general/Back.vue'
import GeneralInput from '@/components/general/Input.vue'
import useRules from '@/assets/js/rules'
import GpButton from '@/components/general/GpButton.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'

export default {
  components: {
    Back,
    GeneralInput,
    GpButton,
    SuccessCard
  },
  props: {
    accountProp: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })
    const { isRequired } = useRules()
    const deleting = ref(null)
    const loading = computed(() => {
      return store.state.general.loading
    })

    onMounted(async () => {
      if (!account.value) {
        store.dispatch('setLoading', true)
        await store.dispatch('getBankAccount')
        store.dispatch('setLoading', false)
      }
    })

    const account = computed(() => {
      if (props.accountProp) return JSON.parse(props.accountProp)
      else return store.state.bankAccounts.account
    })

    const holderName = computed(() => {
      return account.value.holder_firstname + ' ' + account.value.holder_surname
    })

    const confirm = () => {
      store.dispatch('setLoading', true)
      store
        .dispatch('deleteBankAccount', account.value.id)
        .then(() => {
          store.dispatch('setLoading', false)
          router.push({
            name: 'settings-bank-accounts'
          })
          const popup = {
            title: i18n.t(
              'settings.bank_accounts.deleting_bank_account.success.title'
            ),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t(
              'settings.bank_accounts.deleting_bank_account.success.btn'
            )
          }
          store.dispatch('setPopup', popup)
        })
        .catch((err) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', err)
        })
    }

    const save = () => {
      store.dispatch('setLoading', true)
      store
        .dispatch('saveBankAccount', { name: account.value.name })
        .then(() => {
          store.dispatch('setLoading', false)
          router.push({
            name: 'settings-bank-accounts'
          })
          const popup = {
            title: i18n.t(
              'settings.bank_accounts.save_bank_account.success.title'
            ),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t(
              'settings.bank_accounts.save_bank_account.success.btn'
            )
          }
          store.dispatch('setPopup', popup)
        })
        .catch((err) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', err)
        })
    }

    return {
      account,
      holderName,
      isRequired,
      deleting,
      confirm,
      save,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
