<template>
  <div class="">
    <swiper
      class="w-full sdg-slide"
      :loop="false"
      :space-between="16"
      :slides-per-view="'auto'"
      :pagination="false"
      :allowTouchMove="true"
      :speed="1000"
      :autoplay="false"
    >
      <swiper-slide v-for="sdg in sdgs" :key="'sdg-' + sdg.id">
        <img
          :src="
            require(`@/assets/img/sdgs/${locale}/sdg_${locale}_(${sdg.id}).png`)
          "
          :alt="sdg"
          class="w-24"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    sdgs: Array
  },
  setup() {
    const i18n = useI18n({ useScope: 'global' })

    return {
      locale: i18n.locale
    }
  }
}
</script>
<style>
.sdg-slide .swiper-slide {
  min-height: 0rem;
  width: auto;
}
</style>
