<template>
  <div class="px-6">
    <!-- successful bank connection popup -->
    <!-- title -->
    <div class="text-center">
      <p class="font-bold text-lg text-grey-2 mb-4">
        {{ $t('errors.connect_bank.title') }}
      </p>
      <!-- description -->    
      <p v-if="isBorrower" class="text-sm text-grey-2 text-opacity-60">
        {{ $t('errors.connect_bank.description_borrower') }}
      </p>      
      <p v-else class="mb-4 text-sm text-grey-2 text-opacity-60">
        {{ $t('errors.connect_bank.description') }}
      </p>
    </div>
    <GpButton mobileWidth="w-full" desktopWidth="w-full" @click="close">
      {{ $t('errors.connect_bank.close') }}
    </GpButton>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  components: {
    GpButton
  },
  props: {
    isBorrower: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const goToTopup = () => {
      router.push('/topup')
      store.dispatch('setShowPopup', false)
    }

    const goToInvest = () => {
      router.push('/projects')
      store.dispatch('setShowPopup', false)
    }    
    const close = () => {
      store.dispatch('setShowPopup', false)
    }

    return {
      goToInvest,
      goToTopup,
      close
    }
  }
}
</script>