<template>
  <div class="page flex">
    <SuccessCard
      v-if="hasChanges"
      :title="$t('settings.discard_changes.title')"
      :text="$t('settings.discard_changes.content')"
      :closeable="false"
    >
      <template #icon>
        <img
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="exclamation icon"
        />
      </template>
      <template #button>
        <div class="lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto"
            desktopWidth="w-full"
            @click="discard"
          >
            {{ $t('wording.discard_changes') }}
          </GpButton>
          <GpButton @click="cancel" class="mx-auto" desktopWidth="w-full">
            {{ $t('wording.no_go_back') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div
      v-else
      class="lg:pt-20 container flex-grow flex flex-col justify-between"
    >
      <div>
        <div @click="checkChanges">
          <Back :to="null" :title="$t('settings.language.title')" />
        </div>
        <GeneralInput
          v-model:data="activeLocale"
          type="radio"
          name="languages"
          :options="languages"
        />
      </div>

      <GpButton
        class="ml-auto"
        :disabled="!isDirty || loading"
        @click="changeLanguage"
      >
        {{ $t('settings.language.btn') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ref, computed, onMounted } from "vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useProfile from '@/mixins/useProfile.js'
import Back from '@/components/general/Back.vue'
import GeneralInput from '@/components/general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'

export default {
  components: {
    Back,
    GeneralInput,
    GpButton,
    SuccessCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })
    const languages = ref({
      en: i18n.t('wording.english'),
      'pt-pt': i18n.t('wording.portuguese'),
      es: i18n.t('wording.spanish')
    })
    const { locale } = useI18n({ useScope: 'global' })
    const { updateProfile } = useProfile()
    const profile = ref(null)
    const activeLocale = ref(null)
    const hasChanges = ref(null)

    const loading = computed(() => {
      return store.state.general.loading
    })

    onMounted(()=>{
      profile.value = store.getters.getProfile()
      activeLocale.value =  profile.value ? profile.value.language : 'en'
    })

    const changeLanguage = () => {
      store.dispatch('setLoading', true)
      updateProfile({
        language: activeLocale.value
      })
        .then(() => {
          store.dispatch('setLoading', false)
          locale.value = activeLocale.value
          const popup = {
            title: i18n.t('settings.language.success_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t('settings.language.success_popup.btn')
          }
          store.dispatch('setPopup', popup)
          router.push({name: 'settings'})
        })
        .catch((e) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', e)
        })
    }

    const isDirty = computed(() => {
      if (profile.value && activeLocale.value !== profile.value.language) return true
      return false
    })

    const checkChanges = () => {
      if (isDirty.value) {
        hasChanges.value = true
      } else {
        router.go(-1)
      }
    }

    const discard = () => {
      router.go(-1)
    }

    const cancel = () => {
      hasChanges.value = false
    }

    return {
      languages,
      changeLanguage,
      activeLocale,
      profile,
      checkChanges,
      discard,
      loading,
      isDirty,
      cancel,
      hasChanges
    }
  }
}
</script>

<style lang="sass" scoped></style>
