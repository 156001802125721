<template>
  <div >
    <div class="steps text-left pt-4 md:pt-0 h-full">
        <Back id="gp-link-app-giftcards-payment-back-10" swiper/>
      <div >
        <div class="flex md:hidden pb-4 mb-6 border-b border-grey-6">
          <img
            src="@/assets/svg/arrows/left_grey_arrow.svg"
            alt=""
            class="mr-5 block md:hidden mt-1"
            @click="previous"
          />
          <p class="text-xl font-bold text-grey-2 ml-0">{{$tm('giftcards.method.title')}}</p>
        </div>
        <div class="steps__title mt-8">{{ $tm('giftcards.method.title') }}</div>
        <div class="steps__subtitle mt-5">{{ $tm('giftcards.method.subtitle') }}</div>
        <div id="gp-button-app-giftcards-payment-wallet-balance-11" class="steps__investor mb-6" @click="proceed('wallet')">
          <div class="flex md:items-center">
            <div class=" mr-6">
              <img src="@/assets/svg/wallet-w-bg.svg" alt="" class="hidden md:block"/>
              <img src="@/assets/svg/wallet.svg" alt="" class="block md:hidden mt-1 w-6"/>
            </div>
            <div class="-ml-1 md:ml-0">
              <div class="font-bold md:mb-0 mb-2 text-grey-2 md:opacity-80">
                {{ $tm('giftcards.method.wallet.title') }}
              </div>
              <div class="text-sm md:text-13 md:opacity-50 text-grey-4">
                {{ $tm('giftcards.method.wallet.subtitle') }}
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <img src="@/assets/svg/chev-right-2.svg" alt="" />
          </div>
        </div>
        <div id="gp-button-app-giftcards-payment-card-12" class="steps__investor mb-6" @click="proceed('etransfer')">
          <div class="flex md:items-center">
            <div class="block mr-6">
              <img src="@/assets/svg/card.svg" alt="" class="hidden md:block"/>
              <img src="@/assets/svg/card-orange.svg" alt="" class="block md:hidden w-5 mt-1"/>

            </div>
            <div class="-ml-1 md:ml-0">
              <div class="font-bold md:mb-0 mb-2 text-grey-2 md:opacity-80">
                {{ $tm('giftcards.method.etransfer.title') }}
              </div>
              <div class="text-sm md:text-13 md:opacity-50 text-grey-4">
                {{ $tm('giftcards.method.etransfer.subtitle') }}
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <img src="@/assets/svg/chev-right-2.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Back from "@/components/general/Back";
import { useStore } from "vuex";
import { computed } from 'vue'
import { useI18n } from "vue-i18n";
import { useRouter } from 'vue-router'

export default {
  components: {
    Back
  },
  emits:['next', 'previous'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n()

    const wallets = computed(() => {
      return [store.state.wallets.wallet]
    })

    const total = computed(()=>{
      return store.state.general.giftcards.data.reduce(function(sum, current) {
        return sum + parseFloat(current.amount.replace(/\./g, '').replace(/,/g, '.'));
      }, 0)
    })

    function next(method){
      if(method === 'etransfer'){
        router.push('/topup')
      }
      else {
        store.dispatch('setGiftcards', {method: method })
        emit('next');
      }
    }

    function previous(){
      emit('previous')
    }

    function proceed(method){
      if(method === 'etransfer') next(method)
      else if(wallets.value[0].balance < total.value){
        const popup = {
          title: i18n.t("giftcards.popup.error.title"),
          content: i18n.t("giftcards.popup.error.desc"),
          align: "center",
          side: false,
          iconBg: 'bg-red-light',
          icon: "/img/popup/exclamation_red.svg",
          primaryButton: i18n.t("giftcards.popup.error.btn"),
          primaryCallback: ()=>{next('etransfer')},
          secondaryButton: i18n.t("giftcards.popup.error.sec_btn"),
          secondaryLink: '/dashboard'
        };
        store.dispatch('setPopup', popup)
      }else{
        store.dispatch('setGiftcards', {wallet: {id: wallets.value[0].id, name: wallets.value[0].name ?? i18n.t('transactions.filters.main_wallet'), balance: wallets.value[0].balance}})
        next(method)
      }
    }

    return {
      proceed,
      previous
    }
  }
}

</script>