import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import autoInvestment from '@/store/modules/autoInvestment'
import onboarding from '@/store/modules/onboarding'
import bankAccounts from '@/store/modules/bankAccounts'
import notifications from '@/store/modules/notifications'
import kycs from '@/store/modules/kycs'
import strategies from '@/store/modules/strategies'
import metrics from '@/store/modules/metrics'
import vouchers from '@/store/modules/vouchers'
import transactions from '@/store/modules/transactions'
import wallets from '@/store/modules/wallets'
import projects from '@/store/modules/projects'
import general from '@/store/modules/general'
import topup from '@/store/modules/topup'
import impact from '@/store/modules/impact'
import error from '@/store/modules/error'
import representatives from '@/store/modules/representatives'
import invest from '@/store/modules/invest'
import withdraw from '@/store/modules/withdraw'
import phone from '@/store/modules/phone'
import popups from '@/store/modules/popups'
import promoters from '@/store/modules/promoters'
import contracts from '@/store/modules/contracts'
import offers from '@/store/modules/offers'
import suitability from '@/store/modules/suitability'

export default createStore({
  modules: {
    auth,
    autoInvestment,
    bankAccounts,
    kycs,
    notifications,
    promoters,
    onboarding,
    strategies,
    metrics,
    vouchers,
    transactions,
    general,
    impact,
    wallets,
    topup,
    projects,
    error,
    representatives,
    invest,
    withdraw,
    phone,
    popups,
    contracts,
    offers,
    suitability
  }
})
