<template>
  <div>
    <div class="christmas-ball christmas-ball--star right-4 w-6 top-12">
      <img src="@/assets/svg/star.svg" alt="green ball">
    </div>
    <div class="christmas-ball right-8 w-6 top-3">
      <img src="@/assets/svg/white-ball.svg" alt="green ball">
    </div>
    <div class="christmas-ball right-14 w-5 top-9">
      <img src="@/assets/svg/white-ball.svg" alt="green ball">
    </div>
    <div class="christmas-ball  right-2 w-4 top-8">
      <img src="@/assets/svg/white-ball.svg" alt="green ball">
    </div>
  </div>
</template>
<script>
export default {
  setup () {}
}
</script>
<style lang="postcss" scoped>
.christmas-ball {
  @apply absolute;
}

.christmas-ball img {
  @apply w-full;
}

.christmas-ball::before {
  content: '';
  background: rgba(255,255,255,0.2);
  width: 1px;
  height: 20rem;
  top: -20rem;
  position: absolute;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
}

.christmas-ball--star::before {
  margin-top: 2px;
}

.christmas-ball--yellow::before {
  margin-left: 0px;
  margin-top: 6px;
}
</style>
