<template>
  <div class="page flex flex-col items-end">
    <SideBar>
      <FlowProgress step="2" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div class="strategies__desktop-container justify-between">
      <div class="lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <div class="mt-2 mb-4">
          <Back
            :to="'/strategies/topup'"
            :title="$t('strategies.setup.why_saving.title')"
          />
        </div>
        <ProgressBar class="lg:hidden" :step="1" :total="7" />
        <GeneralInput
          class="mb-8 mt-6"
          v-model:data="planName"
          type="name"
          name="name"
          :label="$t('strategies.setup.why_saving.name_placeholder')"
        />
        <p class="text-grey-2 text-left text-sm opacity-60 mb-3.5">
          {{ $t('strategies.setup.why_saving.beneficiary_label') }}
        </p>
        <GeneralInput
          v-model:data="planOwner"
          type="radio"
          name="owner"
          :options="{
            own: $t('strategies.setup.why_saving.beneficiary_me'),
            other: $t('strategies.setup.why_saving.beneficiary_other')
          }"
        />
      </div>
      <div class="lg:flex lg:justify-end lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <GpButton :disabled="!allFilled" @click="selectWhy">
          {{ $t('wording.next') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import SideBar from '@/components/general/SideBar.vue'
import FlowProgress from '@/components/general/FlowProgress.vue'
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useFlows from '@/mixins/useFlows.js'
export default {
  components: {
    Back,
    GeneralInput,
    ProgressBar,
    GpButton,
    FlowProgress,
    SideBar
  },
  setup() {
    const planName = ref(null)
    const planOwner = ref(null)
    const store = useStore()
    const router = useRouter()

    const { noNewStrategy } = useFlows()
    onBeforeMount(() => {
      noNewStrategy()
    })

    const selectWhy = () => {
      store.dispatch('updateNewStrategy', {
        name: planName.value,
        owner: planOwner.value
      })
      if (planOwner.value === 'own') router.push('/strategies/direct-debit')
      else if (planOwner.value === 'other')
        router.push('/strategies/other-beneficiary')
    }

    const allFilled = computed(() => {
      return planName.value && planOwner.value
    })

    return {
      planName,
      planOwner,
      selectWhy,
      allFilled
    }
  }
}
</script>

<style lang="scss" scoped></style>
