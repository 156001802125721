import { VueCookieNext } from 'vue-cookie-next'
// import useSecurity from '@/mixins/useSecurity'

export default function isActivated({ next, router }) {
  if (!VueCookieNext.isCookieAvailable('profile')) {
    return router.push({name: 'login'})
  }
  // const { decrypt } = useSecurity()
  let profile = VueCookieNext.getCookie('profile')
  // if (profile) profile = decrypt(profile)
  // profile = JSON.parse(profile)
  if (!profile.activated) {
    return router.push({name: 'dashboard'})
  }
  return next()
}
