<template>
  <div>
    <!-- LOG -->
    <div
      class="
        h-20
        bottom-0
        z-30
        absolute
        w-14
        left-1/2
        transform
        -translate-x-1/2
        log
      "
    >
      <img
        src="@/assets/svg/impact/gamification/components/log.svg"
        alt=""
        class="w-full"
        style="height: inherit"
      />
    </div>

    <!-- TREETOPS -->

    <!-- BIG TREETOP -->
    <div
      class="w-14 bottom-16 z-20 absolute left-1/2 transform -translate-x-1/2"
    >
      <img
        src="@/assets/svg/impact/gamification/components/ball.svg"
        alt=""
        class="w-full treetop"
      />

      <!-- BERRRIES -->
      <img
        src="@/assets/svg/impact/gamification/components/berry.svg"
        alt=""
        class="w-2 absolute bottom-3 left-3 berry"
        v-if="berries"
        style="animation-delay: 1.5s"
      />
      <img
        src="@/assets/svg/impact/gamification/components/berry.svg"
        alt=""
        class="w-2 absolute bottom-5 right-3 berry"
        v-if="berries"
        style="animation-delay: 1.7s"
      />
    </div>

    <!-- MEDIUM TREETOP -->
    <div
      class="w-12 z-20 absolute left-1/2 transform -translate-x-1/2"
      style="bottom: 89px"
    >
      <img
        src="@/assets/svg/impact/gamification/components/ball.svg"
        alt=""
        class="w-full treetop"
        style="animation-delay: 0.5s"
      />
      <!-- BERRRIES -->
      <img
        src="@/assets/svg/impact/gamification/components/berry.svg"
        alt=""
        class="w-2 absolute bottom-3 left-3 berry"
        v-if="berries"
        style="animation-delay: 1.9s"
      />
      <img
        src="@/assets/svg/impact/gamification/components/berry.svg"
        alt=""
        class="w-2 absolute bottom-4 right-2 berry"
        v-if="berries"
        style="animation-delay: 2.1s"
      />
    </div>

    <!-- SMALL TREETOP -->
    <div
      class="w-10 bottom-28 z-20 absolute left-1/2 transform -translate-x-1/2"
    >
      <img
        src="@/assets/svg/impact/gamification/components/ball.svg"
        alt=""
        class="w-full treetop"
        style="animation-delay: 1s"
      />
      <!-- BERRRIES -->
      <img
        src="@/assets/svg/impact/gamification/components/berry.svg"
        alt=""
        class="w-2 absolute bottom-3 left-2 berry"
        v-if="berries"
        style="animation-delay: 2.3s"
      />
      <img
        src="@/assets/svg/impact/gamification/components/berry.svg"
        alt=""
        class="w-2 absolute bottom-5 right-2 berry"
        v-if="berries"
        style="animation-delay: 2.4s"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    berries: { type: Boolean, default: false }
  },
  setup() {}
}
</script>
<style>
.log {
  animation: log-grow 0.5s ease-out forwards;
}

.treetop {
  transform: scale(0);
  transform-origin: center;
  animation: scale-top 0.5s ease-out forwards;
}

.berry {
  transform: scale(0);
  transform-origin: center;
  animation: scale-berry 0.2s ease-out forwards;
}

@keyframes scale-top {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-berry {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes log-grow {
  0% {
    height: 0px;
  }
  70% {
    height: 5.2rem;
  }
  100% {
    height: 5rem;
    overflow: auto !important;
  }
}
</style>
