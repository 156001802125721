<template>
  <div class="page">
    <div class="container lg:pt-20">
      <div
        class="absolute lg:relative lg:top-auto lg:left-auto top-8 left-6 z-50"
      >
        <back white />
      </div>
      <div
        class="
          project-card__header--skeleton
          w-full
          h-64
          lg:h-52 lg:rounded-3xl
          mb-12
          absolute
          top-0
          left-0
          lg:relative
          z-50
        "
        v-if="!loan"
      ></div>
      <LoanBanner :project="loan" class="mb-12" v-if="loan" />
      <div
        class="
          project-card__header--skeleton
          w-full
          h-96
          rounded-3xl
          mb-12
          lg:mt-0
          mt-60
        "
        v-if="!loan"
      ></div>
      <Table :data="tableData" class="mb-8 lg:mb-16 mt-60 lg:mt-0" mobileList />
      <div class="justify-between flex mb-4">
        <div class="text-xl text-grey-2 font-semibold">
          {{ $t('promoter.loan.payment.home_title') }}
        </div>
        <div class="orange-link">
          <router-link :to="'/loan/' + id + '/details/payments'">
            {{ $t('promoter.loan.payment.link') }}
          </router-link>
        </div>
      </div>
      <List :payments="loan ? loan.payments : null" :has_next_page="null" />
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import LoanBanner from '@/components/promoter/LoanBanner'
import List from '@/components/promoter/List'
import Table from '@/components/general/Table'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
export default {
  components: { Back, LoanBanner, Table, List },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting, dateFormatting } = useGlobalHelpers()
    const id = ref(route.params.id)

    const loan = computed(() => {
      return store.state.promoters.loan
    })

    const tableData = computed(() => {
      let data = {}
      data.top = []
      data.bottom = []
      if (loan.value) {
        i18n.tm('promoter.loan.table.top').forEach((el) => {
          if (
            el.value !== 'extra_payments' ||
            (el.value === 'extra_payments' &&
              loan.value.loan_status.extra_payments > 0)
          ) {
            let helper = {
              title: el.title,
              value: '$' + dollarFormatting(loan.value.loan_status[el.value]),
              subtitle: []
            }
            el.subitems.forEach((subitem) => {
              helper.subtitle.push({
                title: subitem.title,
                value:
                  '$' + dollarFormatting(loan.value.loan_status[subitem.value]),
                underline: subitem.underline
              })
            })
            data.top.push(helper)
          }
        })
        i18n.tm('promoter.loan.table.bottom').forEach((el) => {
          let helper = {
            title: el.title,
            value: dateFormatting(new Date(loan.value[el.value].slice(0, 9))),
            subtitle: []
          }
          data.bottom.push(helper)
        })
      }
      return data
    })

    onMounted(() => {
      if (route.params.id) store.dispatch('fetchLoan', route.params.id)
      else router.push('loans')
    })
    return { loan, tableData, id, route }
  }
}
</script>
