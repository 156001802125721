<template>
  <div class="">
    <swiper
      class="w-full contract-slide"
      :loop="false"
      :slides-per-view="1.2"
      :space-between="16"
      :pagination="false"
      :allowTouchMove="true"
      :centered-slides="true"
      :speed="1000"
      :autoplay="false"
      :breakpoints="{
        // when window width is >= 320px
        640: {
          slidesPerView: 2,
          centeredSlides: false
        },
        1800: {
          slidesPerView: 3,
          centeredSlides: false
        },
        2200: {
          slidesPerView: 4,
          centeredSlides: false
        },
        2700: {
          slidesPerView: 5,
          centeredSlides: false
        }
      }"
    >
      <swiper-slide
        v-for="contract in contracts"
        v-bind:key="'contract-' + contract.id"
      >
        <ContractCard :contract="contract" carousel />
      </swiper-slide>
      <template v-if="skeleton">
        <swiper-slide
          v-for="index in 7"
          v-bind:key="'project-skeleton-' + index"
        >
          <ContractSkeleton />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import ContractCard from './ContractCard'
import ContractSkeleton from './ContractSkeleton'
export default {
  components: {
    Swiper,
    ContractCard,
    SwiperSlide,
    ContractSkeleton
  },
  props: {
    contracts: Array,
    skeleton: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
}
</script>
<style>
.contract-slide .swiper-slide {
  min-height: 0px;
}
</style>
