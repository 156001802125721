<template>
  <div>
    <div class="flex-grow" id="popup-content-amount-not-enough">
      <p
        class="text-grey-2 mb-10 mt-6 md:mt-0 text-xl font-medium text-center"
        v-html="$t('promoter.topup.popup_amount_not_enough.title')"
      />
      <p
        class="text-sm text-grey-3"
        v-html="$t('promoter.topup.popup_amount_not_enough.content')"
      />
      <div class="flex mt-10 gap-4">
        <GpButton @click="next(false)" type="submit" color="white">
          {{ $t('promoter.topup.popup_amount_not_enough.secondary_button') }}
        </GpButton>
        <GpButton @click="next(true)" type="submit">
          {{ $t('promoter.topup.popup_amount_not_enough.primary_button') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    GpButton
  },
  props: {},
  setup() {
    const store = useStore()

    async function next(value) {
      if (value) await store.dispatch('setConfirmation', true)
      store.dispatch('setShowPopup', false)
    }

    return { next }
  }
}
</script>
