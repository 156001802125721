import store from '@/store/index'

const add_instruments_details = () =>
{
  return new Promise((resolve, reject)=>{
    const obj = {
      ...store.state.general.formData,
    }
    
    const previous_investments = []
    for (const investment in obj.previous_investments){
      if(obj.previous_investments[investment]){
        previous_investments.push(investment)
      }
    }
    obj.previous_investments = previous_investments.toString()

    delete obj.sub_type
    delete obj.type

    store.dispatch('setLoading', true)
    store.dispatch('updateSuitability', obj).then((data)=>{
      store.dispatch('setForm', null)
      store.dispatch('clearFormData')
      store.dispatch('fetchProfile').then(() => {
        store.dispatch('setLoading', false)
        window.location.href = '/investor-classification'
      }).catch((e)=> {
        store.dispatch('setLoading', false)
        store.dispatch('setError', e).then(() => {
          reject(e)
        })
      })
      resolve(data)
      }).catch((e)=> {
        store.dispatch('setLoading', false)
        store.dispatch('setError', e).then(() => {
          reject(e)
        })
      })
    }).catch((e)=> {
      store.dispatch('setLoading', false)
      store.dispatch('setError', e)
    })

}

const get_type = () =>
  {
    return new Promise((resolve, reject) =>{
  
      store.dispatch('setLoading', true)
      const profile = store.getters.getProfile()
      if(!profile) store.dispatch('fetchProfile')
      store.dispatch('setFormData', {'type': profile.type})
      store.dispatch('setLoading', false)
  
    })
  
  }

export {
  add_instruments_details,
  get_type
}
