<template>
  <div class="text-left">
    <div class="grid grid-cols-3 md:grid-cols-6 gap-3">
      <div v-for="goal in goals" v-bind:key="goal">
        <img
          :src="
            require('@/assets/svg/goals/' +
              goal +
              (impact.sdgs && impact.sdgs.includes(goal) ? '_color' : '') +
              '.svg')
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  components: {},

  setup() {
    const store = useStore()
    const impact = computed(() => {
      return store.state.impact.impact
    })
    const goals = ref([
      '1_no_poverty',
      '2_zero_hunger',
      '3_good_health_and_well-being',
      '4_quality_education',
      '5_gender_equality',
      '6_clean_water_and_sanitation',
      '7_affordable_and_clean_energy',
      '8_decent_work_and_economic_growth',
      '9_industry_innovation_and_infrastructure',
      '10_reduced_inequalities',
      '11_sustainable_cities_and_communities',
      '12_responsible_consumption_and_production',
      '13_climate_action',
      '14_life_below_water',
      '15_life_on_land',
      '16_peace_and_justice_strong_institutions',
      '17_partnerships_to_achieve_the_goal'
    ])

    return { goals, impact }
  }
}
</script>
