<template>
  <Suspense>
    <template #default>
      <RedeemAsync />
    </template>
    <template #fallback>
      <LoadingLegacy />
    </template>
  </Suspense>
</template>

<script>
import RedeemAsync from '@/components/vouchers/RedeemAsync.vue'
import LoadingLegacy from '@/components/general/LoadingLegacy'
export default {
  components: {
    LoadingLegacy,
    RedeemAsync
  }
}
</script>
