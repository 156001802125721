<template>
  <div>
    <div
      class="
        text-left text-sm text-grey-3
        py-6
        px-4
        rounded-3xl
        flex
        items-start
        border border-orange-8
        mt-4
      "
    >
      <img src="@/assets/svg/toast/warning.svg" alt="" class="w-3 mr-2 mt-1" />
      <div>
        <div class="text-grey-3 font-bold text-sm mb-2">
          {{ $t('promoter.overdue.title') }}
        </div>
        <div class="text-sm text-grey-4">
          {{ $t('promoter.overdue.content') }}
        </div>
      </div>
    </div>
    <gp-button class="mt-6 mx-auto" @click="paynow"
      >{{ $t('promoter.overdue.button') }}
    </gp-button>
  </div>
</template>
<script>
import { computed } from 'vue'
import GpButton from '@/components/general/GpButton'
import { useRouter } from 'vue-router'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: { GpButton },
  props: {
    loan: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const router = useRouter()
    const { slugIt } = useGlobalHelpers()
    const projectSlug = computed(() => {
        return props.loan.name
          ? slugIt(props.loan.name) + '-' + props.loan.id
          : props.loan.id
    })

    const paynow = () => {
      if (props.loan)
        router.push({
          path: '/topup/' + projectSlug.value,
          query: { paynow: 1 }
        })
      else {
        router.push({name: 'dashboard'})
      }
    }

    return { paynow }
  }
}
</script>

<style>
.bg-gradient-yellow {
  background-image: linear-gradient(
    114deg,
    rgba(255, 182, 0, 0.1) -18%,
    rgba(255, 182, 0, 0) 90%
  );
}
</style>
