<template>
  <div class="page flex">
    <div v-if="profile && walletObj" class="lg:pt-20 container flex-grow">
      <!-- For now wallet name is dynamic just on desktop. Waiting update on screens to fix mobile with wallet name -->
      <Back
        :to="'/settings/wallets'"
        :title="
          walletObj.name && isDesktop()
            ? walletObj.name
            : $t('settings.wallets.details.title')
        "
      />
      <div class="pt-9 lg:pt-6">
        <Table :data="walletData" :isWallet="true" />

        <div class="pt-8 lg:pt-4">
          <GpButton
            mobileWidth="w-full"
            type="submit"
            class="mt-8 block md:hidden"
            color="grey"
            @click="showDetailsPopup"
          >
            {{ $t('settings.wallets.details.send_details') }}
          </GpButton>

          <div class="hidden md:block">
            <GpButton
              mobileWidth="w-full"
              type="submit"
              class="mt-4 md:mt-8 ml-auto"
              color="grey"
              center
              @click="sendDetails('email')"
            >
              {{ $t('settings.wallets.details.send_details') }}
            </GpButton>

            <transition name="toast-fade">
              <div class="absolute -bottom-10 right-72" v-if="show === 'email'">
                <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
                  <img src="@/assets/svg/copy_inverted.svg" alt="" />
                  <div class="ml-3 opacity-80 font-semibold text-13">
                    {{ $t('wording.sent') }}
                  </div>
                </div>
              </div>
            </transition>
          </div>

          <!-- Direct debit associated with the promoter's wallet -->
          <div v-if="profile.promoter_id && walletObj.dd_associated">
            <div class="pt-12">
              <div class="bg-grey-7 rounded-t-2xl px-4 py-5">
                <p class="text-base text-grey-3 font-bold">
                  {{ $t('settings.wallets.dd_associated.title') }}
                </p>
              </div>
              <div class="bg-white bg-opacity-60 rounded-b-2xl px-4 py-5 mb-8">
                <!-- Account holder name -->
                <div class="flex items-center justify-between mb-4 px-2">
                  <p class="text-left text-sm text-grey-3">
                    {{
                      $t('settings.wallets.dd_associated.account_holder_name')
                    }}
                  </p>
                  <p class="text-right text-sm text-grey-2 font-semibold">
                    {{ walletObj.dd_associated.profile }}
                  </p>
                </div>
                <!-- Name of bank account -->
                <div class="flex items-center justify-between mb-4 px-2">
                  <p class="text-left text-sm text-grey-3">
                    {{ $t('settings.wallets.dd_associated.bank_account_name') }}
                  </p>
                  <p class="text-right text-sm text-grey-2 font-semibold">
                    {{ walletObj.dd_associated.bank_account_name }}
                  </p>
                </div>
                <!-- Day -->
                <div class="flex items-center justify-between px-2">
                  <p class="text-left text-sm text-grey-3">
                    {{ $t('settings.wallets.dd_associated.day') }}
                  </p>
                  <p class="text-right text-sm text-grey-2 font-semibold">
                    {{
                      $t('settings.wallets.dd_associated.day_description', {
                        day: ddDay(walletObj.dd_associated.dd_day),
                        month: ddMonth(walletObj.dd_associated.dd_day)
                      })
                    }}
                  </p>
                </div>
              </div>
              <div>
                <p class="text-left text-sm text-grey-3 mb-14 lg:mb-2">
                  {{ $t('settings.wallets.dd_associated.warning') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import Table from '@/components/general/Table.vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    Back,
    Table,
    GpButton
  },
  props: {
    wallet: { type: String, default: '' }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })
    const show = ref(null)
    const error = ref(null)
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const walletObj = ref(null)
    const walletData = ref({
      top: [],
      bottom: []
    })

    const project = computed(() => {
      return store.state.projects.project
    })

    onMounted(async () => {
      if (!props.wallet) {
        router.push({
          name: 'settings-wallets'
        })
      } else {
        walletObj.value = JSON.parse(props.wallet)
        await store.dispatch('getProject', walletObj.value.project_id)
        walletData.value.top = [
          {
            title: i18n.t('settings.wallets.details.beneficiary'),
            value: profile.value.name + ' ' + profile.value.surname
          },
          {
            title: i18n.t('settings.wallets.details.bic'),
            value: walletObj.value.bic,
            copyable: true
          },
          {
            title: i18n.t('settings.wallets.details.type'),
            value: i18n.t('settings.wallets.details.transfer')
          }
        ]
        walletData.value.bottom = [
          {
            title: i18n.t('settings.wallets.details.address.address_label'),
            value: i18n.t('settings.wallets.details.address.address')
          },
          {
            title: i18n.t('settings.wallets.details.address.zip_code_label'),
            value: i18n.t('settings.wallets.details.address.zip_code')
          },
          {
            title: i18n.t('settings.wallets.details.address.city_label'),
            value: i18n.t('settings.wallets.details.address.city')
          },
          {
            title: i18n.t('settings.wallets.details.address.country_label'),
            value: i18n.t('settings.wallets.details.address.country')
          }
        ]
      }
    })

    function sendDetails(method) {
      store
        .dispatch('sendDetails', {
          method: method,
          id: walletObj.value.id,
          type: 'wallet'
        })
        .then(() => {
          show.value = method
          setTimeout(function () {
            show.value = null
          }, 500)
        })
        .catch((e) => {
          error.value = e.response.data
          setTimeout(function () {
            error.value = null
          }, 1000)
        })
    }

    function showDetailsPopup() {
      const popup = {
        align: 'left',
        side: true,
        component: 'WalletInfo',
        props: { profile: profile.value, walletId: walletObj.value.id }
      }
      store.dispatch('setPopup', popup)
    }

    // Test if nav browser is mobile or tablet
    function isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return false
      } else {
        return true
      }
    }

    const ddDay = (date) => {
      let onlyDate = date.split(' ')[0].split('-')
      let day = onlyDate[2]
      return day
    }

    const ddMonth = (date) => {
      const i18n = useI18n({ useScope: 'global' })
      let onlyDate = date.split(' ')[0].split('-')
      let month = i18n.t(`wording.months.${onlyDate[1]}`).slice(0, 3)
      return month
    }

    return {
      profile,
      walletData,
      project,
      walletObj,
      sendDetails,
      showDetailsPopup,
      show,
      error,
      ddDay,
      ddMonth,
      isDesktop
    }
  }
}
</script>

<style lang="postcss" scoped></style>
