<template>
  <div
    class="project-card cursor-pointer"
    :class="{
      'h-80': !campaign,
      'cursor-pointer': campaign,
      'project-card--animation': animated
    }"
    @click="campaign ? goToCampaign() : goToProjectPage()"
  >
    <div
      class="project-card__header"
      :class="{ 'h-1/2': !campaign, 'h-40': campaign }"
    >
      <div
        :style="
          project.images && project.images.card
            ? 'background-image: url(\'' + project.images.card.path + '\')'
            : ''
        "
        class="
          w-full
          h-full
          absolute
          bg-cover bg-center
          transform
          transition
          duration-300
          ease-in-out
        "
        :class="{ 'scale-105': hover }"
      ></div>
      <div class="project-card__header__filter"></div>
      <div class="absolute text-white w-full p-6 text-left">
        <div class="flex justify-between">
          <div class="mr-1">
            <OfferTags v-if="isOffer" :daysToExpire="project.days_to_expire" />
            <div v-if="!isOffer" class="text-11 flex items-center">
              <div
                class="flex h-2.5 w-2.5 relative mr-2"
                v-if="daysToClose < 2 && project.state === 'campaign'"
              >
                <span
                  class="
                    animate-ping
                    absolute
                    inline-flex
                    h-full
                    w-full
                    rounded-full
                    bg-orange-3
                    opacity-75
                  "
                ></span>
                <span
                  class="rounded-full h-1.5 w-1.5 bg-orange-3 m-auto"
                ></span>
              </div>
              <div

                :class="{ ' text-13': daysToClose === 1, 'rounded px-1 py-0.5 bg-orange-1' : daysToClose > 1 }"
                v-if="project.state === 'campaign'"
              >
                {{
                  $t(
                    'projects.state_options.campaign',
                    {
                      dayCount: daysToClose.toString()
                    },
                    daysToClose
                  )
                }}
              </div>
              <div class="rounded px-1 py-0.5" v-if="project.state !== 'campaign' && project.state !== 'funded'"
                   :class="{'bg-grey-1': project.state === 'comingsoon', 'bg-green-1': project.state === 'reserved'}"
              >
                {{
                  $t('projects.state_options.' + project.state)
                }}
              </div>
              <div v-if="project.state === 'funded'" class="mb-4"></div>
            </div>

            <div class="font-bold text-xl mb-1">
              {{ project.name }}
            </div>
            <div class="font-bold text-xs mb-1">
              {{ project.location }}, {{project.province}}
            </div>
          </div>
          <RiskAndCategory
            :risk="project.risk"
            :category="isOffer ? project.project_category : project.category"
          />
        </div>
      </div>
      <!-- Project progress bar -->
      <project-progress-bar
        v-if="!isOffer && project.invested"
        :current="
          project.state.comingsoon
            ? null
            : project.invested
            ? project.invested.confirmed
            : ''
        "
        :investors="project.invested.investors"
        :goal="project.crowdlending_value"
        :state="project.state"
        :reserved="project.invested ? project.invested.reserved : ''"
        class="absolute bottom-0"
      />
      <!-- Offer amount and seller -->
      <div
        v-else
        class="
          absolute
          bottom-5
          px-6
          text-white
          w-full
          flex
          items-center
          justify-between
        "
      >
        <p class="font-semibold">$ {{ dollarFormatting(project.offer_amount) }}</p>
        <p class="text-sm">{{ seller(project.seller_name) }}</p>
      </div>
    </div>
    <div class="project-card__body" v-if="conditions">
      <div class="flex justify-between">
        <div class="project-card__body__detail">
          <div class="project-card__body__detail__title">
            {{ $t('projects.interest_rate') }}
          </div>
          <div class="project-card__body__detail__value">
            {{ conditions.interest_investor }}%
          </div>
        </div>
        <div class="project-card__body__detail">
          <div class="project-card__body__detail__title">
            {{ $t('projects.periodicity') }}
          </div>
          <div class="project-card__body__detail__value">
            {{ $t('projects.periodicity_duration.' + conditions.periodicity) }}
          </div>
        </div>
        <div class="project-card__body__detail">
          <div class="project-card__body__detail__title">
            {{ $t('projects.term') }}
          </div>
          <div class="project-card__body__detail__value">
            {{ monthFormat(conditions.duration_months) }}
          </div>
        </div>
      </div>
      <div class="w-full mt-4" v-if="!campaign && investableState || isOffer">
        <div v-if="isOffer">
          <router-link
            v-if="profile.profile_id === project.seller_id"
            :to="'/contract-details/' + contractSlug"
          >
            <GpButton desktopWidth="w-full" color="white">
              {{ $t('mkt.card_cta_own') }}
            </GpButton>
          </router-link>
          <GpButton
            v-else
            @click="goToProjectPage"
            desktopWidth="w-full"
            color="grey"
          >
            {{ $t('mkt.card_cta') }}
          </GpButton>
        </div>
        <GpButton
          v-else
          @click="goToProjectPage"
          type="submit"
          name="button"
          class="my-6 ml-auto"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          desktopWidth="w-2/3"
          mobileWidth="w-1/2"
        >
          {{ $t('projects.see_more') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectProgressBar from '@/components/general/ProjectProgressBar.vue'
import GpButton from '@/components/general/GpButton.vue'
import OfferTags from '@/components/projects/OfferTags.vue'
import RiskAndCategory from '@/components/projects/RiskAndCategory.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useRouter } from 'vue-router'
import useMarketplace from '@/mixins/useMarketplace.js'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  components: { ProjectProgressBar, RiskAndCategory, GpButton, OfferTags },
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    isOffer: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: true
    },
    campaign: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      riskConversion,
      monthFormat,
      stateConversion,
      dollarFormatting,
      slugIt
    } = useGlobalHelpers()
    const { seller } = useMarketplace()
    const hover = ref(false)
    const router = useRouter()
    const store = useStore()
    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const isKycRegular = computed(() => {
      return profile.value.kyc_level === 'regular' ? true : false
    })

    const investableState = computed(() => {
      return ['campaign', 'reserved', 'comingsoon'].includes(props.project.state)
    })

    const daysToClose = computed(() => {
      if(props.project.closing_date){
        const date1 = new Date(props.project.closing_date.replace(/-/g, "/"))
        const date2 = new Date()
        const diffTime = (date2 - date1) * -1
        return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0)
      }
      return 0
    })

    const goToCampaign = () => {
      router.push({
        name: 'campaign-details',
        params: { id: props.project.id }
      })
    }

    const conditions = computed(() => {
      if (props.isOffer) return props.project.offer_conditions
      else return props.project.funding_rate
    })

    const contractSlug = computed(() => {
      return props.project.name
        ? slugIt(props.project.name) + '-' + props.project.contract_id
        : props.project.contract_id
    })

    const projectSlug = computed(() => {
      if (props.isOffer)
        return props.project.project_name
          ? slugIt(props.project.project_name) + '-' + props.project.project_id
          : props.project.project_id
      else
        return props.project.name
          ? slugIt(props.project.name) + '-' + props.project.id
          : props.project.id
    })

    const goToProjectPage = () => {
      if (props.isOffer)
        router.push({
          path: '/projects/' + projectSlug.value,
          query: { offer: props.project.id }
        })
      else router.push('/projects/' + projectSlug.value)
    }

    return {
      riskConversion,
      monthFormat,
      daysToClose,
      stateConversion,
      hover,
      goToCampaign,
      conditions,
      isKycRegular,
      dollarFormatting,
      seller,
      profile,
      contractSlug,
      goToProjectPage,
      investableState
    }
  }
}
</script>
