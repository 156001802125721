<template>
  <div class="page flex">
    <div
      v-if="strategy"
      class="container flex-grow flex flex-col justify-between lg:pt-20"
    >
      <div>
        <Back :to="-1" :title="$t('strategies.edit.name_title')" />
        <GeneralInput
          class="mt-8"
          ref="nameInput"
          v-model:data="name"
          type="text"
          name="name"
          :label="$t('strategies.edit.name_placeholder')"
        />
      </div>
      <GpButton :disabled="disabled" @click="edit" class="ml-auto">
        {{ $t('wording.change') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { ref } from 'vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {
    Back,
    GeneralInput,
    GpButton
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const strategy = computed(() => {
      return store.state.strategies.strategy
    })

    if (!strategy.value) {
      router.go(-1)
    }

    const name = ref(strategy.value ? strategy.value.name : null)

    const edit = () => {
      store
        .dispatch('updateStrategy', {
          id: strategy.value.id,
          data: {
            name: name.value
          }
        })
        .then(() => {
          router.go(-1)
        })
    }

    const disabled = computed(() => {
      return name.value === strategy.value.name
    })

    return {
      name,
      edit,
      disabled,
      strategy
    }
  }
}
</script>

<style lang="scss" scoped></style>
