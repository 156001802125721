<template>
  <div
    class="w-full rounded-3xl p-4 text-left text-grey-3 text-13"
    :class="{ 'bg-orange-5': type === 'security', 'bg-grey-5': type === '' }"
  >
    <div class="flex items-start">
      <img
        src="@/assets/svg/security.svg"
        alt="security logo"
        class="mr-2"
        v-if="type === 'security'"
      />
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  setup() {}
}
</script>
