<template>
  <div class="lg:max-w-2/3 lg:mx-auto min-h-screen">
  <transition name="fade" mode="out-in">
    <div v-if="allLoaded">
      <div class="lg:relative lg:mr-80 lg:pt-20">
        <div
          class="h-20 w-full fixed top-0 bg-bottom bg-cover px-6 flex z-40 lg:hidden"
          :class="{ 'bg-grey-600': !project.images.card }"
          :style="
            project.images.card
              ? 'background-image: url(\'' + project.images.card.path + '\')'
              : ''
          "
        >
          <div
            class="absolute w-full h-full top-0 left-0 grey-image-filter pointer-events-none"
          />
          <router-link to="/projects" class="flex items-center">
            <img src="@/assets/svg/arrows/left_white_arrow.svg" alt="back" />
            <p class="text-white text-lg font-bold text-left my-2 ml-2">
              {{ project.name }}
            </p>
          </router-link>
        </div>
        <div class="relative lg:pt-6 lg:pb-8">
          <div class="hidden lg:block">
            <Back :to="'/projects'" title="" />
          </div>
          <div
            :style="
              project.images.card
                ? 'background-image: url(\'' + project.images.card.path + '\')'
                : ''
            "
            class="w-full relative p-6 bg-cover bg-center z-40 lg:rounded-2xl lg:mt-6"
            :class="{ 'bg-grey-600': !project.images.card }"
          >
            <!-- Image filter -->
            <div
              class="absolute w-full h-full top-0 left-0 grey-image-filter lg:rounded-2xl"
            />
            <div class="lg:hidden relative z-50">
              <Back :to="'/projects'" title="" white />
            </div>
            <!-- Risk and category -->
            <div class="absolute top-6 right-6">
              <RiskAndCategory
                class="hidden lg:flex"
                :risk="project.risk"
                :category="project.category"
                :horizontal="true"
              />
              <RiskAndCategory
                class="lg:hidden"
                :risk="project.risk"
                :category="project.category"
              />
            </div>
            <!-- State indicator -->
            <States
              v-if="!offerId && project.state !== 'funded'"
              class="mt-8 lg:mt-0 mb-1"
              :project="project"
            />
            <OfferTags v-else-if="offer" :daysToExpire="offer.days_to_expire" />
            <!-- Project name -->
            <p class="text-white text-2xl font-bold text-left my-2 relative">
              {{ project.name }}
            </p>
            <!-- Location -->
            <div class="flex items-center mb-24 relative">
              <img src="@/assets/svg/location.svg" alt="pin" class="mr-1" />
              <p
                class="lg:font-semibold text-white text-opacity-80 lg:text-opacity-100 text-sm"
              >
                <span v-if="project.location">{{
                  project.location + ', '
                }}</span>
                <span>{{ project.province }}</span>
              </p>
            </div>
            <div />
            <!-- Goal -->
            <!-- Offer amount and seller -->
            <div
              v-if="offer"
              class="absolute bottom-5 left-0 px-6 text-white w-full flex items-center justify-between"
            >
              <p class="font-semibold">
                ${{ dollarFormatting(offer.offer_amount) }}
              </p>
              <p class="text-sm">{{ seller(offer.seller_name) }}</p>
            </div>
            <div v-if="!offerId">
              <div
                v-if="
                  project.state === 'comingsoon' || project.state === 'funded'
                "
                class="text-left relative"
              >
                <span class="mr-4 text-white">
                  {{
                    project.state === 'funded'
                      ? $t(
                          'projects.state_options.funded',
                          { investorCount: project.invested.investors },
                          project.invested.investors
                        )
                      : $t('projects.details.goal')
                  }}
                </span>
                <span
                  class="lg:hidden font-bold"
                  :class="
                    project.state === 'funded' ? 'text-green-2' : 'text-white'
                  "
                >
                  ${{ dollarFormatting(project.crowdlending_value) }}
                </span>
                <span
                  class="hidden lg:inline-block font-bold"
                  :class="
                    project.state === 'funded' ? 'text-green-1' : 'text-white'
                  "
                >
                  ${{ dollarFormatting(project.crowdlending_value) }}
                </span>
              </div>
              <div v-else class="relative w-full">
                <ProjectProgressBar
                  :current="
                    project.state.comingsoon ? null : project.invested.confirmed
                  "
                  :goal="project.crowdlending_value"
                  :reserved="project.invested.reserved"
                  :state="project.state"
                  :investors="project.invested.investors"
                  class="absolute bottom-0 left-0"
                  :class="
                    campaign
                      ? '-bottom-6 lg:bottom-2 margin-campaign'
                      : 'bottom-2'
                  "
                  labels
                  details
                  :contain="isMobile && campaign ? false : true"
                />
              </div>
            </div>
          </div>
        </div>
        <TemporaryPopup
          v-if="!isKycRegular"
          name="projects:kyc"
          :closeable-times="0"
          :copy="'home.kyc_popup.'+ kycConditionsResult.localesPath"
          icon="/img/popup/exclamation_orange.svg"
          :btn-link="'settings/bank-accounts'"
          class="mb-6 mt-6 lg:mb-0 lg:relative lg:z-40"
        />
        <!-- Updates -->
        <div class="mb-12 z-50 relative" v-if="project.timelines && (project.state === 'funded' || project.state === 'settled')">
          <TimelineBox :projectId="project.id" :last-update="lastUpdate"/>
        </div>
        <div class="page px-6 lg:p-0 relative bg-orange-2">
          <!-- Conditions -->
          <div
            class="lg:w-2/3 lg:mx-auto lg:fixed lg:top-16 lg:flex lg:justify-end lg:top-36 z-0"
          >
            <div class="lg:bg-white lg:rounded-2xl lg:p-6 lg:w-72">
              <Conditions
                :project="project"
                :offer="offer ?? null"
                :campaign="campaign"
              />
              <router-link v-if="offer" :to="'/mkt-purchase/' + slug">
                <GpButton
                  class="hidden lg:flex"
                  desktopWidth="w-full"
                  :disabled="
                    !isKycRegular || !profile.dealer_approved || isBorrower || !profile.expired_suitability
                  "
                >
                  {{ $t('wording.purchase') }}
                </GpButton>
              </router-link>

              <GpButton
                v-if="investableState && !campaign && !isBorrower && !profile.expired_suitability"
                v-show="
                  !(project.state === 'reserved' && profile.kyc_level === 'light') ||
                  (reserved && reserved.length)
                  "
                  class="hidden lg:flex"
                  :color="
                    (reserved && reserved.length) ||
                    profile.kyc_level === 'light' ||
                    project.state === 'reserved'
                      ? 'grey'
                      : 'orange'
                  "
                  desktopWidth="w-full"
                  @click="invest"
                  id="gp-button-invest-about-project-invest-12"
                >
                  <span
                    v-if="
                      profile.kyc_level === 'light' &&
                      !profile.dealer_approved &&
                      profile.activated &&
                      (!reserved || (reserved && !reserved.length))
                    "
                    id="gp-button-invest-about-project-reserve-14"
                    data-test="reserveButton"
                  >
                    {{ $t('wording.reserve') }}
                  </span>
                  <span v-else-if="reserved && reserved.length" data-test="reservationDetailsButton">
                    {{ $t('projects.details.investments.reservation_details') }}
                  </span>
                  <!-- <span v-else-if="project.state === 'reserved' && waitingList && waitingList.length">
                    {{ $t('projects.details.waiting_list_btn') }}
                  </span> -->
                  <!-- <span v-else-if="project.state === 'reserved'" id="gp-button-invest-about-project-waiting-list-15">
                    {{ $t('projects.details.waiting_list_btn') }}
                  </span> -->
                  <span data-test="investmentTest-investMoreButton" v-else-if="investments && investments.length" id="gp-button-invest-about-project-invest-more-16">
                    {{ $t('projects.details.investments.more') }}
                  </span>
                  <span id="gp-button-invest-about-project-invest-12" data-test="investmentTest-investButton" v-else>{{ $t('wording.invest') }}</span>
                </GpButton>
            </div>
          </div>
          <!-- Campaign -->
          <div v-if="campaign" class="my-2">
            <a
              :href="exportURL"
              class="flex items-center justify-between w-full bg-white p-6 text-sm rounded-3xl green-shadow cursor-pointer mb-4 transform transition ease-in-out duration-200 hover:shadow-light hover:-translate-y-1 mt-2"
            >
              <div class="text-grey-2 font-bold text-left mr-4">
                {{ $t('promoter.campaigns.investment_sheet') }}
              </div>
              <div class="flex">
                <p class="orange-link text-xs">
                  {{ $t('wording.download') }}
                </p>
                <img
                  src="@/assets/svg/arrows/down_orange_arrow.svg"
                  alt="down"
                  class="ml-2"
                />
              </div>
            </a>

            <Badge :type="'security'">
              <template v-slot:content>
                {{
                  $t('promoter.campaigns.secure', {
                    company: project.promoter.name
                  })
                }}
              </template>
            </Badge>
          </div>

          <div class="lg:relative lg:z-40">
            <!-- Investments -->
            <div
              v-if="investments && investments.length && investableState"
              class="my-8"
            >
              <p class="text-left text-sm font-bold text-grey-2 mb-4">
                {{ $t('projects.details.investments.title') }}
              </p>
              <div
                class="bg-white py-4 lg:py-0"
                :class="
                  investments.length > 1 ? 'rounded-t-2xl mb-px' : 'rounded-2xl'
                "
              >
                <TransactionListElement
                  v-for="(investment, index) of investments"
                  :key="investment.transaction.id"
                  :transaction="investment.transaction"
                  :isLast="index + 1 === investments.length"
                />
              </div>
            </div>
            <!-- Reservations -->
            <div
              v-if="reserved && reserved.length && investableState"
              class="mb-8"
            >
              <p class="text-left text-sm font-bold text-grey-2 mb-4">
                {{ $t('projects.details.investments.reserved_title') }}
              </p>
              <div class="bg-white rounded-2xl py-4 lg:py-0 mb-2">
                <TransactionListElement
                  v-for="(reservation, index) of reserved"
                  :key="reservation.transaction.id"
                  :transaction="reservation.transaction"
                  :isLast="index + 1 === reserved.length"
                />
              </div>
              <!-- <TopupProofPopup
                v-else
                :projectName="project.name"
                :reserved="reserved[0].transaction"
                :hours="
                  reserved[0].investment_expiration_warning.hours_to_expire
                "
              /> -->
            </div>
            <!-- Waiting list investments -->
            <div
              v-if="waitingList && waitingList.length && investableState"
              class="lg:mb-8"
            >
              <p class="text-left text-sm font-bold text-grey-2 mb-4">
                {{ $t('projects.details.investments.waiting_list_title') }}
              </p>
              <div class="bg-white rounded-2xl py-4 lg:py-0 mb-2">
                <TransactionListElement
                  v-for="(waiting, index) of waitingList"
                  :key="waiting.transaction.id"
                  :transaction="waiting.transaction"
                  :isLast="index + 1 === waitingList.length"
                />
              </div>
            </div>
            <!-- Simulate -->
            <div
              @click="simulate"
              class="mt-8 mb-12 bg-white rounded-2xl flex items-center justify-between p-4 lg:hidden cursor-pointer"
            >
              <p class="text-sm font-semibold text-grey-2 text-opacity-80">
                {{ $t('projects.details.simulate_title') }}
              </p>
              <p class="orange-link text-xs cursor-pointer">
                {{ $t('projects.details.simulate_link') }}
              </p>
            </div>
            <!-- Tabs -->
            <div class="sticky top-20 lg:top-16 gp-background pt-4 pb-2 z-40">
              <Tabs
                data="projects.details.tabs"
                :nTabs="4"
                v-model:current="currentTab"
                :anchors="anchors"
              />
            </div>
            <!-- About -->
            <p id="about" class="text-xl font-bold text-grey-2 text-left">
              {{ $t('projects.details.about_title') }}
            </p>
            <!-- Short description -->
            <p
              class="text-sm text-left font-medium text-grey-2 text-opacity-80 mt-8 lg:mt-6 mb-4"
            >
              {{ project.short_description }}
            </p>
            <!-- Category -->
            <div class="flex items-center mb-8">
              <img
                :src="
                  require('@/assets/svg/project_categories/' +
                    project.category +
                    '_details.svg')
                "
                alt="project_categories"
                class="mr-5"
              />
              <p class="text-sm text-grey-2 text-opacity-80 font-semibold">
                {{ $t('projects.category_options.' + project.category) }}
              </p>
            </div>
            <div
              v-if="project.description"
              v-html="
                project.description.replace(
                  /https:\/\/app.goparity.ca\/storage\/app\/media\/uploaded-files/g,
                  'https://backend.goparity.ca/storage/app/media/uploaded-files/'
                )
              "
              class="text-sm text-grey-2 text-opacity-80 text-left indented-custom-bullet full-width-img full-width-iframe spacing-p"
            />
            <p
              id="impact"
              class="mt-12 mb-4 font-bold text-xl text-left text-grey-2"
            >
              {{ $t('projects.details.impact_title') }}
            </p>
            <ImpactCarousel v-if="cards.length" :cards="cards" />
            <div
              v-html="project.impact_description"
              class="text-sm text-grey-2 text-opacity-80 text-left mt-6 indented-custom-bullet"
            />
            <!-- SDGs -->
            <p
              class="mt-8 mb-4 font-semibold text-sm text-left text-grey-2 text-opacity-80"
            >
              {{ $t('projects.details.sdgs_title') }}
            </p>
            <div
              class="hidden lg:grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4"
            >
              <div v-for="sdg in project.sdgs" :key="sdg.id" class="w-28">
                <img
                  :src="
                    require(`@/assets/img/sdgs/${locale}/sdg_${locale}_(${sdg.id}).png`)
                  "
                  :alt="sdg"
                />
              </div>
            </div>

            <SDGCarousel :sdgs="project.sdgs" class="lg:hidden" />

            <!-- Viability -->
            <p
              id="viability"
              class="mt-16 mb-4 font-bold text-xl text-left text-grey-2"
            >
              {{ $t('projects.details.viability_title') }}
            </p>
            <div
              v-html="project.financial_viability"
              class="text-sm text-grey-2 text-opacity-80 text-left indented-custom-bullet"
            />
            <!-- Guarantee -->
            <div
              class="mt-4 rounded-2xl bg-green-6 p-4"
              v-if="project.guarantee_description"
            >
              <img class="mb-3" src="@/assets/svg/shield.svg" alt="shield" />
              <p
                class="text-grey-2 text-13 text-left indented-custom-bullet"
                v-html="project.guarantee_description"
              />
            </div>
            <!-- borrower information sheet -->
            <div
              class="flex items-center justify-between px-4 py-2 lg:py-4 bg-white rounded-2xl mt-6"
            >
              <p class="text-sm text-grey-2 font-bold text-left w-1/2">
                {{ $t('projects.details.kiis') }}
              </p>
              <Link
                :text="$t('transactions.details.download')"
                textStyle="text-sm text-orange-1"
                @click="downloadIfifc"
                imageFirst
              >
                <template #singleImage>
                  <img
                    src="@/assets/svg/arrows/down_orange_arrow.svg"
                    alt="right"
                  />
                </template>
              </Link>
            </div>
            <!-- Promoter -->
            <p
              id="promoter"
              class="mt-16 mb-4 font-bold text-xl text-left text-grey-2"
            >
              {{ $t('projects.details.promoter_title') }}
            </p>
            <!-- Logo -->
            <div class="mb-4">
              <div>
                <p class="text-grey-2 font-semibold text-sm text-left">
                  {{ project.promoter.name }}
                </p>
                <p class="text-grey-2 text-13 text-opacity-80 text-left">
                  {{ project.promoter.promoter_fiscal_country }}
                </p>
              </div>
              <img
                v-if="project.images.promoter_logo"
                :src="project.images.promoter_logo.path"
                alt="logo"
                class="h-32 lg:h-36 mr-3"
              />
            </div>
            <div
              v-html="
                project.promoter.description.replace(
                  /https:\/\/app.goparity.ca\/storage\/app\/media\/uploaded-files/g,
                  'https://backend.goparity.ca/storage/app/media/uploaded-files/'
                )
              "
              class="text-sm text-grey-2 text-opacity-80 text-left indented-custom-bullet"
            />
            <!-- Team -->
            <p
              class="text-left text-grey-2 font-semibold text-opacity-80 text-sm mt-8 mb-4"
              v-if="
                project.promoter.team_members &&
                project.promoter.team_members.length > 0
              "
            >
              {{ $t('projects.details.team_title') }}
            </p>
            <div
              v-for="(member, index) in project.promoter.team_members"
              :key="index"
              :class="{
                'mb-6 lg:mb-4':
                  index !== project.promoter.team_members.length - 1
              }"
            >
              <div class="flex justify-between">
                <div class="flex items-start">
                  <div
                    class="w-10 h-10 mr-2 bg-cover bg-center rounded-2xl"
                    :style="
                      'background-image: url(\'' +
                      member.member_photo +
                      '\'); flex-shrink: 0'
                    "
                    alt="member photo"
                  ></div>
                  <div>
                    <div class="text-grey-2 font-semibold text-sm text-left">
                      {{ member.name }}
                    </div>
                    <div class="text-grey-3 font-semibold text-13 text-left">
                      {{ member.role }}
                    </div>
                    <div>
                      <div
                        class="flex md:hidden mb-2"
                        v-if="member.description"
                        @click="
                          memberIndex = memberIndex === index ? -1 : index
                        "
                      >
                        <div
                          class="-ml-1 mr-2 mt-px transition-all"
                          :class="
                            memberIndex === index ? 'transform rotate-90' : ''
                          "
                        >
                          <img src="@/assets/svg/orange-chev.svg" alt="" />
                        </div>
                        <div class="font-semibold text-sm text-orange-1">
                          {{ $t('wording.about') }}
                        </div>
                      </div>
                      <div
                        class="text-grey-3 text-sm text-left ml-5 md:ml-0 hidden md:block"
                      >
                        {{ member.description }}
                      </div>
                      <div
                        class="text-grey-3 text-sm text-left ml-5 md:ml-0 block md:hidden overflow-hidden transition-all"
                        :class="memberIndex === index ? 'max-h-160' : 'max-h-0'"
                      >
                        <p>{{ member.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="flex-shrink: 0" class="justify-self-end">
                  <a :href="member.link" target="_blank">
                    <img src="@/assets/svg/linkedin.svg" alt="linkedin" />
                  </a>
                </div>
              </div>
              <div class="hidden lg:block w-full h-px bg-grey-6 mt-4" />
            </div>
            <!-- Business model -->
            <p
              class="text-left text-grey-2 font-semibold text-opacity-80 text-sm mt-8 mb-4"
            >
              {{ $t('projects.details.business_model_title') }}
            </p>
            <div
              v-html="project.promoter.business_model"
              class="text-sm text-grey-2 text-opacity-80 text-left indented-custom-bullet"
            />
            <div class="my-4 bg-white rounded-2xl p-6">
              <div class="flex items-center justify-between mb-4">
                <p class="text-sm text-grey-2 text-left text-opacity-80">
                  {{ $t('projects.details.activity') }}
                </p>
                <p class="text-sm text-grey-2 font-semibold text-right">
                  {{ project.promoter.company_year }}
                </p>
              </div>
              <div class="hidden lg:block w-full h-px bg-grey-6 mb-4" />
              <div class="flex items-center justify-between mb-4">
                <p class="text-sm text-grey-2 text-left text-opacity-80">
                  {{ $t('projects.details.fiscal_country') }}
                </p>
                <p class="text-sm text-grey-2 font-semibold text-right">
                  {{ project.promoter.promoter_fiscal_country }}
                </p>
              </div>
              <div class="hidden lg:block w-full h-px bg-grey-6 mb-4" />
              <div class="flex items-center justify-between mb-4">
                <p class="text-sm text-grey-2 text-left text-opacity-80">
                  {{ $t('projects.details.operating_in') }}
                </p>
                <p class="text-sm text-grey-2 font-semibold text-right">
                  {{ project.promoter.company_operation_in }}
                </p>
              </div>
              <div class="hidden lg:block w-full h-px bg-grey-6 mb-4" />
              <div class="flex items-center justify-between mb-4">
                <p class="text-sm text-grey-2 text-left text-opacity-80">
                  {{ $t('projects.details.gp_loans') }}
                </p>
                <p class="text-sm text-grey-2 font-semibold text-right">
                  {{ project.promoter.gp_loans }}
                </p>
              </div>
              <div class="hidden lg:block w-full h-px bg-grey-6 mb-4" />
              <div class="flex items-center justify-between">
                <p class="text-sm text-grey-2 text-left text-opacity-80">
                  {{ $t('projects.details.women_on_board') }}
                </p>
                <p class="text-sm text-grey-2 font-semibold text-right">
                  {{ project.promoter.woman_on_board }}
                </p>
              </div>
            </div>
            <!-- Company url -->
            <div class="w-full flex items-center justify-center">
              <a
                :href="project.promoter.company_url"
                target="_blank"
                class="text-sm font-semibold orange-link mb-8"
              >
                {{ project.promoter.company_url }}
              </a>
            </div>
            <!-- Partners -->
            <p
              class="text-left text-grey-2 font-semibold text-opacity-80 text-sm mb-4"
              v-if="project.partners && project.partners.length"
            >
              {{ $t('projects.details.partners_title') }}
            </p>
            <div
              v-for="(partner, index) in project.partners"
              :key="partner.id"
              :class="{ 'mb-6': index !== project.partners.id }"
            >
              <a class="flex items-center mb-4" :href="partner.url">
                <img
                  v-if="partner.partner_logo"
                  :src="partner.partner_logo.path"
                  alt="logo"
                  class="w-12 h-12 rounded-xl mr-3"
                />
                <p class="text-grey-2 text-opacity-80 text-sm font-bold">
                  {{ partner.name }}
                </p>
              </a>
              <div
                v-html="partner.description"
                class="text-sm text-grey-2 text-opacity-80 text-left indented-custom-bullet"
              />
            </div>
            <!-- Documents -->
            <!-- Keeping for potential use in the future -->
            
            <!-- <p
              v-if="project.state === 'campaign'"
              class="hidden lg:block text-left text-grey-2 font-semibold text-opacity-80 text-sm mb-4"
            >
              {{ $t('projects.details.documents_title') }}
            </p> -->
            
            <!-- Updates -->
            <div class="mb-12" v-if="project.timelines && project.state !== 'funded' && project.state !== 'settled'">
              <TimelineBox :projectId="project.id" :last-update="lastUpdate"/>
            </div>
            <!-- Blur behind the mobile button -->
            <div
              v-if="investableState"
              v-show="
                !(project.state === 'reserved' && profile.kyc_level === 'light')
              "
              class="bg-green-8 bg-opacity-80 w-full sticky bottom-12 lg:hidden h-24 filter blur-xl"
            />
            <!-- Button mobile -->
            <router-link :to="'/mkt-purchase/' + slug" v-if="offer">
              <GpButton
                class="sticky z-50 lg:hidden"
                :class="isIos ? 'bottom-32' : 'bottom-16'"
                :disabled="!isKycRegular || !profile.dealer_approved || isBorrower || !profile.expired_suitability"
              >
                {{ $t('wording.purchase') }}
              </GpButton>
            </router-link>
            <GpButton
              v-else-if="investableState && !campaign"
              v-show="
                !(
                  project.state === 'reserved' &&
                  profile.kyc_level === 'light' &&
                  !profile.expired_suitability
                ) ||
                (reserved && reserved.length)
              "
              class="sticky z-50 lg:hidden"
              :class="isIos ? 'bottom-32' : 'bottom-16'"
              :color="
                reservationsOnly || (reserved && reserved.length) ||
                profile.kyc_level === 'light' ||
                project.state === 'reserved'
                  ? 'grey'
                  : 'orange'
              "
              @click="invest"
            >
              <span
                v-if="
                (reservationsOnly &&
                  (!reserved || (reserved && !reserved.length)))||
                  profile.kyc_level === 'light' &&
                  project.state === 'campaign'
                "
              >
                {{ $t('wording.reserve') }}
              </span>
              <span v-else-if="reserved && reserved.length">
                {{ $t('projects.details.investments.reservation_details') }}
              </span>
              <!-- <span v-else-if="project.state === 'reserved' && waitingList && waitingList.length">
                {{ $t('projects.details.waiting_list_details') }}
              </span> -->
              <!-- <span v-else-if="project.state === 'reserved'">
                {{ $t('projects.details.waiting_list_btn') }}
              </span> -->
              <span v-else-if="investments && investments.length">
                {{ $t('projects.details.investments.more') }}
              </span>
              <span v-else>{{ $t('wording.invest') }}</span>
            </GpButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade" mode="out-in">
    <div
      v-if="!allLoaded"
      class="lg:pt-20 absolute top-0 w-full lg:w-2/3 z-50"
    >
      <div class="relative">
        <div
        class="project-card project-card--skeleton h-72 lg:h-60 lg:mr-80 lg:mt-16 mb-8 lg:mb-0"
      >
        <div
          class="project-card__header project-card__header--skeleton project-card__header--no-rounded block lg:hidden h-full lg:rounded-3xl"
        ></div>
        <div
          class="project-card__header project-card__header--skeleton h-full lg:rounded-3xl hidden lg:block"
        ></div>
      </div>
      <div
        class="project-card project-card--skeleton h-96 lg:mr-80 lg:mt-8 mx-6 lg:mx-0"
      >
        <div
          class="project-card__header project-card__header--skeleton h-full rounded-3xl"
        ></div>
      </div>
      <div
        class="project-card project-card--skeleton h-96 lg:mr-80 lg:mt-8 mx-6 lg:mx-0 mt-8 block lg:hidden"
      >
        <div
          class="project-card__header project-card__header--skeleton h-full rounded-3xl"
        ></div>
      </div>
    </div>
      <div
        class="lg:w-2/3 lg:mx-auto lg:fixed lg:top-16 lg:flex lg:justify-end lg:top-40 z-0 hidden lg:block"
      >
        <div class="project-card project-card--skeleton h-96 w-72">
          <div
            class="project-card__header project-card__header--skeleton h-full rounded-3xl"
          ></div>
        </div>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref, computed, onBeforeMount, onUnmounted, onMounted } from 'vue'
import ProjectProgressBar from '@/components/general/ProjectProgressBar.vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import RiskAndCategory from '@/components/projects/RiskAndCategory.vue'
import ImpactCarousel from '@/components/impact/ImpactCarousel'
import TransactionListElement from '@/components/transactions/TransactionListElement.vue'
import Tabs from '@/components/projects/Tabs.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useMarketplace from '@/mixins/useMarketplace.js'
import States from '@/components/projects/States.vue'
import TemporaryPopup from '@/components/dashboard/TemporaryPopup.vue'
import Back from '@/components/general/Back.vue'
import Link from '@/components/general/Link.vue'
import Conditions from '@/components/projects/Conditions.vue'
import OfferTags from '@/components/projects/OfferTags.vue'
import Badge from '@/components/general/Badge'
// import TopupProofPopup from '@/components/general/TopupProofPopup.vue'
import GpButton from '@/components/general/GpButton.vue'
import SDGCarousel from '@/components/projects/SDGCarousel'
import TimelineBox from "@/components/projects/TimelineBox.vue";

export default {
  components: {
    TimelineBox,
    SDGCarousel,
    Badge,
    RiskAndCategory,
    States,
    Back,
    GpButton,
    Tabs,
    ProjectProgressBar,
    TransactionListElement,
    // TopupProofPopup,
    Conditions,
    OfferTags,
    TemporaryPopup,
    Link,
    ImpactCarousel
  },
  props: {
    campaign: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const isIos = ref(false)
    const innerWidth = ref(window.innerWidth)
    const currentTab = ref(1)
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting, riskConversion, slugIt } = useGlobalHelpers()
    const { seller } = useMarketplace()
    const allLoaded = ref(false)
    const memberIndex = ref(-1)
    const kycsConditions = ref(null)

    onBeforeMount(async () => {
      await store.dispatch('fetchProfile')
      await store.dispatch('getKycs')
      kycsConditions.value = await store.getters.getKycsConditions()
      await store.dispatch('setKycsConditions', kycsConditions.value)

      if (props.campaign)
        await store.dispatch('getCampaign', route.params.id.split('-').pop())
      else {
        await store.dispatch('getProject', route.params.id.split('-').pop())
        await store.dispatch(
          'fetchInvestments',
          route.params.id.split('-').pop()
        )
        if (route.query.offer)
          await store.dispatch('fetchOffer', route.query.offer)
      }
      allLoaded.value = true
    })

    window.gonative_device_info = function (deviceInfo) {
      if (deviceInfo) {
        isIos.value = deviceInfo.platform === 'ios'
      }
    }

    onMounted(() => {
      // if (route.query.ififc) downloadIfifc()
      window.addEventListener('scroll', updateCurrentTab)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', updateCurrentTab)
    })

    const reservationsOnly = computed(() => {
      return process.env.VUE_APP_RESERVATIONS_ONLY == 'true'
    })

    const offerId = computed(() => {
      return route.query.offer ? parseInt(route.query.offer) : null
    })

    const offer = computed(() => {
      return route.query.offer ? store.state.offers.offer : null
    })

    const downloadIfifc = () => {
      axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/projects/' +
            parseInt(route.params.id.split('-').pop()) +
            '/ififc',
          { responseType: 'arraybuffer' }
        )
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'borrower_financial_info.pdf'
          link.click()
        })
        .catch((e) => {
          store.dispatch('setError', e)
        })
    }

    const updateCurrentTab = () => {
      let extraOffset = window.innerWidth < 1024 ? 128 : 256
      if (
        Math.round(window.pageYOffset) > 0 &&
        Math.round(window.pageYOffset) <
          document.getElementById('impact')?.offsetTop + extraOffset
      )
        // eslint-disable-next-line no-undef
        currentTab.value = 1
      if (
        Math.round(window.pageYOffset) >=
          document.getElementById('impact')?.offsetTop + extraOffset &&
        Math.round(window.pageYOffset) <
          document.getElementById('viability')?.offsetTop + extraOffset
      )
        // eslint-disable-next-line no-undef
        currentTab.value = 2
      if (
        Math.round(window.pageYOffset) >=
          document.getElementById('viability')?.offsetTop + extraOffset &&
        Math.round(window.pageYOffset) <
          document.getElementById('promoter')?.offsetTop + extraOffset
      )
        // eslint-disable-next-line no-undef
        currentTab.value = 3
      if (
        Math.round(window.pageYOffset) >=
        document.getElementById('promoter')?.offsetTop + extraOffset
      )
        // eslint-disable-next-line no-undef
        currentTab.value = 4
    }

    const investments = computed(() => {
      return store.getters.getPendingInvestments
    })

    const reserved = computed(() => {
      return store.getters.getWaitingInvestments
    })

    const waitingList = computed(() => {
      return store.getters.getWaitingListInvestments
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const isBorrower = computed(() => {
      if (profile.value && profile.value.sub_type === 'borrower') return true
      return false
    })

    const project = computed(() => {
      return store.state.projects.project
    })

    const anchors = computed(() => {
      return ['about', 'impact', 'viability', 'promoter']
    })

    const lastUpdate = computed(() => {
      return project.value.timelines[0]
    })
    const isMobile = computed(() => {
      return innerWidth.value < 768
    })

    const investableState = computed(() => {
      return ['campaign', 'reserved'].includes(project.value.state)
    })

    const invest = () => {

      if (reserved.value && reserved.value.length) {
        store.dispatch('storeReservation', reserved.value[0])
        router.push({
          path: '/reservation-details'
        })
      } else if (waitingList.value && waitingList.value.length) {
        store.dispatch('storeReservation', waitingList.value[0])
        router.push({
          path: '/reservation-details'
        })
      } else {
        router.push({
          name: 'invest-wallet',
          params: { project: route.params.id.split('-').pop() }
        })
      }
    }

    const isKycRegular = computed(() => {
      return profile.value.kyc_level === 'regular'
    })

    const kycConditionsResult = computed(() => {
      return store.state.kycs ? store.state.kycs.conditions : null
    })

    const slug = computed(() => {
      return project.value.name
        ? slugIt(project.value.name) + '-' + offer.value.id
        : offer.value.id
    })

    const simulate = () => {
      let simulationSlug = project.value.name
        ? slugIt(project.value.name) + '-' + project.value.id
        : project.value.id
      if (offer.value)
        router.push({
          path: '/simulation/' + simulationSlug,
          query: { offer: offer.value.id, amount: offer.value.offer_amount }
        })
      else router.push('/simulation/' + simulationSlug)
    }

    const cards = computed(() => {
      let finalCards = []
      if (Math.round(project.value.impact_metrics.kwh_clean_energy / 1000) > 0)
        finalCards.push({
          key: 'clean_energy',
          title: i18n.t('impact.impact.clean_energy.title'),
          longTitle: i18n.t('impact.impact.clean_energy.long_title'),
          description: i18n.t('impact.impact.clean_energy.description'),
          value: project.value.impact_metrics.kwh_clean_energy / 1000,
          rank: 21,
          suffix: 'MWh',
          totalRank: 5768,
          bg: 'blue-night',
          dark: true
        })
      if (Math.round(project.value.impact_metrics.people_impacted) > 0)
        finalCards.push({
          key: 'people',
          title: i18n.t('impact.impact.people.title'),
          longTitle: i18n.t('impact.impact.people.long_title'),
          description: i18n.t('impact.impact.people.description'),
          value: project.value.impact_metrics.people_impacted,
          rank: 21,
          totalRank: 5768,
          bg: 'green-mint',
          dark: false
        })
      if (Math.round(project.value.impact_metrics.jobs_generated) > 0)
        finalCards.push({
          key: 'jobs',
          title: i18n.t(
            'impact.impact.jobs.title',
            project.value.impact_metrics.jobs_generated == 1 ? 1 : 0
          ),
          longTitle: i18n.t(
            'impact.impact.jobs.long_title',
            project.value.impact_metrics.jobs_generated == 1 ? 1 : 0
          ),
          description: i18n.t('impact.impact.jobs.description'),
          value: project.value.impact_metrics.jobs_generated,
          rank: 21,
          totalRank: 5768,
          bg: 'green-lime',
          dark: false
        })
      if (Math.round(project.value.impact_metrics.ha_impacted) > 0)
        finalCards.push({
          key: 'sustainable_land',
          title: i18n.t('impact.impact.sustainable_land.title'),
          longTitle: i18n.t('impact.impact.sustainable_land.long_title'),
          description: i18n.t('impact.impact.sustainable_land.description'),
          value: project.value.impact_metrics.ha_impacted,
          suffix: 'ha',
          rank: 21,
          totalRank: 5768,
          bg: 'orange-3',
          dark: true
        })
      return finalCards
    })

    return {
      project,
      dollarFormatting,
      riskConversion,
      downloadIfifc,
      currentTab,
      anchors,
      locale: i18n.locale,
      lastUpdate,
      invest,
      investments,
      reserved,
      waitingList,
      profile,
      isBorrower,
      isMobile,
      investableState,
      isIos,
      offerId,
      offer,
      seller,
      isKycRegular,
      kycsConditions,
      kycConditionsResult,
      slug,
      simulate,
      memberIndex,
      allLoaded,
      cards,
      reservationsOnly
    }
  }
}
</script>

<style scoped>
.white-text {
  color: rgba(255, 255, 255);
}
.white-text-60 {
  color: rgba(255, 255, 255, 0.6);
}
ul {
  /*@apply list-disc;*/
  padding-left: 1rem;
}

@media (max-width: 1024px) {
  .margin-campaign {
    margin: 0px -24px;
    width: calc(100% + 47px);
  }
}
</style>
