<template>
  <div>
    <l-map
      :zoom="2"
      :center="[36.441161, -5.767338]"
      style="height: 55vh"
      class="md:rounded-3xl z-0"
      :options="{
        zoomControl: false,
        doubleClickZoom: true,
        dragging: true,
        scrollWheelZoom: true,
        worldCopyJump: true
      }"
    >
      <l-tile-layer
        :url="'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}'"
        :options="{ attribution: null, noWrap: true }"
      />
      <l-geo-json
        :geojson="require('@/assets/json/geojson.json')"
        :options="{ style: geoJsonStyle }"
      />
      <heatmap
        :data="heatmapData"
        :gradient="{ 0.0: 'rgba(0, 231, 99, 0)', 1.0: '#00e763' }"
        :radius="5"
        :use-local-extrema="false"
        :max="7"
      />
    </l-map>
  </div>
</template>

<script>
import { LMap, LGeoJson, LTileLayer } from '@vue-leaflet/vue-leaflet'
import useCountriesLocation from '@/assets/js/countries_location'
import Heatmap from './Heatmap'
import 'leaflet/dist/leaflet.css'
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  components: { LMap, LGeoJson, LTileLayer, Heatmap },

  setup() {
    const { getCountriesLocation } = useCountriesLocation()
    const userCoord = ref([36.441161, -5.767338])
    const store = useStore()
    const heatmapData = ref([])
    const impact = computed(() => {
      return store.state.impact.impact
    })
    onMounted(() => {
      getLocation()
      if (impact.value.project_countries) {
        impact.value.project_countries.forEach((element) => {
          const country = getCountriesLocation().find((el) => {
            return el.alpha2 === element.code
          })
          heatmapData.value.push({
            lat: country.latitude,
            lng: country.longitude,
            count: element.value
          })
        })
      }
    })

    function geoJsonStyle() {
      return {
        opacity: 1,
        color: 'transparent',
        fillOpacity: 1
      }
    }

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition)
      }
    }

    function showPosition(position) {
      userCoord.value = [position.coords.latitude, position.coords.longitude]
    }

    return { geoJsonStyle, heatmapData }
  }
}
</script>

<style lang="css" scoped></style>
