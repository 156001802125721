export default () => {
  function isRequired(value) {
    if (value && typeof value === 'string' && value.trim() !== '') {
      return true
    } else if (value && typeof value === 'object') {
      return true
    } else if (value) {
      return true
    }
    return false
  }
  return { isRequired }
}
