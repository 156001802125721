<template>
  <div
    class="
      bg-white
      text-left
      rounded-3xl
      p-4
      text-grey-4
      green-shadow
      transition
      ease-in-out
      duration-200
      transform
      hover:shadow-light hover:-translate-y-1
    "
  >
    <div class="flex items-start justify-between">
      <div>
        <div>
          <div>
            {{
              $t(
                'promoter.next_dd.title',
                isFailed || state === 'SUCCEEDED' ? 2 : 1
              )
            }}
          </div>
          <div
            class="font-bold text-sm text-grey-3 mb-4"
            v-if="isFailed || state === 'SUCCEEDED'"
          >
            {{ date }}
          </div>
          <div
            class="text-lg text-grey-3 font-bold mb-3 green-shadow"
            v-if="state && state !== 'SUCCEEDED' && !isFailed"
          >
            {{
              $t('promoter.next_dd.when', {
                amount:  '$ ' + dollarFormatting(amount),
                days: days
              })
            }}
          </div>

          <div
            v-if="state === 'SUCCEEDED'"
            class="text-sm"
            v-html="$t('promoter.next_dd.content_succeeded', { days: days })"
          ></div>
        </div>
      </div>
      <div
        class="text-white rounded text-sm px-1"
        :class="stateBg[state.toLowerCase()]"
        v-if="state && state !== 'TO_BE_ORDERED'"
      >
        {{ $t('promoter.next_dd.status.' + state.toLowerCase()) }}
      </div>
    </div>
    <div
      v-if="state !== 'SUCCEEDED'"
      class="text-sm"
      :class="{ 'md:w-2/3': isFailed }"
      v-html="$t('promoter.next_dd.content', { fee: fee }, isFailed ? 2 : 1)"
    ></div>
  </div>
</template>
<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
import { ref, computed } from 'vue'

export default {
  props: {
    state: {
      type: String,
      default: 'created'
    },
    date: String,
    days: Number,
    amount: Number,
    fee: {
      type: String,
      default: '2460'
    }
  },
  setup(props) {
    const { dollarFormatting } = useGlobalHelpers()
    const stateBg = ref({
      succeeded: 'bg-green-1',
      created: 'bg-grey-1',
      failed: 'pulse bg-failed'
    })

    const isFailed = computed(() => {
      return props.state === 'FAILED'
    })

    return { dollarFormatting, stateBg, isFailed }
  }
}
</script>
<style>
.bg-failed {
  background: #e98873;
}
.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(233, 136, 115, 0.7);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(233, 136, 115, 0);
  }
}
</style>
