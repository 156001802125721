<template>
  <div
    class="
      flex
      justify-between
      items-center
      bg-white
      p-4
      rounded-3xl
      shadow-greenInput
      mb-6
      transition
      ease-in-out
      duration-200
      cursor-pointer
      transform
      hover:shadow-light hover:-translate-y-1
    "
  >
    <div class="flex">
      <div class="w-4 mr-2 mt-1">
        <img src="@/assets/svg/profile.svg" alt="" />
      </div>
      <div class="text-left">
        <div class="font-bold text-sm text-grey-2 opacity-80 mb-1">
          {{ beneficiary.name }} {{ beneficiary.surname }}
        </div>
        <div class="text-grey-2 opacity-50 text-13">
          <div>{{ beneficiary.address }}</div>
          <div>
            {{ beneficiary.post_code }}, {{ beneficiary.city_of_birth }},
            {{ beneficiary.country_of_birth }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <img src="@/assets/svg/chev-right-2.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    beneficiary: {
      type: Object,
      default: () => {}
    }
  },
  setup() {}
}
</script>

<style lang="scss" scoped></style>
