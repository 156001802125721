<template>
  <div
    class="
      p-4
      text-left
      rounded-3xl
      impact-card
      transition-all
      transform
      duration-700
      ease-in-out
      relative
    "
    :class="[
      'bg-' + card.bg + ' ' + (card.dark ? 'text-white' : 'text-grey-1'),
      noValue ? 'w-28 h-32' : small ? 'w-40 h-56' : 'h-56 md:h-40'
    ]"
    @mouseover="showExpand = true"
    @mouseleave="showExpand = false"
    @click="expand"
  >
    <div v-if="card.key && noValue" class="w-full mb-4">
      <img
        :src="require('@/assets/svg/impact/' + card.key + '.svg')"
        class="mx-auto h-10"
        alt=""
      />
    </div>
    <div
      class="absolute right-4 top-4 bg-white p-3 rounded-xl cursor-pointer z-40"
      v-if="showExpand && !small"
      @click="expand"
    >
      <img src="@/assets/svg/expand.svg" alt="" />
    </div>
    <div v-if="!noValue" class="text-2xl font-bold mt-2 mb-2">
      {{ card.value ? Math.round(card.value) : 0 }} {{ card.suffix }}
    </div>
    <div
      class="relative font-medium text-sm z-40"
      :class="[
        {
          'md:font-semibold md:text-base': !small
        },
        noValue ? 'text-center' : 'w-32'
      ]"
    >
      {{ card.title }}
    </div>
    <div
      v-if="!noValue"
      class="
        absolute
        right-8
        w-28
        -bottom-8
        -translate-y-1/2 -translate-x-1/2
        left-1/2
        transform
        z-30
      "
      :class="{
        'md:bottom-auto md:top-1/2 md:left-auto md:-translate-x-0': !small
      }"
    >
      <img
        :src="require('@/assets/svg/impact/' + card.key + '.svg')"
        alt=""
        v-if="card.key"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    card: {
      type: Object,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    },
    noValue: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const showExpand = ref(false)
    const store = useStore()
    const value = ref(0)
    function expand() {
      if (!props.small) store.dispatch('setImpactPopup', props.card)
    }

    onMounted(() => {
      value.value = props.card.value
    })

    watch(
      () => props.card,
      () => {
        (async () => {
          value.value = 0
          var i = 0
          var x = Math.ceil(props.card.value / 500)
          while (
            (await new Promise((resolve) =>
              setTimeout(
                () => resolve(i++),
                props.card.value > 0 ? (2000 * x) / props.card.value : 0
              )
            )) < props.card.value
          ) {
            if (value.value + x <= props.card.value) value.value += x
            else value.value = props.card.value
          }
        })()
      },
      { deep: true }
    )

    return { showExpand, expand, value }
  }
}
</script>

<style lang="css" scoped>
.impact-card {
  animation: appear 0.5s ease-out;
  animation-fill-mode: both;
}
</style>
