<template>
  <div>
    <div
      class="h-2 bottom-0 z-30 absolute w-2 log"
      style="left: 10px; bottom: 41px"
    >
      <img
        :src="
          require('@/assets/svg/impact/gamification/components/' +
            (blue ? 'green-leaf-branch' : 'leaf-branch') +
            '.svg')
        "
        alt=""
        class="w-full"
        style="height: inherit"
      />
    </div>

    <div class="w-4 bottom-16 z-20 absolute" style="left: 2px">
      <img
        :src="
          require('@/assets/svg/impact/gamification/components/' +
            (blue ? 'blue-leaf' : 'brown-leaf') +
            '.svg')
        "
        alt=""
        class="w-full treetop"
      />
    </div>

    <div class="absolute transform" style="--tw-rotate: -30deg; left: 15px">
      <div
        class="h-1 bottom-0 z-30 absolute w-1 log"
        style="left: 22px; bottom: 32px"
      >
        <img
          :src="
            require('@/assets/svg/impact/gamification/components/' +
              (blue ? 'green-leaf-branch' : 'leaf-branch') +
              '.svg')
          "
          alt=""
          class="w-full"
          style="height: inherit"
        />
      </div>

      <div class="w-2 z-20 absolute" style="left: 18px; bottom: 53px">
        <img
          :src="
            require('@/assets/svg/impact/gamification/components/' +
              (blue ? 'blue-leaf' : 'brown-leaf') +
              '.svg')
          "
          alt=""
          class="w-full treetop"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    blue: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
}
</script>
<style scoped>
.log {
  animation: log-grow 0.5s ease-out forwards;
}

.treetop {
  transform: scale(0);
  transform-origin: center;
  animation: scale-top 0.5s ease-out forwards;
}

.berry {
  transform: scale(0);
  transform-origin: center;
  animation: scale-berry 0.2s ease-out forwards;
}

@keyframes scale-top {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-berry {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes log-grow {
  0% {
    height: 0px;
  }
  70% {
    height: 2.2rem;
  }
  100% {
    height: 2rem;
    overflow: auto !important;
  }
}
</style>
