<template>
  <div>
    <div class="flex relative items-center my-2">
      <div v-if="currentStep > step" class="flex items-center w-5 h-5">
        <img src="@/assets/svg/done_step.svg" alt="" />
      </div>
      <img
        v-if="currentStep === step"
        class="absolute left-0"
        src="@/assets/svg/active_step.svg"
        alt=""
      />
      <p
        v-if="currentStep <= step"
        class="text-grey-2 w-6 text-11"
        :class="{
          'text-opacity-100 font-bold': currentStep === step,
          'text-opacity-20': currentStep < step
        }"
      >
        <span v-if="step < 10">0</span>{{ step }}
      </p>
      <p
        class="text-grey-2 text-sm ml-2"
        :class="{
          'text-opacity-100 font-bold': currentStep === step,
          'text-opacity-50': currentStep < step,
          'text-opacity-80': currentStep > step
        }"
      >
        {{ $t(copy + '.step_' + step) }}
      </p>
    </div>
    <div v-if="subSteps[step] && currentStep === step">
      <div
        v-for="n in subSteps[step]"
        :key="n"
        class="flex items-center pl-12 mb-2"
      >
        <img
          v-if="n === currentSubStep"
          src="@/assets/svg/active-substep.svg"
          alt="active"
          class="mr-2 w-2 h-2"
        />
        <img
          v-else-if="n < currentSubStep"
          src="@/assets/svg/done_step.svg"
          alt="done"
          class="mr-2 w-2 h-2"
        />
        <p
          class="text-13 text-grey-2 text-opacity-80 text-left"
          :class="{
            'font-semibold': n === currentSubStep
          }"
        >
          {{ $t(copy + '.step_' + step + '_' + n) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
export default {
  props: {
    current: {
      type: String,
      default: '1'
    },
    step: {
      type: Number,
      default: 1
    },
    copy: {
      type: String,
      default: ''
    },
    subSteps: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const currentStep = computed(() => {
      return parseInt(props.current.split('.')[0])
    })

    const currentSubStep = computed(() => {
      const splits = props.current.split('.')
      if (splits.length > 1) return parseInt(splits[1])
      return null
    })

    return {
      currentStep,
      currentSubStep
    }
  }
}
</script>

<style lang="scss" scoped></style>
