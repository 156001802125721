<template>
  <div v-if="data">
    <div>
      <div class="pt-6 flex md:block items-center">
        <div v-if="data.back" @click="back" class="mb-2 md:mt-0">
          <Back :to="null" />
        </div>

        <div v-if="!data.hideHeader" class="steps__title--mobile md:ml-0">
          {{ data.title }}
        </div>
      </div>
      <div
        v-if="!data.hideHeader"
        class="steps__progress-bar overflow-hidden md:hidden"
      >
        <div
          class="steps__progress-bar__completed"
          :style="'width:' + progress + '%'"
        ></div>
      </div>
      <div
        v-if="!data.hideHeader"
        class="border-b border-grey-6 w-full h-2 absolute left-0 md:hidden"
      ></div>
      <div
        v-if="typeof data.subtitle === 'string'"
        class="steps__subtitle"
        v-html="data.subtitle"
      />
      <FormInfo
        v-else-if="data.subtitle"
        :img="data.subtitle.img"
        :title="data.subtitle.title"
        :subtitle="data.subtitle.subtitle"
      />
    </div>
    <Form
      @submit=";"
      class="form flex flex-col flex-grow"
      :class="data.fields.length ? 'steps justify-between mt-4 lg:mt-0' : 'justify-end'"
      ref="form"
      v-slot="{ errors, meta }"
      :style="data.fields.length ? 'height:67%' : ''"
    >
      <div v-for="field in data.fields" v-bind:key="field">
        <div v-if="!field.condition || (model[field.condition.key] == field.condition.value)" class="pb-4">
          <InfoTooltip 
            v-if="field.info"
            :copy="field.info"
            :positionXAxisMobile="'-left-72'"
            class="ml-60"
          />
          <div class="mb-2 text-grey-2" v-if="field.title">
            {{ field.title }}
          </div>
          <p class="mb-6 text-grey-3" v-if="field.subtitle"
                v-html="field.subtitle">
          </p>
          <GeneralInput
            v-model:data="model[field.key]"
            v-if="field.limit"
            :toDate="subYears(new Date(), field.limit.min)"
            :fromDate="subYears(new Date(), field.limit.max)"
            :type="field.type"
            :name="field.key"
            :error="errors[field.key]"
            :label="field.label"
            :rules="field.rules"
            :suffix="field.suffix"
            :options="field.options"
            @next="handle($event, field.key)"
            @update:data="checkDate"
          />
          <GeneralInput
            v-model:data="model[field.key]"
            v-else
            :type="field.type"
            :name="field.key"
            :error="errors[field.key]"
            :label="field.label"
            :rules="field.rules"
            :suffix="field.suffix"
            :options="field.options"
            @next="handle($event, field.key)"
          />
        </div>
      </div>

      <div v-if="data.next">
        <GeneralInput
          v-model:data="confirmation[index]"
          type="checkbox"
          :name="'confirmation-' + index"
          :error="errors['confirmation-' + index]"
          :label="el.label"
          :rules="el.required ? 'required' : ''"
          v-for="(el, index) in data.confirmations"
          v-bind:key="el"
        />
        <div class="lg:grid gap-8 lg:grid-cols-2 lg:mb-8"
             style="margin-bottom: calc(var(--sab) + 20px)"
        >
          <GpButton 
            v-if="data.skipTo"
            mobileWidth="w-full"
            desktopWidth="w-full"
            color="white"
            class="mb-4 lg:mb-0"
            @click="skip"
          >
            {{ $t('wording.skip') }}
          </GpButton>
          <GpButton
            mobileWidth="w-full"
            desktopWidth="w-full"
            type="submit"
            class="ml-auto"
            :disabled="!meta.valid || !valid"
            @click="meta.valid && valid ? next() : ''"
          >
            {{ $t('wording.continue') }}
          </GpButton>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { Form } from 'vee-validate';
import GeneralInput from '@/components/general/Input';
import FormInfo from '@/components/legislation/FormInfo';
import GpButton from '@/components/general/GpButton';
import InfoTooltip from '@/components/general/InfoTooltip.vue'
import { subYears } from 'date-fns'
import Back from '@/components/general/Back';
import { useStore } from 'vuex';
import router from '@/router';

export default {
  components: { Back, Form, GeneralInput, GpButton, FormInfo, InfoTooltip },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    progress: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: false
    },
    name:{
      type: String,
      default: '',
    },
    index:{
      type: Number,
      default:0,
    }
  },
  emits: ['next', 'back'],
  setup(props, { emit }) {
    const model = ref({});
    const form = ref(null);
    const confirmation = ref({});
    const store = useStore();

    const setFormObj = () => {
      let obj = {};
      if (props.data.nest)
        obj[props.data.subkey] = props.data.stringify
          ? JSON.stringify(model.value)
          : model.value;
      else obj = model.value;

      store.dispatch('setFormData', obj);

    }

    const next = () => {
      setFormObj()

      if (props.data.nextCallback)
        props.data.nextCallback().then(() => {
          emit('next');
        });
      emit('next');
    };

    const checkDate = () => {
      setFormObj()
      
      if (props.data.otherCallback)
        props.data.otherCallback()
    }

    const handle = (value, key) => {
      model.value[key] = value;
      next();
    };

    watch(
      () => props.isActive,
      () => {
        if (props.isActive) {
          for (let key in model.value) {
            if (
              !props.data.fields.find((el) => {
                return el.key === key;
              })
            ) {
              delete model.value[key];
            }
          }
          if (props.data.mountedCallback){
            props.data.mountedCallback()
            if(store.state.general.formData?.type) {
              model.value.type = store.state.general.formData.type 
            }
          }
        }
      },
      { deep: true }
    );

    const back = () => {
      emit('back');
    };

    const skip = () => {
      router.push(props.data.skipTo);
    };

    const valid = computed(() => {
      if (props.data) {
        for (const el in props.data.fields) {
          if (props.data.fields[el].rules?.includes('required')) {
            // Validate required radios
            if (
              props.data.fields[el] &&
              props.data.fields[el].type === 'radio' &&
              model.value[props.data.fields[el].key] === undefined
            )
              return false;

            // Validate required checkbox-group
            if (
              props.data.fields[el] &&
              props.data.fields[el].type === 'checkbox-group' &&
              (model.value[props.data.fields[el].key] === undefined ||
                !Object.keys(model.value[props.data.fields[el].key]).find(
                  (key) => model.value[props.data.fields[el].key][key] === true
                ))
            ) {
              return false;
            }
             // Validate DatePicker
             if(
              props.data.fields[el] &&
              props.data.fields[el].type === 'date' &&
              model.value[props.data.fields[el].key] === undefined
            )
              return false;
          }
        }
        for (const index in props.data.confirmations) {
          if (
            props.data.confirmations[index] &&
            props.data.confirmations[index].required &&
            !confirmation.value[index]
          )
            return false;
        }
      }
      return true;
    });

    return { 
      model, 
      next, 
      back, 
      form, 
      valid, 
      confirmation, 
      handle, 
      subYears,
      skip,
      checkDate,
      setFormObj
    };
  }
};
</script>

<style lang="scss" scoped></style>
