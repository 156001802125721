import axios from 'axios'
import { VueCookieNext } from 'vue-cookie-next'
//import useSecurity from '@/mixins/useSecurity'
import { useRoute } from "vue-router";
import store from "@/store";

export default {
  state: {
    user: null
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
    },
    CLEAR_USER_DATA() {
      location.reload()
    }
  },
  actions: {
    signup({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/signup', credentials)
          .then(({ data }) => {
            commit('SET_USER_DATA', data)
            if(data.token)
              VueCookieNext.setCookie('token', data.token, { expire: "20min" })
            let twentyMinutesLater = new Date();
            twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);
            VueCookieNext.setCookie('token_expiry', JSON.stringify(twentyMinutesLater), { expire: "20min" })
            resolve(data)
          })
          .catch((e) => {
            reject(e)          
          })
        })
    },
    login({ commit }, credentials) {
      // const { encrypt } = useSecurity()
      // credentials.password = encrypt(credentials.password)
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/login', credentials)
          .then(({ data }) => {
            commit('SET_USER_DATA', data)
            VueCookieNext.setCookie('token', data.token, { expire: "20min" })
            let twentyMinutesLater = new Date();
            twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);
            VueCookieNext.setCookie('token_expiry', JSON.stringify(twentyMinutesLater), { expire: "20min" })
            resolve(data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    fetchProfile() {
      return new Promise((resolve, reject) => {
        const route = useRoute()
        axios
          .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profile')
          .then((data) => {
            VueCookieNext.setCookie('profile', data.data, {
              path: '/',
              domain: '',
              secure: true
            })
            resolve(data)
          })
          .catch((e) => {
            if (
              e.response &&
              e.response.status === 404
            ) {
              if(route && route.name !== 'onboarding' && route.name !== 'activate' && route.name !== 'verification-code'){
                const lang = e.response.data && e.response.data.code ? ('/' + e.response.data.code) : ''
                window.location.href = lang + '/onboarding'
              }

              if(e.response.data.profile && (e.response.data.profile.surname === 'particular' || e.response.data.profile.surname === 'companny'))
                  VueCookieNext.setCookie('profile', e.response.data.profile, {
                    path: '/',
                    domain: '',
                    secure: true
                  })
              store.dispatch('setTrigger', e.response.data.profile)
            }
            reject(e)
          })
      })
    },
    logout({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/invalidate', {
            token: token
          })
          .then(({ data }) => {
            VueCookieNext.keys().forEach((cookieName) => {
              VueCookieNext.removeCookie(cookieName)
            })
            commit('CLEAR_USER_DATA', data)
            resolve(data)
          })
          .catch((e) => {
            VueCookieNext.keys().forEach((cookieName) => {
              VueCookieNext.removeCookie(cookieName)
            })
            commit('CLEAR_USER_DATA')
            window.location.href='/login'
            reject(e)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    forgotPassword({}, email) {
      return axios.post(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/password/forgot',
        email
      )
    },
    // eslint-disable-next-line no-empty-pattern
    changePassword({}, data) {
      return axios.put(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/password',
        data
      )
    },
    // eslint-disable-next-line no-empty-pattern
    resetPassword({}, params) {
      return new Promise((resolve, reject) =>{
        axios.post(
          process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/password/reset/public',
          params
        ).then((data) =>{
          resolve(data.data)
        }).catch((e)=>{
          reject(e)
        })
      })
    },
    refreshToken(){
      return new Promise((resolve, reject)=>{
        let token = VueCookieNext.getCookie('token')
        if(token)
          axios
            .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/refresh', {
              token: token
            }).then(({ data }) => {
            VueCookieNext.removeCookie('token')
            VueCookieNext.setCookie('token', data.token, { expire: "20min" })
            let twentyMinutesLater = new Date();
            twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);
            VueCookieNext.setCookie('token_expiry', JSON.stringify(twentyMinutesLater), { expire: "20min" })
            resolve()
          }).catch(()=>{
            reject()
          })
      })

    }
  },
  getter: {
    loggedIn(state) {
      return !!state.user
    }
  }
}
