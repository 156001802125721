<template>
  <div>
    <template v-if="loans.length > 0">
      <transition-group
        name="list-complete"
        tag="div"
        class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8"
      >
        <LoanCard
          :project="loan"
          v-for="(loan, index) in loans"
          v-bind:key="loan"
          :style="
            'animation-delay: ' +
            200 * (index - 6 * Math.floor(index / 6)) +
            'ms'
          "
        />
      </transition-group>
    </template>
    <div class="bg-white col-span-2 p-4 rounded-3xl text-left mt-6" v-else>
      <div class="flex items-start">
        <img src="@/assets/svg/no_activity.svg" alt="" class="mr-4" />
        <div>
          <div class="text-sm text-grey-3 mb-1">
            {{ $t('promoter.loans.tabs.' + state + '.no_results.title') }}
          </div>
          <div class="text-13 text-grey-4">
            {{ $t('promoter.loans.tabs.' + state + '.no_results.content') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoanCard from '@/components/promoter/LoanCard.vue'
import { useStore } from 'vuex'
import { watch, computed, ref } from 'vue'

export default {
  components: { LoanCard },
  props: {
    state: {
      type: String,
      default: 'funded'
    }
  },
  emits: ['empty'],
  async setup(props, { emit }) {
    const store = useStore()
    const loans = ref([])
    const order = computed(() => {
      return store.state.promoters.order
    })

    const orderLoans = () => {
      if (order.value === 'payment') {
        loans.value.sort(function (a, b) {
          return (
            (a.next_payment ? a.next_payment.days_to_next_payment : 0) -
            (b.next_payment ? b.next_payment.days_to_next_payment : 0)
          )
        })
      } else if (order.value === 'direct_debit') {
        loans.value.sort(function (a, b) {
          return (
            (a.next_direct_debit ? a.next_direct_debit.days_left : 0) -
            (b.next_direct_debit ? b.next_direct_debit.days_left : 0)
          )
        })
      } else if (order.value === 'outstanding') {
        loans.value.sort(function (a, b) {
          return (
            (b.loan_status ? b.loan_status.capital_outstanding : 0) -
            (a.loan_status ? a.loan_status.capital_outstanding : 0)
          )
        })
      } else if (order.value === 'contract') {
        loans.value.sort(function (a, b) {
          return new Date(b.start_date) - new Date(a.start_date)
        })
      } else if (order.value === 'loan_amount') {
        loans.value.sort(function (a, b) {
          return (
            (b.loan_status ? b.loan_status.loan_amount : 0) -
            (a.loan_status ? a.loan_status.loan_amount : 0)
          )
        })
      } else if (order.value === 'settlement_date') {
        loans.value.sort(function (a, b) {
          return (
            new Date(b.settled_at ?? '01/01/97') -
            new Date(a.settled_at ?? '01/01/97')
          )
        })
      }
    }
    const filterLoans = () => {
      loans.value = JSON.parse(JSON.stringify(store.state.promoters.loans.filter((el) => {
        return el.state === props.state
      })))
      emit('empty', loans.value.length !== 0)
      orderLoans()
    }

    watch(
      () => store.state.promoters.order,
      () => {
        orderLoans()
      }
    )

    watch(
      () => props.state,
      () => {
        filterLoans()
      }
    )

    await store.dispatch('fetchLoans')

    filterLoans()

    return {
      loans
    }
  }
}
</script>
<style scoped>
.list-complete-move {
  transition: transform 0.5s;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>
