<template>
  <div class="steps">
    <span>
      <div class="steps__title--mobile pt-6 lg:pt-0">
        {{ $tm('invest.proof.title') }}
      </div>
      <div class="steps__subtitle">
        {{ $tm('invest.proof.subtitle') }}
      </div>
      <div class="bg-white p-4 rounded-3xl">
        <file-upload
          ref="upload"
          v-model="files"
          accept="image/png,image/jpeg,image/jpg,application/pdf"
          :extensions="['jpg', 'jpeg', 'png', 'pdf']"
          :size="1024 * 1024 * 7"
          @dragenter="detectDrag(true)"
          @dragleave="detectDrag(false)"
          input-id="file1"
          @mouseleave="detectDrag(false)"
          @dragover="moveCursor"
          post-action="/upload/post"
          :multiple="false"
          :maximum="1"
          :thread="2"
          :drop="'.drop-area'"
          class="steps__upload relative w-full"
          :class="{
            'opacity-50 cursor-not-allowed pointer-events-none':
              files.length > 1
          }"
        >
          <div
            class="drop-area w-full h-full z-50 absolute cursor-pointer"
            @click.prevent="addFile(false)"
          ></div>
          <div
            class="
              absolute
              top-2/4
              left-2/4
              transform
              w-full
              -translate-y-1/2 -translate-x-1/2
            "
          >
            <div class="flex justify-center items-center">
              <div class="w-5 mr-3">
                <img src="@/assets/ico/onboarding/upload.svg" alt="" />
              </div>
              <div class="text-sm uppercase">
                {{ $tm('onboarding.kyc.upload.upload_files') }}
              </div>
            </div>
          </div>
          <div
            v-for="(file, index) in files"
            v-bind:key="file"
            class="
              py-2
              px-4
              justify-between
              font-bold
              h-full
              w-full
              z-50
              absolute
            "
            :class="
              validImg(file) ? 'steps__upload--success' : 'steps__upload--error'
            "
          >
            <div
              class="
                absolute
                left-1/2
                top-1/2
                -translate-y-1/2 -translate-x-1/2
                transform
                w-full
                break-all
                px-5
              "
            >
              {{ file.name }}
            </div>
            <div
              class="w-4 cursor-pointer absolute right-4 top-4"
              @click="
                () => {
                  files.splice(index, 1)
                  error = null
                }
              "
            >
              <img src="@/assets/svg/garbage.svg" alt="" />
            </div>
          </div>
          <div
            :style="cursorCircle"
            class="g-cursor__circle"
            :class="isDragging ? '' : 'opacity-0'"
          ></div>
        </file-upload>
      </div>
      <div class="text-right text-red-primary text-sm mt-1">
        {{ error }}
      </div>
    </span>
    <div class="absolute lg:bottom-8 bottom-24 w-4/5 md:w-3/4">
      <div
        class="text-grey-700 opacity-75 mt-3 mb-5 text-13 absolute bottom-20"
      >
        {{ $t('invest.proof.time_left', { hours: hours }) }}
      </div>
      <GpButton
        :disabled="!valid || loading"
        @click="uploadProof"
        class="absolute bottom-8 right-0"
      >
        {{ $tm('invest.proof.confirm') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import useRules from '@/assets/js/rules'
import FileUpload from 'vue-upload-component'
import GpButton from '@/components/general/GpButton'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
export default {
  props: {
    investment: Number,
    hours: Number
  },
  components: {
    FileUpload,
    GpButton
  },
  setup(props) {
    const data = ref({ name: '', surname: '' })
    const upload = ref(null)
    const files = ref([])
    const xParent = ref(0)
    const yParent = ref(0)
    const i18n = useI18n()
    const router = useRouter()
    const valid = ref(false)
    const error = ref(null)
    const store = useStore()
    const validTypes = ref([
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf'
    ])
    let isDragging = ref(false)
    const form = ref(null)

    const { isRequired } = useRules()

    const cursorCircle = computed(() => {
      return `left: ${xParent.value}px ; top: ${yParent.value}px;`
    })
    const loading = computed(() => {
      return store.state.general.loading
    })

    function uploadProof() {
      loading.value = true
      store.dispatch('setLoading', true)
      store
        .dispatch('uploadProof', {
          proof: files.value[0].file,
          investment_id: props.investment
        })
        .then(() => {
          const popup = {
            title: i18n.t('invest.proof.success.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            content: i18n.t('invest.proof.success.description'),
            primaryButton: i18n.t('invest.proof.success.btn')
          }
          store.dispatch('setPopup', popup)
          router.push({name: 'dashboard'})
          store.dispatch('setLoading', false)
        })
        .catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('setLoading', false)
        })
    }

    function validImg(file) {
      if (file) {
        if (!validTypes.value.includes(file.type)) {
          error.value = i18n.t('errors.file.type')
          return false
        }
        if (file.size > 1024 * 1024 * 7) {
          error.value = i18n.t('errors.file.max', { max: '7MB' })
          return false
        }
        if (file.size < 1024 * 3) {
          error.value = i18n.t('errors.file.min', { min: '3kb' })
          return false
        }
        return true
      }
      return false
    }

    function formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      return size.toString() + ' B'
    }

    function handleMobileUpload(e) {
      if (e.extra_fields) {
        if (files.value.length === 2) {
          files.value.shift()
        }
        files.value.push({
          file: e.image,
          size: e.image.size,
          name: e.extra_fields.name,
          type: e.extra_fields.type
        })
        tryEmit()
      }
    }

    function addFile() {
      document.getElementById('file1').click()
    }

    function moveCursor(e) {
      var proR = e.target.getBoundingClientRect()

      setTimeout(() => {
        xParent.value = e.clientX - proR.left - 35
        yParent.value = e.clientY - proR.top - 35
      }, 0)
    }

    function inputFile(newFile) {
      if (!newFile) return
      else files.value.push(newFile)
    }

    function detectDrag(value) {
      isDragging.value = value
    }

    async function tryEmit() {
      let validFiles = true

      if (!files.value.length) {
        validFiles = false
      }

      files.value.forEach((file) => {
        if (!validImg(file)) {
          validFiles = false
        }
      })

      if (validFiles) {
        valid.value = true
      } else {
        valid.value = false
      }
    }

    watch(
      () => files.value,
      async () => {
        detectDrag(false)
        tryEmit()
      },
      { deep: true }
    )

    return {
      data,
      cursorCircle,
      files,
      inputFile,
      uploadProof,
      addFile,
      form,
      detectDrag,
      formatSize,
      validImg,
      isRequired,
      loading,
      error,
      moveCursor,
      handleMobileUpload,
      isDragging,
      upload,
      valid
    }
  }
}
</script>

<style scoped>
.steps {
  padding-bottom: max(10rem, 0%);
  min-height: calc(100vh - 16rem);
  height: initial;
}

@media (max-width: 768px) {
  .steps {
    min-height: calc(100vh);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .steps {
    min-height: calc(100vh - 5rem);
  }
}
</style>
