<template>
  <div
    class="steps"
  >
    <img
      class="display w-20 lg:hidden"
      src="@/assets/img/plant-vase.png"
      alt="plant vase"
    />
    <h2 class="text-2xl text-grey-2 font-medium mb-7 lg:mb-6 lg:hidden">{{$t('auto_investment.setup.title_sidebar_1')}} {{	$t('auto_investment.self')}} {{	$t('auto_investment.setup.title_sidebar_2')}}</h2>
    <p class="text-sm lg:text-xl text-grey-2 font-bold mb-7 lg:mb-6">{{$t('auto_investment.setup.first_step.title')}}</p>
    <p class="text-sm text-grey-2 text-opacity-80 text-left mb-4 lg:mb-8">{{$t('auto_investment.setup.first_step.text')}}</p>
    <a @click="openPopup" class="orange-link">
      {{$t('auto_investment.setup.first_step.link')}}
    </a>
    <div class="hidden">
      <div class="text-left" id="popup-content-auto-investment-details">
        <p class="font-bold text-sm text-grey-3 mb-4">{{$t('auto_investment.setup.first_step.title')}}</p>
        <p class="text-sm text-grey-3 text-opacity-60 mb-4">
          {{$t('auto_investment.setup.first_step.popup.text_1') }}
        </p>
        <p class="font-bold text-sm text-grey-3 mb-4">
          {{ $t('auto_investment.setup.first_step.popup.how_it_works') }}
        </p>
        <p class="text-sm text-grey-3 text-opacity-60 mb-4">
          {{ $t('auto_investment.setup.first_step.popup.text_2') }}
        </p>

        <div class="indented-custom-bullet">
          <ul>
            <li
              v-for="item in $tm('auto_investment.setup.first_step.popup.list')"
              v-bind:key="item"
              class="mb-3 pl-4 text-sm text-grey-3"
            >
              {{ item }}
            </li>
          </ul>
        </div>

        <div class="text-sm text-grey-3 text-opacity-60">
          <p>{{ $t('auto_investment.setup.first_step.popup.text_3') }}</p>
        </div>
      </div>
    </div>
    <GpButton
      mobileWidth="w-4/5"
      class="absolute bottom-20 lg:bottom-8 lg:right-24 mx-auto lg:m-0"
      @click="next"
    >
      {{$t('auto_investment.setup.title_sidebar_1')}} {{	$t('auto_investment.self')}}
    </GpButton>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    GpButton
  },
  setup(props, { emit }) {
    const store = useStore()

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: true,
        content: document.getElementById('popup-content-auto-investment-details')
          .outerHTML
      }
      store.dispatch('setPopup', popup)
	  }

    async function next() {
      emit('next')
    }

    return {
      openPopup,
      next
    }
  }
}
</script>