import { useI18n } from 'vue-i18n'
export default function () {
  const types = () => {
    return {
      topup: {
        icon: 'topup',
        failed_icon: 'topup',
        isGreen: true,
        hasProjectName: false
      },
      project_investment: {
        icon: 'investment',
        failed_icon: 'investment',
        isGreen: false,
        hasProjectName: true
      },
      waiting_list_investment: {
        icon: 'investment',
        failed_icon: 'investment',
        isGreen: false,
        hasProjectName: true
      },
      payment_received: {
        icon: 'payment',
        failed_icon: 'payment',
        isGreen: true,
        hasProjectName: true
      },
      // promotor dashboard
      payment: {
        icon: 'payment',
        failed_icon: 'payment',
        isGreen: false,
        hasProjectName: false
      },
      withdrawal: {
        icon: 'withdraw',
        failed_icon: 'topup',
        isGreen: false,
        hasProjectName: false
      },
      refund: {
        icon: 'refund',
        failed_icon: 'investment',
        isGreen: true,
        hasProjectName: false
      },
      early_settlement: {
        icon: 'payment',
        failed_icon: 'payment',
        isGreen: true,
        hasProjectName: true
      },
      // promotor dashboard
      project_investment_received: {
        icon: 'payment',
        failed_icon: 'payment',
        isGreen: true,
        hasProjectName: false
      },
      marketplace_sale: {
        icon: 'mkt_sale',
        failed_icon: 'mkt',
        isGreen: true,
        hasProjectName: true
      },
      Voucher: {
        icon: 'voucher',
        failed_icon: 'voucher',
        isGreen: true,
        hasProjectName: false
      },
      voucher: {
        icon: 'voucher',
        failed_icon: 'voucher',
        isGreen: true,
        hasProjectName: false
      },
      giftcard: {
        icon: 'giftcard',
        failed_icon: 'giftcard',
        isGreen: true,
        hasProjectName: false
      },
      purchase: {
        icon: 'giftcard',
        failed_icon: 'giftcard',
        isGreen: false,
        hasProjectName: false
      },
      marketplace_investment: {
        icon: 'mkt_purchase',
        failed_icon: 'mkt',
        isGreen: false,
        hasProjectName: true
      },
      autoinvestment: {
        icon: 'investment',
        failed_icon: 'investment',
        isGreen: false,
        hasProjectName: true
      },
      default_interest: {
        icon: 'payment',
        failed_icon: 'payment',
        isGreen: true,
        hasProjectName: true
      },
      saving_investment: {
        icon: 'investment',
        failed_icon: 'investment',
        isGreen: false,
        hasProjectName: true
      },
      // promoter dashboard
      failed_amortization_comission: {
        icon: 'comission',
        failed_icon: 'payment',
        isGreen: false,
        hasProjectName: true
      },
      partial_interest: {
        icon: 'payment',
        failed_icon: 'payment',
        isGreen: true,
        hasProjectName: true
      },
      // promoter dashboard
      fund_release: {
        icon: 'payment',
        failed_icon: 'payment',
        isGreen: true,
        hasProjectName: false
      },
      // promoter dashboard
      direct_debit_failed_comission: {
        icon: 'comission',
        failed_icon: 'payment',
        isGreen: false,
        hasProjectName: true
      }
    }
  }

  const isGiftcard = (transaction) => {
    if (!transaction|| !transaction.type) return false

    if (transaction.type.toLowerCase() === 'voucher') {
      if (
        transaction.transfer_voucher &&
        transaction.transfer_voucher.giftcard
      ) {
        return true
      } else return false
    } else return false
  }

  const isReferral = (transaction) => {
    if (!transaction|| !transaction.type) return false

    if (transaction.type.toLowerCase() === 'voucher') {
      if (
        transaction.transfer_voucher &&
        transaction.transfer_voucher.referral
      ) {
        return true
      } else return false
    } else return false
  }

  const isGreen = (transaction) => {
    if (!transaction || !transaction.type || transaction.type === 'empty') return false

    if (types()[transaction.type].isGreen) return true
    else return false
  }

  const title = (transaction) => {
    if (!transaction) return false

    const i18n = useI18n({ useScope: 'global' })
    return isGiftcard(transaction)
      ? i18n.t('transactions.types.giftcard')
      : isReferral(transaction)
      ? i18n.t('transactions.types.referral')
      : i18n.t(`transactions.types.${transaction.type}`)
  }

  const icon = (transaction) => {
    if (!transaction || !transaction.type || transaction.type === 'empty') return false

    return isGiftcard(transaction)
      ? types().giftcard.icon
      : types()[transaction.type].icon
  }

  const failed_icon = (transaction) => {
    if (!transaction || !transaction.type || transaction.type === 'empty') return false

    return isGiftcard(transaction)
      ? types().giftcard.failed_icon
      : types()[transaction.type].failed_icon
  }

  const isVoucher = (transaction) => {
    if (!transaction || !transaction.type) return false
    if (transaction.type.toLowerCase() === 'voucher') {
      return true
    } else return false
  }

  const description = (transaction) => {
    const i18n = useI18n({ useScope: 'global' })
    if (transaction.type === 'withdrawal') {
      return i18n.t('transactions.types.descriptions.transfer')
    } else if (transaction.type === 'topup') {
      if (transaction.description.toLowerCase().replaceAll(' ', '') === 'creditcard')
        return i18n.t('transactions.types.descriptions.credit_card')
      // legacy?
      else if (transaction.description.toLowerCase().replaceAll(' ', '') === 'directdebitpayment')
        return i18n.t('transactions.types.descriptions.direct_debit')
      // legacy?
      else if (transaction.description.toLowerCase().replaceAll(' ', '') === 'bankwire')
        return i18n.t('transactions.types.descriptions.transfer')
      else if (transaction.extra_information)
        return i18n.t('transactions.types.descriptions.transfer')
      else return transaction.description
    } else if (transaction.type === 'empty') {
      return i18n.t('transactions.types.descriptions.empty')
    } else if (transaction.type.toLowerCase()  === 'voucher') {
      if (transaction.transfer_voucher) {
        return transaction.transfer_voucher.code
      }
      else return transaction.description
    } else if (types()[transaction.type].hasProjectName)
      return transaction.description
    else {
      return ''
    }
  }

  return {
    types,
    isGiftcard,
    isGreen,
    title,
    icon,
    failed_icon,
    isVoucher,
    isReferral,
    description
  }
}
