<template>
  <div
    class="
      w-screen
      h-screen
      max-w-screen max-h-screen
      md:w-screen-2/3 md:h-screen-1/2
      lg:h-screen-3/4 lg:w-screen-2/5
      bg-red-200
      p-10
      fixed
      top-0
      left-0
      md:top-1/2 md:left-1/2
      transform
      md:-translate-x-1/2 md:-translate-y-1/2 md:rounded-5xl
      scale-0
      transition-transform
      z-60
    "
    :class="'bg-' + impactPopup.bg + ' ' + (impactShowPopup ? 'scale-100' : '')"
    :style="share ? 'position:relative; transform: scale(100%); left:0' : ''"
  >
    <img
      :src="require('@/assets/svg/impact/' + impactPopup.key + '.png')"
      alt=""
      class="float-right w-1/2"
      v-if="impactPopup && impactPopup.key"
    />
    <img
      src="@/assets/svg/cross-white.svg"
      alt=""
      class="absolute right-10 top-10 cursor-pointer"
      @click="hidePopup"
      v-if="!share && impactPopup.dark"
    />
    <img
      src="@/assets/svg/cross.svg"
      alt=""
      class="absolute right-10 top-10 cursor-pointer"
      @click="hidePopup"
      v-if="!share && !impactPopup.dark"
    />
    <div
      class="text-left absolute transform left-10 mr-10"
      :class="share ? 'bottom-20' : 'bottom-28'"
    >
      <div
        class="text-4xl font-bold mb-2"
        :class="impactPopup.dark ? 'text-white' : 'text-grey-1'"
      >
        {{ impactPopup.value ? Math.round(impactPopup.value) : 0 }}
        {{ impactPopup.suffix }}
      </div>
      <div
        class="text-xl font-semibold mb-4 w-3/4"
        :class="impactPopup.dark ? 'text-white' : 'text-grey-1'"
      >
        {{ impactPopup.longTitle }}
      </div>
      <!--div
        class="text-sm font-medium mb-6"
        :class="impactPopup.dark ? 'text-white' : 'text-grey-2'"
      >
        #{{ impactPopup.rank }} out of
        {{ impactPopup.totalRank }}
      </div-->
      <div
        class="text-13"
        :class="impactPopup.dark ? 'text-white' : 'text-grey-3'"
      >
        {{ impactPopup.description }}
        <a :href="impactPopup.learn_more" target="_blank">
          {{ $t('wording.learn_more') }}
        </a>
      </div>
    </div>

    <gp-button
      color="grey"
      class="
        absolute
        left-0
        md:left-1/2 md:-translate-x-1/2
        bottom-8
        transform
        mx-10
        md:mx-auto
        w-fit
        popup-button
      "
      mobileWidth="w-1/2"
      v-if="!share"
      @click="shareImage"
    >
      Share on social
    </gp-button>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import GpButton from '@/components/general/GpButton'
export default {
  props: {
    share: {
      type: Boolean,
      default: false
    }
  },
  emits: ['share'],
  components: { GpButton },
  setup(props, { emit }) {
    const store = useStore()

    const impactPopup = computed(() => {
      return store.state.general.impactPopup
    })
    const impactShowPopup = computed(() => {
      return store.state.general.impactShowPopup
    })

    function shareImage() {
      emit('share')
    }

    function hidePopup() {
      store.dispatch('setImpactShowPopup', false)
    }

    return { impactPopup, impactShowPopup, hidePopup, shareImage }
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .popup-button {
    width: -moz-available; /* WebKit-based browsers will ignore this. */
  }
}
</style>
