<template>
  <div
    v-if="showPopup && !permanentlyClose"
    class="bg-white rounded-2xl w-full flex flex-col justify-between p-4"
  >
    <div class="flex justify-between">
      <div class="flex">
        <div class="flex items-start w-8 mr-1 flex-shrink-0">
          <img class="w-6 md:w-full" :src="icon" alt="strategy" />
        </div>
        <div>
          <p class="text-left text-grey-2 text-sm font-semibold mb-2">
            {{ $t(copy + '.title') }}
          </p>
          <p class="text-left text-grey-2 text-sm text-opacity-50">
            <slot name="content">{{ $t(copy + '.content') }}</slot>
          </p>
        </div>
      </div>
      <div
        v-if="closeableTimes > 0"
        class="flex items-start w-8 cursor-pointer"
        @click="closePopup"
      >
        <img class="w-full" src="@/assets/svg/cross.svg" alt="close" />
      </div>
    </div>
    <router-link :id="'gp-link-strategy-popup-' + $t(copy + '.btn').toLowerCase().replace(/\s+/g, '') + '-9'" :to="'/' + btnLink" class="orange-link text-right text-13">{{
      $t(copy + '.btn')
    }}</router-link>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    closeableTimes: {
      type: Number,
      default: 3
    },
    copy: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    btnLink: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const showPopup = ref(true)
    const closePopup = () => {
      showPopup.value = false
      let closedTimes = parseInt(localStorage['close-popup-' + props.name])
      if (closedTimes) {
        closedTimes = closedTimes + 1
      } else {
        closedTimes = 1
      }
      localStorage['close-popup-' + props.name] = closedTimes
    }

    const permanentlyClose = computed(() => {
      let closedTimes = parseInt(localStorage['close-popup-' + props.name])
      if (!closedTimes) return false
      if (closedTimes < props.closeableTimes) {
        return false
      } else {
        return true
      }
    })

    return {
      showPopup,
      closePopup,
      permanentlyClose
    }
  }
}
</script>

<style lang="scss" scoped></style>
