<template>
  <Suspense>
    <template #default>
      <ProjectUpdatesAsync />
    </template>
    <template #fallback>
      <LoadingLegacy />
    </template>
  </Suspense>
</template>

<script>
import ProjectUpdatesAsync from '@/components/projects/UpdatesAsync.vue'
import LoadingLegacy from '@/components/general/LoadingLegacy'
export default {
  components: {
    LoadingLegacy,
    ProjectUpdatesAsync
  },
  setup() {}
}
</script>
