<template>
  <div class="page flex flex-col items-end">
    <SideBar v-if="!isEdit">
      <FlowProgress step="3.1" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      v-if="!isEdit"
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <Form
      v-if="!changed"
      :class="
        !isEdit
          ? 'strategies__desktop-container justify-between'
          : 'container flex-grow flex flex-col justify-between lg:pt-20'
      "
      ref="form"
      v-slot="{ errors, meta }"
      @submit="() => {}"
    >
      <div :class="{ 'lg:w-3/4 xl:w-2/3 2xl:w-1/2 lg:mx-auto': !isEdit }">
        <div class="mt-2 mb-4">
          <Back
            :to="isEdit ? -1 : '/strategies/why-saving'"
            :title="
              isEdit
                ? $t('strategies.edit.direct_debit.title')
                : $t('strategies.setup.direct_debit.title')
            "
          />
        </div>
        <ProgressBar class="lg:hidden" v-if="!isEdit" :step="3" :total="7" />
        <p class="hidden lg:block text-grey-2 text-opacity-80 text-left">
          {{ $t('strategies.setup.direct_debit.description') }}
        </p>
        <GeneralInput
          noMargin
          class="mt-6 lg:mt-8"
          v-model:data="amount"
          type="dollar"
          name="amount"
          :label="$t('strategies.setup.direct_debit.amount_placeholder')"
          :error="errors.amount"
          :rules="'required|minValue:2500'"
        />
        <p class="text-sm text-grey-2 text-opacity-60 text-left mt-2 lg:mt-3">
          {{ $t('strategies.setup.direct_debit.amount_description') }}
        </p>
        <GeneralInput
          class="mt-8"
          v-model:data="day"
          type="day"
          name="day"
          :periodicity="
            $t('strategies.setup.direct_debit.daypicker.periodicity')
          "
          :label="$t('strategies.setup.direct_debit.daypicker.placeholder')"
          :modalTitle="$t('strategies.setup.direct_debit.daypicker.title')"
          :modalText="$t('strategies.setup.direct_debit.daypicker.text')"
        />
        <GeneralInput
          class="mt-5"
          v-model:data="endDate"
          type="date"
          name="date"
          optional
          :toDate="false"
          :fromDate="new Date()"
          :label="$t('strategies.setup.direct_debit.enddate_placeholder')"
        />
        <p class="text-sm text-grey-2 text-opacity-60 text-left lg:mt-3">
          {{ $t('strategies.setup.direct_debit.enddate_description') }}
        </p>
      </div>
      <div
        v-if="isEdit"
        class="lg:flex lg:justify-end"
        :class="{ 'lg:w-3/4 xl:w-2/3 2xl:w-1/2 lg:mx-auto': !isEdit }"
      >
        <GpButton
          class="ml-auto"
          :disabled="!anyFilled || !meta.valid"
          @click="confirm"
        >
          {{ $t('wording.change') }}
        </GpButton>
      </div>
      <div
        v-else
        class="lg:flex lg:justify-end"
        :class="{ 'lg:w-3/4 xl:w-2/3 2xl:w-1/2 lg:mx-auto mt-10': !isEdit }"
      >
        <GpButton
          :disabled="!allFilled || !meta.valid"
          @click="selectConditions"
        >
          {{ $t('wording.next') }}
        </GpButton>
      </div>
    </Form>
    <ChangesConfirmation
      v-else
      @discard="discard"
      :displayData="displayData"
      :data="confirmationData"
      :text="$t('strategies.edit.direct_debit.text')"
      icon="dd"
      :strategyId="strategy.id"
    />
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GpButton from '@/components/general/GpButton.vue'
import SideBar from '@/components/general/SideBar.vue'
import GeneralInput from '@/components/general/Input.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import { Form } from 'vee-validate'
import FlowProgress from '@/components/general/FlowProgress.vue'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useFlows from '@/mixins/useFlows.js'
import ChangesConfirmation from '@/components/strategies/ChangesConfirmation.vue'

export default {
  components: {
    Back,
    GeneralInput,
    ProgressBar,
    ChangesConfirmation,
    GpButton,
    FlowProgress,
    SideBar,
    Form
  },
  setup() {
    const i18n = useI18n({ useScope: 'global' })
    const amount = ref(null)
    const day = ref(null)
    const endDate = ref(null)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isEdit = ref(route.query.edit)
    const changed = ref(false)
    const confirmationData = ref({})
    const displayData = ref([])
    const { dateFormatting, dollarFormatting } = useGlobalHelpers()

    const { noNewStrategy } = useFlows()

    const strategy = computed(() => {
      return store.state.strategies.strategy
    })

    if (!isEdit.value) noNewStrategy()
    else {
      if (!strategy.value) {
        router.go(-1)
      }
    }

    onMounted(() => {
      if(store.state.strategies && store.state.strategies.strategy) {
        amount.value = dollarFormatting(store.state.strategies.strategy.monthly_amount)
        day.value = store.state.strategies.strategy.month_day
        if(store.state.strategies.strategy.end_date) endDate.value = store.state.strategies.strategy.end_date
      }
    })

    const selectConditions = () => {
      let params = {
        amount: parseInt(amount.value.replace(/\D/g, '')),
        day_of_month: day.value,
      }
      if (endDate.value) params.end_date = dateFormatting(endDate.value)

      store.dispatch('updateNewStrategy', params)
      router.push('/strategies/investment')
    }

    const allFilled = computed(() => {
      return amount.value && day.value
    })

    const anyFilled = computed(() => {
      return amount.value || day.value || endDate.value
    })

    const confirm = () => {
      if (amount.value) {
        confirmationData.value['monthly_amount'] = parseInt(amount.value) * 100
        displayData.value.push({
          label: i18n.t('strategies.edit.direct_debit.monthly_amount'),
          value: '$' + parseInt(amount.value) 
        })
      }

      if (day.value) {
        confirmationData.value['month_day'] = day.value
        displayData.value.push({
          label: i18n.t('strategies.edit.direct_debit.month_day'),
          value:
            day.value +
            ' ' +
            i18n.t('strategies.setup.direct_debit.daypicker.periodicity')
        })
      }

      if (endDate.value) {
        confirmationData.value['end_date'] = dateFormatting(endDate.value)
        displayData.value.push({
          label: i18n.t('strategies.edit.direct_debit.end_date'),
          value: dateFormatting(endDate.value)
        })
      }

      changed.value = true
    }

    const discard = () => {
      changed.value = false
      confirmationData.value = {}
      displayData.value = []
      amount.value = day.value = endDate.value = null
    }

    return {
      day,
      endDate,
      amount,
      selectConditions,
      allFilled,
      isEdit,
      changed,
      confirm,
      displayData,
      confirmationData,
      discard,
      anyFilled,
      strategy
    }
  }
}
</script>

<style lang="scss" scoped></style>
